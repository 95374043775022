import styles from './StatusTag.module.scss'

const stateNames: { [index: string]: React.ReactNode } = {
  ing: <span className={styles.onInspection}>검수중</span>,
  ok: <span className={styles.onApproved}>승인</span>,
  fail: <span className={styles.onRejected}>반려</span>,
}
type StatusTagFunction = {
  status?: 'ing' | 'ok' | 'fail'
}
export const StatusTag: React.FC<StatusTagFunction> = ({ status }) => !status ? null : stateNames[status]
