import { useState } from 'react'
import { Form, Radio, Input, Button } from 'antd'
import { toast } from 'react-toastify'
import { AIInspectionHistory } from '@biz/api'
import { Star } from './Star'
import { CheckGroup } from './CheckGroup'
import { StatusTag } from './StatusTag'
import { useAdminMemoMutation } from './useAdminMemoMutation'
import styles from './AdminNote.module.scss'

type AdminConfirmType = {
  adminInspStatus: 'ing' | 'ok' | 'fail'
  adminInspDiv: string // (text,img,movie,slide 조합)
  adminMemo: string
}
type AdminNoteFunction = {
  aiInspectionId: number
  value: AIInspectionHistory
  onItemChange?: () => void
}
export const AdminNote: React.FC<AdminNoteFunction> = ({ value, aiInspectionId, onItemChange }) => {
  const initialValues = {
    adminInspStatus: value.adminInspStatus,
    adminInspDiv: value.adminInspDiv,
    adminMemo: value.adminMemo,
  }
  const initialString = JSON.stringify(initialValues)
  const [form] = Form.useForm()
  const [isDisabled, setIsDisabled] = useState(true)
  const doValuesChange = async () => {
    setIsDisabled(JSON.stringify(form.getFieldsValue()) == initialString)
  }
  const mutation = useAdminMemoMutation()
  const doFinish = (values: AdminConfirmType) => {
    // 기존 코드와 같은 방식으로 인터렉션하게 했지만, 차후에는 필드에 아래 바로 표시하는 것으로 바꾸면 좋겠다.
    if (!values.adminMemo) {
      toast.error('검수 결과 사유를 입력해 주신 후 다시 시도해 주세요.')
      return
    }

    if (!values.adminInspStatus || !values.adminInspDiv) {
      toast.error('검수 결과와 검수 소재 유형을 선택하신 후 다시 시도해 주세요.')
      return
    }
    mutation
      .mutateAsync({
        ...values,
        creationDataGptHisId: aiInspectionId,
      })
      .then(() => {
        if (!onItemChange) {
          return
        }
        onItemChange()
        form.resetFields()
      })
  }
  return (
    <Form
      form={form}
      onValuesChange={doValuesChange}
      onFinish={doFinish}
      initialValues={initialValues}
      className={styles.containAdminNote}
    >
      <h3 className={styles.formTitle}>
        {'관리자 검수'}
        <StatusTag status={initialValues.adminInspStatus} />
      </h3>
      <Form.Item label={<Star>검수 결과</Star>} name="adminInspStatus" colon={false}>
        <Radio.Group
          className={styles.radioGroup}
          options={[
            { value: 'ing', label: '검수중' },
            { value: 'ok', label: '승인' },
            { value: 'fail', label: '반려' },
          ]}
        />
      </Form.Item>
      <Form.Item label={<Star>검수 유형</Star>} name="adminInspDiv" colon={false}>
        <CheckGroup
          options={[
            { value: 'text', label: '텍스트' },
            { value: 'img', label: '이미지' },
            { value: 'movie', label: '영상' },
            { value: 'slide', label: '슬라이드' },
          ]}
        />
      </Form.Item>
      <Form.Item
        label={<Star>검수 사유</Star>}
        name="adminMemo"
        rules={[{ max: 100 }]}
        colon={false}
        style={{ marginBottom: 48 }}
      >
        <Input.TextArea
          showCount
          placeholder="해당 소재를 검수한 결과 및 사유를 입력해 주세요."
          maxLength={100}
          autoSize={{ minRows: 4 }}
        />
      </Form.Item>
      <div className={styles.buttonContainer}>
        <Button type="primary" htmlType="submit" disabled={isDisabled}>
          {'검수 내용 저장'}
        </Button>
      </div>
    </Form>
  )
}
