import React, {
  forwardRef,
  Fragment, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query' // try next react-query;
import { useNavigate } from 'react-router';
import { PlainObject } from 'select2';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import {mediaFbPage} from "./MngMdFbAdTab";

const MngMdFb = (props : any, ref : any) => {
  const [inputs, setInputs] = useState({
    snsToken: '',
    snsAppId: '',
    snsSecretAppId: '',
  });

  const snsToken = useRef({
    snsToken: '',
    snsAppId: '',
    snsSecretAppId: '',
  });

  const [objectStoreUrls, setObjectStoreUrls] = useState({
    android: '',
    ios: '',
  });

  const { snsToken: snsTokenVal, snsAppId: snsAppIdVal, snsSecretAppId: snsSecretAppIdVal } = inputs;
  const { android: androidVal, ios: iosVal } = objectStoreUrls;

  const adAccountSelect = useRef<HTMLSelectElement>(null);
  const adsPixelSelect = useRef<HTMLSelectElement>(null);
  const adAppSelect = useRef<HTMLSelectElement>(null);

  const [errorChk, isError] = useState({
    adAccount: false,
    pixel: false,
    app: false,
    android: false,
    ios: false,
  });
  const navigate = useNavigate();

  const chkToken = () => {
    ApiUtil2.get(`/api/mngMd/chkToken?snsToken=${inputs.snsToken}&snsAppId=${inputs.snsAppId}&snsSecretAppId=${inputs.snsSecretAppId}`)
      .then((response) => {
        if (response.data.ok) {
          const { data } = response.data;
          snsToken.current.snsToken = inputs.snsToken;
          snsToken.current.snsAppId = inputs.snsAppId;
          snsToken.current.snsSecretAppId = inputs.snsSecretAppId;
          props.setTokenReg(true);

          if (typeof data.adAccounts !== 'undefined' && data.adAccounts !== null) {
            for (let i = 0; i < data.adAccounts.length; i += 1) {
              $(adAccountSelect.current as PlainObject).append(new Option(data.adAccounts[i].name, data.adAccounts[i].id, true, true));
            }
          }

          if (typeof data.adsPixels !== 'undefined' && data.adsPixels !== null) {
            for (let i = 0; i < data.adsPixels.length; i += 1) {
              $(adsPixelSelect.current as PlainObject).append(new Option(data.adsPixels[i].name, data.adsPixels[i].id, true, true));
            }
          }

          if (typeof data.apps !== 'undefined' && data.apps !== null) {
            for (let i = 0; i < data.apps.length; i += 1) {
              $(adAppSelect.current as PlainObject).append(new Option(data.apps[i].name, data.apps[i].id, true, true));
            }
          }

          $(adAccountSelect.current as PlainObject).val('').trigger('change');
          $(adsPixelSelect.current as PlainObject).val('').trigger('change');
          $(adAppSelect.current as PlainObject).val('').trigger('change');
          props.insertAdPageList(data.pages);
          props.getAdsVolumes(data.adsVolumes);
          props.insertInstagramAccountList(data.instagramAccounts);
        } else {
          alertify.error('해당 정보로 정상적인 API 사용이 불가합니다.<br/>기입 값을 확인 후 다시 진행해 주세요.');
        }
      });
  };
  const txtChange = (e: any) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };
  const urlChange = (e: any) => {
    const { name, value } = e.target;
    setObjectStoreUrls({ ...objectStoreUrls, [name]: value.length > 100 ? value.substring(0, 99) + value.substring(100, 101) : value });
  };
  const removeApi = () => {
    alertify.confirm('광고 노출 매체를 변경 하시겠습니까?<br/>단, 변경 시 기존 기입 정보는 초기화 됩니다.', () => {
      snsToken.current.snsToken = '';
      snsToken.current.snsAppId = '';
      snsToken.current.snsSecretAppId = '';
      setInputs({
        snsToken: '',
        snsAppId: '',
        snsSecretAppId: '',
      });
      setObjectStoreUrls({
        android: '',
        ios: '',
      });
      props.setTokenReg(false);
      props.setAdAccountSelect(false);
      props.setPixelSelect(false);

      $(adAccountSelect.current as PlainObject).empty().trigger('change');
      $(adsPixelSelect.current as PlainObject).empty().trigger('change');
      $(adAppSelect.current as PlainObject).empty().trigger('change');
      props.insertAdPageList([]);
      props.insertInstagramAccountList([]);
      props.insertCustomAudienceList([]);
      props.setMediaFbPageList([]);
    }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
  };
  useEffect(() => {
    $(adAccountSelect.current as PlainObject).select2({
      width: '330px',
      placeholder: '광고를 집행할 계정을 선택해 주세요.',
    }).on('select2:select', (e) => {
      ApiUtil2.get(`/api/mngMd/getFbCustomAudiences?snsToken=${snsToken.current.snsToken}&snsSecretAppId=${snsToken.current.snsSecretAppId}&adAccountId=${e.params.data.id}`)
        .then((response) => {
          props.setAdAccountSelect(true);
          props.insertCustomAudienceList(response.data.data);
        });

      ApiUtil2.get(`/api/mngMd/getFbCustomConversions?snsToken=${snsToken.current.snsToken}&snsSecretAppId=${snsToken.current.snsSecretAppId}&adAccountId=${e.params.data.id}`)
        .then((response) => {
          props.insertCustomConversionList(response.data.data);
        });
    });

    $(adsPixelSelect.current as PlainObject).select2({
      width: '330px',
      placeholder: '광고를 전환할 픽셀을 선택해 주세요.',
    }).on('select2:select', () => {
      props.setPixelSelect(true);
    });

    $(adAppSelect.current as PlainObject).select2({
      width: '330px',
      placeholder: '광고를 전환할 앱을 선택해 주세요.',
    });
    $('#androidTooltip').tooltip({
      content: '<p class="fz-12 fc-0">전환 추적을 하실 앱이 등록된 구글 플레이 주소를<br/>입력해 주세요. 전환 이벤트 위치를 ‘웹사이트/앱＇으로 선택하신 세트가 있으신 경우, Android 혹은 iOS 중<br/>하나의 어플리케이션 주소는 필수로 입력해 주셔야<br/>합니다.</p>',
    });
    $('#iosTooltip').tooltip({
      content: '<p class="fz-12 fc-0">전환 추적을 하실 앱이 등록된 앱스토어 주소를<br/>입력해 주세요. 전환 이벤트 위치를 ‘웹사이트/앱＇으로 선택하신 세트가 있으신 경우 Android 혹은 iOS 중<br/>하나의 어플리케이션 주소는 필수로 입력해 주셔야<br/>합니다.</p>',
    });
  }, []);
  useEffect(() => {
    $(adAccountSelect.current as PlainObject).select2('destroy');
    $(adsPixelSelect.current as PlainObject).select2('destroy');
    $(adAppSelect.current as PlainObject).select2('destroy');
    $(adAccountSelect.current as PlainObject).select2({
      width: '330px',
      placeholder: '광고를 집행할 계정을 선택해 주세요.',
    });

    $(adsPixelSelect.current as PlainObject).select2({
      width: '330px',
      placeholder: '광고를 전환할 픽셀을 선택해 주세요.',
    });

    $(adAppSelect.current as PlainObject).select2({
      width: '330px',
      placeholder: '광고를 전환할 앱을 선택해 주세요.',
    });
  }, [errorChk]);
  const mediaFbReg = useMutation({
    mutationFn: (formData: any) => ApiUtil2.post('/api/mngMdFb/mediaFbReg', formData),
    onSuccess: (resp) => {
      if (resp.data.ok) {
        props.setSubmit(true)
        alertify.success('정상적으로 등록되었습니다.', 2, () => {
          navigate('/mngMd/mngMd')
        })
      }
    },
  })
  const mediaFbRegSubmit = (fbAdSetForm : any) => {
    if (props.project === null || props.project === '') {
      $(props.regProjectSelect.current as PlainObject).addClass('error');
    } else {
      $(props.regProjectSelect.current as PlainObject).removeClass('error');
    }
    let isDestinationApp = false;
    _.forEach(fbAdSetForm.fbAdSets, (v: any) => {
      if (v.destinationType === 'WEBSITE_APP') {
        isDestinationApp = true;
      }
    });
    isError(() => ({
      adAccount: $(adAccountSelect.current as PlainObject).val() === null,
      pixel: $(adsPixelSelect.current as PlainObject).val() === null,
      app: $(adAppSelect.current as PlainObject).val() === null,
      android: isDestinationApp && androidVal === '' && iosVal === '',
      ios: isDestinationApp && androidVal === '' && iosVal === '',
    }));

    let isAdSetFormValid = true;
    let isMediaFbPageValid = true;
    for (let i = 0; i < 3; i += 1) {
      if (fbAdSetForm.fbAdSets[i].convDomain === '') {
        isAdSetFormValid = false;
        break;
      }

      if (props.mediaFbPageList.filter((v: mediaFbPage) => v.sortOrder == i+1 && v.useYn).length <= 0) {
        isMediaFbPageValid = false;
        break;
      }
    }


    if (props.project === null || props.project === '' || $(adAppSelect.current as PlainObject).val() === null || $(adAccountSelect.current as PlainObject).val() === null || $(adsPixelSelect.current as PlainObject).val() === null || !isAdSetFormValid) {
      alertify.error('선택하지 않으신 필수 항목이 있습니다.<br/>확인 후 다시 시도해 주세요.');
    } else if (isDestinationApp && androidVal === '' && iosVal === '') {
      alertify.error('전환 이벤트 위치를 웹사이트/앱으로<br/>설정하신 경우,<br/>앱의 스토어 주소는 Android or iOS 중<br/>최소 1개 이상 등록해 주셔야 합니다.');
    } else if(!isMediaFbPageValid){
      alertify.error('현재 메타 광고에 필요한 페이지가 등록되어 있지 않습니다. \n' +
        '광고 노출 페이지 항목의 \'페이지 관리\' 버튼을 통해 페이지를 등록해 주시기 바랍니다.');
    } else {
      const adAccountSelectId = $(adAccountSelect.current as PlainObject).attr('id');
      const mediaAccNm = $(`#${adAccountSelectId} option:selected`).text();
      const formData = {
        mediaDiv2: props.md,
        projectDiv3: props.project,
        mediaCatDiv: props.category,
        mediaAccNm,
        snsToken: snsToken.current,
        snsAdId: $(adAccountSelect.current as PlainObject).val(),
        snsPixel: $(adsPixelSelect.current as PlainObject).val(),
        snsApp: $(adAppSelect.current as PlainObject).val(),
        android: androidVal,
        ios: iosVal,
        fbAdCategory: fbAdSetForm.fbAdCategory,
        fbAdSets: fbAdSetForm.fbAdSets,
        mediaFbPages: props.mediaFbPageList,
      };
      mediaFbReg.mutate(formData);
    }
  };
  useImperativeHandle(ref, () => ({
    mediaFbRegSubmit,
  }));

  return (
    <Fragment>
      { !props.isTokenReg === true
        ? <Fragment>
          <dl className="vertical">
            <dt>
              <div className="dt-inner">
                <span className="fz-16 fc-1">
                  사용 API 정보 등록
                  <i className="fz-12 fc-5">*</i>
                </span>
              </div>
            </dt>
            <dd>
              <div className="form-group">
                <span className="comp-txt w-100">
                  <span className="table">
                    <span className="table-cell">
                      <b className="fz-14 fc-2">토큰</b>
                      </span>
                  </span>
                </span>
                <div className="input-group expand">
                  <div className="inner-input-group">
                    <input type="text" name="snsToken" className="tf-comm" placeholder="API 이용 토큰을 입력해 주세요." value={snsTokenVal} onChange={txtChange}/>
                  </div>
                  <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                </div>
              </div>
              <div className="form-group">
                <span className="comp-txt w-100">
                  <span className="table">
                    <span className="table-cell">
                      <b className="fz-14 fc-2">앱ID</b>
                      </span>
                  </span>
                </span>
                <div className="input-group expand">
                  <div className="inner-input-group">
                    <input type="text" name="snsAppId" className="tf-comm" placeholder="검수 받은 앱 ID를 입력해 주세요." value={snsAppIdVal} onChange={txtChange}/>
                  </div>
                  <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                </div>
              </div>
              <div className="form-group">
                <span className="comp-txt w-100">
                  <span className="table">
                    <span className="table-cell">
                      <b className="fz-14 fc-2">시크릿 앱ID</b>
                      </span>
                  </span>
                </span>
                <div className="input-group expand">
                  <div className="inner-input-group">
                    <input type="text" name="snsSecretAppId" className="tf-comm" placeholder="시크릿 앱 ID를 입력해 주세요." value={snsSecretAppIdVal} onChange={txtChange}/>
                  </div>
                  <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                </div>
              </div>
              <div className="form-group txt-right">
                <button type="button" className="btn btn-primary" onClick={chkToken}>조회</button>
              </div>
            </dd>
          </dl>
        </Fragment>
        : <Fragment>
            <dl className="vertical">
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">
                    사용 API 정보 등록
                    <i className="fz-12 fc-5">*</i>
                  </span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <span className="comp-txt">
                    <span className="table">
                      <span className="table-cell">
                        <b className="fz-14 fc-2">
                          정상적으로 API 정보를 저장하였습니다.
                        </b>
                      </span>
                    </span>
                  </span>
                </div>
                <div className="form-group">
                  <button type="button" className="btn btn-primary" onClick={removeApi}>다른 API 정보 등록 하기</button>
                </div>
              </dd>
            </dl>
        </Fragment>
      }
      <dl className="vertical">
        <dt>
          <div className="dt-inner">
            <span className="fz-16 fc-1">
              광고 집행 계정
              <i className="fz-12 fc-5">*</i>
            </span>
          </div>
        </dt>
        <dd>
          <div className="form-group">
            <select id="adAccountSelect" className={`select2-single expand ${errorChk.adAccount ? 'error' : ''}`} ref={adAccountSelect} disabled={!props.isTokenReg}>
            </select>
          </div>
        </dd>
      </dl>
      <dl className="vertical">
        <dt>
          <div className="dt-inner">
            <span className="fz-16 fc-1">
              픽셀
              <i className="fz-12 fc-5">*</i>
            </span>
          </div>
        </dt>
        <dd>
          <div className="form-group">
            <select className={`select2-single expand ${errorChk.pixel ? 'error' : ''}`} ref={adsPixelSelect} disabled={!props.isAdAccountSelect}>
            </select>
          </div>
        </dd>
      </dl>
      <dl className="vertical">
        <dt>
          <div className="dt-inner">
            <span className="fz-16 fc-1">
              앱
              <i className="fz-12 fc-5">*</i>
            </span>
          </div>
        </dt>
        <dd>
          <div className="form-group">
            <select className={`select2-single expand ${errorChk.app ? 'error' : ''}`} ref={adAppSelect} disabled={!props.isAdAccountSelect}>
            </select>
          </div>
        </dd>
      </dl>
      <dl className="vertical">
        <dt>
          <div className="dt-inner">
            <span className="fz-16 fc-1">
              Android 구글플레이 주소
              <Link to="#" id="androidTooltip" className="ico-tooltip" title=""></Link>
            </span>
          </div>
        </dt>
        <dd>
          <div className="form-group">
            <div className={`input-group expand ${errorChk.android ? 'negative' : ''}`}>
              <div className="inner-input-group">
                <input type="text" name="android" value={androidVal} className="tf-comm" placeholder="선택하신 앱의 구글 플레이 주소를 입력해 주세요." disabled={!props.isAdAccountSelect} onChange={urlChange}/>
              </div>
            </div>
          </div>
        </dd>
      </dl>
      <dl className="vertical">
        <dt>
          <div className="dt-inner">
            <span className="fz-16 fc-1">
              iOS 앱스토어 주소
              <Link to="#" id="iosTooltip" className="ico-tooltip" title=""></Link>
            </span>
          </div>
        </dt>
        <dd>
          <div className="form-group">
            <div className={`input-group expand ${errorChk.ios ? 'negative' : ''}`}>
              <div className="inner-input-group">
                <input type="text" name="ios" value={iosVal} className="tf-comm" placeholder="선택하신 앱의 앱스토어 주소를 입력해 주세요." disabled={!props.isAdAccountSelect} onChange={urlChange}/>
              </div>
            </div>
          </div>
        </dd>
      </dl>
    </Fragment>
  );
};

export default forwardRef(MngMdFb);
