import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query' // try next react-query;
import { ApiUtil } from '@biz/api'
import alertify from 'alertifyjs'
import { ApiUtil2 } from '@biz/api'
import { CkEditor5 } from '@biz/ui'
import CategorySelectBox from './CategorySelectBox';

export interface IFaq {
    title: string | undefined;
    text: string | undefined;
    faqDiv: any;
    sortOrder: string | undefined | number;
    useYn: any;
}
const FaqPost = () => {
  const setTitle = useOutletContext<any>();
  const sortOrderList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [faqDivList, setFaqDivList] = useState<string[][]>([]);
  const navigate = useNavigate();
  const { faqId } = useParams();
  const {
    register, handleSubmit, setValue, watch,
  } = useForm<IFaq>();

  const setText = (data:any) => {
    setValue('text', data);
  };
  const {
    title, text, faqDiv, sortOrder, useYn,
  } = watch();
  const formData = {
    title, text, faqDiv, sortOrder, useYn,
  };
  const modifyFormData = {
    ...formData, faqId,
  };

  const getPrevData = async () => {
    const { data } = await ApiUtil2.get<IFaq>('/api/community/faq/detail', { params: { data: { faqId } } });
    return data;
  };
  const faqDetailData = useQuery({ queryKey: ['faqDetail', faqId], queryFn: getPrevData, enabled: false })

  const setPrevData = () => {
    faqDetailData.refetch().then((data:any) => {
      if (data.isSuccess) {
        const faq = data.data.data;
        setValue('useYn', faq.useYn.toString());
        setValue('sortOrder', faq.sortOrder);
        $('#sortOrderSelect').val(faq.sortOrder).trigger('change');
        setValue('faqDiv', faq.faqDiv);
        $('#categorySelect').val(faq.faqDiv).trigger('change');
        setValue('title', faq.title);
        setValue('text', faq.text);
      }
    });
  };

  useEffect(() => {
    setTitle('FAQ 관리');
    if (faqId !== undefined) {
      setPrevData();
    } else {
      /** 초기값 설정 */
      setValue('useYn', 'true');
      setValue('sortOrder', 1);
      setValue('faqDiv', 'REG_AD');
      setValue('text', '');
    }

    $('#sortOrderSelect').select2(
      { width: 'auto', minimumResultsForSearch: Infinity },
    ).on('select2:select', (e) => setValue('sortOrder', e.params.data.id));

    $('#categorySelect').select2(
      { width: 'auto', minimumResultsForSearch: Infinity },
    ).on('select2:select', (e) => {
      setValue('faqDiv', e.params.data.id);
    });

    ApiUtil.get('/api/community/faq/getFaqDivs').then((resp) => {
      const options = Object.keys(resp.data.data).map((key) => [String(key), resp.data.data[key]]);
      setFaqDivList(options);
    });

    return () => {
      // 달력관련 모든걸 remove 해줘야 한다 한페이지에서 동작하는거라 이거 안해주면 달력 관련 소스가 계속 늘어남
      $('.datepicker-single').remove();
      setTitle('');
    };
  }, []);

  const cancelHandler = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    navigate('/community/faq');
  };

  const onValid = (error:any) => {
    if (error.title !== undefined) {
      if (error.title.message !== '') {
        alertify.error(error.title.message);
      }
    }
  };

  const onSubmit = () => {
    if (faqId !== undefined) {
      ApiUtil2.post('/api/community/faq/modify', modifyFormData).then(() => {
        navigate('/community/faq');
      });
    } else {
      ApiUtil2.post('/api/community/faq/post', formData).then(() => {
        navigate('/community/faq');
      });
    }
  };
  return (
        <>
            <section className="wrap-section wrap-tbl">
                <div className="box-header">
                    <h2 className="fz-20 fc-1 fw-bold">{faqId === undefined ? 'FAQ 작성' : 'FAQ 수정'}</h2>
                </div>
                <form onSubmit={handleSubmit(onSubmit, onValid)}>
                <div className="box-body">
                    <div className="tbl">
                        <dl className="column-two">
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        공개여부
                                    </span>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <div className="comp-radio">
                                        <input
                                            type="radio" id="inp-radio-01" checked={useYn === 'true'} value="true" {...register('useYn')}
                                        />
                                        <label htmlFor="inp-radio-01">공개</label>
                                    </div>
                                    <div className="comp-radio" >
                                        <input
                                            type="radio" id="inp-radio-02" checked={useYn === 'false'} value="false" {...register('useYn')}
                                        />
                                        <label htmlFor="inp-radio-02">미공개</label>
                                    </div>
                                </div>
                            </dd>
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                       정렬순위
                                    </span>
                                </div>
                            </dt>
                            <dd>
                                <div className='form-group'>
                                    <select className="select2-single" id="sortOrderSelect" value={sortOrder}>
                                        {sortOrderList.map((k) => <option key={k} value={k}>{k}</option>)}
                                    </select>
                                </div>
                            </dd>
                        </dl>
                        <dl className='column-two'>
                            <dt>
                                <div className='dt-inner'>
                                    <span className='fz-16 fc-1'>
                                        구분
                                    </span>
                                </div>
                            </dt>
                            <dd>
                                <div className='form-group'>
                                    <CategorySelectBox options={faqDivList} defaultValue={faqDiv}/>
                                </div>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        제목
                                    </span>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <div className="input-group expand">
                                        <div className="inner-input-group">
                                            <input className="tf-comm" {...register('title', { required: { value: true, message: '제목을 입력하세요.' } })} placeholder="제목"/>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        내용
                                    </span>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <CkEditor5
                                        setText={setText}
                                        defaultValue={text}
                                    />
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="box-footer">
                    <div className="box-right">
                        <button className="btn btn-secondary-outline" onClick={cancelHandler}>취소</button>
                        <button type="submit" className="btn btn-primary">{faqId === undefined ? '저장' : '수정'}</button>
                    </div>
                </div>
                </form>
            </section>
        </>
  );
};

export default FaqPost;
