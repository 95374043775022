import alertify from 'alertifyjs'
const checkRatio = (width: number, height: number, isSlideOnly: boolean) => {
  const ratio = Math.round((width / height) * 100) / 100
  if (isSlideOnly && ratio !== 1) {
    alertify.error('슬라이드 이미지는 1:1 규격의 이미지/영상 첨부만 가능합니다.')
    return false
  }
  // biz-center/src/pages/TargetCreateLayout/legacy/fileUploader/FileFbUtil.ts 를 따름
  // https://wadiz.atlassian.net/browse/BIZ-395?focusedCommentId=164991&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-164991
  const validRatios = [1 / 1, 4 / 5]
  const tRatio1: number = 1.91
  const tRatio2 = 9 / 16

  if (
    !validRatios.includes(ratio) &&
    Math.abs(ratio - tRatio1) > 0.03 &&
    ratio !== tRatio1 &&
    Math.abs(ratio - tRatio2) > 0.03 &&
    ratio !== tRatio2
  ) {
    alertify.error('1:1, 9:16, 4:5, 1.91:1 규격의 이미지/영상 첨부만 가능합니다.')
    return false
  }
  return true
}

const checkFileSizeSpec = (size: number) => (file: File) => {
  const MAX_SIZE = size * 1024 * 1024
  if (file.size > MAX_SIZE) {
    alertify.error('업로드 가능한 용량을 초과하였습니다.\n' + `${size}MB 이내`)
    return false
  }
  return true
}

const checkImageResolution = (width: number, height: number) => {
  // 최소 사이즈: 500 * 500 픽셀
  // 최대 사이즈: 9000 * 9000 픽셀
  if (width < 500 || 9000 < width || height < 500 || 9000 < height) {
    alertify.error('권장하는 이미지 해상도가 아닙니다.\n' + '최소 500픽셀에서 최대 9000픽셀, 권장 사이즈 1080')
    return false
  }
  return true
}

const checkVideoResolution = (width: number, height: number, duration: number) => {
  // 해상도 최소 너비: 600 픽셀
  if (width < 600 || height < 600) {
    alertify.error('권장하는 비디오 해상도가 아닙니다.\n' + '최소 600픽셀을 넘어야 합니다.')
    return false
  }

  // 최대 길이: 60분
  const MAX_DURATION = 60 * 60
  if (duration > MAX_DURATION) {
    alertify.error('권장하는 비디오 길이가 아닙니다.\n' + '최대 60분을 넘을 수 없습니다.')
    return false
  }
  return true
}

type CheckedImageSpec = Partial<{
  width: number
  height: number
  duration: number
}> | null
type GenerateFunctionType = (isSlideOnly: boolean) => (file: File) => Promise<CheckedImageSpec>
export const checkBeforeUpload: GenerateFunctionType = (isSlideOnly) => (file) =>
  new Promise((resolve, reject) => {
    if (file.name.length > 99) {
      alertify.error('파일 이름이 너무 깁니다.\n' + '100 글자를 넘지않게 해주세요.')
      reject(null)
      return
    }
    if (file.type.startsWith('image/')) {
      const img = new Image()
      img.src = URL.createObjectURL(file)
      img.onload = () => {
        if (
          checkRatio(img.width, img.height, isSlideOnly) &&
          checkImageResolution(img.width, img.height) &&
          checkFileSizeSpec(30)(file)
        ) {
          resolve({ width: img.width, height: img.height })
        } else {
          reject(null)
        }
      }
    }
    if (file.type.startsWith('video/')) {
      const video = document.createElement('video')
      video.src = URL.createObjectURL(file)
      video.onloadedmetadata = () => {
        if (
          checkRatio(video.videoWidth, video.videoHeight, isSlideOnly) &&
          checkFileSizeSpec(200)(file) &&
          checkVideoResolution(video.videoWidth, video.videoHeight, video.duration)
        ) {
          resolve({ width: video.videoWidth, height: video.videoHeight, duration: video.duration })
        } else {
          reject(null)
        }
      }
    }
  })
