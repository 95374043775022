import { useState } from 'react'
import { Modal, Button } from 'antd'
import alertify from 'alertifyjs'
import { type ImageMetaDataType } from '@biz/query'
import { ImageList } from './ImageList'
import { PreviewPanel } from './PreviewPanel'
import { MediaUploader } from './MediaUploader'
import { CreationTipMessageBox } from './CreationTipMessageBox'
import styles from './ImagePickerModal.module.scss'

type ImagePickerModalFunction = {
  isOpen: boolean
  isSlideImageOnly: boolean
  onClose: () => void
  onConfirm: (data: ImageMetaDataType) => void
}
export const ImagePickerModal: React.FC<ImagePickerModalFunction> = ({
  isOpen,
  isSlideImageOnly,
  onClose,
  onConfirm,
}) => {
  const [selectedImage, setSelectedImage] = useState<ImageMetaDataType | null>(null)
  const doSelect = (img: ImageMetaDataType) => {
    if (!isSlideImageOnly) {
      setSelectedImage(img)
      return
    }
    const { width, height } = img.afMeta || { width: 0, height: 1 }
    if (width === height) {
      setSelectedImage(img)
      return
    }
    alertify.error('슬라이드 이미지는 1:1 규격의 이미지/영상 첨부만 가능합니다.')
  }
  return (
    <Modal
      width={1200}
      open={isOpen}
      closable={false}
      title={null}
      footer={[
        <div className={styles.modalFooter} key={'footer'}>
          <Button type="default" block size="large" onClick={onClose} style={{ width: 200 }}>
            {'취소'}
          </Button>
          <Button
            type="primary"
            block
            size="large"
            onClick={() => {
              if (!!selectedImage) {
                onConfirm(selectedImage)
              }
            }}
            style={{ width: 200 }}
            disabled={selectedImage?.isFetching}
          >
            {'제출'}
          </Button>
        </div>,
      ]}
      className={styles.ImagePickerModal}
    >
      <div className={styles.customBody}>
        <ImageList onSelect={doSelect} />
        <section className={styles.previewPanel}>
          <CreationTipMessageBox />
          <PreviewPanel mediaData={selectedImage} />
          <MediaUploader isSlideOnly={isSlideImageOnly} value={selectedImage} onChange={setSelectedImage} />
        </section>
      </div>
    </Modal>
  )
}
