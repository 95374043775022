import { useCallback } from 'react'
import { Button } from 'antd'
import { ReactComponent as DownloadIcon } from '@common/svg/download.svg'
import styles from './AIInspectionListDownloadButton.module.scss'

type AIInspectionListDownloadButtonFunction = {
  filter: string | null
}
export const AIInspectionListDownloadButton: React.FC<AIInspectionListDownloadButtonFunction> = ({ filter }) => {
  const doDownload = useCallback(() => {
    if (filter !== null) {
      const url = `/api/mngAd/gptHis/list/download?${filter}`
      window.location.assign(url)
    }
  }, [filter])
  return (
    <div className={styles.containAIInspectionListDownloadButton}>
      <Button className={styles.button} icon={<DownloadIcon />} disabled={!Boolean(filter)} onClick={doDownload}>
        다운로드
      </Button>
    </div>
  )
}
