import axios from 'axios'
import Cookies from 'universal-cookie'

export interface ResponseType<T> {
  result: T
  message: string
}

const instance = axios.create({
  baseURL: process.env.VITE ? import.meta.env.VITE_AUTH_API : '',
  timeout: 50000,
})

// 요청 인터셉터 추가하기
instance.interceptors.request.use(
  function (config) {
    // 요청이 전달되기 전에 작업 수행
    return config
  },
  function (error) {
    // 요청 오류가 있는 작업 수행
    return Promise.reject(error)
  }
)

// 응답 인터셉터 추가하기
instance.interceptors.response.use(
  function (response) {
    // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    // 응답 데이터가 있는 작업 수행
    return response.data
  },
  function (err) {
    // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    // 응답 오류가 있는 작업 수행
    return Promise.reject(err)
  }
)

export { instance as axiosAuth }
