import { Form } from 'antd'
import { ItemLabel } from '../ItemLabel'
import { SelectWrapper } from '../SelectWrapper'

const AdminConfirmName = 'adminConfirm'
export const AdminConfirm: React.FC & {
  key: string
  serialize: (value: any) => string
} = () => {
  return (
    <Form.Item name={AdminConfirmName} label={<ItemLabel>관리자 확인 여부</ItemLabel>} layout="vertical">
      <SelectWrapper
        options={[
          { value: 'all', label: '전체' },
          { value: 'true', label: '확인 완료' },
          { value: 'false', label: '미확인' },
        ]}
      />
    </Form.Item>
  )
}
AdminConfirm.key = AdminConfirmName
AdminConfirm.serialize = (values: any) => {
  const { [AdminConfirmName]: value } = values
  return value === 'all' ? '' : value
}
