import { Button, Form } from 'antd'
import { useCreativeDataQuery, useCampaignStatus } from '@biz/query'
import { targetFormRegistry } from '@biz/ui'
import styles from './ToolBox.module.scss'

type ToolBoxProps = {
  onAttachDocument?: () => void
  disabledNext?: boolean
  onNext?: () => void
  onPrev?: () => void
}
const { ProjectSelectorName } = targetFormRegistry
export const ToolBox: React.FC<ToolBoxProps> = ({ onAttachDocument, onNext, onPrev }) => {
  const { isBeforeInspection } = useCampaignStatus()
  const { data: assets } = useCreativeDataQuery()
  return (
    <Form.Item dependencies={[ProjectSelectorName]} style={{ padding: 0, margin: 0 }}>
      {({ getFieldValue }) => {
        const projectId = getFieldValue(ProjectSelectorName)
        const inspectionDisabled =
          !projectId ||
          !assets ||
          assets.length < 1 ||
          !assets[0].afList ||
          assets[0].afList.length < 1 ||
          (assets.length > 1 && assets.reduce((acc, cur) => acc || !cur.afList || cur.afList.length < 1, false))

        return (
          <div className={styles.toolBox}>
            <div className={styles.buttonGroup}>
              <Button className={styles.campaignList} href={'/mngCamp/mngCamp'}>
                {'캠페인 목록'}
              </Button>

              {isBeforeInspection?.() && (
                <Button htmlType="submit" type="primary" className={styles.inspection} disabled={inspectionDisabled}>
                  {'검수 요청'}
                </Button>
              )}

              {onAttachDocument && (
                <Button type="default" className={styles.attachDocument} onClick={onAttachDocument}>
                  {'증빙 서류 첨부'}
                </Button>
              )}
            </div>
            <div className={styles.buttonGroup}>
              {!!onPrev && (
                <Button type="primary" className={styles.prev} disabled={!projectId} onClick={onPrev}>
                  {'이전'}
                </Button>
              )}
              {!!onNext && (
                <Button type="primary" className={styles.next} disabled={!projectId} onClick={onNext}>
                  {'다음'}
                </Button>
              )}
            </div>
          </div>
        )
      }}
    </Form.Item>
  )
}
