import React, {
  Fragment, useContext, useEffect, useRef,
} from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query' // try next react-query;
import dayjs from 'dayjs';
import alertify from 'alertifyjs'
import { ApiUtil2 } from '@biz/api'
import { AdminIdContext } from './MngAdminAccount';

export interface IAdminAccountRes {
    loginId: string,
    useYn: boolean,
    adminRoleId: number,
    memberName: string,
    hp: string,
    adminExpireDate: string
    exRole01: string
}

export interface IAdminAccountReq {
    id: string,
    loginId: string,
    pwd: string,
    pwdChk: string,
    useYn: boolean,
    adminRoleId: string,
    memberName: string,
    hp: string,
    adminExpireDate: string
    lastLoginTime: string,
}

const AdminAccountRegPopUP = (props: any) => {
  const { adminId, setAdminId } = useContext(AdminIdContext);
  const getAdminAccountDetail = async () => {
    const { data } = await ApiUtil2.get<IAdminAccountRes>('/api/mngAccount/admin/detail', { params: { data: { adminId } } });
    return data;
  };
    const adminAccountInfo = useQuery({
      queryKey: ['adminAccountInfo', adminId],
      queryFn: getAdminAccountDetail,
      enabled: false,
    })

  const adminExpireDateYn = useRef<boolean>(false);
  const useYn = useRef<any>(true);
  const useShadow = useRef<any>(true);
  const { setValue, watch } = useForm<IAdminAccountReq>();

  const {
    id, loginId, pwd, pwdChk, adminRoleId, memberName, hp, adminExpireDate,
  } = watch();

  const formData = {
    id, loginId, pwd, pwdChk, useYn, adminRoleId, memberName, hp, adminExpireDate, useShadow,
  };

  /** 리액트 문법 하나도 안 먹어서 제이쿼리 문법 처리 함. */
  useEffect(() => {
    if (adminId !== null) {
      adminAccountInfo.refetch().then((data:any) => {
        if (data.isSuccess) {
          const accountInfo = data.data.data;
          $('#id').val(accountInfo.loginId);
          $('#memberName').val(accountInfo.memberName);
          $('#hp').val(accountInfo.hp);
          $('#adminRoleSelect').val(accountInfo.adminRoleId).trigger('change');
          useYn.current = accountInfo.useYn;
          if (accountInfo.adminExpireDate === '') {
            $('#adminExpireDate').val(dayjs().startOf('day').add(30, 'day').format('YYYY-MM-DD') as any);
            $('#adminExpireDate').attr('disabled', 'disabled');
          } else {
            const formattedDate = `${accountInfo.adminExpireDate.substring(0, 4)}-${accountInfo.adminExpireDate.substring(4, 6)}-${accountInfo.adminExpireDate.substring(6, 8)}`;
            adminExpireDateYn.current = true;
            $('#adminExpireDate').removeAttr('disabled');
            $('#datepickerUseYn').removeClass('disabled');
            $('#adminExpireDate').val(formattedDate);
          }
          if (accountInfo.exRole01 === 'ROLE_SHADOW') {
            useShadow.current = true;
          } else {
            useShadow.current = false;
          }
        }
      });
    } else {
      $('#adminExpireDate').attr('disabled', 'disabled');
    }
    /** 다이얼로그 */
    $('#adminAccountRegPopUp').dialog({
      title: adminId === null ? '관리자 계정 등록' : '관리자 계정 정보 수정', closeOnEscape: true, width: 900,
    });

    $(document).on('click', '#cancelBtn', () => {
      setAdminId(null);
      $('#adminAccountRegPopUp').dialog('close');
      props.setIsClicked(false);
      $('#adminAccountRegPopUp').dialog('destroy');
    });

    /** 데이트피커 */
    $('.datepicker-single').daterangepicker({
      startDate: dayjs().startOf('day').add(30, 'day'),
      minDate: dayjs().startOf('day').add(1, 'day'),
      singleDatePicker: true,
      locale: {
        format: 'YYYY-MM-DD',
      },
    }).on('change', () => {
      const date = $('#adminExpireDate').val() as string;
      setValue('adminExpireDate', date);
    });

    /** 셀렉2 */
    $('#adminRoleSelect').select2(
      { width: '300', minimumResultsForSearch: Infinity },
    ).on('select2:select', (e) => {
      setValue('adminRoleId', e.params.data.id);
    });

    /** 초기값 */
    // const today = new Date();
    // const dd = String(today.getDate()).padStart(2, '0');
    // const mm = String(today.getMonth() + 2).padStart(2, '0');
    // const yyyy = today.getFullYear();
    // const date = `${yyyy}-${mm}-${dd}`;
    setValue('adminExpireDate', $('#adminExpireDate').val() as any);
    setValue('adminRoleId', props.adminRoles[0].id);

    /** 아이디 */
    $('#id').on('keyup', () => {
      const value = $('#id').val() as string;
      if (value.length > 10) {
        $('#id').val(value.substring(0, 10));
      }
    });

    /** 패스워드 */
    $('#pwd').on('keyup', () => {
      const value = $('#pwd').val() as string;
      if (value.length > 30) {
        $('#pwd').val(value.substring(0, 30));
      }
    });

    /** 패스워드 확인 */
    $('#pwdChk').on('keyup', () => {
      const value = $('#pwdChk').val() as string;
      if (value.length > 30) {
        $('#pwdChk').val(value.substring(0, 30));
      }
    });

    /** 담당자명 */
    $('#memberName').on('keyup', () => {
      const value = $('#memberName').val() as string;
      if (value.length > 10) {
        $('#memberName').val(value.substring(0, 10));
      }
    });

    /** 휴대 전화번호 */
    $('#hp').on('keyup', (e:any) => {
      const value = $('#hp').val() as string;
      if (e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40) {
        return;
      }
      if (/[^0-9]/g.test(value)) {
        $('#hp').val(value.substring(0, value.length - 1));
      }
      if (value.length > 11) {
        $('#hp').val(value.substring(0, 11));
      }
    });

    /** 상태 */
    $('[name=useYnInPopUP]').on('click', (e: any) => {
      useYn.current = e.currentTarget.value === 'true';
    });

    /** 유효기간 설정 여부 */
    $('[name=adminExpireDateYn]').on('click', (e: any) => {
      adminExpireDateYn.current = e.currentTarget.value === 'true';
      if (e.currentTarget.value === 'true') {
        $('#datepickerUseYn').removeClass('disabled');
        $('#adminExpireDate').removeAttr('disabled');
      } else {
        $('#datepickerUseYn').addClass('disabled');
        $('#adminExpireDate').attr('disabled', 'disabled');
      }
    });

    /** 쉐도우 허용 설정 */
    $('[name=useShadowYn]').on('click', (e: any) => {
      useShadow.current = e.currentTarget.value === 'true';
    });

    /** 등록 */
    $('#reg').on('click', () => {
      formData.id = adminId;
      formData.loginId = $('#id').val() as string;
      formData.pwd = $('#pwd').val() as string;
      formData.pwdChk = $('#pwdChk').val() as string;
      formData.memberName = $('#memberName').val() as string;
      formData.hp = $('#hp').val() as string;
      formData.adminRoleId = $('#adminRoleSelect').val() as string;
      formData.useYn = useYn.current;
      formData.useShadow = useShadow.current;

      console.log('formData > ', formData);

      if (formData.loginId === '' || formData.memberName === '' || formData.hp === '') {
        alertify.error(`기입하지 않은 항목이 있습니다. \n
        확인 후 다시 시도해 주세요.`);
        return;
      }

      if (formData.pwdChk !== '') {
        if (formData.pwd === '') {
          alertify.error(`기입하지 않은 항목이 있습니다. \n
        확인 후 다시 시도해 주세요.`);
          return;
        }
      }

      if (formData.pwd !== formData.pwdChk) {
        alertify.error('PW와 PW 재확인의 기입 문자가 다릅니다.\n'
                + '확인 후 다시 시도해 주세요.\n');
      }
      if (adminId === null) { // 등록
        formData.adminExpireDate = adminExpireDateYn.current === true ? $('#adminExpireDate').val() as string : '';
        if (formData.pwd === '' || formData.pwdChk === '') {
          alertify.error(`기입하지 않은 항목이 있습니다. \n
        확인 후 다시 시도해 주세요.`);
          return;
        }
        ApiUtil2.post<IAdminAccountReq>('/api/mngAccount/admin/reg', formData).then(() => {
          alertify.success('정상적으로 등록 되었습니다.');
          $('#adminAccountRegPopUp').dialog('close');
          props.setIsClicked('false');
          props.onGridReady();
        });
      } else { // 수정
        formData.adminExpireDate = adminExpireDateYn.current === true ? $('#adminExpireDate').val() as string : '';
        ApiUtil2.post<IAdminAccountReq>('/api/mngAccount/admin/modify', formData).then(() => {
          alertify.success('정상적으로 수정 되었습니다.');
          $('#adminAccountRegPopUp').dialog('close');
          props.setIsClicked('false');
          props.onGridReady();
        });
      }
    });

    return () => {
      setAdminId(null);
      $('#adminAccountRegPopUp').dialog('destroy');
      $('.datepicker-single').remove();
    };
  }, []);

  return (
        <Fragment>
            <form>
                <div id='adminAccountRegPopUp' className="dialog" style={{ display: 'none' }}>
                    <div className="dialog-body">
                        <div className="wrap-section wrap-tbl">
                            <div className="box-body">
                                <div className="tbl">
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    ID
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <input type="text" className="tf-comm"
                                                               placeholder="ID를 입력해 주세요."
                                                               id="id" disabled={adminId !== null}/>
                                                    </div>
                                                    <p className="fz-12 fc-4">영문, 숫자, 특수기호로 3~10자를 입력해 주세요.</p>
                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    PW
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <input type="password" className="tf-comm"
                                                               placeholder="PW를 입력해 주세요." id="pwd"/>
                                                    </div>
                                                    <p className="fz-12 fc-4">{adminId === null ? '영문, 숫자, 특수기호를 모두 조합하여 8자~30자를 입력해 주세요.' : '변경하실 경우 입력해 주세요.'}</p>
                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    PW 재확인
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <input type="password" className="tf-comm"
                                                               placeholder="PW를 다시 입력해 주세요." id="pwdChk"/>
                                                    </div>
                                                    <p className="fz-12 fc-4">영문, 숫자, 특수기호를 모두 조합하여 8자~30자를 입력해 주세요.</p>
                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    담당자명
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <input type="text" className="tf-comm"
                                                               placeholder="담당자명을 입력해 주세요." id="memberName"/>
                                                    </div>
                                                    <p className="fz-12 fc-4">1~10자를 입력해 주세요.</p>
                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    휴대전화번호
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <input type="text" className="tf-comm"
                                                               placeholder="‘-’없이 휴대전화 번호를 입력해주세요." id="hp"/>
                                                    </div>
                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    권한
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <select className=".select2-single" id="adminRoleSelect">
                                                    {props.adminRoles.map((role: any) => (
                                                        <option key={role.id}
                                                                value={role.id}>{role.adminRoleNm}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    상태
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="comp-radio">
                                                    <input type="radio" id="useY" name='useYnInPopUP' value={'true'}
                                                           checked={useYn.current} readOnly/>
                                                    <label htmlFor="useY">활성</label>
                                                </div>
                                                <div className="comp-radio">
                                                    <input type="radio" id="useN" value={'false'}
                                                           name='useYnInPopUP'
                                                           checked={!useYn.current} readOnly/>
                                                    <label htmlFor="useN">비활성</label>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    유효기간
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="comp-radio">
                                                    <input type="radio" id="adminExpireDateN" value={'false'}
                                                           name='adminExpireDateYn'
                                                           checked={!adminExpireDateYn.current} readOnly/>
                                                    <label htmlFor="adminExpireDateN">미설정</label>
                                                </div>
                                                <div className="comp-radio margin-right-8">
                                                    <input type="radio" id="adminExpireDateY" value={'true'}
                                                           name='adminExpireDateYn'
                                                           checked={adminExpireDateYn.current} readOnly/>
                                                    <label htmlFor="adminExpireDateY">설정</label>
                                                </div>
                                                <div className="comp-datepicker disabled" id='datepickerUseYn'>
                                                    <div className="inner-datepicker">
                                                        <i className="ico ico-calendar"></i>
                                                        <input type="text" className="tf-comm datepicker-single"
                                                               id='adminExpireDate'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    쉐도우 로그인
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="comp-radio">
                                                    <input type="radio" id="useShadowY" value={'true'}
                                                           name='useShadowYn'
                                                           checked={useShadow.current} readOnly/>
                                                    <label htmlFor="useShadowY">허용</label>
                                                </div>
                                                <div className="comp-radio margin-right-8">
                                                    <input type="radio" id="useShadowN" value={'false'}
                                                           name='useShadowYn'
                                                           checked={!useShadow.current} readOnly/>
                                                    <label htmlFor="useShadowN">비허용</label>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-secondary-outline" id={'cancelBtn'}>취소
                        </button>
                        <button type="button" className="btn btn-primary" id="reg">{adminId === null ? '등록' : '수정'}</button>
                    </div>
                </div>
            </form>
        </Fragment>
  );
};
export default React.memo(AdminAccountRegPopUP);
