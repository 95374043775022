import { Input, Form } from 'antd'
import { ReactComponent as SearchIcon } from '@common/svg/search.svg'
import { ItemLabel } from '../ItemLabel'
import { SelectWrapper } from '../SelectWrapper'
import styles from './IDSearch.module.scss'

const IDSearchFilterName = 'selectId'
const IDSearchKeywordName = 'keyword'
export const IDSearch: React.FC & {
  type: string
  keyword: string
  serialize: (values: any) => { searchType: string; searchTxt: string }
} = () => (
  <div className={styles.containIDSearch}>
    <Form.Item name={IDSearchFilterName} label={<ItemLabel>검색어</ItemLabel>} layout="vertical">
      <SelectWrapper
        options={[
          { value: 'all', label: '전체' },
          { value: 'makerName', label: '메이커명' },
          { value: 'creationDataId', label: '소재 ID' },
          { value: 'campId', label: '캠페인 ID' },
        ]}
      />
    </Form.Item>
    <Form.Item
      name={IDSearchKeywordName}
      rules={[
        {
          max: 30,
          message: '30자 이하로 입력해주세요.',
        },
      ]}
      style={{ minWidth: 300 }}
    >
      <Input className={styles.input} prefix={<SearchIcon />} placeholder="검색어를 입력해 주세요." />
    </Form.Item>
  </div>
)

IDSearch.type = IDSearchFilterName
IDSearch.keyword = IDSearchKeywordName
IDSearch.serialize = (values: any) => ({
  searchType: values[IDSearchFilterName],
  searchTxt: values[IDSearchKeywordName],
})
