import { Modal, Button, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { IDPanel } from './IDPanel'
import { InspectionList } from './InspectionList'
import styles from './AIInspectionResultModal.module.scss'

type ParamType = {
  campId: number
  creationDataId: number
  creationDataGptHisId: number
}
type AIInspectionResultModalFunction = {
  isOpen: boolean
  onClose: () => void
  data?: ParamType
  onItemChange?: () => void
}
export const AIInspectionResultModal: React.FC<AIInspectionResultModalFunction> = ({
  isOpen,
  onClose,
  data,
  onItemChange,
}) => {
  return (
    <Modal
      width={900}
      open={isOpen}
      closable={true}
      onCancel={onClose}
      centered
      title={'AI 심의 결과'}
      footer={[
        <Button type="default" block size="large" onClick={onClose} style={{ width: 200 }}>
          {'닫기'}
        </Button>,
      ]}
      classNames={{
        header: styles.modalHeader,
        content: styles.modalContent,
        body: styles.modalBody,
        footer: styles.modalFooter,
      }}
    >
      {!!data && <IDPanel campaignId={data.campId} creationDataId={data.creationDataId} onItemChange={onItemChange} />}
      <div className={styles.divider} />
      {!!data && <InspectionList creationDataId={data.creationDataId} onItemChange={onItemChange} />}
    </Modal>
  )
}
