import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useQuery, useQueryClient } from '@tanstack/react-query' // try next react-query;
import alertify from 'alertifyjs'
import { FixedSizeList as List } from 'react-window'

import { ApiUtil, ApiUtil2 } from '@biz/api'
import { useRoleQuery, useShadowLoginQuery, useMemberIdQuery, useUsableCostQuery } from '@biz/query'

import type { ShadowLoginMember } from './Header'

import styles from './ShadowLoginMemberList.module.scss'

interface ShadowLoginMemberListProps {
  isAdmin: boolean
  isShadowLoggedOut: boolean
  members: ShadowLoginMember[]
  searchText: string
  onShadowLoginSuccess: () => void
  setIsShadowLoggedOut: Dispatch<SetStateAction<boolean>>
  setMakerName: Dispatch<SetStateAction<string>>
}

export const ShadowLoginMemberList = (props: ShadowLoginMemberListProps) => {
  const { invalidate } = useUsableCostQuery()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { data: shadowLogin, update: setShadowLogin } = useShadowLoginQuery()
  const { update: setLoginMemberId } = useMemberIdQuery()
  const { update: setAuth } = useRoleQuery()
  const [memberId, setMemberId] = useState(0)
  // const { authFailRedirect } = useContext(AuthFailRedirectContext)

  const getAuthShadow = async () => {
    const { data } = await ApiUtil.get('/api/auth')
    return data
  }

  const useAuthShadowQueryResult = useQuery({ queryKey: ['getAuthShadow'], queryFn: getAuthShadow, enabled: false })

  const handleMemberClick = (event: React.MouseEvent<HTMLElement>) => {
    ApiUtil2.get<ShadowLoginMember>(`/api/shadowLogin/login?makerId=${event.currentTarget.dataset.id}`).then(
      (response) => {
        if (response.data) {
          // queryClient.invalidateQueries({ queryKey: ['curAuth']});
          // 쉐도우 로그인시 상태 여부를 체크한다.
          useAuthShadowQueryResult.refetch().then((r) => {
            if (r.isSuccess) {
              if (r.data !== undefined) {
                localStorage.removeItem('shadow')
                localStorage.removeItem('shadowRole')
                invalidate()
                alertify.alert('쉐도우 로그인', `${response.data.memberName} 메이커 계정으로 접속하였습니다.`)
                // 쉐도우 로그인 성공시 메뉴 리스트 리로딩
                props.setMakerName(response.data.memberName)
                props.setIsShadowLoggedOut(false)
                setMemberId(response.data.id)
                setLoginMemberId(response.data.id)
                localStorage.setItem('shadow', JSON.stringify(response.data))
                localStorage.setItem('shadowRole', JSON.stringify(r.data))
                $('#shadowLogoutBtn').show()
                queryClient.invalidateQueries({ queryKey: ['menus'] })
                queryClient.invalidateQueries({ queryKey: ['subMenus'] })
                if (r.data.indexOf('ROLE_USER_MAKER') >= 0) {
                  setAuth('ROLE_USER_MAKER')
                } else {
                  setAuth('ROLE_ADMIN')
                }
                if (r.data.indexOf('ROLE_ADMIN_ACTION') < 0) {
                  // 권한이 없으면
                  setShadowLogin(false)
                } else {
                  setShadowLogin(true)
                }
                // 쉐도우 로그인 완료 후에 헤더에서 사용하는 메이커 기능 로드
                props.onShadowLoginSuccess()
                navigate('/')
              }
            }
          })
        }
      }
    )
  }

  // 브라우저에서 이중으로 쉐도우 로그인이 발생했을 경우 로그인 리스트 현재 메이커 선택 변경
  const checkShadowInfo = localStorage.getItem('shadow')
  useEffect(() => {
    if (shadowLogin) {
      if (checkShadowInfo !== null) {
        const shadowInfo = JSON.parse(checkShadowInfo)
        props.setMakerName(shadowInfo.memberName)
        props.setIsShadowLoggedOut(false)
        setMemberId(shadowInfo.id)
      }
    }
  }, [checkShadowInfo])

  useEffect(() => {
    // 메이커 계정 관리에서 메이커가 쉐도우 로그인했을때 호출
    if (shadowLogin) {
      const shadow = localStorage.getItem('shadow')
      if (shadow !== null) {
        const shadowInfo = JSON.parse(shadow)
        props.setMakerName(shadowInfo.memberName)
        props.setIsShadowLoggedOut(false)
        setMemberId(shadowInfo.id)
      }
      useAuthShadowQueryResult.refetch().then((r) => {
        if (r.isSuccess) {
          if (r.data !== undefined) {
            localStorage.setItem('shadowRole', JSON.stringify(r.data))
            if (r.data.indexOf('ROLE_USER_MAKER') >= 0) {
              setAuth('ROLE_USER_MAKER')
            } else {
              setAuth('ROLE_ADMIN')
            }
            if (r.data.indexOf('ROLE_ADMIN_ACTION') < 0) {
              // 권한이 없으면
              setShadowLogin(false)
            } else {
              setShadowLogin(true)
            }
          }
        }
      })
    } else {
      setMemberId(0)
    }
  }, [shadowLogin])

  // useEffect(() => {
  //   // 권한 오류로 인한 좌측 메뉴와 헤더 부분 관련 변경 처리
  //   // ApiUtil2InterceptorSetup 에서 authFailRedirect를 변경 처리 후 메인으로 리다이렉트 한다.
  //   if (authFailRedirect) {
  //     const shadow = localStorage.getItem('shadow')
  //     // const shadowRoleInfo = localStorage.getItem('shadowRole');
  //     if (shadow === null) {
  //       localStorage.removeItem('shadow')
  //       localStorage.removeItem('shadowRole')
  //       props.setMakerName('메이커 로그인')
  //       props.setIsShadowLoggedOut(true)
  //       $('#shadowLogoutBtn').hide()
  //       setAuth('ROLE_ADMIN')
  //       setMemberId(0)
  //     } else {
  //       const shadowInfo = JSON.parse(shadow)
  //       props.setMakerName(shadowInfo.memberName)
  //       props.setIsShadowLoggedOut(false)
  //       $('#shadowLogoutBtn').show()
  //       setMemberId(shadowInfo.id)
  //       setAuth('ROLE_USER_MAKER')
  //     }
  //     $('#searchText').val('')
  //     queryClient.invalidateQueries({ queryKey: ['menus']})
  //     queryClient.invalidateQueries({ queryKey: ['subMenus']})
  //   }
  // }, [authFailRedirect])

  const filteredMembers = useMemo(() => {
    return props.members.filter((member) => member.memberName.toLowerCase().includes(props.searchText.toLowerCase()))
  }, [props.members, props.searchText])

  const ListItem = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    const member = filteredMembers[index]

    const highlightText = (text: string, searchText: string) => {
      if (!searchText) return text

      const escapedSearchText = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      const substrings = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))

      return substrings.map((part: string, index: number) =>
        part.toLowerCase() === searchText.toLowerCase() ? (
          <span key={index} className="txt-mark">
            {part}
          </span>
        ) : (
          part
        )
      )
    }

    return (
      <button className={styles.item} data-id={member.id} style={style} onClick={handleMemberClick}>
        <span>{highlightText(member.memberName, props.searchText)}</span>
      </button>
    )
  }

  return (
    <>
      {props.members.length === 0 ? (
        <li className="comp-no-data">
          <div className="box-group">
            <i className="ico ico-no-search"></i>
            <p className="fz-14 fc-2">조회된 목록이 없습니다</p>
            <p className="fz-12 fc-3">담당자에게 문의해 주세요.</p>
          </div>
        </li>
      ) : (
        <List className={styles.container} height={200} itemCount={filteredMembers.length} itemSize={40} width={300}>
          {ListItem}
        </List>
      )}
    </>
  )
}
