import { useCallback } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Form, Button } from 'antd'
import { ReactComponent as FilterIcon } from '@common/svg/filter.svg'
import { RangeWrapPicker } from './RangeWrapPicker'
import { IDSearch } from './IDSearch'
import { ReviewType } from './ReviewType'
import { CategoryFilter } from './CategoryFilter'
import { InspectionResult } from './InspectionResult'
import { AIInspectionStatus } from './AIInspectionStatus'
import { TeamReviewStatus } from './TeamReviewStatus'
import { MediaTypeFilter } from './MediaTypeFilter'
import { AdminConfirm } from './AdminConfirm'
import { useParamsSerializer } from './useParamsSerializer'

import styles from './AIInspectionFilter.module.scss'

const initialValues = {
  [RangeWrapPicker.key]: [dayjs().subtract(7, 'day'), dayjs()],
  [IDSearch.type]: 'all',
  [IDSearch.keyword]: '',
  [ReviewType.key]: 'all',
  [CategoryFilter.key]: 'all',
  [InspectionResult.key]: 'all',
  [AIInspectionStatus.key]: 'all',
  [TeamReviewStatus.key]: 'all',
  [MediaTypeFilter.key]: 'all',
  [AdminConfirm.key]: 'all',
}

type AIInspectionFilterFunction = {
  onChange: (filter: string) => void
}
export const AIInspectionFilter: React.FC<AIInspectionFilterFunction> = ({ onChange }) => {
  const [form] = Form.useForm()
  const serializer = useParamsSerializer()
  const doFinish = useCallback(
    (values: { [key: string]: number | string | [Dayjs, Dayjs] }) => {
      onChange?.(
        serializer({
          data: {
            ...IDSearch.serialize(values), // srchType, searchTxt
            ...RangeWrapPicker.serialize(values), // startDate, endDate
            inspectionMode: ReviewType.serialize(values), // aiApprove, adminApprove
            catCodes: CategoryFilter.serialize(values),
            isInspEqual: InspectionResult.serialize(values), // "true"
            gptInspDiv: AIInspectionStatus.serialize(values), // "ing"
            inspDiv: TeamReviewStatus.serialize(values), // "ok"
            adminInspDivs: MediaTypeFilter.serialize(values), // 'img,movie,slide',
            manualCheckYn: AdminConfirm.serialize(values),
          },
        })
      )
    },
    [serializer]
  )
  return (
    <Form form={form} initialValues={initialValues} onFinish={doFinish} className={styles.containAIInspectionFilter}>
      <div className={styles.filterContain}>
        <RangeWrapPicker /> {/* AI 검수 요청/완료 */}
        <IDSearch /> {/*  검색어 */}
        <ReviewType /> {/*  심의 방식 */}
        <CategoryFilter /> {/*  카테고리 */}
        <InspectionResult /> {/*  검수 결과 일치 여부 */}
        <AIInspectionStatus /> {/* AI 검수 결과 */}
        <TeamReviewStatus /> {/*  관리자 검수 결과 */}
        <MediaTypeFilter /> {/*  관리자 검수 유형 */}
        <AdminConfirm /> {/* 관리자 확인 여부 */}
      </div>
      <Button htmlType="submit" className={styles.button}>
        <FilterIcon />
        <div>필터조회</div>
      </Button>
    </Form>
  )
}
