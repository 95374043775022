import React, { Fragment, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query' // try next react-query;
import { INoticeDetail } from '../../community/notice/NoticeForAdmin';
import { ApiUtil2 } from '@biz/api'
import { ApiUtil } from '@biz/api'
import alertify from 'alertifyjs'

const NoticeDetail = () => {
  const { noticeId } = useParams();
  const navigator = useNavigate();
  const [currentFileName, setCurrentFileName] = useState<string>();
  const [currentFileSize, setCurrentFileSize] = useState<number>();
  const [currentFilePath, setCurrentFilePath] = useState<any>();
  const [isFileExist, setIsFileExist] = useState<boolean>(false);
  const getNoticeDatail = async () => {
    const { data } = await ApiUtil2.get<INoticeDetail>('/api/makerCommon/notice/detail', { params: { data: { noticeId } } });
    if (data.data.filePath !== undefined) {
      setIsFileExist(true);
      setCurrentFileName(data.data.fileName);
      setCurrentFileSize(data.data.fileSize);
      setCurrentFilePath(data.data.filePath);
    }
    return data;
  };

  const { data } = useQuery({ queryKey: ['noticeDetail', noticeId], queryFn: getNoticeDatail })

  const BoardDiv:any = () => {
    let boardDiv;
    if (data?.data.boardDiv === 'SYSTEM') boardDiv = <div className="txt-state red">시스템</div>;
    if (data?.data.boardDiv === 'EVENT') boardDiv = <div className="txt-state mint">이벤트</div>;
    if (data?.data.boardDiv === 'GUIDE') boardDiv = <div className="txt-state blue">가이드</div>;
    if (data?.data.boardDiv === 'NORMAL') boardDiv = <div className="txt-state black">일반</div>;

    return boardDiv;
  };

  const noticeFileDownHandler = async (e:any) => {
    e.preventDefault();
    const response = await fetch(currentFilePath);
    const file = await response.blob();
    ApiUtil.get('/api/common/isLogin').then((resp) => {
      if (resp.data.data === true) {
        const downloadUrl = window.URL.createObjectURL(file); // 해당 file을 가리키는 url 생성
        const anchorElement = document.createElement('a');
        document.body.appendChild(anchorElement);
        anchorElement.download = currentFileName as any; // a tag에 download 속성을 줘서 클릭할 때 다운로드가 일어날 수 있도록 하기
        anchorElement.href = downloadUrl; // href에 url 달아주기
        anchorElement.click(); // 코드 상으로 클릭을 해줘서 다운로드를 트리거
        document.body.removeChild(anchorElement); // cleanup - 쓰임을 다한 a 태그 삭제
      } else {
        alertify.error('와디즈 비즈센터 회원만 다운로드 가능합니다. ');
      }
    });
  };

  return (
      <Fragment>
          <section className="wrap-section wrap-tbl wrap-editor">
              <div className="box-body">
                  <div className="tbl">
                      <dl className="column-two">
                          <dt>
                              <div className="dt-inner">
                        <span className="fz-16 fc-1">
                           제목
                        </span>
                              </div>
                          </dt>
                          <dd>
                              <div className="form-group">
                                  <BoardDiv/>
                                  <span className="comp-txt">
                            <span className="table">
                                <span className="table-cell">
                                    <b className="fz-14 fc-2">{data?.data.title}</b>
                                </span>
                            </span>
                        </span>
                              </div>
                          </dd>
                          <dt>
                              <div className="dt-inner">
                        <span className="fz-16 fc-1">
                           등록일
                        </span>
                              </div>
                          </dt>
                          <dd>
                              <div className="form-group">
                                    <span className="comp-txt">
                            <span className="table">
                                <span className="table-cell">
                                    <b className="fz-14 fc-2">{data?.data.regTime}</b>
                                </span>
                            </span>
                        </span>
                              </div>
                          </dd>
                      </dl>
                      <dl className="vertical">
                          <dd className="mh-300">
                              <div className="form-group">
                                  <p className="comp-txt">
                            <span className="table">
                                <span className="table-cell">
                                    <b className="fz-14 fc-4" dangerouslySetInnerHTML={{ __html: data?.data.text }}/>
                                </span>
                            </span>
                                  </p>
                              </div>
                          </dd>
                      </dl>
                      { isFileExist
                        ? <dl className="vertical">
                              <dt>
                                  <div className="dt-inner">
                                        <span className="fz-16 fc-1">
                                            파일 리스트
                                        </span>
                                  </div>
                              </dt>
                              <dd>
                                  <div className="form-group">
                                      <div className="comp-file-list">
                                          <div className="file-list-top">
                                                <span className="fz-14 fc-2">첨부 파일
                                                    {/* 파일첨부 정책상 최대 1개이므로 하드코딩 유지 */}
                                                    <span className="fc-5">1</span>개</span>
                                          </div>
                                          <div className="file-list-bottom">
                                              <div className="file-item">
                                                  <i className="ico ico-file-list"></i>
                                                  <span className="fz-12 fc-2">{currentFileName}</span>
                                                  <span className="fz-12 fc-3">({Math.floor(currentFileSize! / 1024)} KB)</span>
                                                  <button onClick={noticeFileDownHandler}>
                                                      <i className="ico ico-download"></i>
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </dd>
                          </dl>
                        : null
                      }
                  </div>
              </div>
              <div className="box-footer">
                  <div className="box-left">
                      <button type="button" className="btn btn-secondary-outline"
                              onClick={() => navigator(-1)}>이전
                      </button>
                  </div>
                  <div className="box-right">
                      <button type="button" className="btn btn-primary-outline"
                              onClick={() => navigator('/makerCommon/notice')}>공지사항 목록
                      </button>
                  </div>
              </div>
          </section>
      </Fragment>
  );
};

export default React.memo(NoticeDetail);
