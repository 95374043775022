import { Fragment, useEffect, useRef } from 'react'
import alertify from 'alertifyjs'

import { ApiUtil3 } from '@biz/api'
import { useQuery } from '@tanstack/react-query'

interface IPomPermissionByServiceReq {
  campUseYn: string
  consultingUseYn: string
}

interface IPomPermissionByServiceRes {
  CAMP_POM_PERMISSION_YN: boolean
  CONSULTING_POM_PERMISSION_YN: boolean
}

export const PomPermissionByServiceDialog = (props: any) => {
  const campUseYn = useRef<any>(true)
  const consultingUseYn = useRef<any>(true)

  const formData = {
    campUseYn,
    consultingUseYn,
  }

  const getPrevData = async () => {
    const { data } = await ApiUtil3.get<IPomPermissionByServiceRes>(
      '/api/bzmAdmin/postpaidMoneyManagement/AEM/getPomPermissionByService',
      {}
    )
    return data
  }

  const serviceParamData = useQuery({ queryKey: [], queryFn: getPrevData, enabled: false })

  const setPrevData = () => {
    serviceParamData.refetch().then((resp: any) => {
      campUseYn.current = resp.data.data.CAMP_POM_PERMISSION_YN === 'true' ? 'true' : 'false'
      consultingUseYn.current = resp.data.data.CONSULTING_POM_PERMISSION_YN === 'true' ? 'true' : 'false'
    })
  }

  useEffect(() => {
    setPrevData()
    /** 다이얼로그 */
    $('#PomPermissionByServiceDialog').dialog({
      title: '서비스별 후불머니 허용 설정',
      closeOnEscape: true,
      width: 900,
    })
    $(document).on('click', '#cancelBtn', () => {
      $('#PomPermissionByServiceDialog').dialog('close')
      props.setIsDialogOpened(false)
      $('#PomPermissionByServiceDialog').dialog('destroy')
    })
    $('[name=campUseYn]').on('click', (e: any) => {
      campUseYn.current = e.currentTarget.value === 'true'
    })
    $('[name=consultingUseYn]').on('click', (e: any) => {
      consultingUseYn.current = e.currentTarget.value === 'true'
    })
    $('#reg').on('click', () => {
      formData.campUseYn = campUseYn.current
      formData.consultingUseYn = consultingUseYn.current
      alertify
        .confirm('서비스별 후불머니 허용 설정을 변경합니다.', () => {
          ApiUtil3.post<IPomPermissionByServiceReq>(
            '/api/bzmAdmin/postpaidMoneyManagement/AEM/changePomPermissionByService',
            formData
          ).then(() => {
            $('#PomPermissionByServiceDialog').dialog('close')
            props.setIsDialogOpened(false)
            $('#PomPermissionByServiceDialog').dialog('destroy')
          })
        })
        .set({ labels: { cancel: '취소', ok: '확인' } })
        .setHeader('')
    })
  }, [])

  return (
    <Fragment>
      <form>
        <div id="PomPermissionByServiceDialog" className="dialog" style={{ display: 'none' }}>
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">광고 캠페인</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="comp-radio">
                          <input
                            type="radio"
                            id="inp-radio-01"
                            checked={campUseYn.current === 'true'}
                            value="true"
                            name="campUseYn"
                            readOnly
                          />
                          <label htmlFor="inp-radio-01">허용</label>
                        </div>
                        <div className="comp-radio">
                          <input
                            type="radio"
                            id="inp-radio-02"
                            checked={campUseYn.current !== 'true'}
                            value="false"
                            name="campUseYn"
                            readOnly
                          />
                          <label htmlFor="inp-radio-02">비허용</label>
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">광고 대행</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="comp-radio">
                          <input
                            type="radio"
                            id="inp-radio-03"
                            checked={consultingUseYn.current === 'true'}
                            value="true"
                            name="consultingUseYn"
                            readOnly
                          />
                          <label htmlFor="inp-radio-03">허용</label>
                        </div>
                        <div className="comp-radio">
                          <input
                            type="radio"
                            id="inp-radio-04"
                            checked={consultingUseYn.current !== 'true'}
                            value="false"
                            name="consultingUseYn"
                            readOnly
                          />
                          <label htmlFor="inp-radio-04">비허용</label>
                        </div>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button type="button" className="btn btn-secondary-outline" id={'cancelBtn'}>
              취소
            </button>
            <button type="submit" className="btn btn-primary" id="reg">
              확인
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  )
}
