import { Form } from 'antd'
import { ItemLabel } from '../ItemLabel'
import { SelectWrapper } from '../SelectWrapper'

const InspectionResultName = 'resultName'
export const InspectionResult: React.FC & { key: string; serialize: (value: any) => string } = () => {
  return (
    <Form.Item name={InspectionResultName} label={<ItemLabel>검수 결과 일치 여부</ItemLabel>} layout="vertical">
      <SelectWrapper
        options={[
          { value: 'all', label: '전체' },
          { value: 'true', label: '일치' },
          { value: 'false', label: '불일치' },
        ]}
      />
    </Form.Item>
  )
}

InspectionResult.key = InspectionResultName
InspectionResult.serialize = (values: any) => {
  const { [InspectionResultName]: value } = values
  return value === 'all' ? '' : value
}
