import { Form } from 'antd'
import { ItemLabel } from '../ItemLabel'
import { FilterList } from '@biz/ui'
import { useCategoryQuery } from './useCategoryQuery'

const CategoryFilterName = 'category'
export const CategoryFilter: React.FC & { key: string; serialize: (value: any) => string } = () => {
  const { data, isLoading } = useCategoryQuery()
  return isLoading ? null : (
    <Form.Item name={CategoryFilterName} label={<ItemLabel>카테고리</ItemLabel>} layout="vertical">
      <FilterList
        options={
          data
            ? Object.keys(data).map((k) => ({
                value: k,
                label: data[k],
              }))
            : [{ value: 'all', label: '전체' }]
        }
      />
    </Form.Item>
  )
}

CategoryFilter.key = CategoryFilterName

CategoryFilter.serialize = (values: any) => {
  const { [CategoryFilterName]: value } = values
  return value === 'all' ? '' : value
}
