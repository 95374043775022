import { useState } from 'react'
import { Select } from 'antd'
import { ReactComponent as ChevronDownIcon } from '@common/svg/chevronDown.svg'
import styles from './SelectWrapper.module.scss'

type SelectWrapperFunction = {
  options: { value: string; label: string }[]
  className?: string
  value?: string
  onChange?: (v: string) => void
}
export const SelectWrapper: React.FC<SelectWrapperFunction> = (props) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <Select
      suffixIcon={<ChevronDownIcon className={`${styles.icon}  ${isOpen ? styles.openIcon : ''}`} />}
      onDropdownVisibleChange={setOpen}
      {...props}
      className={`${props.className} ${styles.select}`}
    />
  )
}
