import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { AgGridReact } from 'ag-grid-react'
import dayjs from 'dayjs'
import alertify from 'alertifyjs'
import { ColDef, GridOptions, ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'

import { ApiUtil, ApiUtil2, ApiUtil3, blobInstance } from '@biz/api'
import { useUsableCostQuery, useShadowLoginQuery } from '@biz/query'

import Pagination from '../views/common/Pagination'
import NoDataTemplate from '../views/common/NoDataTemplate'
import TreeGridHeightLayout from '../views/common/TreeGridHeightLayout'
import { GridCustomHeader } from '../views/common/GridCustomHeader'
import { GridCustomHeaderToolTip } from '../views/common/GridCustomHeaderTooltip'

import FbDialog from './FbDialog'
import ItgDialog from './ItgDialog'
import GdnDialog from './GdnDialog'
import LogTooltip from '../views/log/LogTooltip'

const topOptions: GridOptions = {
  alignedGrids: [],
  defaultColDef: {
    resizable: false,
    suppressMovable: true,
    flex: 1,
    minWidth: 200,
    sortable: true,
  },
}

let elemType = 'img'
const imgLoad = async (e: any) => {
  await ApiUtil3.post('/api/file/downloadReq', { fileId: e[0].id }).then((resp: any) => {
    $('.comp-thumb').empty()
    // file을 blob으로 받아와서 download
    blobInstance
      .get(resp.data.data)
      .then((blob: any) => {
        const element: any = document.createElement(elemType)
        element.alt = e.afName
        element.style.width = '100%'

        if (elemType === 'video') {
          element.type = 'video'
          element.controls = true
          element.muted = true

          const source = document.createElement('source')
          source.src = URL.createObjectURL(new Blob([blob.data]))
          element.appendChild(source)

          $('.comp-thumb').append(element)
          element.play()
        } else {
          element.src = URL.createObjectURL(new Blob([blob.data]))
          $('.comp-thumb').append(element)
        }
      })
      .then(() => {
        document.querySelector('.loader.file')!.classList.add('none')
      })
  })
}

const getAf = async (e: any) => {
  ApiUtil2.get('/api/mngCamp/mngCamp/getFiles', { params: { data: { refId: e } } }).then((resp) => {
    $('.comp-thumb').empty()
    // eslint-disable-next-line no-use-before-define
    if (resp.data.data.length !== 0) {
      document.querySelector('.loader.file')!.classList.remove('none')
      elemType =
        resp.data.data[0].afMeta.fileDiv === 'VIDEO' && resp.data.data[0].afMeta.ext !== 'gif' ? 'video' : 'img'
      imgLoad(resp.data.data).then()
    }
  })
}

const dialogOpen = (value: any) => {
  if (value.mediaDiv4 === 'FB' || value.mediaDiv4 === 'FBITG') {
    $('#fbDialog').dialog({ width: 600, title: '이미지 미리보기', closeOnEscape: true })
    $('#fbDialog').dialog('open')
  } else if (value.mediaDiv4 === 'ITG') {
    $('#itgDialog').dialog({ width: 600, title: '이미지 미리보기', closeOnEscape: true })
    $('#itgDialog').dialog('open')
  } else {
    $('#gdnDialog').dialog({ width: 600, title: '이미지 미리보기', closeOnEscape: true })
    $('#gdnDialog').dialog('open')
  }
  // 이미지
  getAf(value.creationDataId)
}

const getProjectIdCellRenderer = (props: ICellRendererParams) => {
  const { projectId } = props.data
  return projectId === undefined ? '-' : projectId
}

const getSnsIdCellRenderer = (props: ICellRendererParams) => {
  let snscampId = props.data.snsCampId === undefined ? '-' : props.data.snsCampId
  if (props.data.type === 'adSet') snscampId = '-'
  // eslint-disable-next-line prefer-destructuring
  if (props.data.type === 'creation') snscampId = props.data.snsAdId === undefined ? '-' : props.data.snsAdId
  return snscampId
}

// const getSnsAdIdCellRenderer = (props: ICellRendererParams) => (props.data.snsAdId === undefined ? '-' : props.data.snsAdId);

// eslint-disable-next-line consistent-return
const getLinkCellRenderer = (props: ICellRendererParams) => {
  if (props.data.type === 'camp')
    return (
      <Link
        className="txt-link"
        to={`/mngCamp/mngCamp/mng/${props.data.campId}/step01/${props.data.mediaDiv4}/${props.data.adminCampYn}/${
          props.data.campViewStatus
        }/${props.data.projectId === undefined ? null : props.data.projectId}`}
      >
        관리
      </Link>
    )
  if (props.data.type === 'adSet')
    return (
      <Link
        className="txt-link"
        to={`/mngCamp/mngCamp/mng/${props.data.campId}/step02/${props.data.mediaDiv4}/${props.data.adminCampYn}/${
          props.data.campViewStatus
        }/${props.data.projectId === undefined ? null : props.data.projectId}`}
      >
        관리
      </Link>
    )
  if (props.data.type === 'creation')
    return (
      <Link
        className="txt-link"
        to={`/mngCamp/mngCamp/mng/${props.data.campId}/step03/${props.data.mediaDiv4}/${props.data.adminCampYn}/${
          props.data.campViewStatus
        }/${props.data.projectId === undefined ? null : props.data.projectId}`}
      >
        관리
      </Link>
    )
}

const MngCamp = () => {
  const { invalidate: invalidateUsableCost } = useUsableCostQuery()
  const { data: shadowLogin } = useShadowLoginQuery()
  const setTitle = useOutletContext<any>()
  // 자식 컴포넌트의 함수를 호출하기 위한 부분
  const childRef = useRef<any>(null)
  const gridRef = useRef<AgGridReact>(null)
  const [rowData, setRowData] = useState<any[]>()
  const [row, setRow] = useState<any[]>()
  const [disabled, setDisabled] = useState<any>(false)
  const [statusList, setStatusList] = useState<string[][]>([])
  const searchParams = new URLSearchParams(window.location.search)
  const q_type = searchParams.get('srchType')
  const q_keyword = searchParams.get('keyword')

  const errorEvent = (msg: string) => {
    alertify.error(msg, 1)
  }

  const confirmError = (msg: string) => {
    errorEvent(msg)
  }

  const confirmSyncError = (msg: string) => {
    errorEvent(msg)
  }

  const successEvent = (msg: string) => {
    alertify.success(msg, 1)
  }
  const confirmSuccess = (msg: string) => {
    successEvent(msg)
  }

  const successSyncEvent = (msg: string) => {
    alertify.success(msg, 5)
  }
  const confirmSyncSuccess = (msg: string) => {
    successSyncEvent(msg)
  }

  const initTooltip = () => {
    $('#useSum').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">소진금액</p><p class="fz-12 fc-0">· 캠페인/광고세트/소재에 사용된 광고비를<br/> 의미합니다.</p><p class="fz-12 fc-0">· 해당 영역에서 확인되는 소진 금액은 비즈센터<br/> 수수료와 vat가 제외된 금액입니다.</p>',
    })
    $('#impCnt').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">노출</p><p class="fz-12 fc-0">· 광고가 사용자의 화면에 표시된 횟수를 의미합니다.</p>',
    })
    $('#clickCnt').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">클릭</p><p class="fz-12 fc-0">· 노출된 광고를 클릭한 횟수를 의미합니다.<br/>· 클릭에는 아래와 같은 행동들이 포함됩니다.<br/>' +
        '　· 링크클릭<br/>' +
        '　· 연결된 비즈니스 페이지 프로필 또는 프로필 사진<br/>　클릭<br/>' +
        '　· 게시물 공감 및 댓글 또는 공유<br/>' +
        '　· 미디어(예: 사진)를 전체 화면으로 확장하는 클릭<br/>' +
        '　· 캠페인 목표와 일치하는 클릭<br/>　(예: 페이지 참여 캠페인에서 페이지 좋아요)<br/></p>',
    })
    $('#cpm').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">CPM(1천회당 노출비용)</p><p class="fz-12 fc-0">· 1000번 노출되는데 사용된 비용을 의미합니다.<br/>' +
        '· CPM이 낮을 수록 CPA/ROAS를 획득할 가능성이<br/> 있습니다.<br/>' +
        '· (소진금액/노출수) *1000<br/></p>',
    })
    $('#ctr').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">클릭률(CTR)</p><p class="fz-12 fc-0">· 사람들이 광고를 보고 클릭 행동을 취한 비율입니다.<br/> ' +
        '· CTR이 높을 수록 고성과의 CVR을 획득 할 가능성이<br/> 있습니다.<br/> ' +
        '· (클릭수 / 노출수)*100</p>',
    })
    $('#cpc').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">클릭당비용(CPC)</p><p class="fz-12 fc-0">· 한번 클릭하는데 지불된 평균 비용을 의미합니다.<br/>' +
        '· CPC는 실제 과금기준이 아닌 평균지표를 나타내는<br/> 지표입니다.<br/>' +
        '· 지출금액/클릭수<br/></p>',
    })
    $('#convCnt').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">전환수</p><p class="fz-12 fc-0">· 해당 캠페인/광고세트 / 소재를 통해 발생되어진<br/> · 알림신청 수 / 펀딩 수를 의미합니다.</p>',
    })
    $('#costPerConv').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">전환당비용(CPA)</p><p class="fz-12 fc-0">· 1개의 전환을 일으키는데 발생된 평균 비용입니다.<br/> ' +
        '· 소진금액/전환수<br/></p>',
    })
    $('#convRate').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">전환율(CVR)</p><p class="fz-12 fc-0">· 광고를 보고 클릭한 사람 대비 전환된 비율을<br/> 의미합니다.<br/> ' +
        '· (전환수/클릭)*100 <br/></p>',
    })
    $('#convCa').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">전환매출</p><p class="fz-12 fc-0">· 광고를 보고 펀딩한 사람이 구매한 값을 의미합니다.</p>',
    })
    $('#roas').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">수익률</p><p class="fz-12 fc-0">· 광고 지출 대비 수익률로 소진금액에 대한 매출비율을 측정하는 지표입니다.<br/>' +
        '· 높을 수록 좋은 성과를 의미합니다.</p>',
    })
    $('#depBudget').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">예치금(잔액/총액)</p><p class="fz-12 fc-0">· 신규 캠페인 생성시 캠페인예산 + 수수료 + 보증금 + 부가세가 포함된 금액으로 캠페인에 예치(약속의 개념)되는 비즈머니 금액입니다<br/>' +
        '· 광고일자 +4일에 확정된 광고비가 예치금에서 일별로 차감됩니다.<br/>' +
        '· 사용되지 않은 광고비는 수수료/보증금/부가세를 포함하여 캠페인 종료 +4일 이후 비즈머니로 반환됩니다.<br/>' +
        '· 예치금과 실제 광고비는 일치하지 않으니 광고성과 확인을 위해서는 소진금액을 확인해주세요<br/>' +
        '· 예치금 반환은 비즈센터 비즈머니 현황/ 후불머니 현황에서 확인할 수 있습니다.<br/></p>',
    })
    // $('#frequency').tooltip({ content: '<p class="fz-12 fc-0 fw-medium">게재 빈도</p><p class="fz-12 fc-0">조회기간 기준으로 해당 캠페인/세트/소재의 게재빈도</p>' });
  }

  // eslint-disable-next-line no-shadow
  const updateItems = (rptData: any) => {
    const itemsToUpdate: any[] = []
    gridRef.current?.api?.forEachNode((rowNode, index) => {
      const { data } = rowNode
      data.cost = rptData[index].cost !== undefined ? rptData[index].cost : 0
      data.ctr = rptData[index].ctr != undefined ? rptData[index].ctr : 0
      data.convRate = rptData[index].convRate !== undefined ? rptData[index].convRate : 0
      data.costPerConv = rptData[index].costPerConv !== undefined ? rptData[index].costPerConv : 0
      data.roas = rptData[index].roas !== undefined ? rptData[index].roas : 0
      data.impCnt = rptData[index].impCnt !== undefined ? rptData[index].impCnt : 0
      data.clickCnt = rptData[index].clickCnt !== undefined ? rptData[index].clickCnt : 0
      data.cpm = rptData[index].cpm !== undefined ? rptData[index].cpm : 0
      data.cpc = rptData[index].cpc !== undefined ? rptData[index].cpc : 0
      data.convCa = rptData[index].convCa !== undefined ? rptData[index].convCa : 0
      data.convCnt = rptData[index].convCnt !== undefined ? rptData[index].convCnt : 0
      // data.frequency = rptData[index].frequency;
      itemsToUpdate.push(data)
    })
    gridRef.current?.api?.applyTransaction({ update: itemsToUpdate })!
  }

  const updateItem = (id: any, type: any, res: any) => {
    const itemsToUpdate: any[] = []
    gridRef.current!.api!.forEachNode((rowNode) => {
      const { data } = rowNode
      if (data.type === type && (type === 'camp' || type === 'adSet' ? data.id === id : data.creationLinkId === id)) {
        data.frequency = res === 0 ? '-' : res.toFixed(1)
      }
      itemsToUpdate.push(data)
    })
    gridRef.current!.api!.applyTransaction({ update: itemsToUpdate })!
  }

  const updateFrequencies = (rptData: any) => {
    if (rptData.length === 0) return
    const itemsToUpdate: any[] = []
    gridRef.current!.api!.forEachNode((rowNode, index) => {
      const { data } = rowNode
      const { frequency } = rptData[index]
      data.frequency = frequency === 0 ? '-' : frequency.toFixed(1)
      itemsToUpdate.push(data)
    })
    gridRef.current!.api!.applyTransaction({ update: itemsToUpdate })!
    $('#frequencyAll').prop('disabled', false)
  }

  const onGridReady = useCallback((gridReloadd: any) => {
    if (gridReloadd !== false) gridRef.current!.api.showLoadingOverlay()

    // startDate와 endDate 접근
    const datePicker = $('#datepicker').data('daterangepicker')
    const startDate = datePicker.startDate.format('YYYYMMDD')
    const endDate = datePicker.endDate.format('YYYYMMDD')

    /** 쿼리파라미터 유무*/
    ApiUtil2.get('/api/mngCamp/mngCamp/list', {
      params: {
        data: {
          campStartTime: startDate,
          campEndTime: endDate,
          campViewStatus: $('#statusList').val(),
          srchType: q_type !== undefined && q_type !== null ? q_type : $('#type').val(),
          keyword: q_keyword !== undefined && q_keyword !== null ? q_keyword : $('#keyword').val(),
        },
      },
    }).then((resp) => {
      setRowData(resp.data.data)
      if (resp.data.data.length === 0) gridRef.current!.api.showLoadingOverlay()
      ;(document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any).style.height = ''
      ;(
        document.querySelector<HTMLElement>('.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height')! as any
      ).style.height = ''
      gridRef.current!.columnApi.moveColumn('mng', 0)
      gridRef.current!.columnApi.moveColumn('useYn', 1)
      initTooltip()
      onRptReady()
    })
    return setRowData([])
  }, [])

  const onRptReady = useCallback(() => {
    // startDate와 endDate 접근
    const datePicker = $('#datepicker').data('daterangepicker')
    const startDate = datePicker.startDate.format('YYYYMMDD')
    const endDate = datePicker.endDate.format('YYYYMMDD')

    ApiUtil.get('/api/mngCamp/mngCamp/rpt', {
      params: {
        data: {
          campStartTime: startDate,
          campEndTime: endDate,
          campViewStatus: $('#statusList').val(),
          srchType: $('#type').val(),
          keyword: $('#keyword').val(),
        },
      },
    }).then((resp) => {
      updateItems(resp.data.data)
      initTooltip()
    })
  }, [])

  const onGridClick = useCallback(() => {
    if ($('#type').val() === '') {
      confirmError('검색조건을 선택해주세요')
      return
    }

    // startDate와 endDate 접근
    const datePicker = $('#datepicker').data('daterangepicker')
    const startDate = datePicker.startDate.format('YYYYMMDD')
    const endDate = datePicker.endDate.format('YYYYMMDD')

    setRowData([])
    ApiUtil2.get('/api/mngCamp/mngCamp/list', {
      params: {
        data: {
          campStartTime: startDate,
          campEndTime: endDate,
          campViewStatus: $('#statusList').val(),
          srchType: $('#type').val(),
          keyword: $('#keyword').val(),
        },
      },
    }).then((resp) => {
      setRowData(resp.data.data)
      if (resp.data.data.length === 0) gridRef.current!.api.showLoadingOverlay()
      ;(document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any).style.height = ''
      ;(
        document.querySelector<HTMLElement>('.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height')! as any
      ).style.height = ''
      gridRef.current!.columnApi.moveColumn('mng', 0)
      gridRef.current!.columnApi.moveColumn('useYn', 1)
      initTooltip()
      onRptReady()
    })
  }, [])

  /** 소재상태 새로고침 */
  const onSyncReady = useCallback(() => {
    ApiUtil.get('/api/mngCamp/mngCamp/checkCreationSyncYn', {
      params: { data: { menuPath: '/api/mngCamp/mngCamp/list' } },
    }).then((resp) => {
      if (resp.data.data.isReqAvail === false) confirmSyncError(resp.data.data.msg)
      if (resp.data.data.isReqAvail) {
        alertify
          .confirm(
            '소재상태 새로고침',
            resp.data.data.msg,
            () => {
              ApiUtil.post('/api/mngCamp/mngCamp/syncCreation', { menuPath: '/api/mngCamp/mngCamp/list' }).then(() => {
                confirmSyncSuccess('캠페인 소재 상태값 새로고침이 진행되고 있습니다. 약 10분가량 소요될 수 있습니다.')
              })
            },
            {}
          )
          .set({ labels: { cancel: '취소', ok: '확인' } })
      }
    })
  }, [])

  /** 전체보기 */
  const onFrequencyReady = useCallback(() => {
    // startDate와 endDate 접근
    const datePicker = $('#datepicker').data('daterangepicker')
    const startDate = datePicker.startDate.format('YYYYMMDD')
    const endDate = datePicker.endDate.format('YYYYMMDD')

    $('#frequencyAll').prop('disabled', true)
    ApiUtil.get('/api/mngCamp/mngCamp/rpt/frequencyAll', {
      params: {
        data: {
          campStartTime: startDate,
          campEndTime: endDate,
          campViewStatus: $('#statusList').val(),
          srchType: $('#type').val(),
          keyword: $('#keyword').val(),
        },
      },
    }).then((resp) => {
      updateFrequencies(resp.data.data)
    })
  }, [])

  /** 개별클릭 */
  const confirmFrequency = (e: any) => {
    // eslint-disable-next-line no-nested-ternary
    ApiUtil.get('/api/mngCamp/mngCamp/rpt/frequency', {
      params: { data: { type: e.type, id: e.type === 'camp' || e.type === 'adSet' ? e.id : e.creationLinkId } },
    }).then((resp) => {
      updateItem(e.type === 'camp' || e.type === 'adSet' ? e.id : e.creationLinkId, e.type, resp.data.data)
    })
  }

  const confirmClickEvent = (e: any) => {
    let msg = ''
    if (e.payType === 'BIZ_MONEY') {
      if (e.campViewStatus === 'temp') msg = '삭제된 캠페인은 복원이 불가능합니다.'
      if (e.campViewStatus === 'insp_req' || e.campViewStatus === 'invalid' || e.campViewStatus === 'wait')
        msg = '삭제된 캠페인은 복원이 불가능합니다. <br/> 캠페인 예치금이 비즈머니로 반환됩니다.'
      if (e.campViewStatus === 'ing_update' || e.campViewStatus === 'ing_insp' || e.campViewStatus === 'ing')
        msg = '삭제된 캠페인은 복원이 불가능합니다. <br/> 잔여 캠페인 예치금은 4~5일 후 비즈머니로 반환됩니다.'
      if (
        (e.campViewStatus === 'stop_creation_off' ||
          e.campViewStatus === 'stop_camp_off' ||
          e.campViewStatus === 'stop_camp_range') &&
        e.snsCampId === undefined
      )
        msg = '삭제된 캠페인은 복원이 불가능합니다. <br/> 캠페인 예치금이 비즈머니로 반환됩니다.'
      if (
        (e.campViewStatus === 'stop_creation_off' ||
          e.campViewStatus === 'stop_camp_off' ||
          e.campViewStatus === 'stop_camp_range') &&
        e.snsCampId !== undefined
      )
        msg = '삭제된 캠페인은 복원이 불가능합니다. <br/> 잔여 캠페인 예치금은 4~5일 후 비즈머니로 반환됩니다.'
      if (e.campViewStatus === 'complete' || e.campViewStatus === 'del_ready' || e.campViewStatus === 'del_complete')
        msg = '삭제된 캠페인은 복원이 불가능합니다. <br/> 잔여 캠페인 예치금은 4~5일 후 비즈머니로 반환됩니다.'
    } else {
      if (e.campViewStatus === 'temp') msg = '삭제된 캠페인은 복원이 불가능합니다.'
      if (e.campViewStatus === 'insp_req' || e.campViewStatus === 'invalid' || e.campViewStatus === 'wait')
        msg =
          '삭제된 캠페인은 복원이 불가능합니다. <br/> 캠페인 설정 후불머니 한도가 프로젝트 후불머니 한도로 변환됩니다.'
      if (e.campViewStatus === 'ing_update' || e.campViewStatus === 'ing_insp' || e.campViewStatus === 'ing')
        msg =
          '삭제된 캠페인은 복원이 불가능합니다. <br/> 잔여 캠페인 설정 후불머니 한도는  4~5일 후 프로젝트 후불머니 한도로 변환됩니다.'
      if (
        (e.campViewStatus === 'stop_creation_off' ||
          e.campViewStatus === 'stop_camp_off' ||
          e.campViewStatus === 'stop_camp_range') &&
        e.snsCampId === undefined
      )
        msg =
          '삭제된 캠페인은 복원이 불가능합니다. <br/> 캠페인 설정 후불머니 한도가  프로젝트 후불머니 한도로 변환됩니다.'
      if (
        (e.campViewStatus === 'stop_creation_off' ||
          e.campViewStatus === 'stop_camp_off' ||
          e.campViewStatus === 'stop_camp_range') &&
        e.snsCampId !== undefined
      )
        msg =
          '삭제된 캠페인은 복원이 불가능합니다. <br/> 잔여 캠페인 설정 후불머니 한도는  4~5일 후 프로젝트 후불머니 한도로 변환됩니다.'
      if (e.campViewStatus === 'complete' || e.campViewStatus === 'del_ready' || e.campViewStatus === 'del_complete')
        msg =
          '삭제된 캠페인은 복원이 불가능합니다. <br/> 잔여 캠페인 설정 후불머니 한도는  4~5일 후 프로젝트 후불머니 한도로 변환됩니다.'
    }

    alertify
      .confirm(msg, () => {
        // 캠페인, 광고세트, 소재삭제로직
        ApiUtil.post('/api/mngCamp/mngCamp/updateActYn', { id: e.id }).then(() => {
          onGridReady(false)
          invalidateUsableCost()
        })
        confirmSuccess('캠페인이 삭제되었습니다.')
      })
      .set({ labels: { cancel: '취소', ok: '확인' } })
      .setHeader('캠페인을 삭제하시겠습니까?')
  }

  //fixme ------------------------------------------------------------- RENDERER START ---------------------------------------------------------------------------
  const getIdCellRenderer = (props: ICellRendererParams) => {
    /***
     * 첫 로딩시 -> 하이라이드 x
     * 검색 / 반려알림을통해 -> 하이라이트 o
     */
    if (props.data.type === 'adSet') {
      return '-'
    }

    if (props.data.type === 'camp' && $('#keyword').val()?.toString()?.includes(props.data.id.toString())) {
      return <span className="txt-mark">{props.data.id}</span>
    }

    if (
      props.data.type === 'creation' &&
      $('#keyword').val()?.toString()?.includes(props.data.creationLinkId.toString())
    ) {
      return <span className="txt-mark">{props.data.creationLinkId}</span>
    } else if (props.data.type === 'creation' && q_type === 'creationId' && q_keyword !== undefined) {
      return <span className="txt-mark">{props.data.creationLinkId}</span>
    } else if (
      props.data.type === 'creation' &&
      q_type === undefined &&
      q_keyword === undefined &&
      $('#keyword').val() === ''
    ) {
      return props.data.creationLinkId
    }

    return props.data.id
  }

  // eslint-disable-next-line react/prop-types,no-nested-ternary
  const setCpmRenderer = (props: ICellRendererParams) =>
    props.data.cpm === undefined
      ? '로딩중'
      : props.data.cpm === 0
      ? '-'
      : `${props.data.cpm.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  const setCtrRenderer = (props: ICellRendererParams) =>
    props.data.ctr === undefined ? '로딩중' : props.data.ctr === 0 ? '-' : `${props.data.ctr}%`
  const setCpcRenderer = (props: ICellRendererParams) =>
    props.data.cpc === undefined
      ? '로딩중'
      : props.data.cpc === 0
      ? '-'
      : `${props.data.cpc.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  const setConvCntRenderer = (props: ICellRendererParams) =>
    props.data.convCnt === undefined
      ? '로딩중'
      : props.data.convCnt === 0
      ? '-'
      : `${props.data.convCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  const setConvCaRenderer = (props: ICellRendererParams) =>
    props.data.convCa === undefined
      ? '로딩중'
      : props.data.convCa === 0
      ? '-'
      : `${props.data.convCa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  const setImpCntRenderer = (props: ICellRendererParams) =>
    props.data.impCnt === undefined
      ? '로딩중'
      : props.data.impCnt === 0
      ? '-'
      : `${props.data.impCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  const setClickCntRenderer = (props: ICellRendererParams) =>
    props.data.clickCnt === undefined
      ? '로딩중'
      : props.data.clickCnt === 0
      ? '-'
      : `${props.data.clickCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  // eslint-disable-next-line react/prop-types,no-nested-ternary
  const setConvRateRenderer = (props: ICellRendererParams) =>
    props.data.convRate === undefined ? '로딩중' : props.data.convRate === 0 ? '-' : `${props.data.convRate}%`
  // eslint-disable-next-line react/prop-types,no-nested-ternary
  const setRoasRenderer = (props: ICellRendererParams) =>
    props.data.roas === undefined ? '로딩중' : props.data.roas === 0 ? '-' : `${Math.floor(props.data.roas)}%`
  // eslint-disable-next-line react/prop-types,no-nested-ternary
  const setCostPerConvRenderer = (props: ICellRendererParams) =>
    props.data.costPerConv === undefined
      ? '로딩중'
      : props.data.costPerConv === 0
      ? '-'
      : `${props.data.costPerConv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`

  const setUseYnCellRenderer = (props: ICellRendererParams) => {
    const currentStatus = props.data.type === 'creation' ? props.data.creationViewStatus : props.data.campViewStatus

    // 소재가 관리자에 의해 중단 상태인지(혹은 중단 후 재검수 상태인지) 여부
    const isSuspendedStatus = currentStatus === 'stop_by_admin' || currentStatus === 'reg_insp_after_stop'

    // on/off 상태 API 업데이트
    const updateOnOffState = (event: React.ChangeEvent<HTMLInputElement>) => {
      ApiUtil.post('/api/mngCamp/mngCamp/updateUseYn', {
        // eslint-disable-next-line react/prop-types
        type: props.data.type,
        // eslint-disable-next-line react/prop-types
        campId: props.data.campId,
        id: event.target.id.split('-')[1],
        useYn: event.target.checked,
      })
        .then(() => {
          onGridReady(false)
        })
        .catch((e) => {
          event.target.checked = !event.target.checked // 원래 체크 상태로 되돌림
        })
    }

    return (
      <div className="comp-switch">
        {/* eslint-disable-next-line react/prop-types */}
        <input
          type="checkbox"
          id={`useYn-${props.data.type === 'creation' ? props.data.creationLinkId : props.data.id}`}
          defaultChecked={props.data.useYn}
          disabled={
            (!shadowLogin && props.data.adminCampYn === true) ||
            props.data.campViewStatus === 'temp' ||
            props.data.campViewStatus === 'complete'
          }
          onChange={(e) => {
            // OFF
            if (!e.target.checked) {
              if (props.data.type === 'camp') {
                alertify
                  .confirm(
                    '캠페인을 off 하시겠습니까?', // title
                    `메이커님! 타겟 광고 효율은 최소 5일의 운영 데이터를 보고 판단하시는 것을 권장 드려요. \n 메이커님의 광고에 알맞은 타겟을 학습하고 있으니 최소 5일 이상 광고를 유지해주세요.`, // message
                    () => {
                      updateOnOffState(e)
                    }, // onOK
                    () => {
                      e.preventDefault()
                      e.target.checked = props.data.useYn // 원래 체크 상태로 되돌림
                    } // onCancel
                  )
                  .set({ labels: { cancel: '취소', ok: '네, 광고 중단할께요.' } }) // set modal options

                return
              }

              updateOnOffState(e)
            }

            // ON
            if (e.target.checked) {
              // 캠페인 및 소재 중단된 경우 ON 불가
              if (isSuspendedStatus) {
                e.preventDefault()
                alertify.warning(
                  '관리자가 노출 중단한 소재입니다.\n소재 재검수 후 관리자가 승인하면 자동으로 ON이 됩니다.'
                )
                e.target.checked = props.data.useYn // 원래 체크 상태로 되돌림
                return
              }

              updateOnOffState(e)
            }
          }}
        />
        {/* eslint-disable-next-line react/prop-types */}
        <label htmlFor={`useYn-${props.data.type === 'creation' ? props.data.creationLinkId : props.data.id}`}>
          <i className="ico"></i>
        </label>
      </div>
    )
  }

  const setActiYnRenderer = (props: ICellRendererParams) => {
    // eslint-disable-next-line react/prop-types
    if (props.data.type === 'camp') {
      // eslint-disable-next-line react/prop-types
      return (
        <Link className="txt-link" to="#javascript" onClick={() => confirmClickEvent(props.data)}>
          삭제
        </Link>
      )
    }
    return null
  }

  const setCampDepBudgetRenderer = (props: ICellRendererParams) => {
    // eslint-disable-next-line react/prop-types
    if (props.data.type === 'camp' && props.data.payType !== 'POST_PAYMENT') {
      // eslint-disable-next-line react/prop-types
      return `${props.data.campDepRemainBudget
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/${props.data.campDepBudget
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    } else if (props.data.type === 'camp' && props.data.payType === 'POST_PAYMENT') {
      return `${props.data.pomRemain.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/${props.data.campBudgetTotal2
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    }
    return null
  }

  const setCampBudgetTotal1Renderer = (props: ICellRendererParams) => {
    return props.data.campBudgetTotal1 === null ||
      props.data.campBudgetTotal1 === '' ||
      props.data.campBudgetTotal1 === undefined
      ? '-'
      : props.data.campBudgetTotal1.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
  }

  const setCostRenderer = (props: ICellRendererParams) => {
    return props.data.cost === null || props.data.cost === '' || props.data.cost === undefined
      ? '로딩중'
      : props.data.cost.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
  }

  const getStatusCellRenderer = (props: ICellRendererParams) => {
    // eslint-disable-next-line react/prop-types
    const { type } = props.data
    let statusdiv: any
    // eslint-disable-next-line react/prop-types
    if (type === 'camp') statusdiv = props.data.campViewStatusDesc
    // eslint-disable-next-line react/prop-types
    if (type === 'adSet') statusdiv = props.data.useYn === true ? 'ON' : 'OFF'
    // eslint-disable-next-line react/prop-types
    if (type === 'creation') statusdiv = props.data.creationViewStatusDesc
    return statusdiv
  }

  const getFileIcon = (name: string, type: string, advancement: string, creationViewStatus: string) => {
    if (
      type === 'creation' &&
      (creationViewStatus === 'reg' ||
        creationViewStatus === 'stop_creation_off' ||
        creationViewStatus === 'stop_ad_set_off' ||
        creationViewStatus === 'stop_camp_off' ||
        creationViewStatus === 'stop_camp_range' ||
        creationViewStatus === 'complete' ||
        creationViewStatus === 'del')
    )
      return 'far fa-file'
    if (type === 'camp') return advancement
    return ''
  }

  const getFileCellRenderer = () => {
    class FileCellRenderer implements ICellRendererComp {
      eGui: any

      init(params: ICellRendererParams) {
        const tempDiv = document.createElement('div')
        const { value } = params
        const keyword = $('#keyword').val() as string
        const icon = getFileIcon(
          params.value,
          params.data.type,
          params.data.advancement,
          params.data.creationViewStatus
        )
        if (params.data.type === 'creation') {
          const creationKey = params.data.id
          // eslint-disable-next-line no-useless-concat
          if ($('#type').val() === 'adTitle01' && $('#keyword').val() !== '') {
            const deKeyword = decodeURI(keyword)
            let reg = new RegExp(deKeyword, 'g')
            const filter = value.replace(reg, '<span class="txt-mark">' + deKeyword + '</span>')
            tempDiv.innerHTML =
              `<span id="creation_${creationKey}"><i class="${icon}"></i>` +
              `<span class="filename"></span>${filter}</span>`
          } else {
            tempDiv.innerHTML =
              `<span id="creation_${creationKey}"><i class="${icon}"></i>` +
              `<span class="filename"></span>${value}</span>`
          }
        } else if (params.data.type === 'adSet') {
          if (
            params.data.campViewStatus !== 'temp' &&
            params.data.snsAdSetId !== undefined &&
            params.data.progress !== undefined
          ) {
            tempDiv.innerHTML = `<span> ${value} <span id="percent" class="fz-12 fc-2"> ${
              params.data.progress === 'SUCCESS' || params.data.progress === 'FAIL'
                ? params.data.progress
                : `${params.data.progress}%`
            } </span></span>`
          } else {
            tempDiv.innerHTML = `<span> ${value}</span>`
          }
        } else if (params.data.type === 'camp') {
          if ($('#type').val() === 'campNm' && $('#keyword').val() !== '') {
            const deKeyword = decodeURI(keyword)
            let reg = new RegExp(deKeyword, 'g')
            const filter = value.replace(reg, '<span class="txt-mark">' + deKeyword + '</span>')
            tempDiv.innerHTML = `<span><div class='${
              params.data.payType === undefined || params.data.payType === 'BIZ_MONEY'
                ? 'btn btn-tertiary-mint xsmall'
                : 'btn btn-primary xsmall'
            }'>${
              params.data.payType === undefined || params.data.payType === 'BIZ_MONEY' ? '비즈머니' : '후불머니'
            }</div> ${filter}</span>`
          } else {
            tempDiv.innerHTML = `<span><div class='${
              params.data.payType === undefined || params.data.payType === 'BIZ_MONEY'
                ? 'btn btn-tertiary-mint xsmall'
                : 'btn btn-primary xsmall'
            }'>${
              params.data.payType === undefined || params.data.payType === 'BIZ_MONEY' ? '비즈머니' : '후불머니'
            }</div> ${value}</span>`
          }
        } else {
          console.log(value)
        }
        this.eGui = tempDiv.firstChild
        // eslint-disable-next-line no-restricted-globals
        if (params.data.type === 'creation') {
          // 파일 아이콘 클릭 이벤트
          this.eGui.addEventListener('click', (event: any) => {
            if (params.data.type === 'creation' && event.target.className === 'far fa-file') {
              setRow(params.data)
              dialogOpen(params.data)
            }
          })
        }
      }

      getGui() {
        return this.eGui
      }

      // eslint-disable-next-line class-methods-use-this
      refresh() {
        return false
      }
    }
    return FileCellRenderer
  }

  //fixme ------------------------------------------------------------- RENDERER END ---------------------------------------------------------------------------

  //fixme ------------------------------------------------------------- COLUMNDEFS START ---------------------------------------------------------------------------
  const [columnDefs] = useState<ColDef[]>([
    // 헤더
    {
      field: 'mng',
      minWidth: 100,
      headerName: '',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: getLinkCellRenderer,
      cellRendererParams: {
        suppressCount: true,
      },
    },
    {
      field: 'useYn',
      headerName: 'ON/OFF',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: setUseYnCellRenderer,
      cellRendererParams: {
        suppressCount: true,
      },
      minWidth: 100,
    },
    {
      field: 'projectId',
      headerName: '프로젝트ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: getProjectIdCellRenderer,
      rowDrag: true,
      minWidth: 100,
    },
    {
      field: 'id',
      headerClass: 'ag-center-aligned-header',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '비즈센터 캠페인/', header2: '소재ID' },
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: getIdCellRenderer,
      rowDrag: true,
      minWidth: 150,
    },
    {
      field: 'snsCampid',
      headerClass: 'ag-center-aligned-header',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: 'SNS 캠페인/', header2: '소재ID' },
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: getSnsIdCellRenderer,
      rowDrag: true,
      minWidth: 170,
      hide: shadowLogin === false, // shadowLogin이 false인 경우 컬럼 숨김
    },
    {
      field: 'campPerd',
      headerName: '캠페인 기간',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      minWidth: 200,
    },
    {
      field: 'campViewStatusDesc',
      headerName: '상태',
      tooltipField: 'desc',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      minWidth: 150,
      cellRenderer: getStatusCellRenderer,
    },
    {
      field: 'mediaDiv4Des',
      headerName: '노출 매체',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      minWidth: 200,
    },
    {
      field: 'campBudgetTotal1',
      headerName: '캠페인 예산',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: setCampBudgetTotal1Renderer,
      minWidth: 150,
    },
    //fixme 소진금액 -- 로직필요
    {
      field: 'cost',
      headerName: '소진 금액',
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="useSum" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: setCostRenderer,
      minWidth: 150,
    },
    {
      field: 'impCnt',
      headerName: '노출',
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="impCnt" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: setImpCntRenderer,
      minWidth: 125,
    },
    {
      field: 'clickCnt',
      headerName: '클릭',
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="clickCnt" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: setClickCntRenderer,
      minWidth: 125,
    },
    {
      field: 'cpm',
      headerName: 'CPM(1천회당 노출비용)',
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="cpm" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: setCpmRenderer,
      minWidth: 200,
    },
    {
      field: 'ctr',
      headerName: '클릭률(CTR)',
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="ctr" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: setCtrRenderer,
      minWidth: 150,
    },
    {
      field: 'cpc',
      headerName: '클릭당 비용(CPC)',
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="cpc" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: setCpcRenderer,
      minWidth: 150,
    },
    {
      field: 'convCnt',
      headerName: '전환수',
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="convCnt" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: setConvCntRenderer,
      minWidth: 150,
    },
    {
      field: 'costPerConv',
      headerName: '전환당 비용(CPA)',
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="costPerConv" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: setCostPerConvRenderer,
      minWidth: 150,
    },
    {
      field: 'convRate',
      headerName: '전환율(CVR)',
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="convRate" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: setConvRateRenderer,
      minWidth: 150,
    },
    {
      field: 'convCa',
      headerName: '전환매출',
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="convCa" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: setConvCaRenderer,
      minWidth: 150,
    },
    {
      field: 'roas',
      headerName: '수익률(ROAS)',
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="roas" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: setRoasRenderer,
      minWidth: 150,
    },
    {
      field: 'campDepBudget',
      headerComponent: GridCustomHeaderToolTip,
      headerComponentParams: {
        header1: '예치금',
        header2: '(잔액/총액)',
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="depBudget" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: setCampDepBudgetRenderer,
      minWidth: 170,
    },
    {
      field: 'delete',
      headerName: '삭제',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: setActiYnRenderer,
      minWidth: 80,
    },
  ])

  const defaultColDef = useMemo<ColDef>(
    () => ({
      resizable: true,
      suppressMovable: true,
      filter: true,
      flex: 1,
      tooltipComponent: LogTooltip,
    }),
    []
  )

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      // field: 'campNm',
      headerName: '명칭',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      minWidth: 400,
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: getFileCellRenderer(),
      },
    }),
    []
  )

  const getDataPath = useCallback((data: any) => data.orgHierarchy, [])

  //fixme ------------------------------------------------------------- COLUMNDEFS END ---------------------------------------------------------------------------

  //fixme ------------------------------------------------------------- 그외 END ---------------------------------------------------------------------------
  const changePagination = () => {
    childRef.current!.onPaginationChanged()
  }

  const popupParent = useMemo(() => document.body, [])

  const onBtnExport = useCallback(() => {
    // startDate와 endDate 접근
    const datePicker = $('#datepicker').data('daterangepicker')
    const startDate = datePicker.startDate.format('YYYYMMDD')
    const endDate = datePicker.endDate.format('YYYYMMDD')

    // gridRef.current!.api.exportDataAsCsv();
    window.location.assign(
      `/api/mngCamp/mngCamp/download?${
        $('#keyword').val() === ''
          ? `&campStartTime=${startDate}&campEndTime=${endDate}`
          : `&keyword=${$('#keyword').val()}&srchType=${$(
              '#type'
            ).val()}&campStartTime=${startDate}&campEndTime=${endDate}`
      }`
    )
  }, [])

  // eslint-disable-next-line consistent-return
  const onCellClicked = (params: any) => {
    /** alt키 */
    if (params.event.altKey === true && params.data.type === 'camp') {
      window.open(`/wabiz/event/${params.data.campId}`)
    }
    if (params.event.altKey === true && params.data.type === 'creation') {
      window.open(`/wabiz/event/${params.data.creationLinkId}`)
    }
    /** ctrl키 */
    if (params.event.ctrlKey === true && params.data.mediaDiv4 !== 'GDN' && params.data.snsCampId !== undefined) {
      // ctrl키
      window.open(
        `https://business.facebook.com/adsmanager/manage/adsets?act=${params.data.mediaConfigSnsAdId}&selected_campaign_ids=${params.data.snsCampId}`
      )
    }
  }
  //fixme ------------------------------------------------------------- 그외 END ---------------------------------------------------------------------------

  //fixme ------------------------------------------------------------- USEEFFECT START ---------------------------------------------------------------------------
  useEffect(() => {
    setTitle('캠페인 관리')
    initTooltip()
    return () => {
      setTitle('')
    }
  }, [shadowLogin])

  useEffect(() => {
    $('#datepicker').daterangepicker(
      {
        opens: 'left',
        ranges: {
          오늘: [dayjs().startOf('day'), dayjs().endOf('day').subtract(59, 'm')],
          어제: [
            dayjs().startOf('day').subtract(1, 'days'),
            dayjs().endOf('day').subtract(59, 'm').subtract(1, 'days'),
          ],
          '이번 주': [dayjs().startOf('day').day(0), dayjs().day(1).endOf('day').subtract(-7, 'd').subtract(59, 'm')],
          '저번 주': [
            dayjs().startOf('day').subtract(1, 'week').day(0),
            dayjs().endOf('day').subtract(1, 'week').day(6).subtract(59, 'm'),
          ],
          '최근 7일': [dayjs().startOf('day').subtract(7, 'd'), dayjs().endOf('day').subtract(59, 'm')],
          '최근 30일': [dayjs().startOf('day').subtract(30, 'd'), dayjs().endOf('day').subtract(59, 'm')],
        },
        // maxDate: dayjs(), // 오늘 이후도 선택하려면 maxDate가 없어야 해요
        startDate: dayjs().startOf('day').subtract(30, 'd'),
        endDate: dayjs().endOf('day').subtract(59, 'm'),
      },
      (start: any, end: any) => {
        $('#datepickerSpan').text($('#datepicker').data('daterangepicker').chosenLabel)
        onGridReady(false)
      }
    )
    return () => {
      // 달력관련 모든걸 remove 해줘야 한다 한페이지에서 동작하는거라 이거 안해주면 달력 관련 소스가 계속 늘어남
      const daterangepickerCheck = document.querySelector<HTMLElement>('.daterangepicker')! as any
      if (daterangepickerCheck !== null) {
        daterangepickerCheck.remove()
      }
    }
  }, [])

  useEffect(() => {
    $(document).on('keyup', '#keyword', (e: any) => {
      if (e.keyCode === 13) {
        onGridClick()
        return false
      }
      return true
    })

    $(document).on('click', '#frequencyAll', onFrequencyReady)
    $(document).on('click', '#syncCreation', onSyncReady)

    $('#type')
      .select2({
        width: '150',
        minimumResultsForSearch: Infinity,
        placeholder: '선택하세요',
      })
      .on('select2:select', (e) => {
        if (e.params.data.id === 'creationId' || e.params.data.id === 'adTitle01') {
          $('#statusList').val('NONE').trigger('change')
          setDisabled(true)
        } else {
          setDisabled(false)
        }
      })

    $('#statusList')
      .select2({
        width: '200',
        minimumResultsForSearch: Infinity,
        placeholder: '전체',
      })
      .on('select2:select', (e) => {
        // startDate와 endDate 접근
        const datePicker = $('#datepicker').data('daterangepicker')
        const startDate = datePicker.startDate.format('YYYYMMDD')
        const endDate = datePicker.endDate.format('YYYYMMDD')

        ApiUtil2.get('/api/mngCamp/mngCamp/list', {
          params: {
            data: {
              campStartTime: startDate,
              campEndTime: endDate,
              srchType: $('#type').val(),
              keyword: $('#keyword').val(),
              campViewStatus: e.params.data.id,
            },
          },
        }).then((resp) => {
          setRowData(resp.data.data)
          if (resp.data.data.length === 0) {
            gridRef.current!.api.showLoadingOverlay()
          }
          ;(document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any).style.height =
            ''
          ;(
            document.querySelector<HTMLElement>('.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height')! as any
          ).style.height = ''
          gridRef.current!.columnApi.moveColumn('mng', 0)
          gridRef.current!.columnApi.moveColumn('useYn', 1)
          onRptReady()
        })
      })
  }, [])

  useEffect(() => {
    ApiUtil.get('/api/mngCamp/mngCamp/statusList').then((resp) => {
      const options = Object.keys(resp.data.data).map((key) => [String(key), resp.data.data[key]])
      setStatusList(options)
    })
  }, [])
  //fixme ------------------------------------------------------------- USEEFFECT END ---------------------------------------------------------------------------

  return (
    <Fragment>
      <div>
        <section className="wrap-section wrap-datagrid">
          <div className="box-header">
            <div className="box-tit">
              <h2 className="fz-20 fc-1 fw-bold">캠페인 리스트</h2>
            </div>
          </div>
          <div className="wrap-filter">
            <div className="inner-filter">
              <div className="box-left">
                <div className="item-filter v-top">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">조회기간</p>
                  </div>
                  <div className="box-filter">
                    <div className="comp-datepicker">
                      <div className="inner-datepicker">
                        <i className="ico ico-calendar"></i>
                        <input type="text" className="tf-comm datepicker-range right" id="datepicker" readOnly />
                        <span className="fz-12 fc-2" id="datepickerSpan">
                          최근 30일
                        </span>
                      </div>
                      <button type="button" className="btn btn-prev"></button>
                      <button type="button" className="btn btn-next"></button>
                    </div>
                  </div>
                  <div className="filter-desc">
                    <p className="fz-12 fc-3">
                      <span style={{ fontWeight: 700, color: '#00c4c4' }}>
                        캠페인 기간을 포함하여 조회 날짜를 변경해주세요. 조회기간에 따라, 집행한 캠페인과 성과가
                        확인됩니다.
                      </span>
                      <br />
                      당일자 데이터 포함 시 집계 조건 및 시각 차이로 [리포트] 데이터와 상이할 수 있습니다.
                    </p>
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검색조건</p>
                  </div>
                  <div className="box-filter">
                    <select id="type" className="select2-single" defaultValue={q_type ?? ''}>
                      <option></option>
                      <option value="campNm">캠페인명</option>
                      <option value="campId">캠페인ID</option>
                      <option value="adTitle01">소재제목</option>
                      <option value="creationId">소재ID</option>
                    </select>
                  </div>
                  <div className="box-filter">
                    <div className="input-group comp-search">
                      <div className="inner-input-group">
                        <input
                          id="keyword"
                          type="text"
                          className="tf-comm"
                          placeholder="검색어를 입력해 주세요."
                          defaultValue={q_keyword ?? ''}
                        />
                        <i className="ico ico-search"></i>
                      </div>
                    </div>
                  </div>
                  <div className="box-filter">
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      onClick={() => {
                        onGridClick()
                      }}
                    >
                      검색
                    </button>
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">캠페인 상태</p>
                  </div>
                  <div className="box-filter">
                    <select id="statusList" className="select2-single" disabled={disabled}>
                      <option value="NONE" defaultChecked={true}>
                        전체
                      </option>
                      {statusList.map((a: any, k: any) => (
                        <option key={k} value={a[0]} label={a[0]}>
                          {a[1]}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-header">
            <div className="box-option">
              <button id="syncCreation" type="button" className="btn btn-primary-outline">
                소재상태 새로고침
              </button>
              {/*<button id="frequencyAll" type="button" className="btn btn-primary-outline">게재빈도 전체보기</button>*/}
              <button
                type="button"
                className="btn btn btn-secondary-outline btn-ico"
                onClick={() => {
                  onBtnExport()
                }}
              >
                <i className="ico ico-download"></i>다운로드
              </button>
            </div>
          </div>
          <div className="box-body">
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }} className="ag-theme-alpine">
              <div className="ag-grid">
                <div className="ag-grid-inner">
                  <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    gridOptions={topOptions}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    treeData={true}
                    groupDefaultExpanded={
                      ($('#type').val() !== '' && $('#keyword').val() !== '') ||
                      (q_type === 'creationId' && q_keyword !== undefined)
                        ? -1
                        : 0
                    }
                    getDataPath={getDataPath}
                    pagination={true}
                    paginationPageSize={10}
                    rowHeight={48}
                    alwaysShowHorizontalScroll={true}
                    suppressPaginationPanel={true}
                    suppressCsvExport={true}
                    popupParent={popupParent}
                    onPaginationChanged={changePagination}
                    domLayout={'autoHeight'}
                    loadingOverlayComponent={TreeGridHeightLayout}
                    noRowsOverlayComponent={NoDataTemplate}
                    onGridReady={() => onGridReady(false)}
                    rowSelection={'single'}
                    tooltipShowDelay={0}
                    tooltipHideDelay={5000}
                    // onRowSelected={onRowSelected}
                    // onSelectionChanged={onSelectionChanged}
                    suppressRowClickSelection={true}
                    suppressContextMenu={true}
                    onCellClicked={onCellClicked}
                    enableCellTextSelection={true}
                    // cacheQuickFilter={true}
                  ></AgGridReact>
                </div>
              </div>
            </div>
          </div>
          <div className="box-footer">
            <Pagination gridRef={gridRef} ref={childRef} />
          </div>
          <FbDialog row={row} />
          <ItgDialog row={row} />
          <GdnDialog row={row} />
        </section>
      </div>
    </Fragment>
  )
}

export default MngCamp
