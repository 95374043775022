import { nanoid } from 'nanoid'
import { useAIInspectionHistoryQuery } from '@biz/query'
import { AIInspectionHistoryContent } from '../../AIInspectionHistoryContent'
import { AdminNote } from './AdminNote'
import { InspectionResult } from './InspectionResult'
import styles from './InspectionList.module.scss'

type InspectionListFunction = {
  creationDataId: number
  onItemChange?: () => void
}
export const InspectionList: React.FC<InspectionListFunction> = ({ creationDataId, onItemChange }) => {
  const { data: list, isLoading, invalidateQuery } = useAIInspectionHistoryQuery(creationDataId, 'admin')
  const doItemChange = () => {
    onItemChange?.()
    invalidateQuery?.()
  }
  return (
    <div className={styles.containInspectionList}>
      {!isLoading &&
        Array.isArray(list) &&
        list.reverse().map((n) => (
          <section className={styles.inspectionNote} key={nanoid()}>
            <InspectionResult status={n.gptInspStatus} dateString={n.completeTime}>
              {n.gptInspStatus == 'ok' ? (
                '검수한 모든 항목이 승인 되었습니다.'
              ) : n.comments ? (
                // 신규 AI 검수 결과
                <AIInspectionHistoryContent comments={n.comments} />
              ) : !!n.gptInspContent ? (
                // 이전 ChatGPT 검수 결과
                <p>{n.gptInspContent}</p>
              ) : (
                ''
              )}
            </InspectionResult>
            <AdminNote value={n} aiInspectionId={n.creationDataGptHisId} onItemChange={doItemChange} />
          </section>
        ))}
    </div>
  )
}
