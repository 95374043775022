import React, { useCallback, useEffect, useState, useRef } from 'react';

export interface UseOutsideClickProps {
  callback: (event: Event) => void;
  enabledRef?: React.RefObject<boolean>;
}

export function useOutsideClickRef({
  callback,
  enabledRef = { current: true },
}: UseOutsideClickProps) {
  const [element, setElement] = useState<HTMLElement | null>();
  const ref = useCallback((el: HTMLElement | null) => setElement(el), []);
  const localStateRef = useRef({ callback: callback });
  const localState = localStateRef.current;
  localState.callback = callback;

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (element && !element.contains(event.target as Node) && enabledRef.current && localState.callback) {
        localState.callback(event);
      }
    }

    let timer: number;
    /**
     * React 16 이후로 timeout을 적용하지 않으면 아래와 같은 이슈가 있음.
     * button클릭시 모달 오픈을 하는 동작에서 모달이 오픈되고, 아래 핸들러가 바인딩 되고, button click이벤트가 document에 버블링 되어 연결된 핸들러가 호출됩니다.
     * 버튼이 클릭 되었기 때문에 모달 외부 클릭으로 간주되어 모달은 사라집니다.
     * document에 이벤트 바인딩을 다음 랜더링 프로세스부터 적용하도록 timeout을 적용.
     **/
    if (element) {
      timer = window.setTimeout(() => {
        document.addEventListener('click', handler);
      }, 0);
    }

    return () => {
      document.removeEventListener('click', handler);
      window.clearTimeout(timer);
    };
  }, [element]);

  return ref;
}

export default useOutsideClickRef;
