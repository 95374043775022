import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useQuery, useQueryClient } from '@tanstack/react-query' // try next react-query;
import { ApiUtil } from '@biz/api'
import {
  useLoginStatusQuery,
  useAlertUnreadCountQuery,
  useUsableCostQuery,
  useRoleQuery,
  useShadowLoginQuery,
  // useLoginCheckQuery,
} from '@biz/query'
import { loginForm } from '../../AdminLoginPage/Login'
import alertify from 'alertifyjs'
import { ShadowLoginMemberList } from './ShadowLoginMemberList'

export interface ShadowLoginMember {
  id: number
  memberName: string
}

// 쉐도우 로그인 권한 가진 관리자
let isAdminShadow = false
// 쉐도우 로그인 권한없는 관리자
let isAdmin = false
let isMgr = false

// 강제로 로그인 화면으로 이동시 로그아웃 처리
export const initHeader = () => {
  ApiUtil.get<loginForm>('/api/common/logout').then((response) => {
    if (response.data) {
      isAdminShadow = false
      isAdmin = false
      isMgr = false
      $('#shadowDiv').hide()
      $('#loginButton').show()
      $('#logoutButton').hide()
      $('#menuList').find('li').not('.one-depth').attr('class', '')
      $('#menuList').find('li').not('.two-depth').removeClass('active')
      // 로그아웃시 localStorage 정보 삭제
      localStorage.removeItem('user')
      localStorage.removeItem('shadow')
      localStorage.removeItem('shadowRole')
    }
  })
}
const getShadowLoginList = async () => {
  const { data } = await ApiUtil.get<ShadowLoginMember[]>('/api/shadowLogin/shadowLoginList')
  return data
}
const Header = () => {
  const url = useLocation()
  const navigate = useNavigate()
  const { data, refetch: refetchAlertUnreadCount } = useAlertUnreadCountQuery()
  const unreadBellAlertCnt = data || 0
  const { data: usableCostChange, invalidate: invalidateUsableCost } = useUsableCostQuery()
  useEffect(() => {
    if (!usableCostChange) {
      invalidateUsableCost()
    }
  }, [usableCostChange])
  const { data: loginStatus, update: setLoginStatus } = useLoginStatusQuery()
  const { data: auth, update: setAuth } = useRoleQuery()
  const { update: setShadowLogin } = useShadowLoginQuery()

  // FIXME: 화면 로딩이 빠른 경우 쉐도우 로그인 목록을 가져오기 전에 목록을 생성해서 refetch로 데이터를 다시 가져오는 방식으로 해결합니다.
  const useShadowLoginListQueryResult = useQuery({
    queryKey: ['shadowLoginList'],
    queryFn: getShadowLoginList,
    enabled: false,
  })

  const info = localStorage.getItem('user')
  const shadowInfo = localStorage.getItem('shadow')

  const [isShadowLoggedOut, setIsShadowLoggedOut] = useState(false)
  const [makerName, setMakerName] = useState(shadowInfo ? JSON.parse(shadowInfo).memberName : '')
  const [memberName, setMemberName] = useState('')
  const [shadowLoginMakerSearchText, setShadowLoginMakerSearchText] = useState('')
  const [shadowMakers, setShadowMakers] = useState<ShadowLoginMember[]>([])

  const loginCheckEvent = () => {
    ApiUtil.get('/api/loginCheck').then((response) => {
      if (response.data.ok) {
        // 메이커 로그인 또는 쉐도우 로그인 한 경우
        if (response.data.data && response.data.data.makerNm) {
          setMakerName(response.data.data.makerNm)
          window.appboy?.changeUser(response.data.data.wadizUserId) // 브레이즈 유저 설정
        }

        /**
         * 다음 조건을 모두 만족하는 경우 로그인 여부를 `true`로 설정합니다.
         *
         * - 쉐도우 로그인 권한이 있는 사용자인 경우 (관리자)
         * - 약관에 동의한 경우
         */
        if (isAdminShadow || response.data.data.agreeYn) {
          setLoginStatus(true)
        } else {
          navigate('/maker/setting/agreeYn')
        }
      } else {
        setLoginStatus(false)
        // navigate('/common/login');
        // window.location.href = response.data.data;
      }
    })
  }

  // 권한 체크를 위해 로그인 여부를 확인
  useEffect(() => {
    // 로그인체크를 하지 않은 url
    if (url.pathname === '/common/login') {
      // 여기서 우측 헤더 부분 숨김 처리
      $('#userInfoDiv').hide()
      // localStorage.setItem('adminLoginPath', url.pathname);
      return
    }
    $('#userInfoDiv').show()
    loginCheckEvent()
  }, [url.pathname])

  const queryClient = useQueryClient()
  const logoutSuccess = () => {
    isAdminShadow = false
    isAdmin = false
    isMgr = false
    $('#shadowDiv').hide()
    $('#loginButton').show()
    $('#logoutButton').hide()
    $('#menuList').find('li').not('.one-depth').attr('class', '')
    $('#menuList').find('li').not('.two-depth').removeClass('active')
    // 로그 아웃시 메뉴 관련 쿼리를 invalidateQueries를 사용하여 재호출한다.
    queryClient.invalidateQueries({ queryKey: ['menus'] })
    queryClient.invalidateQueries({ queryKey: ['subMenus'] })
  }
  const initLogin = () => {
    $('#loginButton').show()
    $('#logoutButton').hide()
  }

  useEffect(() => {
    initLogin()
    if (info !== null) {
      const userInfo = JSON.parse(info)
      if (userInfo.ok) {
        setLoginStatus(true)
        if (userInfo.data.authorities.indexOf('ROLE_SHADOW') >= 0) {
          isAdminShadow = true
          $('#shadowDiv').show()
          // 쉐도우 로그인 목록 데이터를 가져온다.
          useShadowLoginListQueryResult.refetch().then((r) => {
            if (r.isSuccess) {
              setShadowMakers(r.data)
            }
          })
        } else if (userInfo.data.authorities.indexOf('ROLE_ADMIN_ACTION') >= 0) {
          isAdmin = true
          $('#shadowDiv').hide()
        }
        if (userInfo.data.roleGroup === 'USER_MGR') {
          isMgr = true
        }
        setMemberName(userInfo.data.memberName)
      }
    } else {
      setLoginStatus(false)
      logoutSuccess()
    }
  }, [isAdminShadow])

  const logout = () => {
    ApiUtil.get<loginForm>('/api/common/logout').then((response) => {
      if (response.data) {
        alertify.alert('로그아웃', '이용해 주셔서 감사합니다.', () => {
          setLoginStatus(false)
          const user = localStorage.getItem('user')
          const userInfo = user === null ? null : JSON.parse(user)
          if (userInfo !== null && userInfo.data !== undefined) {
            // 로그아웃시 localStorage 정보 삭제
            localStorage.removeItem('user')
            localStorage.removeItem('shadow')
            localStorage.removeItem('shadowRole')
            navigate('/common/login')
          }
          logoutSuccess()
          // 종알임을 위한 로그인 플래그 false 전환
        })
      }
    })
  }

  const logoutForMaker = () => {
    location.href = '/api/common/logout'
  }

  const shadowLogoutEvent = () => {
    ApiUtil.get<ShadowLoginMember>('/api/shadowLogin/shadowLogout').then((response) => {
      if (response.data) {
        alertify.alert('쉐도우 로그아웃', '이용해 주셔서 감사합니다.')
        localStorage.removeItem('shadow')
        localStorage.removeItem('shadowRole')
        setMakerName('메이커 로그인')
        setIsShadowLoggedOut(true)
        // const shadowLoginList = document.querySelectorAll('[id^=shadowLoginList]');
        // shadowLoginList.forEach((shadowLoginListInfo:any) => {
        //   if (shadowLoginListInfo.classList.contains('selected')) {
        //     shadowLoginListInfo.classList.remove('selected');
        //   }
        // });
        setShadowLoginMakerSearchText('')
        $('#shadowLogoutBtn').hide()
        $('#searchText').val('')
        // 쉐도우 로그아웃 성공시 메뉴리스트 재갱신
        queryClient.invalidateQueries({ queryKey: ['menus'] })
        queryClient.invalidateQueries({ queryKey: ['subMenus'] })
        setShadowLogin(false)
        setAuth('ROLE_ADMIN')
        navigate('/')
      }
    })
  }
  useEffect(() => {
    if (info != null) {
      if (JSON.parse(info).ok) {
        if (url.pathname !== '/common/login') {
          /** 쉐도로그인 하면 쉐도 계정 알림 개수로 */
          refetchAlertUnreadCount()
          loginCheckEvent()
        }
      }
    }
  }, [makerName])
  /** 비즈머니 잔액 표기 및 자동 충전 여부 */
  const [atptActYn, setAtptActYn] = useState(false)
  const atptActYnFunction = () => {
    ApiUtil.get('/api/bzm/common/findAutoChargeInfo').then((resp) => {
      setAtptActYn(resp.data.data.atptActYn)
    })
  }

  /** 일반 메이커가 로그인 했을 경우 (쉐도우 로그인 X) */
  useEffect(() => {
    if (!isAdminShadow && !isMgr && loginStatus) {
      // 로그인했을때만 호출
      // 로그인 url이 아닐때만 실행
      if (url.pathname !== '/common/login') {
        atptActYnFunction()
      }
    }
  }, [])

  /** 어드민인데 쉐도우 로그인 한 경우 쉐도우 로그인 성공시 호출 */
  const updateHeaderInfoOnShadowLogin = () => {
    if (url.pathname !== '/common/login') {
      refetchAlertUnreadCount()
      atptActYnFunction()
      if (shadowInfo !== null) {
        const shadowInfo2 = JSON.parse(shadowInfo)
        setMakerName(shadowInfo2.memberName)
      }
    }
  }

  useEffect(() => {
    if (loginStatus) {
      if (info !== null) {
        const userInfo2 = JSON.parse(info)
        if (userInfo2.ok) {
          // 메이커가 아닌 관리자로 로그인했을 경우에만
          if (userInfo2.data.authorities.indexOf('ROLE_USER_MAKER') < 0) {
            // 화면 리프레시 일경우 처리
            if (shadowInfo !== null) {
              // 로그인 url이 아닐때만 실행
              if (url.pathname !== '/common/login') {
                updateHeaderInfoOnShadowLogin()
                if (shadowInfo !== null) {
                  const shadowInfo2 = JSON.parse(shadowInfo)
                  setMakerName(shadowInfo2.memberName)
                  // 로그인되어 있는 메이커가 현재 화면의 메이커와 다를경우 홈으로 이동
                  if (makerName !== shadowInfo2.memberName) {
                    navigate('/')
                  }
                }
                // 다른브라우저에서 관리자로 로그인되어 있고(쉐도우 로그아웃 상태) 현재 브라우저가 메이커로 쉐도우 로그인되어 있을 경우
                // 메인 경로가 아닌경우 홈으로 이동
                if (url.pathname !== '/main' && auth === 'ROLE_ADMIN') {
                  navigate('/')
                }
                setShadowLogin(true)
                setAuth('ROLE_USER_MAKER')
              }
            } else {
              setMakerName('메이커 로그인')
              refetchAlertUnreadCount()
              // 다른 브라우저에서 메이커로 쉐도우 로그인 되어 있고 현재 브라우저가 관리자로 쉐도우 로그아웃 되어 있을 경우
              // 메인 경로가 아닌경우 홈으로 이동
              if (url.pathname !== '/main' && auth === 'ROLE_USER_MAKER') {
                // navigate('/')
              }
              setShadowLogin(false)
              setAuth('ROLE_ADMIN')
            }
            queryClient.invalidateQueries({ queryKey: ['menus'] })
            queryClient.invalidateQueries({ queryKey: ['subMenus'] })
          }
        }
      }
    }
  }, [shadowInfo, loginStatus])

  return (
    <header className="header" style={{ width: '100vw' }}>
      <h1 className="logo">{url.pathname === '/common/login' ? <Link to="#"></Link> : <Link to="/main"></Link>}</h1>
      <div className="user-info" id="userInfoDiv">
        <div className="name">
          <span className="fz-14 fc-1 fw-medium">{memberName || makerName}</span>
        </div>
        {isAdminShadow || isAdmin ? (
          <div className="name">
            <Link to="#" onClick={logout}>
              <span className="fz-14 fc-4">관리자 로그아웃</span>
            </Link>
          </div>
        ) : null}
        {loginStatus && !isAdmin && !isAdminShadow && (
          <div className="name">
            <Link to="#" onClick={logoutForMaker}>
              <span className="fz-14 fc-4">메이커 로그아웃</span>
            </Link>
          </div>
        )}
        <Link className="notify" to="/makerCommon/bellAlert">
          <i className="ico i-16 ico-notify"></i>
          {unreadBellAlertCnt > 0 ? <span className="badge">{unreadBellAlertCnt}+</span> : null}
        </Link>
        {(isAdminShadow && shadowInfo !== null) || (isMgr && shadowInfo !== null) || (!isAdminShadow && !isMgr) ? (
          <Link className="biz-money" to="/bzm/charge">
            {atptActYn ? (
              <span className="auto-charge on">
                자동충전 <span className="eng">ON</span>
              </span>
            ) : (
              <span className="auto-charge off">
                자동충전 <span className="eng">OFF</span>
              </span>
            )}
            <span className="fz-14 fc-1 fw-medium" id="bzm">
              비즈머니
            </span>
            <span className="fz-14 fc-2" id="usableCost">
              {`${usableCostChange || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
            </span>
          </Link>
        ) : null}
        {isAdminShadow === true && (
          <div className="shadow comp-dropdown" id="shadowDiv">
            <Link className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false" to="#">
              <i className="ico i-24 ico-shadow"></i>
              <span className="fz-14 fc-2 fw-medium">{makerName}</span>
            </Link>
            <div className="dropdown-menu">
              <div className="box-body">
                <div className="input-group comp-search">
                  <div className="inner-input-group selected">
                    <input
                      type="text"
                      className="tf-comm"
                      placeholder="검색어를 입력해 주세요"
                      id="searchText"
                      onChange={(e) => {
                        setShadowLoginMakerSearchText(e.target.value)
                      }}
                    />
                    <i className="ico ico-search" role="presentation"></i>
                  </div>
                  {isAdminShadow ? (
                    <ul className="opt-selectbox" id="makerListScroll">
                      {shadowMakers !== undefined ? (
                        <ShadowLoginMemberList
                          isAdmin={isAdminShadow}
                          isShadowLoggedOut={isShadowLoggedOut}
                          members={shadowMakers}
                          onShadowLoginSuccess={updateHeaderInfoOnShadowLogin}
                          searchText={shadowLoginMakerSearchText}
                          setIsShadowLoggedOut={setIsShadowLoggedOut}
                          setMakerName={setMakerName}
                        />
                      ) : null}
                    </ul>
                  ) : null}
                </div>
              </div>
              <div className="box-footer">
                <button
                  type="button"
                  className="btn btn-tertiary-mint expand"
                  onClick={shadowLogoutEvent}
                  id="shadowLogoutBtn"
                  style={{ display: shadowInfo !== null ? '' : 'none' }}
                >
                  메이커 로그아웃
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  )
}
export default Header
