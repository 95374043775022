import { axios } from '../axios'
import { CategoryInspectionConfig } from './category'

interface Response {
  ok: boolean
  data: boolean
}

/**
 * 카테고리 검수 정보 단건 저장
 * @see https://wadiz.atlassian.net/wiki/spaces/TF/pages/16478732614/-+API#%F0%9F%86%95-%EC%B9%B4%ED%85%8C%EA%B3%A0%EB%A6%AC-%EC%84%A4%EC%A0%95-%EC%A0%95%EB%B3%B4-%EC%A0%80%EC%9E%A5-%EB%8B%A8%EA%B1%B4-%EC%A0%80%EC%9E%A5
 */
export const postCategoryByCode = (param: Omit<CategoryInspectionConfig, 'desc'>): Promise<Response> =>
  axios.post('/api/mngAd/creation/categoryInspMng', param)
