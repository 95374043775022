import React, { forwardRef, useRef, useLayoutEffect } from 'react'
import cx from 'classnames'

import { CheckIcon, RemoveIcon } from '@wadiz-frontend/waffle-icons-modified'
import useForkRef from '../useForkRef'
import HelperMessage from '../HelperMessage'

import styles from './Checkbox.module.scss'

export interface CheckboxProps<T = HTMLInputElement> extends Omit<React.InputHTMLAttributes<T>, 'size'> {
  /** 체크박스 ID */
  id?: string

  /** 체크박스 name */
  name?: string

  /** 체크박스 텍스트 */
  text?: string

  /** 체크박스 크기*/
  size?: 'sm' | 'md' | 'lg'

  /** 일부 체크박스만 체크 */
  indeterminate?: boolean

  /** 비활성화 여부 */
  disabled?: boolean

  /** 가로 정렬 여부 */
  isHorizontal?: boolean

  /** 에러 여부 */
  isError?: boolean | 'error'

  /** 체크박스 설명 */
  description?: string | React.ReactNode

  /** 체크박스 에러 메세지 */
  errorMessage?: string | React.ReactNode

  className?: string

  children?: React.ReactNode
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      id,
      indeterminate = false,
      className,
      size = 'md',
      isHorizontal = false,
      isError = false,
      text,
      description,
      errorMessage,
      children = null,
      ...props
    },
    ref
  ) => {
    const checkRef = useRef<HTMLInputElement>(null)
    const checkboxRef = useForkRef(ref, checkRef)

    const classes = cx(
      styles.checkbox,
      size && {
        [styles.sm]: size === 'sm',
        [styles.md]: size === 'md',
        [styles.lg]: size === 'lg',
      },
      {
        [styles.inline]: isHorizontal,
        [styles.labeled]: children || text,
        [styles.error]: !!isError,
      },
      className
    )

    const messageClasses = cx(
      styles.message,
      size && {
        [styles.sm]: size === 'sm',
        [styles.md]: size === 'md',
        [styles.lg]: size === 'lg',
      }
    )

    useLayoutEffect(() => {
      if (checkRef.current) {
        checkRef.current.indeterminate = indeterminate
      }
    }, [indeterminate])

    return (
      <>
        <label htmlFor={id} className={classes}>
          <input {...props} id={id} type="checkbox" className={styles.input} ref={checkboxRef} />
          <span className={styles.icon} aria-hidden="true">
            <RemoveIcon className={styles.removeIcon} />
            <CheckIcon className={styles.checkIcon} />
          </span>
          <span className={styles.content}>
            {text && <span>{text}</span>}
            {children && <span>{children}</span>}
          </span>
        </label>
        {description && <HelperMessage className={messageClasses}>{description}</HelperMessage>}
        {isError && errorMessage && (
          <HelperMessage className={messageClasses} isError>
            {errorMessage}
          </HelperMessage>
        )}
      </>
    )
  }
)

export default Checkbox
