import { Checkbox, CheckboxOptionType } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import styles from './CheckGroup.module.scss'

type CheckGroupFunction = {
  value?: string
  onChange?: (values: string) => void
  options: CheckboxOptionType[]
}
export const CheckGroup: React.FC<CheckGroupFunction> = ({ value, onChange, options }) => {
  const checkedList = !value ? [] : value.split(',')
  const doChange = (selectedList: string[]) => onChange?.(selectedList.join(','))

  return (
    <div className={styles.checkGroup}>
      <Checkbox
        indeterminate={checkedList.length > 0 && checkedList.length < options.length}
        checked={options.length === checkedList.length}
        onChange={(e: CheckboxChangeEvent) => {
          onChange?.(e.target.checked ? options.map((n) => n.value).join(',') : '')
        }}
      >
        전체
      </Checkbox>
      <Checkbox.Group options={options} value={checkedList} onChange={doChange} />
    </div>
  )
}
