import type { AIComment } from '@biz/api'
import { StatusTag } from './StatusTag'
import styles from './InspectionResult.module.scss'

type InspectionResultFunction = {
  status: 'ing' | 'error' | 'ok' | 'fail'
  dateString: string
  children?: React.ReactNode
}
export const InspectionResult: React.FC<InspectionResultFunction> = ({ status, dateString, children }) => {
  return (
    <div className={styles.containInspectionResult}>
      <div className={styles.resultHead}>
        <div className={styles.titleSection}>
          <h3>AI 검수</h3>
          <StatusTag status={status} />
        </div>
        <div className={styles.date}>{dateString}</div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}
