import { useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query'
import { fetchUsableCost } from '@biz/api'
import { loadRemainAmount } from '../../createTargetAd'
import { useRoleQuery } from '../../auth'

export const useUsableCostQuery = () => {
  const queryKey = ['@biz/query/frame/UsableCost']
  const queryClient = useQueryClient()
  const { data: role } = useRoleQuery()

  return {
    ...useQuery({
      queryKey,
      queryFn: () =>
        fetchUsableCost().then((res) => {
          if (res?.ok === false) {
            // 이 상황에서 data는 값이 아니라 다음 내용이 올라온다.
            // {
            //   action : "REDIRECT_TO_TOME"
            //   msg : "로그인이 해제되었습니다. F5버튼을 눌러서 다시 로그인해주세요"
            //   title : "접근권한 없음"
            // }
            // 그런데 이런 상황에는 대응할 수 없다. 이 query는 헤더에서 사용하기 때문에 main으로 던지면 리프레시 루프를 돌 것이다.
            // 따라서, 다른 쪽에서 대응이 필요하다. 그러므로 여기에서는 그냥 0을 던진다.
            // throw new Error('Data is zero, retrying...')
            return 0
          }
          return res.data
        }),
      retry: 3,
      retryDelay: 500,
      enabled: role !== 'ROLE_ADMIN', // 관리자의 경우 권한 오류가 발생
      refetchOnMount: true,
    }),
    update: (num: string) => queryClient.setQueryData(queryKey, num),
    invalidate: () => queryClient.invalidateQueries({ queryKey }),
    invalidateWithBizMoney: () => {
      loadRemainAmount(queryClient)('BIZ_MONEY').then((res) => {
        if (res.ok === false) {
          return
        }
        queryClient.setQueryData(queryKey, res.data.currentCost)
      })
    },
  }
}
