import React, {
  forwardRef,
  Fragment, useEffect, useImperativeHandle, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query' // try next react-query;
import { useNavigate } from 'react-router';
import { PlainObject } from 'select2';
import { ApiUtil2 } from '@biz/api'
import { ggAdSet } from './MngMdGgAdSet';
import { ApiUtil } from '@biz/api'
import alertify from 'alertifyjs'

export interface mediaGgForm {
  ok: boolean;
  useYn: boolean;
  mediaAccId: string,
  mediaAccNm:string
  excludeLocation:string
  projectDiv3:string
  mediaDiv2:string
  tabOrder:number|any
  adSetDataList: ggAdSet[]
}
interface sharedSetData {
  sharedSetName : string
  sharedSetResourceName : string
}
export interface sharedSets {
  ok: boolean;
  data : sharedSetData[]
}
const MngMdGg = (props: any, ref: any) => {
  const navigate = useNavigate();
  const [ggCustomerId, setGgCustomerId] = useState('');
  // 광고집행 계정여부 확인
  const [customerIdCheck, setCustomerIdCheck] = useState('');
  const {
    register, setValue, getValues,
  } = useForm<mediaGgForm>({
    defaultValues: { tabOrder: 1, excludeLocation: '' },
  });
  // 기본 프로젝트 선택 / 광고노출매체 세팅
  setValue('projectDiv3', props.project);
  setValue('mediaDiv2', props.md);
  setValue('useYn', false);
  // 게제 제외 위치 지역
  const getSharedSets = async () => {
    const result = await ApiUtil2.get<sharedSets>('/api/mngMd/mngMdGg/sharedSetSearch', {
      params: { customerId: ggCustomerId },
    });
    return result.data;
  };
  const getSharedSetsData = useQuery({ queryKey: ['getSharedSets'], queryFn: getSharedSets, enabled: false })

  // 광고주 조회
  const getGgCustomerInfos = async () => {
    const result = await ApiUtil.get<any>('/api/mngMd/mngMdGg/ggCustomerInfoSearch')
    return result.data
  }
  const getGgCustomerInfosApi = useQuery({
    queryKey: ['getGgCustomerInfos'],
    queryFn: getGgCustomerInfos,
    enabled: false,
  })
  const changeAdvEvent = () => {
    // 초기값
    const result: any[] = [{ id: '', text: '' }]
    $('#ggExlocationSelect').empty()
    // 광고주 변경시 refetch로 게제제외위치 지역 API 호출
    getSharedSetsData.refetch().then((r) => {
      if (r.isSuccess) {
        r.data.data.map((option) => {
          result.push({ id: option.sharedSetResourceName, text: option.sharedSetName })
          return result
        })
        $('#ggExlocationSelect').removeAttr('disabled')
        $('#ggExlocationSelect').select2({
          width: '330px',
          minimumResultsForSearch: Infinity,
          placeholder: '게재 제외 위치 목록을 선택해 주세요.',
          data: result,
        })
      }
    })
  }
  useEffect(() => {
    if (customerIdCheck !== '' && ggCustomerId !== '') {
      alertify
        .confirm('다른 광고 집행 계정으로 변경 하시겠습니까?<br/>단, 변경 시 기존 기입 정보는 초기화 됩니다.', () => {
          props.customerIdProps(ggCustomerId)
          changeAdvEvent()
        })
        .set({ labels: { cancel: '취소', ok: '확인' } })
    } else if (customerIdCheck === '' && ggCustomerId !== '') {
      props.customerIdProps(ggCustomerId)
      setCustomerIdCheck(ggCustomerId)
      changeAdvEvent()
    }
  }, [ggCustomerId])
  // 초기 select 설정
  useEffect(() => {
    const advInfos: any[] = [{ id: '', text: '' }]
    getGgCustomerInfosApi.refetch().then((r) => {
      if (r.isSuccess) {
        r.data.data.forEach((info: any) => {
          const infoParse = JSON.parse(info)
          advInfos.push({ id: infoParse.id, text: `${infoParse.text}(${infoParse.etc})` })
        })

        $('#ggAdvSelect')
          .select2({
            width: '330px',
            minimumResultsForSearch: Infinity,
            placeholder: '광고를 집행할 계정을 선택해 주세요.',
            data: advInfos,
          })
          .on('select2:select', (e) => {
            setValue('mediaAccId', e.params.data.id)
            advInfos.map((info: any) => {
              if (info.id === e.params.data.id) {
                setValue('mediaAccNm', info.text)
              }
              return null
            })
            setGgCustomerId(e.params.data.id)
          })
      }
    })
    $('#ggExlocationSelect')
      .select2({
        width: '330px',
        minimumResultsForSearch: Infinity,
        placeholder: '게재 제외 위치 목록을 선택해 주세요.',
      })
      .on('select2:select', (e) => {
        setValue('excludeLocation', e.params.data.id)
      })
    // ggAdSet = [{ changeOrder: 'first' }, { changeOrder: 'second' }];
  }, [])
  // 등록 처리
  const mediaGgRegApi = () => {
    const { mediaAccId, mediaAccNm, excludeLocation, projectDiv3, mediaDiv2, adSetDataList, useYn } = getValues()
    const result = ApiUtil2.post<mediaGgForm>('/api/mngMd/mngMdGg/mediaGgRegReq', {
      mediaAccId,
      mediaAccNm,
      excludeLocation,
      projectDiv3,
      mediaDiv2,
      adSetDataList,
      useYn,
    })
    return result
  }
  const mediaGgReg = useMutation({
    mutationFn: mediaGgRegApi,
    onSuccess: (resp) => {
      if (resp.data.ok) {
        alertify.success('정상적으로 등록되었습니다.', 2, () => {
          navigate('/mngMd/mngMd')
        })
      }
    },
    // onError: (error) => {
    //   console.log(error);
    // },
    // onMutate: () => {
    //   console.log('등록 실행....');
    // },
  })
  const mediaGgRegSubmit = () => {
    const project = getValues('projectDiv3');
    const customerId = getValues('mediaAccId');
    let comNm = '';
    getValues('adSetDataList').map((adSet:ggAdSet) => {
      comNm = adSet.comNm;
      if (adSet.comNm === '') {
        $(`#comNm${adSet.adSetOrder}`).addClass('negative');
      }
      return adSet;
    });
    if (project === '' || customerId === '' || comNm === '') {
      alertify.error('선택하지 않으신 필수 항목이 있습니다.<br/>확인 후 다시 시도해 주세요.');
      // error
      if (project === '') {
        $(props.regProjectSelect.current as PlainObject).addClass('error');
      } else {
        $(props.regProjectSelect.current as PlainObject).removeClass('error');
      }
      if (customerId === '') {
        $('#ggAdvSelect').addClass('error');
      } else {
        $('#ggAdvSelect').removeClass('error');
      }
    } else {
      mediaGgReg.mutate();
    }
  };
  // 넘겨받은 데이터 셋팅
  const mediaGgAdSetDataSetting = (ggAdSetData:any) => {
    setValue('adSetDataList', ggAdSetData);
  };
  useImperativeHandle(ref, () => ({
    mediaGgRegSubmit,
    mediaGgAdSetDataSetting,
  }));
  return (
    <Fragment>
      <dl className="vertical">
        <dt>
          <div className="dt-inner">
            <span className="fz-16 fc-1">
                광고 집행 계정
                <i className="fz-12 fc-5">*</i>
            </span>
          </div>
        </dt>
        <dd>
          <div className="form-group">
            <select className="select2-single expand" id="ggAdvSelect" {...register('mediaAccId')} >
              <option value=""></option>
            </select>
          </div>
        </dd>
      </dl>
      <dl className="vertical">
        <dt>
          <div className="dt-inner">
            <span className="fz-16 fc-1">
                게재 제외 위치 설정
            </span>
          </div>
        </dt>
        <dd>
          <div className="form-group ">
            <select className="select2-single expand" id="ggExlocationSelect" {...register('excludeLocation')} disabled>
              <option value=""></option>
            </select>
          </div>
        </dd>
      </dl>
    </Fragment>
  );
};

export default forwardRef(MngMdGg);
