import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { fetchCreationDataInspectionHistory } from '@biz/api'

type UseFunctionType = (creationDataInspHisId?: number) => UseQueryResult
export const useCreationDataPreviewQuery: UseFunctionType = (creationDataInspHisId) =>
  useQuery({
    queryKey: ['@app/biz/AIInspectionHistoryPage/CreationDataPreview', creationDataInspHisId],
    queryFn: () =>
      !creationDataInspHisId
        ? Promise.reject()
        : fetchCreationDataInspectionHistory(creationDataInspHisId).then((res) => res?.data),
    enabled: Boolean(creationDataInspHisId),
  })
