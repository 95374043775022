import React, { Fragment, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query' // try next react-query;
import dayjs from 'dayjs';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import BzmMngDepositHistoryDialog from './dialog/BzmMngDepositHistoryDialog';

interface IMngDepositDetail {
    data:any;
    makerId:number;
    makerLoginId:string;
    makerNm:string;
    makerStatus:string;
    accAtamId:number;
    accStatus:string;
    atamStatus:string;
    atamStatusDesc:string;
    accBankCode:string;
    accCode:string;
    accUserNm:string;
    reqCost:number;
    resCost:number;
    userNo:number;
    sidNo:number;
    bizDiv:string;
    reqTime:string;
    updateTime:string;
    updateAdmin:string;
    agreeTime:string;
    hp:string;
    comment:string;
    newComment:string
}
const MakerStatusMap:any = {
  IA: '휴면',
  NM: '정상',
  RA: '정상',
  DO: '탈퇴',
};
interface IRejectReq {
    ok:boolean;
    data:any;
    accAtamId : number;
}
interface IConfirmReq {
    ok:boolean;
    data:any;
    accAtamId : number;
}
interface ISaveCommentReq {
    ok:boolean;
    data:any;
    accAtamId : number;
    comment:string;
    makerId:number;
}
const BzmMngDepositDetail = () => {
  const setTitle = useOutletContext<any>()
  const navigate = useNavigate()
  const { accAtamId } = useParams()
  const [inUserNo, setInUserNo] = useState('')
  const [resCost, setResCost] = useState(0)
  const [hisDialog, setHisDialog] = useState<boolean>(false)
  const { setValue, getValues } = useForm<IMngDepositDetail>()
  const saveCommentForm = useForm<ISaveCommentReq>()
  const getDetailInfoApi = () => {
    const result = ApiUtil2.get<IMngDepositDetail>('/api/bzmAdmin/mng/bzmMngDeposit/detail', {
      params: { data: { accAtamId } },
    })
    return result
  }
  const getDetailInfoApiEvent = useQuery({
    queryKey: ['mngBzmDeposit', accAtamId],
    queryFn: getDetailInfoApi,
    enabled: false,
  })
  const goDepositListEvent = () => {
    const commnet = document.getElementById('commentArea') as HTMLInputElement
    const comentValue = getValues('comment') === undefined ? '' : getValues('comment')
    if (comentValue !== commnet.value) {
      alertify
        .confirm('저장하지 않으시고 이전 화면으로 이동 하시겠습니까?', () => {
          navigate('/bzmAdmin/mng/mngDeposit')
        })
        .set({ labels: { cancel: '취소', ok: '확인' } })
        .setHeader('특이사항 항목에 변경하신 내용이 있습니다.')
    } else {
      navigate('/bzmAdmin/mng/mngDeposit')
    }
  }
  // 개인 정보 조회
  const confirmClickEvent = () => {
    alertify
      .confirm('조회하시려는 정보는 개인정보로 조회 시 시스템에 기록됩니다.', () => {
        ApiUtil2.get('/api/bzmAdmin/mng/bzmMngDeposit/getHp', {
          params: { data: { makerId: getValues('makerId') } },
        }).then((r: any) => {
          const hpTextHtml = document.getElementById('hpText') as HTMLInputElement
          hpTextHtml.value = r.data.data.hp
        })
      })
      .set({ labels: { cancel: '취소', ok: '확인' } })
      .setHeader('메이커의 개인 정보를 조회하시겠습니까?')
  }
  const settingDetailInfo = async () => {
    getDetailInfoApiEvent.refetch().then((data: any) => {
      if (data.isSuccess) {
        const info = data.data.data.data
        setValue('accAtamId', info.accAtamId)
        setValue('makerId', info.makerId)
        setValue('makerLoginId', info.makerLoginId)
        setValue('makerNm', info.makerNm)
        setValue('makerStatus', info.makerStatus)
        setValue('atamStatus', info.atamStatus)
        setValue('atamStatusDesc', info.atamStatusDesc)
        setValue('reqTime', info.reqTime)
        setValue('hp', info.hp)
        setValue('accBankCode', info.accBankCode)
        setValue('accCode', info.accCode)
        setValue('reqCost', info.reqCost)
        setValue('resCost', info.resCost)
        setValue('accUserNm', info.accUserNm)
        setValue('agreeTime', info.agreeTime)
        // 지급 정보 관련
        setValue('userNo', info.userNo)
        setValue('sidNo', info.sidNo)
        setValue('bizDiv', info.bizDiv)
        setValue('resCost', info.resCost)
        setValue('updateTime', info.updateTime)
        setValue('updateAdmin', info.updateAdmin)
        setValue('comment', info.comment)

        // 진행 특이 사항 값 넣어주기
        if (info.comment !== undefined) {
          const commnet = document.getElementById('commentArea') as HTMLInputElement
          commnet.value = info.comment
        }

        setInUserNo(info.bizDiv === 'user' ? info.sidNo : info.userNo) //  지급 정보 넣어주기
        setResCost(info.reqCost.toLocaleString())
      }
    })
  }
  // 신청 취소 관련
  const rejectApi = () => {
    const result = ApiUtil2.post<IRejectReq>('/api/bzmAdmin/mng/bzmMngDeposit/rejectReqSingle', {
      accAtamId,
    })
    return result
  }
  const rejectApiEvent = useMutation({
    mutationFn: rejectApi,
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 계속 수정 처리
        alertify.success('정상적으로 신청 취소가 진행 되었습니다.')
        settingDetailInfo()
      }
    },
  })

  const rejectEvent = () => {
    if (getValues('atamStatus') !== 'WAIT') {
      alertify.error('입금 대기 상태가 아닙니다. <br> 새로고침 후 다시 시도해 주시기 바랍니다.')
      return
    }

    alertify
      .confirm(
        '무통장 입금 요청 건에 대해 <br>신청 취소를 진행 하시겠습니까?<br><br>진행 후 다시 요청 상태로 변경은 불가 합니다.',
        () => {
          rejectApiEvent.mutate()
        }
      )
      .set({ labels: { cancel: '취소', ok: '확인' } })
      .setHeader('')
  }

  // 입금 완료 관련
  const confirmApi = () => {
    const result = ApiUtil2.post<IConfirmReq>('/api/bzmAdmin/mng/bzmMngDeposit/confirmReqSingle', {
      accAtamId,
    })
    return result
  }
  const confirmApiEvent = useMutation({
    mutationFn: confirmApi,
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 계속 수정 처리
        alertify.success('정상적으로 입금 완료 및 유상 비즈머니 지급이 완료 되었습니다.')
        settingDetailInfo()
      }
    },
  })
  const confirmEvent = () => {
    if (getValues('atamStatus') !== 'WAIT' && getValues('makerStatus') !== 'NM' && getValues('makerStatus') !== 'RA') {
      alertify.error(
        '계정 상태가 ‘정상‘이고 입금 상태가 ‘입금 대기‘ 상태인 건에 <br> 한하여 입금 완료가 가능합니다.<br>새로고침 후 다시 시도해 주시기 바랍니다.'
      )
      return
    }

    alertify
      .confirm(
        '입금 완료를 진행 하시겠습니까?<br><br>진행 후 다시 요청 상태로 변경은 불가 합니다. <br> 지급된 유상 비즈머니에 대해 지급 취소가 불가 합니다.',
        () => {
          confirmApiEvent.mutate()
        }
      )
      .set({ labels: { cancel: '취소', ok: '확인' } })
      .setHeader('')
  }

  // 메모 저장
  const saveCommentApi = () => {
    const { comment, makerId } = saveCommentForm.getValues()
    const result = ApiUtil2.post<ISaveCommentReq>('/api/bzmAdmin/mng/bzmMngDeposit/saveComment', {
      accAtamId,
      comment,
      makerId,
    })
    return result
  }
  const saveCommentApiEvent = useMutation({
    mutationFn: saveCommentApi,
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 계속 수정 처리
        alertify.success('특이사항이 정상적으로 저장 되었습니다.')
        settingDetailInfo()
      }
    },
  })
  const saveCommentEvent = () => {
    const commnet = document.getElementById('commentArea') as HTMLInputElement
    setValue('newComment', commnet.value)
    saveCommentForm.setValue('comment', commnet.value)
    saveCommentForm.setValue('makerId', getValues('makerId'))
    saveCommentApiEvent.mutate()
  }
  const hisDialogOpen = () => {
    setHisDialog(true)
  }
  useEffect(() => {
    setTitle('무통장 입금 상세내역')
    return () => {
      setTitle('')
    }
  }, [])

  useEffect(() => {
    settingDetailInfo()
  }, [])
  return (
    <Fragment>
      {hisDialog ? <BzmMngDepositHistoryDialog setHisDialog={setHisDialog} accAtamId={accAtamId} /> : null}
      <div className="wrap-section wrap-tbl">
        <div className="box-header">
          <div className="box-option">
            <button type="button" className="btn btn-info" onClick={hisDialogOpen}>
              히스토리
            </button>
          </div>
        </div>
        <div className="box-body">
          <div className="tbl">
            <dl className="vertical">
              <dd>
                <div className="form-group">
                  <div className="wrap-section wrap-tbl">
                    <div className="box-header">
                      <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">메이커 계정정보</h2>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="tbl">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col col-3">
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">메이커ID</span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="input-group expand">
                                      <div className="inner-input-group">
                                        <input
                                          type="text"
                                          className="tf-comm"
                                          value={getValues('makerLoginId') || '-'}
                                          disabled
                                        />
                                      </div>
                                      <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                            <div className="col col-3">
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">메이커명</span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="input-group expand">
                                      <div className="inner-input-group">
                                        <input
                                          type="text"
                                          className="tf-comm"
                                          value={getValues('makerNm') || '-'}
                                          disabled
                                        />
                                      </div>
                                      <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                            <div className="col col-3">
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">충전 사업자 번호</span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="input-group expand">
                                      <div className="inner-input-group">
                                        <input type="text" className="tf-comm" value={inUserNo} disabled />
                                      </div>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                            <div className="col col-3">
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">계정상태</span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div
                                      className={
                                        getValues('makerStatus') === 'NM' || getValues('makerStatus') === 'RA'
                                          ? 'txt-state mint large'
                                          : 'txt-state red large'
                                      }
                                    >
                                      {MakerStatusMap[getValues('makerStatus')]}
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wrap-section wrap-tbl">
                    <div className="box-header">
                      <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">입금 정보</h2>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="tbl">
                        <dl className="vertical column-three">
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">입금 상태</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div className="inner-input-group">
                                <input
                                  type="text"
                                  className="tf-comm"
                                  value={getValues('atamStatusDesc') || '-'}
                                  disabled
                                />
                              </div>
                            </div>
                          </dd>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">신청 일시</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div className="input-group expand">
                                <div className="inner-input-group">
                                  <input
                                    type="text"
                                    className="tf-comm"
                                    value={dayjs(getValues('reqTime')).format('YYYY-MM-DD HH:mm') || '-'}
                                    disabled
                                  />
                                </div>
                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                              </div>
                            </div>
                          </dd>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">휴대전화 번호</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div className="input-group">
                                <div className="inner-input-group">
                                  <input
                                    type="text"
                                    className="tf-comm"
                                    id="hpText"
                                    value={
                                      getValues('agreeTime') === undefined ||
                                      (getValues('makerStatus') !== 'NM' && getValues('makerStatus') !== 'RA')
                                        ? ''
                                        : getValues('hp')
                                    }
                                    disabled
                                  />
                                </div>
                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                              </div>
                              {getValues('agreeTime') === undefined ||
                              (getValues('makerStatus') !== 'NM' && getValues('makerStatus') !== 'RA') ? null : (
                                <button
                                  id="hp"
                                  type="button"
                                  className="btn btn-primary-outline w-100"
                                  onClick={confirmClickEvent}
                                >
                                  상세 조회
                                </button>
                              )}
                            </div>
                          </dd>
                        </dl>
                        <dl className="vertical column-three">
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">입금 계좌번호</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div className="input-group expand">
                                <div className="inner-input-group">
                                  <input
                                    type="text"
                                    className="tf-comm"
                                    value={`${getValues('accBankCode') || ''} ${getValues('accCode') || ''}`}
                                    disabled
                                  />
                                </div>
                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                              </div>
                            </div>
                          </dd>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">신청 금액</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div className="input-group expand">
                                <div className="inner-input-group">
                                  <input
                                    type="text"
                                    className="tf-comm"
                                    value={Number(getValues('reqCost') || 0).toLocaleString()}
                                    disabled
                                  />
                                </div>
                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                              </div>
                            </div>
                          </dd>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">입금자명</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div className="input-group expand">
                                <div className="inner-input-group">
                                  <input
                                    type="text"
                                    className="tf-comm"
                                    value={getValues('accUserNm') || '-'}
                                    disabled
                                  />
                                </div>
                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                              </div>
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  {getValues('atamStatus') === 'CONFIRM' ? (
                    <div className="wrap-section wrap-tbl">
                      <div className="box-header">
                        <div className="box-tit">
                          <h2 className="fz-20 fc-1 fw-bold">지급 정보</h2>
                        </div>
                      </div>
                      <div className="box-body">
                        <div className="tbl">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col col-3">
                                <dl className="vertical">
                                  <dt>
                                    <div className="dt-inner">
                                      <span className="fz-16 fc-1">지급 충전 사업자 번호</span>
                                    </div>
                                  </dt>
                                  <dd>
                                    <div className="form-group">
                                      <div className="input-group">
                                        <div className="inner-input-group">
                                          <input type="text" className="tf-comm" value={inUserNo} disabled />
                                        </div>
                                      </div>
                                    </div>
                                  </dd>
                                </dl>
                              </div>
                              <div className="col col-3">
                                <dl className="vertical">
                                  <dt>
                                    <div className="dt-inner">
                                      <span className="fz-16 fc-1">지급 금액</span>
                                    </div>
                                  </dt>
                                  <dd>
                                    <div className="form-group">
                                      <div className="input-group">
                                        <div className="inner-input-group">
                                          <input type="text" className="tf-comm" value={resCost} disabled />
                                        </div>
                                        <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                      </div>
                                    </div>
                                  </dd>
                                </dl>
                              </div>
                              <div className="col col-3">
                                <dl className="vertical">
                                  <dt>
                                    <div className="dt-inner">
                                      <span className="fz-16 fc-1">지급 일시</span>
                                    </div>
                                  </dt>
                                  <dd>
                                    <div className="form-group">
                                      <div className="input-group">
                                        <div className="inner-input-group">
                                          <input
                                            type="text"
                                            className="tf-comm"
                                            value={dayjs(getValues('updateTime')).format('YYYY-MM-DD HH:mm') || '-'}
                                            disabled
                                          />
                                        </div>
                                        <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                      </div>
                                    </div>
                                  </dd>
                                </dl>
                              </div>
                              <div className="col col-3">
                                <dl className="vertical">
                                  <dt>
                                    <div className="dt-inner">
                                      <span className="fz-16 fc-1">진행자</span>
                                    </div>
                                  </dt>
                                  <dd>
                                    <div className="form-group">
                                      <div className="input-group">
                                        <div className="inner-input-group">
                                          <input
                                            type="text"
                                            className="tf-comm"
                                            value={getValues('updateAdmin') || '-'}
                                            disabled
                                          />
                                        </div>
                                        <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                      </div>
                                    </div>
                                  </dd>
                                </dl>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="wrap-section wrap-tbl">
                    <div className="box-header">
                      <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">추가 정보</h2>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="tbl">
                        <dl className="vertical">
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">진행 특이사항 (메모)</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div className="input-group expand">
                                <div className="inner-input-group">
                                  <textarea
                                    className="tf-textarea"
                                    placeholder="무통장 입금 확인 진행시 특이사항이 있다면 기입해 주세요. 400자 내로 기입이 가능합니다."
                                    maxLength={400}
                                    id="commentArea"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div className="form-group txt-right">
                              <button type="button" className="btn btn-primary" onClick={saveCommentEvent}>
                                저장
                              </button>
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="box-footer">
          <div className="box-right">
            {getValues('atamStatus') === 'WAIT' ? (
              <>
                <button type="button" className="btn btn-secondary-outline" onClick={rejectEvent}>
                  신청취소
                </button>
                <button type="button" className="btn btn-primary-outline" onClick={confirmEvent}>
                  입금완료
                </button>
              </>
            ) : null}
            <button type="button" className="btn btn-primary" onClick={goDepositListEvent}>
              확인
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
};
export default BzmMngDepositDetail;
