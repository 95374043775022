import { useCallback } from 'react'
import { PopoverButton } from '../PopoverButton'
import { OptionsView } from './OptionsView'
import { useTranslateOptions, OptionType, OptionLabelType } from './useTranslateOptions'

type FilterListFunction = {
  options: OptionType[]
  value?: string
  onChange?: (v: string) => void
}
export const FilterList: React.FC<FilterListFunction> = ({ options, value, onChange }) => {
  const { labelList, labelToValue, valueToLabel } = useTranslateOptions(options)
  const checkedList = valueToLabel(value)
  const setCheckedList = useCallback(
    (v: OptionLabelType[]) => {
      if (v.length === 0) {
        onChange?.('')
      } else if (v.length === labelList.length) {
        onChange?.('all')
      } else {
        onChange?.(labelToValue(v).join(','))
      }
    },
    [labelList, labelToValue, onChange]
  )
  const firstSelected = labelList.find((n) => n === checkedList?.[0])
  return (
    <PopoverButton
      caption={
        <>
          {checkedList.length === 0
            ? '선택 안함'
            : checkedList.length === labelList.length
            ? '전체'
            : checkedList.length === 1
            ? firstSelected
            : `${firstSelected} 외 ${checkedList.length - 1}개`}
        </>
      }
    >
      <OptionsView options={labelList} value={checkedList} onChange={setCheckedList} />
    </PopoverButton>
  )
}
