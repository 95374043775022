import { useState } from 'react'
import { InspectionType } from '@biz/api'
import { PageTitle, AIInspectionResultModal } from '@biz/ui'
import { AIInspectionFilter } from './AIInspectionFilter'
import { AIInspectionListDownloadButton } from './AIInspectionListDownloadButton'
import { AIInspectionTable } from './AIInspectionTable'
import { useAIInspectionListQuery } from './useAIInspectionListQuery'
import { PreviewModal } from './PreviewModal'

import styles from './AIInspectionHistoryPage.module.scss'

type ModalProps = {
  select: 'preview' | 'ai-result' | 'team-result'
  field: any
  data?: InspectionType
}
export const AIInspectionHistoryPage: React.FC = () => {
  const [filter, setFilter] = useState<string | null>(null)
  const [modalData, setModalData] = useState<ModalProps | null>(null)
  const { data, isLoading, update } = useAIInspectionListQuery({ params: filter })
  return (
    <div className={styles.containAIInspectionHistoryPage}>
      <PageTitle>AI 심의 현황</PageTitle>
      <section className={styles.containAIInspectionFilter}>
        <AIInspectionFilter
          onChange={(f: string) => {
            if (f === filter) {
              update() // 일단 같은 값이 올라오면 강제로 update하게 해둔다.
            } else {
              setFilter(f)
            }
          }}
        />
        <AIInspectionListDownloadButton filter={filter} />
        <AIInspectionTable
          list={data || []}
          isLoading={filter !== null && isLoading}
          onColumn={(select, field, data) => setModalData({ select, field, data })}
        />
      </section>
      <PreviewModal
        isOpen={modalData?.select === 'preview'}
        onClose={() => setModalData(null)}
        creationDataInspHisId={modalData?.select === 'preview' ? modalData?.field : undefined}
      />
      <AIInspectionResultModal
        isOpen={modalData?.select === 'ai-result' || modalData?.select === 'team-result'}
        onClose={() => setModalData(null)}
        data={
          modalData?.select === 'ai-result' || modalData?.select === 'team-result'
            ? (modalData?.data as {
                campId: number
                creationDataId: number
                creationDataGptHisId: number
              })
            : undefined
        }
        onItemChange={update}
      />
    </div>
  )
}
