import { axios } from '../axios'
type paramType = {
  adminInspDiv : string // "text,img,movie"
  adminInspStatus : string // "ok"
  adminMemo : string // "AI 결과 메모 테스트 a"
  creationDataGptHisId : number // 528550017
}
type ReturnType = {
  ok: boolean
  data: boolean
}
export const fetchPostAdminMemo = (params: paramType): Promise<ReturnType> =>
  axios.post(`/api/mngAd/creation/chatGptHis/saveMemo`, params)
