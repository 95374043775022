import { ReactComponent as InfoIcon } from '@wadiz-frontend/waffle-system-icons/info.svg'
import styles from './CreationTipMessageBox.module.scss'

export const CreationTipMessageBox: React.FC = () => {
  return (
    <div className={styles.containCreationTipMessageBox}>
      <h5>
        <InfoIcon />
        <span>{'광고 소재 제작 TIP'}</span>
      </h5>
      9:16(1080x1920) 비율의 세로형 소재는 릴스, 스토리 등 다양한 영역에서 높은 광고 성과를 기대할 수 있어요. 단, 일부
      영역에서는 소재 상하단이 조정될 수 있으며, 특정 위치에서는 노출되지 않을 수 있는 점을 참고해 주세요. 더 자세한
      사항은 광고{' '}
      <a
        href="https://bizstatic.wadiz.kr/static/usingGuide/%E1%84%8B%E1%85%AA%E1%84%83%E1%85%B5%E1%84%8C%E1%85%B3+%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%A9+%E1%84%89%E1%85%B5%E1%86%B7%E1%84%89%E1%85%A1+%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%8E%E1%85%A2%E1%86%A8.pdf"
        target="_blank"
        type="primary"
        className={styles.creativeGuideButton}
      >
        {'소재 제작 가이드'}
      </a>
      에서 확인하세요.
    </div>
  )
}
