import { Form } from 'antd'
import { ItemLabel } from '../ItemLabel'
import { SelectWrapper } from '../SelectWrapper'

const AIInspectionStatusName = 'aiResult'
export const AIInspectionStatus: React.FC & { key: string; serialize: (value: any) => string } = () => {
  return (
    <Form.Item name={AIInspectionStatusName} label={<ItemLabel>AI 검수 결과</ItemLabel>} layout="vertical">
      <SelectWrapper
        options={[
          { value: 'all', label: '전체' },
          { value: 'ing', label: '검수중' },
          { value: 'ok', label: '승인' },
          { value: 'fail', label: '반려' },
          { value: 'error', label: '실패' },
        ]}
      />
    </Form.Item>
  )
}
AIInspectionStatus.key = AIInspectionStatusName
AIInspectionStatus.serialize = (values: any) => {
  const { [AIInspectionStatusName]: value } = values
  return value === 'all' ? '' : value
}
