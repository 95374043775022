import dayjs, { Dayjs } from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
dayjs.extend(weekday)

type Present = {
  label: string
  value: () => [Dayjs, Dayjs]
}
const makeRange = (d1: Dayjs, d2: Dayjs) => () => [d1.startOf('day'), d2.endOf('day')] as [Dayjs, Dayjs]
export const presentList: Present[] = [
  { label: '오늘', value: makeRange(dayjs(), dayjs()) },
  {
    label: '어제',
    value: makeRange(dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')),
  },
  { label: '이번 주', value: makeRange(dayjs().weekday(0), dayjs().weekday(6)) },
  {
    label: '저번 주',
    value: makeRange(dayjs().weekday(0).subtract(1, 'week'), dayjs().weekday(6).subtract(1, 'week')),
  },
  { label: '최근 7일', value: makeRange(dayjs().subtract(7, 'day'), dayjs()) },
  { label: '최근 30일', value: makeRange(dayjs().subtract(30, 'day'), dayjs()) },
]
