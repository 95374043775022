import { Carousel } from 'antd'
import { nanoid } from 'nanoid'
import { type ImageMetaDataType } from '@biz/query'
import styles from './FacebookContent.module.scss'

type FacebookContentFunction = {
  ctaBtn: string
  desc: string
  mainCopy: string
  imageList: ImageMetaDataType[]
  adTitle: string
}
export const FacebookContent: React.FC<FacebookContentFunction> = ({ ctaBtn, desc, mainCopy, imageList, adTitle }) => {
  return (
    <div className={styles.containFacebookContent}>
      <div className={styles.mainCopy}>{mainCopy}</div>
      <div className={styles.imageContainer}>
        <Carousel autoplay>
          {(imageList || []).map((item: ImageMetaDataType) => {
            const ext = (item.afMeta?.fileDiv || '').toLocaleLowerCase()
            const isImage = !!item.fileName && (ext === 'image' || ext === 'img' || item.afMeta?.ext === 'gif')
            const isVideo = !!item.fileName && item.afMeta?.ext !== 'gif' && (ext === 'video' || ext === 'vod')

            return (
              <div key={nanoid()} style={{ position: 'relative' }}>
                <div className={styles.imagePreview}>
                  {isImage && <img src={item.publicUrl || item.fileName} alt={item.afName} className={styles.img} />}
                  {isVideo && (
                    <video
                      autoPlay
                      controls
                      muted
                      loop
                      src={`${item.publicUrl || item.fileName}`}
                      className={styles.img}
                    />
                  )}
                </div>
              </div>
            )
          })}
        </Carousel>
        <div className={styles.bottomComment}>
          <div>
            {
              '* 미리보기 이미지/영상 크기에 따라 미리보기에서 일부 표시되지 않으나 실제 광고 노출시에는 정상적으로 노출됩니다.'
            }
          </div>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.leftSection}>
          <div className={styles.wadizUrl}>{'wadiz.kr'}</div>
          <div className={!!desc ? styles.title : styles.titleMulti}>{adTitle}</div>
          <div className={styles.description}>{desc}</div>
        </div>
        {!!ctaBtn && <div className={styles.cta}>{ctaBtn}</div>}
      </div>
    </div>
  )
}
