import { Form } from 'antd'
import { ItemLabel } from '../ItemLabel'
import { SelectWrapper } from '../SelectWrapper'

const ReviewTypeName = 'reviewType'
export const ReviewType: React.FC & {
  key: string
  serialize: (value: any) => string
} = () => {
  return (
    <Form.Item name={ReviewTypeName} label={<ItemLabel>심의 방식</ItemLabel>} layout="vertical">
      <SelectWrapper
        options={[
          { value: 'all', label: '전체' },
          { value: 'adminApprove', label: '관리자 심의' },
          { value: 'aiApprove', label: 'AI 자동 심의' },
        ]}
      />
    </Form.Item>
  )
}
ReviewType.key = ReviewTypeName
ReviewType.serialize = (values: any) => {
  const { [ReviewTypeName]: value } = values
  return value === 'all' ? '' : value
}
