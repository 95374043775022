import { axios } from '../axios'
import { CategoryMap } from './category'
import type { CategoryInspectionConfig } from './category'

interface ResponseType {
  ok: boolean
  data: {
    enumMap: CategoryMap
    catInspConfigs: CategoryInspectionConfig[]
  }
}

/**
 * 전체 카테고리 검수 정보 조회
 * @see https://wadiz.atlassian.net/l/cp/YsETfS1Z
 */
export const fetchCategoryAll = (): Promise<ResponseType> => axios.get('/api/mngAd/creation/categoryInspMng/getCateDiv')
