import React, {forwardRef, Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {Dialog} from 'rc-easyui';
import Pagination from '../common/Pagination';
import {AgGridReact} from 'ag-grid-react';
import NoDataTemplate from '../common/NoDataTemplate';
import MngMdFbPageAddDialog from './MngMdFbPageAddDialog';
import {ColDef, ICellRendererParams} from 'ag-grid-community';
import alertify from 'alertifyjs'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { useMutation } from '@tanstack/react-query'
import { ApiUtil2 } from '@biz/api'

export interface mediaFbPage {
  mediaFbPageId?: bigint
  adDpPageId: string // 페이지 ID
  name: string // 이름
  useYn: boolean // 사용 여부
  sortOrder: number // 광고세트 순서
  regTime: Date
  regName: string
}

const MngMdFbPageDialog = (props: any, ref: any) => {
  const dialogRef = useRef<any>()

  const [showFbPageAddDialog, setShowFbPageAddDialog] = useState<boolean>(false)
  const [makerSetNum, setMakerSetNum] = useState(1)

  /* 그리드 */
  const childRef = useRef<any>(null)
  const gridRef = useRef<AgGridReact>(null)
  const [rowData, setRowData] = useState<[]>([])
  const getMediaFbPageList = useRef(props.mediaFbPageList)

  const mediaFbPageSwitchUseYn = useMutation({
    mutationFn: (reqData: any) =>
      ApiUtil2.post(`/api/mngMdFb/mediaFb/fbPage/mod/${props.mediaConfigId}`, reqData.formData),

    onSuccess: (resp, reqData) => {
      if (resp.data.ok) {
        alertify.success(reqData.msg)
        props.setMediaFbPageList(resp.data.data)
      }
    },
  })

  const switchUseYn = (selectedVal: mediaFbPage) => {
    if (selectedVal.useYn) {
      // 활성 => 비활성

      if (props.isUpdate) {
        const formData = {
          mediaFbPageId: selectedVal.mediaFbPageId,
          useYn: false,
        }
        const data = {
          formData,
          msg: '비활성 상태로 변경되었습니다. 광고의 정상적인 등록을 위해서는 활성화된 페이지가 존재해야 합니다.',
        }
        mediaFbPageSwitchUseYn.mutate(data)
      } else {
        props.setMediaFbPageList((prevState: any) => {
          const updateState = [...prevState]
          updateState.forEach((v: mediaFbPage) => {
            if (v.adDpPageId == selectedVal.adDpPageId && v.sortOrder == selectedVal.sortOrder) {
              v.useYn = false
              return
            }
          })

          return updateState
        })

        alertify.success(
          '비활성 상태로 변경되었습니다. 광고의 정상적인 등록을 위해서는 활성화된 페이지가 존재해야 합니다.'
        )
      }
    } else {
      // 비활성 => 활성
      if (
        getMediaFbPageList.current.filter((v: mediaFbPage) => v.sortOrder == selectedVal.sortOrder && v.useYn).length >
        0
      ) {
        alertify.error('이미 활성화된 페이지가 있습니다.<br>해당 페이지를 비활성 처리 후 다시 시도해 주세요.')
        return
      }

      if (props.isUpdate) {
        const formData = {
          mediaFbPageId: selectedVal.mediaFbPageId,
          useYn: true,
        }
        const data = {
          formData,
          msg: '정상적으로 활성 처리가 되었습니다.',
        }
        mediaFbPageSwitchUseYn.mutate(data)
      } else {
        props.setMediaFbPageList((prevState: any) => {
          const updateState = [...prevState]

          updateState.forEach((v: mediaFbPage) => {
            if (v.adDpPageId == selectedVal.adDpPageId && v.sortOrder == selectedVal.sortOrder) {
              v.useYn = true
              return
            }
          })

          return updateState
        })

        alertify.success('정상적으로 활성 처리가 되었습니다.')
      }
    }
  }

  const useYnRenderer = (props: ICellRendererParams) => {
    const useYn = props.value
    const str = useYn ? '활성' : '비활성'
    return (
      <a className="txt-link" onClick={() => switchUseYn(props.data)}>
        {str}
      </a>
    )
  }

  const pageLimitRenderer = (p: ICellRendererParams) => {
    const vol = props.adsVolumes.filter((v: any) => v.actorId == p.data.adDpPageId)
    return vol.length == 0 ? '-' : vol[0].adsRunningOrInReviewCount + ' / ' + vol[0].limitOnAdsRunningOrInReview
  }

  const dateFormatRenderer = (props: ICellRendererParams) => {
    if (typeof props.value == 'undefined' || isEmpty(props.value)) return '-'
    const { date } = props.value
    const { time } = props.value

    return typeof props.value == 'undefined' || isEmpty(props.value)
      ? '-'
      : dayjs(new Date(date.year, date.month - 1, date.day, time.hour, time.minute, time.second)).format(
          'YYYY-MM-DD HH:mm:ss'
        )
  }

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'useYn',
      headerName: '광고 진행',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: useYnRenderer,
      cellClass: 'ag-center-aligned-cell',
      sort: 'desc',
      sortIndex: 0,
    },
    {
      field: 'name',
      headerName: '페이지 명',
      //cellRenderer: mediaDiv2Renderer,
    },
    {
      field: 'pageLimit',
      headerName: '광고 한도',
      cellRenderer: pageLimitRenderer,
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'regTime',
      headerName: '등록일시',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: dateFormatRenderer,
      sort: 'desc',
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (typeof valueA == 'undefined' || valueA == null || typeof valueB == 'undefined' || valueB == null) return 0

        const { date } = valueA
        const { time } = valueA

        const date2 = valueB.date
        const time2 = valueB.time

        if (
          dayjs(new Date(date.year, date.month - 1, date.day, time.hour, time.minute, time.second)).isSame(
            dayjs(new Date(date2.year, date2.month - 1, date2.day, time2.hour, time2.minute, time2.second))
          )
        )
          return 0
        return dayjs(new Date(date.year, date.month - 1, date.day, time.hour, time.minute, time.second)).isAfter(
          dayjs(new Date(date2.year, date2.month - 1, date2.day, time2.hour, time2.minute, time2.second))
        )
          ? 1
          : -1
      },
      sortIndex: 1,
    },
    { field: 'regName', headerName: '등록자', cellClass: 'ag-center-aligned-cell' },
  ])

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: true,
      suppressMovable: true,
      sortable: true,
    }),
    []
  )

  const changePagination = () => {
    childRef.current!.onPaginationChanged()
  }

  const mediaFbPageDel = useMutation({
    mutationFn: (formData: any) => ApiUtil2.post(`/api/mngMdFb/mediaFb/fbPage/del/${props.mediaConfigId}`, formData),

    onSuccess: (resp) => {
      if (resp.data.ok) {
        alertify.success('정상적으로 삭제되었습니다.')
        props.setMediaFbPageList(resp.data.data)
      }
    },
  })

  const pageDelEvent = () => {
    const selectedRows = gridRef.current!.api.getSelectedRows()
    if (selectedRows.length <= 0) {
      alertify.error('삭제하실 페이지를 선택 후 다시 시도해주세요.')
      return
    }

    if (selectedRows.filter((v: any) => v.useYn).length > 0) {
      alertify.error(
        '광고가 진행되고 있는 활성 페이지는 삭제 하실 수 없습니다. 삭제하시려면 비활성화로 변경 후 삭제해 주시기바랍니다.'
      )
      return
    }

    alertify
      .confirm('선택하신 페이지들을 삭제 하시겠습니까?\n', () => {
        if (props.isUpdate) {
          const mediaFbPageIds = selectedRows.map((checkedData: mediaFbPage) => checkedData.mediaFbPageId)
          mediaFbPageDel.mutate(mediaFbPageIds)
        } else {
          const adDpPageIds = selectedRows.map((checkedData: mediaFbPage) => checkedData.adDpPageId)

          props.setMediaFbPageList((prevState: any) => {
            const updateState = [...prevState]
            return updateState.filter(
              (v: any) => !(v.sortOrder == makerSetNum && adDpPageIds.indexOf(v.adDpPageId) > -1)
            )
          })

          alertify.success('정상적으로 삭제되었습니다.')
        }
      })
      .set({ labels: { cancel: '취소', ok: '확인' } })
      .setHeader('')
  }

  useEffect(() => {
    setRowData(props.mediaFbPageList.filter((v: any) => v.sortOrder == makerSetNum))
  }, [makerSetNum])

  useEffect(() => {
    setRowData(props.mediaFbPageList.filter((v: any) => v.sortOrder == makerSetNum))
    getMediaFbPageList.current = props.mediaFbPageList
    if (gridRef.current!.api) gridRef.current!.api.setRowData(rowData)
  }, [props.mediaFbPageList])

  return (
    <Fragment>
      <Dialog title="페이지 관리" modal resizable shadow={false} ref={dialogRef} style={{ width: '1200px' }}>
        <div id="MngMdFbPageDialog" className="dialog">
          <div className="dialog-body">
            <div className="comp-tab">
              <a
                className={`tab ${makerSetNum === 1 ? 'selected' : ''}`}
                href="#!"
                onClick={(e) => {
                  e.preventDefault()
                  setMakerSetNum(1)
                }}
              >
                <span className="tab-item">메이커 세트</span>
              </a>
              <a
                className={`tab ${makerSetNum === 2 ? 'selected' : ''}`}
                href="#!"
                onClick={(e) => {
                  e.preventDefault()
                  setMakerSetNum(2)
                }}
              >
                <span className="tab-item">와디즈 기본 세트</span>
              </a>
              <a
                className={`tab ${makerSetNum === 3 ? 'selected' : ''}`}
                href="#!"
                onClick={(e) => {
                  e.preventDefault()
                  setMakerSetNum(3)
                }}
              >
                <span className="tab-item">와디즈 확장 세트</span>
              </a>
            </div>
            <div className="comp-tab-content selected">
              <section className="wrap-section wrap-datagrid">
                <div className="box-header">
                  <div className="box-option">
                    <button
                      type="button"
                      className="btn btn-primary-outline"
                      onClick={() => setShowFbPageAddDialog(true)}
                    >
                      추가
                    </button>
                    <button type="button" className="btn btn-secondary-outline" onClick={pageDelEvent}>
                      삭제
                    </button>
                  </div>
                </div>
                <div className="box-body">
                  <div className="ag-grid">
                    <div className="ag-grid-inner">
                      <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        onGridSizeChanged={(params) => dialogRef.current.center()}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowHeight={48}
                        pagination={true}
                        paginationPageSize={10}
                        suppressPaginationPanel={true}
                        suppressRowClickSelection={true}
                        onPaginationChanged={changePagination}
                        domLayout={'autoHeight'}
                        noRowsOverlayComponent={NoDataTemplate}
                        rowSelection={'multiple'}
                      />
                    </div>
                  </div>
                </div>
                <div className="box-footer">
                  <Pagination gridRef={gridRef} ref={childRef} />
                </div>
              </section>
            </div>
          </div>
          <div className="dialog-footer">
            <button
              type="button"
              className="btn btn-primary large"
              onClick={(e) => {
                props.setShowFbPageDialog(false)
              }}
            >
              확인
            </button>
          </div>
        </div>
      </Dialog>
      {showFbPageAddDialog ? (
        <MngMdFbPageAddDialog
          setShowFbPageAddDialog={setShowFbPageAddDialog}
          isUpdate={props.isUpdate}
          mediaConfigId={props.mediaConfigId}
          makerSetNum={makerSetNum}
          adPageList={props.adPageList}
          mediaFbPageList={props.mediaFbPageList}
          setMediaFbPageList={props.setMediaFbPageList}
        />
      ) : null}
    </Fragment>
  )
}

export default forwardRef(MngMdFbPageDialog)
