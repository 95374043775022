import React, {
  Fragment, useContext, useEffect, useState,
} from 'react';
import { useQuery } from '@tanstack/react-query' // try next react-query;
import alertify from 'alertifyjs'
import { ApiUtil2 } from '@biz/api'
import { IAdminAccountRes } from './AdminAccountRegPopUP';
import { AdminIpContext } from './MngAdminIp';

export interface IAdminIpRegReq {
    ip1: string;
    ip2: string;
    ip3: string;
    ip4: string;
    ipDesc: string;
}

const AdminIpRegPopUp = (props:any) => {
  const [ipDescLength, SetIpDescLength] = useState(0);
  const { adminIpId, setAdminIpId } = useContext(AdminIpContext);
  const getAdminIpInfo = async () => {
    const { data } = await ApiUtil2.get<IAdminAccountRes>('/api/mngAccount/admin/ip/detail', { params: { data: { adminIpId } } });
    return data;
  };
  const adminIpInfo = useQuery({ queryKey: ['adminIp', adminIpId], queryFn: getAdminIpInfo, enabled: false })

  useEffect(() => {
    if (adminIpId !== null) {
      adminIpInfo.refetch().then((data:any) => {
        if (data.isSuccess) {
          const adminIp = data.data.data;
          $('#ip1').val(adminIp.ip1);
          $('#ip2').val(adminIp.ip2);
          $('#ip3').val(adminIp.ip3);
          $('#ip4').val(adminIp.ip4);
          $('#ipDesc').val(adminIp.ipDesc);
        }
      });
    }

    /** 다이얼로그 */
    $('#adminIpRegPopUp').dialog({
      title: adminIpInfo === null ? '관리자 접속 IP 등록' : '관리자 접속 IP 수정', closeOnEscape: true, width: '900',
    });

    /** 아이피 1 */
    $('#ip1').on('keyup', () => {
      const value = $('#ip1').val() as string;
      if (/[^0-9]/g.test(value)) {
        $('#ip1').val(value.substring(0, value.length - 1));
      }
      if (value.length > 3) {
        $('#ip1').val(value.substring(0, 3));
      }
    });

    /** 아이피 2 */
    $('#ip2').on('keyup', () => {
      const value = $('#ip2').val() as string;
      if (/[^0-9]/g.test(value)) {
        $('#ip2').val(value.substring(0, value.length - 1));
      }
      if (value.length > 3) {
        $('#ip2').val(value.substring(0, 3));
      }
    });

    /** 아이피 3 */
    $('#ip3').on('keyup', () => {
      const value = $('#ip3').val() as string;
      if (/[^0-9]/g.test(value)) {
        $('#ip3').val(value.substring(0, value.length - 1));
      }
      if (value.length > 3) {
        $('#ip3').val(value.substring(0, 3));
      }
    });

    /** 아이피 4 */
    $('#ip4').on('keyup', () => {
      const value = $('#ip4').val() as string;
      if (/[^0-9*]/g.test(value)) {
        $('#ip4').val(value.substring(0, value.length - 1));
      }
      if (value.length > 3) {
        $('#ip4').val(value.substring(0, 3));
      }
    });

    /** 아이피 메모 */
    $('#ipDesc').on('keyup', () => {
      const value = $('#ipDesc').val() as string;
      SetIpDescLength(value.length);
      if (value.length > 400) {
        $('#ipDesc').val(value.substring(0, 400));
      }
    });

    /** 다이얼로그 닫기 */
    $(document).on('click', '#cancelBtn', () => {
      $('#adminIpRegPopUp').dialog('close');
      props.setIsClicked(false);
      $('#adminIpRegPopUp').dialog('destroy');
      setAdminIpId(null);
    });

    /** 등록 */
    $('#reg').on('click', () => {
      const ip1 = $('#ip1').val() as string;
      const ip2 = $('#ip2').val() as string;
      const ip3 = $('#ip3').val() as string;
      const ip4 = $('#ip4').val() as string;
      const ipDesc = $('#ipDesc').val() as string;

      if (ip1 === '' || ip2 === '' || ip3 === '') {
        alertify.error(`기입하지 않은 항목이 있습니다. \n
      확인 후 다시 시도해 주세요.`);
      }

      if (adminIpId === null) { // 등록
        ApiUtil2.post<IAdminIpRegReq>('/api/mngAccount/admin/ip/reg', {
          ip1, ip2, ip3, ip4, ipDesc,
        }).then(() => {
          alertify.success('정상적으로 등록 되었습니다.');
          $('#adminIpRegPopUp').dialog('close');
          props.setIsClicked(false);
          props.onGridReady();
        });
      } else { // 수정
        ApiUtil2.post<IAdminIpRegReq>('/api/mngAccount/admin/ip/modify', {
          adminIpId, ip1, ip2, ip3, ip4, ipDesc,
        }).then(() => {
          alertify.success('정상적으로 수정 되었습니다.');
          $('#adminIpRegPopUp').dialog('close');
          props.setIsClicked(false);
          props.onGridReady();
        });
      }
    });

    return () => {
      $('#adminIpRegPopUp').dialog('destroy');
      setAdminIpId(null);
    };
  }, []);
  return (
        <Fragment>
            <form>
            <div id="adminIpRegPopUp" className="dialog" style={{ display: 'none' }}>
                <div className="dialog-body">
                    <div className="wrap-section wrap-tbl">
                        <div className="box-body">
                            <div className="tbl">
                                <dl>
                                    <dt>
                                        <div className="dt-inner">
                                            <span className="fz-16 fc-1">
                                                IP
                                            </span>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="inner-input-group">
                                                    <input type="text" className="tf-comm w-100" id='ip1'/>
                                                </div>
                                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                            </div>
                                            <span className="comp-txt">
                                                <span className="table">
                                                    <span className="table-cell v-bottom">
                                                        <b className="fz-12 fc-1">.</b>
                                                    </span>
                                                </span>
                                            </span>
                                            <div className="input-group">
                                                <div className="inner-input-group">
                                                    <input type="text" className="tf-comm w-100" id='ip2'/>
                                                </div>
                                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                            </div>
                                            <span className="comp-txt">
                                                <span className="table">
                                                    <span className="table-cell v-bottom">
                                                        <b className="fz-12 fc-1">.</b>
                                                    </span>
                                                </span>
                                            </span>
                                            <div className="input-group">
                                                <div className="inner-input-group">
                                                    <input type="text" className="tf-comm w-100" id='ip3'/>
                                                </div>
                                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                            </div>
                                            <span className="comp-txt">
                                                <span className="table">
                                                    <span className="table-cell v-bottom">
                                                        <b className="fz-12 fc-1">.</b>
                                                    </span>
                                                </span>
                                            </span>
                                            <div className="input-group">
                                                <div className="inner-input-group">
                                                    <input type="text" className="tf-comm w-100" id='ip4'/>
                                                </div>
                                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <div className="dt-inner">
                                            <span className="fz-16 fc-1">
                                                메모
                                            </span>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <div className="input-group expand">
                                                <div className="inner-input-group">
                                                    <textarea className="tf-textarea"
                                                              placeholder="400자까지 기입이 가능합니다." id='ipDesc'></textarea>
                                                </div>
                                                <p className="fz-12 fc-3"><span className="fc-2">{ipDescLength}</span> / 400</p>
                                                <p className="fz-12"><i className="ico"></i>체크 / 에러 문구 내용 영역</p>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dialog-footer">
                    <button type="button" className="btn btn-secondary-outline large" id={'cancelBtn'}>취소
                    </button>
                    <button type="button" className="btn btn-primary large" id={'reg'}>{adminIpId === null ? '등록' : '수정'}</button>
                </div>
            </div>
            </form>
        </Fragment>
  );
};

export default React.memo(AdminIpRegPopUp);
