import { Form } from 'antd'
import { ItemLabel } from '../ItemLabel'
import { FilterList } from '@biz/ui'

const MediaTypeFilterName = 'mediaType'
export const MediaTypeFilter: React.FC & { key: string; serialize: (value: any) => string } = () => {
  return (
    <Form.Item name={MediaTypeFilterName} label={<ItemLabel>관리자 검수 유형</ItemLabel>} layout="vertical">
      <FilterList
        options={[
          { value: 'all', label: '전체' },
          { value: 'text', label: '텍스트' },
          { value: 'img', label: '이미지' },
          { value: 'movie', label: '영상' },
          { value: 'slide', label: '슬라이드' },
        ]}
      />
    </Form.Item>
  )
}

MediaTypeFilter.key = MediaTypeFilterName
MediaTypeFilter.serialize = (values: any) => {
  const { [MediaTypeFilterName]: value } = values
  return value === 'all' ? '' : 'img'
}
