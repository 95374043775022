import React, {
  useState,
  useLayoutEffect,
  CSSProperties,
  RefObject,
} from 'react';
import cx from 'classnames';

import Portal from '../Portal';
import useWindowSize from '../useWindowSize';

import { Placement } from './Tooltip';

import styles from './Tooltip.module.scss';

const MIN_TOOLTIP_WIDTH = 96;
const MAX_TOOLTIP_WIDTH = 288;

export interface Props {
  id: string;
  isOpened: boolean;
  placement: Placement;
  button: HTMLButtonElement | null;
  target: HTMLSpanElement | null;
  children: React.ReactNode;
  refObject: RefObject<HTMLDivElement>;
  messageWidth: number | undefined;
  /** 메시지박스 zIndex */
  portalZIndex?: number;
}

export const TooltipMessageBox = ({
  id,
  isOpened,
  button,
  target,
  placement,
  children,
  refObject,
  messageWidth,
  portalZIndex,
}: Props) => {
  const [bodyWidth, bodyHeight] = useWindowSize();
  const [style, setStyle] = useState<CSSProperties>({});

  const classes = cx(
    styles.tooltip,
    {
      [styles.hide]: !isOpened,
    },
  );

  const setTooltipLayout = () => {
    const style: CSSProperties = {
      top: 0,
      left: 0,
      right: 'auto',
      bottom: 'auto',
      maxWidth: messageWidth,
    };

    const tooltip = refObject?.current;

    if (tooltip && isOpened && button && target) {
      const rect = button.getBoundingClientRect();
      const iconRect = target.getBoundingClientRect();

      let startPositionX = placement.split('-')[1];
      let clientWidth = startPositionX === 'start' ? (bodyWidth - rect.right) : (rect.right - iconRect.width);
      let xOffset = 0;

      const props: any = { placement };

      if (clientWidth < MAX_TOOLTIP_WIDTH) {
        if (clientWidth < MIN_TOOLTIP_WIDTH) {
          props.placement = placement.replace(startPositionX, startPositionX === 'start' ? 'end' : 'start');

          startPositionX = props.placement.split('-')[1];
          clientWidth = startPositionX === 'start' ? (bodyWidth - rect.right) : (rect.right - iconRect.width);
        }

        if (clientWidth < tooltip.clientWidth) {
          xOffset = Math.abs(clientWidth - tooltip.clientWidth);
        }
      }

      const { pageYOffset: yOffset } = window;

      switch (props.placement) {
        case 'top-start':
          style.transform = `translate(${rect.right - xOffset - iconRect.width - 2}px, calc(${rect.top + yOffset - 3}px - 100%))`;
          break;

        case 'top-end':
          style.transform = `translate(calc(${rect.right + xOffset + 2}px - 100%), calc(${rect.top + yOffset - 3}px - 100%))`;
          break;

        case 'bottom-start':
          style.transform = `translate(${rect.right - xOffset - iconRect.width - 2}px, ${rect.height + rect.top + yOffset + 3}px)`;
          break;

        case 'bottom-end':
          style.transform = `translate(calc(${rect.right + xOffset + 2}px - 100%), ${rect.height + rect.top + yOffset + 3}px)`;
          break;
      }
    }

    setStyle({
      ...style,
      ...(portalZIndex ? { zIndex: portalZIndex } : {}),
    });
  };

  useLayoutEffect(() => {
    setTooltipLayout();
  }, [isOpened, bodyWidth, bodyHeight, portalZIndex]);

  return (
    <Portal>
      <div
        id={id}
        role="tooltip"
        ref={refObject}
        className={classes}
        style={style}
      >
        {children}
      </div>
    </Portal>
  );
};

export default TooltipMessageBox;
