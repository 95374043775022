import { useEffect } from 'react'

export const MakerClassRedirect = () => {
  useEffect(() => {
    window.open('https://www.wadiz.kr/link/wa_class_bizcenter')
    window.history.back()
  }, [])

  return null
}
