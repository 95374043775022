import { getEnvByHost } from './getEnv'

interface InitializationOptions {
  allowCrawlerActivity?: boolean
  allowUserSuppliedJavascript?: boolean
  appVersion?: string
  baseUrl: string
  contentSecurityNonce?: string
  devicePropertyAllowlist?: string[]
  devicePropertyWhitelist?: string[]
  disablePushTokenMaintenance?: boolean
  doNotLoadFontAwesome?: boolean
  enableHtmlInAppMessages?: boolean
  enableLogging?: boolean
  localization?: string
  manageServiceWorkerExternally?: boolean
  minimumIntervalBetweenTriggerActionsInSeconds?: number
  noCookies?: boolean
  openCardsInNewTab?: boolean
  openInAppMessagesInNewTab?: boolean
  requireExplicitInAppMessageDismissal?: boolean
  safariWebsitePushId?: string
  serviceWorkerLocation?: string
  sessionTimeoutInSeconds?: number
}
declare global {
  interface Window {
    appboy?: {
      display: {
        automaticallyShowNewInAppMessages: () => string
      }
      initialize: (apiKey: string, options: InitializationOptions) => boolean
      logCustomEvent: (eventName: string, eventProperties?: object) => boolean
      getUser: () => {
        setCustomUserAttribute: (key: string, value: number | boolean | Date | string | string[] | null) => boolean
        getUserId: (callback: (userId: string | null) => void) => void
      }
      changeUser: (userId: string) => void
      openSession: () => void
      requestImmediateDataFlush: (callback?: undefined | ((success: boolean) => void)) => void
    }
    WebInterface?: any
  }
}
/**
 * Braze 초기 설정
 */
export const initializeBraze = () => {
  if (!window.appboy) {
    console.log('Braze (Appboy) is not available. Skipping initialization.')
    return
  }

  const environment = (getEnvByHost() || 'live') as keyof typeof BRAZE_API_KEYS

  const BRAZE_API_KEYS = {
    // local: '09c8971b-8c79-497a-a8f9-429b9163fa1a', // .env.local에 설정한 값으로 대체
    dev: '09c8971b-8c79-497a-a8f9-429b9163fa1a',
    rc: '2a16a131-0038-4448-9967-7e78ade318e1',
    rc2: 'a30b578e-38c4-4cbd-aeb4-ae34ac9dd148',
    // rc3: 'e17a4487-bb58-4be7-b77c-cdddd90b9bd4',   // 광고는 미사용
    // stage: '67ccab67-5d9f-4bba-980c-2d4a045c857e', // 광고는 미사용
    live: '0c308381-846f-4726-ad61-23ea6add9f4e',
  }
  const BRAZE_SDK_ENDPOINT = 'https://sdk.iad-06.braze.com'

  window.appboy.initialize(BRAZE_API_KEYS[environment], {
    baseUrl: BRAZE_SDK_ENDPOINT,
    allowUserSuppliedJavascript: true,
    enableLogging: true,
    sessionTimeoutInSeconds: 3600,
  })

  // headless 테스트가 아닌 경우에만 인앱메시지 노출
  if (!navigator.webdriver) {
    window.appboy.display.automaticallyShowNewInAppMessages()
  }
  window.appboy.openSession()
}
