import { useCallback, useRef, useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import debounce from 'lodash.debounce'
import {
  useCurrentCampaignIdQuery,
  useCreativeAssetInsertMutation,
  useCreativeAssetUpdateMutation,
  useCreativeAssetDeleteMutation,
  loadCampaignInfo,
} from '@biz/query'

type StoreValue = any
type ResetFunction = (values: StoreValue) => void
export const useContentOperation = () => {
  const queryClient = useQueryClient() // 소재개서 변경에 대해 수정하기 위해 추가
  const { data: campaignId } = useCurrentCampaignIdQuery()

  const insert = useCreativeAssetInsertMutation()

  const preDataRef = useRef('')
  const update = useCreativeAssetUpdateMutation()
  const updateFn = useCallback(
    (values: StoreValue, reset?: ResetFunction) => {
      if (!values) {
        return
      }
      if (preDataRef.current === JSON.stringify(values)) {
        return
      }
      update
        .mutateAsync({
          ...values,
          campId: campaignId || 0 /*type guard */,
        })
        .then(() => {
          //TODO 전체 데이터를 가져와서 제대로 반영되었는지 확인해야 하는데, 그럴 필요가 있을까 싶다. 그래서 일단 하지 않는다.
          preDataRef.current = JSON.stringify(values)
          loadCampaignInfo(queryClient)()
        })
        .catch(() => {
          reset?.(JSON.parse(preDataRef.current || '{}'))
        })
    },
    [update, preDataRef, campaignId]
  )

  const remove = useCreativeAssetDeleteMutation()
  return {
    isLoading: insert.isPending || update.isPending,
    insert: useCallback(
      (values: StoreValue) =>
        insert
          .mutateAsync({
            ...values,
            campId: campaignId || 0 /*type guard */,
          })
          .finally(loadCampaignInfo(queryClient)),
      [insert, campaignId]
    ),
    update: useMemo(() => debounce(updateFn, 400), []),
    remove: useCallback(
      (id: number) => remove.mutateAsync(id).then(loadCampaignInfo(queryClient)),
      [remove, campaignId]
    ),
  }
}
