import { useCallback } from 'react'
import { Modal, Button } from 'antd'
import { DocumentUploader } from './DocumentUploader'
import { useAttachDocumentMutation } from './_queries/useAttachDocumentMutation'
import { useAttachDocumentQuery, type AttachedDocumentType } from './_queries/useAttachDocumentQuery'
import { useUploadDocumentMutation } from './_queries/useUploadDocumentMutation'
import styles from './AttachDocumentModal.module.scss'

type AttachDocumentModalFunction = {
  isOpen: boolean
  onClose: () => void
  projectId: string
  campaignId: string | number
}
export const AttachDocumentModal: React.FC<AttachDocumentModalFunction> = ({
  isOpen,
  onClose,
  projectId,
  campaignId,
}) => {
  const { data, update: setFileList, invalidate } = useAttachDocumentQuery()
  const { attachedDocuments } = data || {}
  const attachedMutation = useAttachDocumentMutation({ projectId })
  const uploaderMutation = useUploadDocumentMutation()
  const doClose = useCallback(() => {
    onClose()
    invalidate()
  }, [])
  const doAttach = useCallback(() => {
    attachedMutation.mutateAsync({}).finally(doClose)
  }, [doClose, attachedMutation])
  return (
    <Modal
      width={720}
      open={isOpen}
      closable={false}
      title={<div className={styles.modalTitle}>{'첨부 서류 등록'}</div>}
      footer={[
        <div className={styles.modalFooter} key={'footer'}>
          <Button
            type="default"
            block
            size="large"
            onClick={doClose}
            style={{ width: 200 }}
            loading={uploaderMutation.isPending}
          >
            {'취소'}
          </Button>
          <Button
            type="primary"
            block
            size="large"
            onClick={doAttach}
            style={{ width: 200 }}
            disabled={uploaderMutation.isPending || !attachedDocuments?.length || attachedDocuments.length < 1}
            loading={uploaderMutation.isPending}
          >
            {'확인'}
          </Button>
        </div>,
      ]}
      className={styles.containAttachDocumentModal}
    >
      <div className={styles.customBody}>
        <div className={styles.contentTitle}>{'프로젝트 및 캠페인 정보'}</div>
        <div className={styles.content}>
          <dl>
            <dt>{'프로젝트ID'}</dt>
            <dd>{projectId}</dd>
          </dl>
          <dl>
            <dt>{'캠페인ID'}</dt>
            <dd>{campaignId}</dd>
          </dl>
        </div>

        <DocumentUploader
          value={attachedDocuments as AttachedDocumentType[]}
          onChange={setFileList}
          uploader={uploaderMutation}
        />
      </div>
    </Modal>
  )
}
