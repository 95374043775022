import { Tooltip } from 'antd'
import { ReactComponent as HelpIcon } from '@common/svg/helpOutline.svg'
import styles from './MediaSpecTip.module.scss'
const Spec = () => (
  <div className={styles.specTip}>
    <h4>{'이미지'}</h4>
    <ul>
      <li>{'권장 사이즈: 1080 * 1080 또는 1080 * 1920 픽셀'}</li>
      <li>{'최소 사이즈: 500 * 500 픽셀'}</li>
      <li>{'최대 사이즈: 9000 * 9000 픽셀'}</li>
      <li>{'파일 형식: JPEG, JPG, PNG, GIF'}</li>
      <li>{'파일 크기: 30MB 이내'}</li>
    </ul>

    <h4>{'영상'}</h4>
    <ul>
      <li>{'권장 길이: 15초'}</li>
      <li>{'최대 길이: 60분'}</li>
      <li>{'화면 비율: 세로방향 (4:5 또는 9:16)'}</li>
      <li>{'소리: 사용(캡션 포함)'}</li>
      <li>{'파일형식: MP4, MOV, GIF'}</li>
      <li>{'해상도 최소 너비: 600 픽셀'}</li>
      <li>{'파일 크기: 200 MB 이내'}</li>
    </ul>
  </div>
)
export const MediaSpecTip: React.FC = () => {
  return (
    <div className={styles.MediaSpecTip}>
      <Tooltip title={<Spec />} arrow={false} placement="bottom" trigger="hover">
        <div className={styles.icon}>
          <HelpIcon />
        </div>
      </Tooltip>
    </div>
  )
}
