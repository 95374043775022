import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PomForMakerTab from "./PomForMakerTab";
import {AgGridReact} from "ag-grid-react";
import NoDataTemplate from "../common/NoDataTemplate";
import {ColDef} from "ag-grid-community";
import { useForm } from 'react-hook-form'
import {useOutletContext} from "react-router";
import dayjs from "dayjs";
import alertify from 'alertifyjs'
import { ApiUtil2 } from '@biz/api'
import Pagination from "../common/Pagination";

interface IPomDailyUsage {
    basicDate: string,
    usingPomProjectCnt: number,
    usingPomServiceCnt: number,
    pom: number,
    returnBalance: number,
}
interface IPomDailyUsagesList {
    ok: boolean;
    data: IPomDailyUsage[];
}

interface PomDailyUsageSrchForm {
    startDate: string,
    endDate: string,
}

const dateFormatter = (props:any) => {
    const year = props.data.basicDate.substring(0, 4);
    const month = props.data.basicDate.substring(4, 6);
    const day = props.data.basicDate.substring(6, 8);
    return `${year}-${month}-${day}`;
};
const PomDailyUsage = () => {
    const setTitle = useOutletContext<any>();
    const childRef = useRef<any>(null);
    const [isDateValid, setIsDateValid] = useState<boolean>(true);
    const gridRef = useRef<AgGridReact>(null);
    const [rowData, setRowData] = useState<any[]>();
    const [columnDefs] = useState<ColDef[]>([
        { field: 'basicDate', headerName: '날짜', cellRenderer: dateFormatter, cellClass: 'ag-center-aligned-cell',},
        {
            field: 'usingPomProjectCnt',
            headerName: '후불머니 소진 프로젝트',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-right-aligned-cell',
            cellRenderer: (props:any) => (props.data.usingPomProjectCnt === 0 || props.data.usingPomProjectCnt === undefined ? '-' : props.data.usingPomProjectCnt.toLocaleString())
        },
        {
            field: 'usingPomServiceCnt',
            headerName: '후불머니 소진 캠페인',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-right-aligned-cell',
            cellRenderer: (props:any) => (props.data.usingPomServiceCnt === 0 || props.data.usingPomServiceCnt === undefined ? '-' : props.data.usingPomServiceCnt.toLocaleString())
        },
        {
            field: 'pom',
            headerName: '후불머니 소진',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-right-aligned-cell',
            cellRenderer: (props:any) => (props.data.pom === 0 || props.data.pom === undefined ? '-' : props.data.pom.toLocaleString())
        },
        {
            field: 'returnBalance',
            headerName: '후불머니 잔액반환',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-right-aligned-cell',
            cellRenderer: (props:any) => (props.data.returnBalance === 0 || props.data.returnBalance === undefined ? '-' : props.data.returnBalance.toLocaleString())
        },
    ]);
    const defaultColDef = useMemo<ColDef>(() => ({
        flex: 1,
        resizable: true,
        suppressMovable: true,
        sortable: true,
    }), []);

    const {
        handleSubmit, setValue, getValues,
    } = useForm<PomDailyUsageSrchForm>();

    const onGridReady = useCallback(() => {
        const {
            startDate, endDate
        } = getValues();
        ApiUtil2.get<IPomDailyUsagesList>('/api/pom/dailyUsage', {
            params: {
                data: {
                    startDate, endDate,
                },
            },
        }).then((resp) => {
            setRowData(resp.data.data);
        });
    }, []);

    const downloadHandler = () => {
        window.location.assign(
                `/api/pom/dailyUsage/download?startDate=${getValues('startDate')}&endDate=${getValues('endDate')}`,
        );
    };
    const changePagination = () => {
        childRef.current!.onPaginationChanged();
    };
    useEffect(() => {
        setTitle('후불머니 이용내역');
        /** 초기 값 */
        setValue('startDate', dayjs().startOf('day').subtract(8, 'd').format('YYYYMMDD'));
        setValue('endDate', dayjs().startOf('day').subtract(1, 'd').format('YYYYMMDD'));
        const getDateDiff = (d1:any, d2:any) => {
            const date1 = new Date(d1);
            const date2 = new Date(d2);

            const diffDate = date1.getTime() - date2.getTime();

            return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
        };

        /** datepicker */
        $('#datepicker').daterangepicker({
            maxDate: dayjs().startOf('day'),
            startDate: dayjs().startOf('day').subtract(8, 'd'),
            endDate: dayjs().startOf('day').subtract(1, 'd'),
            opens: 'left',
            ranges: {
                '오늘 ': [dayjs().startOf('day'), dayjs().endOf('day')],
                '어제 ': [dayjs().startOf('day').subtract(1, 'days'), dayjs().endOf('day').subtract(1, 'days')],
                '이번 주': [dayjs().startOf('day').day(1), dayjs().day(1).endOf('day').subtract(-6, 'd')],
                '저번 주': [dayjs().startOf('day').subtract(1, 'week').day(1), dayjs().endOf('day').subtract(1, 'week').day(7)],
                '최근 7일': [dayjs().startOf('day').subtract(7, 'd'), dayjs().endOf('day').subtract(1, 'd')],
                '최근 30일': [dayjs().startOf('day').subtract(30, 'd'), dayjs().endOf('day').subtract(1, 'd')],
            },
        }, (start:any, end:any) => {
            const startDate = start.format('YYYYMMDD');
            const endDate = end.format('YYYYMMDD');
            if (getDateDiff(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')) > 93) {
              alertify.error('조회 기간은 최대 93일까지 설정할 수 있습니다.')
              setIsDateValid(false)
              return
            }
            setIsDateValid(true);
            setValue('startDate', startDate);
            setValue('endDate', endDate);
        });

        return () => {
            setTitle('');
        };
    }, []);

    return (
        <Fragment>
        <PomForMakerTab/>
            <div className="comp-tab-content selected">
                <section className="wrap-section wrap-datagrid">
                    <form onSubmit={handleSubmit(onGridReady)}>
                        <div className="wrap-filter">
                            <div className="inner-filter">
                                <div className="box-left">
                                    <div className="item-filter">
                                        <div className="filter-tit">
                                            <p className="fz-12 fc-2">조회기간</p>
                                        </div>
                                        <div className="box-filter">
                                            <div className="comp-datepicker">
                                                <div className="inner-datepicker">
                                                    <i className="ico ico-calendar"></i>
                                                    <input type="text" className="tf-comm datepicker-range" id='datepicker' readOnly/>
                                                    <span className="fz-12 fc-2">사용자 설정</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-right">
                                    <button type="submit" className="btn btn-tertiary-mint btn-ico" disabled={!isDateValid}><i className="ico ico-filter"></i> 조회 </button>
                                </div>
                            </div>
                        </div>
                    </form>
                            <div className="box-header">
                                <div className="box-option">
                                    <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={downloadHandler}><i className="ico ico-download"></i>다운로드</button>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="ag-grid">
                                    <div className="ag-grid-inner">
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={rowData}
                                            columnDefs={columnDefs}
                                            defaultColDef={defaultColDef}
                                            onGridReady={onGridReady}
                                            rowHeight={48}
                                            pagination={true}
                                            paginationPageSize={10}
                                            suppressPaginationPanel={true}
                                            onPaginationChanged={changePagination}
                                            domLayout={'autoHeight'}
                                            noRowsOverlayComponent={NoDataTemplate}
                                        ></AgGridReact>
                                    </div>
                                </div>
                            </div>
                            <div className="box-footer">
                                <Pagination gridRef={gridRef} ref={childRef}/>
                            </div>
                    { rowData === undefined ? <NoDataTemplate/> : null}
                </section>
            </div>
        </Fragment>
    );
};

export default PomDailyUsage;
