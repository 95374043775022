import { Modal } from 'antd'
import type { AIComment } from '@biz/api'
import { ReactComponent as AiIcon } from './assets/ai-icon.svg'
import { ReactComponent as CloseIcon } from './assets/close-icon.svg'
import { ReactComponent as ChevronRightIcon } from './assets/chevron-right.svg'
import { AIInspectionHistoryContent } from '@biz/ui'
import styles from './FailReasonViewModal.module.scss'

interface FailReasonViewModalProps {
  isOpen: boolean
  reason: string | AIComment[]
  isSuspendedStatus: boolean
  reviewerType: 'AI' | 'AD_ADMIN' | 'NONE'
  handleClose: () => void
}

/**
 * 반려, 중단 사유 보기 모달
 */
export const FailReasonViewModal = ({
  isOpen,
  reason,
  reviewerType,
  isSuspendedStatus = false,
  handleClose,
}: FailReasonViewModalProps) => {
  const classNames = {
    body: styles.body,
    mask: styles.mask,
    header: styles.header,
    footer: styles.footer,
    content: styles.content,
  }

  return (
    <Modal
      open={isOpen}
      title={isSuspendedStatus ? '중단 사유' : '반려 사유'}
      footer={null}
      width={600}
      className={styles.container}
      classNames={classNames}
      closeIcon={<CloseIcon />}
      onCancel={handleClose}
    >
      {reviewerType === 'AI' && (
        <div className={styles.reasonHeader}>
          <AiIcon className={styles.icon} /> 심의 결과
        </div>
      )}
      <div className={styles.reason}>
        {reviewerType === 'AI' ? <AIInspectionHistoryContent comments={reason as AIComment[]} /> : <>{reason}</>}
      </div>
      {reviewerType === 'AI' && (
        <div className={styles.reasonFooter}>
          <p className={styles.text}>
            <h4 className={styles.title}>AI 심사 결과에 대한 문의는 [문의하기]를 통해 접수해주세요</h4>
            <ul className={styles.description}>
              <li>캠페인 ID와 소재 ID를 함께 입력해 주시면 빠른 상담이 가능해요.</li>
              <li>
                AI 심의 <span className={styles.mint}>승인</span>/<span className={styles.red}>반려</span> 결과와
                관계없이, 광고 정책에 따라 광고 관리자가 소재의 승인 상태를 변경할 수 있어요.
              </li>
            </ul>
          </p>
          <a
            href="https://adwadiz.zendesk.com/hc/ko/requests/new?ticket_form_id=24699344939033"
            target="_blank"
            className={styles.inquiryButton}
          >
            문의하기
            <ChevronRightIcon />
          </a>
        </div>
      )}
    </Modal>
  )
}
