import React, {
  Fragment, useEffect, useMemo, useRef, useState,
} from 'react';
import { Dialog } from 'rc-easyui';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query' // try next react-query;
import { PlainObject } from 'select2';
import { ApiUtil2 } from '@biz/api'
import NoDataTemplate from '../../common/NoDataTemplate';
import SingleSelect2 from '../../accAdjust/SingleSelect2';
import {
  IBzmComponents2,
  IBzmExpireSearchForm, makerExpireGridBzm, refundExpireGridBzm,
} from '../BzmAdminUtil';

interface IBzmExpire{
    ok:boolean;
    data:any;
    userNo:string;
    bizDiv:string;
    sidNo:string;
    projectId:string;
    accRefundId:number;
}
const BzmMngExpireDialog = (props:any) => {
  const dialogRef = useRef<any>();
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const userNoSelect = useRef<HTMLSelectElement>(null);
  const [userNoData, setUserNoData] = useState<any[]>([]);
  const [columnDefs] = useState<IBzmComponents2>(props.showType === 'refund' ? refundExpireGridBzm : makerExpireGridBzm);
  const {
    getValues, setValue,
  } = useForm<IBzmExpireSearchForm>();

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: false,
    headerClass: 'ag-center-aligned-header',
    cellClass: 'ag-center-aligned-cell',
  }), []);

  const loadData = () => {
    setValue('makerId', props.makerId);
    setValue('showType', props.showType);
    if (props.showType === 'refund') setValue('accRefundId', props.accRefundId);
    if (getValues('projectId') !== undefined) {
      ApiUtil2.get(columnDefs.searchUrl, {
        params: {
          data: columnDefs.callback(getValues()),
        },
      }).then((resp) => {
        setRowData(resp.data.data);
      });
    }
  };

  const closeEvent = () => {
    props.setExpireDialog(false);
  };
  const moveToCenter = () => {
    dialogRef.current.center();
  };
  const getUserNosApi = () => {
    const result = ApiUtil2.get<IBzmExpire>('/api/bzmAdmin/mng/bzmExpireUserNoList', { params: { data: { makerId: props.makerId } } });
    return result;
  };

  const getUserNosApiEvent = useQuery({ queryKey: ['getUserNosApi'], queryFn: getUserNosApi, enabled: false })

  const settingUserNoSelect = () => {
    getUserNosApiEvent.refetch().then((r:any) => {
      if (r.isSuccess) {
        const info = r.data.data.data;
        $(userNoSelect.current as PlainObject).empty();
        const datas: any[] = [];
        info.forEach((res: any) => {
          const textValue = res.liveYn ? `${res.userNo} (활성)` : res.userNo;
          datas.push({ id: res.projectId, text: textValue });
        });
        setUserNoData(datas);
        $(userNoSelect as PlainObject).val(info[0].projectId).trigger('change');
        setValue('projectId', info[0].projectId);
        loadData();
      }
    });
  };
  const updateSearchTypeForRefund = (value: any) => {
    setValue('searchTypeForRefund', value);
  };

  const updateSearchTypeForMaker = (value: any) => {
    setValue('searchTypeForMaker', value);
  };
  const updateCostType = (value: any) => {
    setValue('costType', value);
  };
  useEffect(() => {
    settingUserNoSelect();
  }, []);
  // 충전 사업자 번호 제어
  useEffect(() => {
    $(userNoSelect.current as PlainObject).select2({
      width: '330px',
      minimumResultsForSearch: Infinity,
      data: userNoData,
    }).on('select2:select', (e : any) => {
      setValue('projectId', e.params.data.id);
    });
  });
  return (
        <Fragment>
            <form>
                <Dialog title="비즈머니 유효기간" modal resizable shadow={false} ref={dialogRef} style={{ width: '1200px' }}>
                    <div className="dialog">
                        <div className="dialog-body">
                            <div className="wrap-section wrap-datagrid">
                                    <div className="wrap-filter">
                                        <div className="inner-filter">
                                            <div className="box-left">
                                                <div className="item-filter">
                                                    <div className="filter-tit">
                                                        <p className="fz-12 fc-2">구분</p>
                                                    </div>
                                                    <div className="box-filter">
                                                        <div className="box-filter" style={{ display: `${props.showType === 'refund' ? '' : 'none'}` }}>
                                                            <SingleSelect2 data={refundExpireGridBzm.searchTypes} selectEvent={updateSearchTypeForRefund} class={'w-150'} />
                                                        </div>
                                                        <div className="box-filter" style={{ display: `${props.showType === 'maker' ? '' : 'none'}` }}>
                                                            <SingleSelect2 data={makerExpireGridBzm.searchTypes} selectEvent={updateSearchTypeForMaker} class={'w-150'} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item-filter">
                                                    <div className="filter-tit">
                                                        <p className="fz-12 fc-2">유형</p>
                                                    </div>
                                                    <div className="box-filter" style={{ display: `${props.showType === 'refund' ? '' : 'none'}` }}>
                                                        <SingleSelect2 data={refundExpireGridBzm.costTypes} selectEvent={updateCostType } class={'w-150'} />
                                                    </div>
                                                    <div className="box-filter" style={{ display: `${props.showType === 'maker' ? '' : 'none'}` }}>
                                                         <SingleSelect2 data={makerExpireGridBzm.costTypes} selectEvent={updateCostType } class={'w-150'} />
                                                    </div>
                                                </div>

                                                <div className="item-filter">
                                                    <div className="filter-tit">
                                                        <p className="fz-12 fc-2">충전 사업자 번호</p>
                                                    </div>
                                                    <div className="box-filter">
                                                        {/* Input-Group : Start */}
                                                        <div className="input-group">
                                                            <div className="inner-input-group">
                                                                <select className="select2-single expand" id="userNoSelect" ref={userNoSelect}>
                                                                </select>
                                                            </div>
                                                            <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                        </div>
                                                        {/* Input-Group : End */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box-right">
                                                <button type="submit" className="btn btn-tertiary-mint btn-ico" onClick={loadData}>
                                                    <i className="ico ico-filter"></i>필터 조회
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                <div className="box-body">
                                    <div className="ag-grid">
                                        <div className="ag-grid-inner">
                                             <AgGridReact
                                                    ref={gridRef}
                                                    rowData={rowData}
                                                    columnDefs={columnDefs.colDefs}
                                                    defaultColDef={defaultColDef}
                                                    rowHeight={48}
                                                    domLayout={'autoHeight'}
                                                    noRowsOverlayComponent={NoDataTemplate}
                                                    onGridReady={loadData}
                                                    onGridSizeChanged={moveToCenter}
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dialog-footer">
                            <button type="button" className="btn btn-primary large" onClick={closeEvent}>확인</button>
                        </div>
                    </div>
                </Dialog>
            </form>
        </Fragment>
  );
};

export default BzmMngExpireDialog;
