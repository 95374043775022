import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { Dialog } from 'rc-easyui';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query' // try next react-query;
import NumberFormat from 'react-number-format';
import BzmMakerSearchUserNoDialog from './BzmMakerSearchUserNoDialog';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'

interface IBzmMngReturn {
    ok: boolean;
    data: any;
    makerId: number;
    inUserNo: string;
    txUserNo: string;
    campNm: string;
    returnableCostPaid: number;
    returnableCostFree: number;
    returnableCostPaidReq: number;
    returnableCostFreeReq: number;
    returnableReason: string;
    campId: number;
    accId: number;
    consultingId:number;
    projectId: string;
}
interface IBzmMngCalcaulrateReturnableCost {
    ok: boolean;
    data: any;
    returnableCostPaid: number;
    returnableCostFree: number;
}
const BzmMngMakerBzmReturnDialog = (props: any) => {
  const dialogRef = useRef<any>();
  const [userNoDialog, setUserNoDialog] = useState(false);
  const [bzmReturnTarget, setBzmReturnTarget] = useState<any>(undefined);
  const [reasonCount, setReasonCount] = useState(0);

  const {
    getValues, setValue, register,
  } = useForm<IBzmMngReturn>();

  const showUserNoDialog = () => {
    setUserNoDialog(true);
  };
  const cancelEvent = () => {
    props.setReturnDialog(false);
  };
  // 환급 금액 관련 이벤트
  const calculrateReturnableCostApi = async () => {
    const { data } = await ApiUtil2.get<IBzmMngCalcaulrateReturnableCost>('/api/bzmAdmin/mng/bzmMngMaker/calculrateReturnableCost', {
      params: {
        data: {
          makerId: getValues('makerId'), campId: getValues('campId'), consultingId: getValues('consultingId'), accId: getValues('accId'),
        },
      },
    });
    return data;
  };

  const getCalculrateReturnableCostApi = useQuery({
    queryKey: ['calculrateReturnableCostApi'],
    queryFn: calculrateReturnableCostApi,
    enabled: false,
  })
  // 환급 관련 이벤트
  const returnApi = () => {
    setValue('makerId', props.makerId)
    const {
      makerId,
      inUserNo,
      txUserNo,
      returnableCostPaid,
      returnableCostFree,
      returnableCostPaidReq,
      returnableCostFreeReq,
      returnableReason,
      campId,
      consultingId,
      projectId,
      accId,
    } = getValues()
    const result = ApiUtil2.post<IBzmMngReturn>('/api/bzmAdmin/mng/bzmMngMaker/returnReq', {
      makerId,
      inUserNo,
      txUserNo,
      returnableCostPaid,
      returnableCostFree,
      returnableCostPaidReq,
      returnableCostFreeReq,
      returnableReason,
      campId,
      consultingId,
      projectId,
      accId,
    })
    return result
  }

  const returnApiEvent = useMutation({
    mutationFn: returnApi,
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 계속 수정 처리
        alertify.success(`총 ${resp.data.data.toLocaleString()} 원의 비즈머니가 환급 되었습니다.`)
        props.setReturnDialog(false)
        props.afterEvent()
      }
    },
  })
  const returnEvent = () => {
    returnApiEvent.mutate();
  };
  useEffect(() => {
    setValue('inUserNo', bzmReturnTarget?.inUserNo);
    setValue('txUserNo', bzmReturnTarget?.userNo);
    setValue('campNm', bzmReturnTarget?.campNm);
    setValue('campId', bzmReturnTarget?.campId);
    setValue('consultingId', bzmReturnTarget?.consultingId);
    setValue('accId', bzmReturnTarget?.inAccId);
    setValue('makerId', bzmReturnTarget?.makerId);
    setValue('projectId', bzmReturnTarget?.projectId);
    if (bzmReturnTarget?.campId && bzmReturnTarget?.inAccId) {
      getCalculrateReturnableCostApi.refetch().then((r) => {
        if (r.isSuccess) {
          setValue('returnableCostFree', r.data.data.returnableCostFree);
          setValue('returnableCostPaid', r.data.data.returnableCostPaid);
        }
      });
    }
  }, [bzmReturnTarget]);

  return (
        <Fragment>
            {
                userNoDialog ? <BzmMakerSearchUserNoDialog setUserNoDialog={setUserNoDialog} setBzmReturnTarget={setBzmReturnTarget} makerId={props.makerId}/> : null
            }
            <form>
                <Dialog title="비즈머니 환급" modal resizable shadow={false} ref={dialogRef} style={{ width: '900px' }}>
                    <div className="dialog-body">
                        <div className="wrap-section wrap-tbl">
                            <div className="box-body">
                                <div className="tbl">
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    환급 사업자 번호
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <button type="button" className="btn btn-primary-outline" onClick={showUserNoDialog}>환급 사업자 번호 조회
                                                </button>
                                            </div>
                                            <div className="form-group">
                                                <p className="comp-txt">
                                                <span className="table">
                                                    <span className="table-cell">
                                                        <b className="fz-14 fc-2">환급 충전 사업자 번호</b>
                                                    </span>
                                                </span>
                                                </p>
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <input type="text" className="tf-comm" {...register('inUserNo')} value={getValues('inUserNo') || '-'} disabled />

                                                    </div>
                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <p className="comp-txt">
                                                <span className="table">
                                                    <span className="table-cell">
                                                      <b className="fz-14 fc-2">소진 사업자 번호</b>
                                                    </span>
                                                </span>
                                                </p>
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <input type="text" className="tf-comm" {...register('txUserNo')} value={getValues('txUserNo') || '-'} disabled />

                                                    </div>
                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    환급 가능 금액 (유상)
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <NumberFormat className="tf-comm tf-unit"
                                                                      thousandSeparator={true}
                                                                      value={getValues('returnableCostPaid') || 0}
                                                                      disabled
                                                        />
                                                            <span className="fz-14 fc-1">원</span>

                                                    </div>
                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    환급 가능 금액 (쿠폰)
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <NumberFormat className="tf-comm tf-unit"
                                                                      thousandSeparator={true}
                                                                      value={getValues('returnableCostFree') || 0}
                                                                      disabled
                                                        />
                                                            <span className="fz-14 fc-1">원</span>

                                                    </div>
                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-section wrap-tbl">
                            <div className="box-body">
                                <div className="tbl">
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    환급 진행 금액 (유상)
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <NumberFormat id="couponReqCost"
                                                                      className="tf-comm tf-unit"
                                                                      thousandSeparator={true}
                                                                      onChange={(e: any) => {
                                                                        setValue('returnableCostPaidReq', Number(e.target.value.replaceAll(',', '')));
                                                                      }}
                                                        />
                                                            <span className="fz-14 fc-1">원</span>

                                                    </div>
                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    환급 진행 금액 (쿠폰)
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <NumberFormat id="couponReqCost"
                                                                      className="tf-comm tf-unit"
                                                                      thousandSeparator={true}
                                                                      onChange={(e: any) => {
                                                                        setValue('returnableCostFreeReq', Number(e.target.value.replaceAll(',', '')));
                                                                      }}
                                                        />
                                                            <span className="fz-14 fc-1">원</span>

                                                    </div>
                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    환급 사유
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group small expand">
                                                    <div className="inner-input-group">
                                                              <textarea className="tf-textarea" placeholder="1 ~ 100자 내로 입력해 주세요." maxLength={100} {...register('returnableReason', {
                                                                required: {
                                                                  value: true,
                                                                  message: '환급 금액과 환급 사유는 필수적으로 입력해 주셔야 합니다.',
                                                                },
                                                              })} onChange={(e) => {
                                                                setReasonCount(e.target.value.length);
                                                              }}></textarea>

                                                    </div>
                                                    <p className="fz-12 fc-3"><span className="fc-2">{reasonCount}</span> / 100</p>
                                                    <p className="fz-12"><i className="ico"></i>체크 / 에러 문구 내용 영역</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-secondary-outline large" onClick={cancelEvent}>취소
                        </button>
                        <button type="button" className="btn btn-primary large" onClick={returnEvent}>확인</button>
                    </div>
                </Dialog>
            </form>
        </Fragment>
  );
};
export default BzmMngMakerBzmReturnDialog;
