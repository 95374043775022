import { Tooltip } from 'antd'
import helpOutlineSvg from '@wadiz-frontend/waffle-system-icons/help-outline.svg'
import helpSvg from '@wadiz-frontend/waffle-system-icons/help.svg'
import styles from './TooltipHelp.module.scss'

type TooltipHelpFunction = {
  tooltip: React.ReactNode
}
export const TooltipHelp: React.FC<TooltipHelpFunction> = ({ tooltip }) => {
  return (
    <div className={styles.tooltipHelp}>
      <Tooltip title={tooltip} arrow={false} placement="bottomLeft" trigger="hover">
        <div className={styles.icon}>
          <img src={helpOutlineSvg} className={styles.normalIcon} />
          <img src={helpSvg} className={styles.hoverIcon} />
        </div>
      </Tooltip>
    </div>
  )
}
