import { useMutation } from '@tanstack/react-query'
import { CategoryInspectionConfig, postCategoryByCode } from '@biz/api'

const mutationKey = ['@biz/query/category/useCreateCategoryByCodeMutation']

/**
 * 카테고리 단건 저장
 */
export const useCreateCategoryByCodeMutation = () => {
  return useMutation({
    mutationFn: (payload: Omit<CategoryInspectionConfig, 'desc'>) => {
      return postCategoryByCode(payload)
    },
    mutationKey: mutationKey,
  })
}
