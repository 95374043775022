import { Checkbox } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import styles from './OptionsView.module.scss'

type OptionsViewFunction = {
  options: string[]
  value?: string[]
  onChange?: (list: string[]) => void
}
export const OptionsView: React.FC<OptionsViewFunction> = ({ options, value, onChange }) => {
  const checkedList = value || []
  return (
    <div>
      <Checkbox
        indeterminate={checkedList.length > 0 && checkedList.length < options.length}
        checked={options.length === checkedList.length}
        className={styles.allCheck}
        onChange={(e: CheckboxChangeEvent) => {
          onChange?.(e.target.checked ? options : [])
        }}
      >
        전체
      </Checkbox>
      <div className={styles.line} />
      <Checkbox.Group className={styles.checkboxGroup} options={options} value={value} onChange={onChange} />
    </div>
  )
}
