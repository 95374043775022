import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router';
import Pagination from '../common/Pagination';
import NoDataTemplate from '../common/NoDataTemplate';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'

interface IBzmoneyDailyUsageDetails {
    nbasicDate:any,
    paidCharging:any,
    couponCharging:any,
    paidExhaustion:any,
    couponExhaustion:any,
    paidReturn:any,
    couponReturn:any,
    paidRedemption:any,
    couponRedemption:any,
    refund:any,
    adjustPaid:any,
    adjustCoupon:any,
    depositDeducted:any,
    bt002:any,
    depositReturn:any,
    /** 20230203 부채 추가 */
    dept:any,
    redemptionOfDept:any,
    freeRedemptionOfDept:any,
}

export interface IBzmoneyDailyUsageDetailsList {
    ok: boolean;
    data: IBzmoneyDailyUsageDetails[];
}

export interface BzmoneyDailyUsageSrchForm {
    accId:any,
    startDate:any,
    endDate:any
}
const dateFormatter = (props:any) => {
  const year = props.data.basicDate.substring(0, 4);
  const month = props.data.basicDate.substring(4, 6);
  const day = props.data.basicDate.substring(6, 8);
  return `${year}-${month}-${day}`;
};

const adDivFormatter = (props:any) => {
  return (props.data.adDiv == null) ? "" :
          (props.data.adDiv == 'target') ? "타겟 광고" :
          (props.data.adDiv == 'display') ? "디스플레이 광고" :
          (props.data.adDiv == 'push') ? "푸시 광고" : "";
};

export const txDivRenderer = (e:any) => {
  if (e.data.txDiv === 'bi001' || e.data.txDiv === 'bi002' || e.data.txDiv === 'bi003' || e.data.txDiv === 'bi004') {
    return '유상 충전';
  } if (e.data.txDiv === 'bi009' || e.data.txDiv === 'bi010') {
    return '쿠폰 충전';
  } if (e.data.txDiv === 'bt104' || e.data.txDiv === 'dt101') {
    return '소진';
  } if (e.data.txDiv === 'bt001') {
    return '환급';
  } if (e.data.txDiv === 'bt109') {
    return '유상 환수';
  } if (e.data.txDiv === 'bt110' || e.data.txDiv === 'bt111') {
    return '쿠폰 환수';
  } if (e.data.txDiv === 'bt101' || e.data.txDiv === 'bt102' || e.data.txDiv === 'bt103') {
    return '환불';
  } if (e.data.txDiv === 'bi005' || e.data.txDiv === 'bi007') {
    return '임의조정 유상';
  } if (e.data.txDiv === 'bt107' || e.data.txDiv === 'bt108') {
    return '임의조정 감산';
  } if (e.data.txDiv === 'bi006' || e.data.txDiv === 'bi008') {
    return '임의조정 무상';
  } if (e.data.txDiv === 'bt106' || e.data.txDiv === 'di001' || e.data.txDiv === 'di002' || e.data.txDiv === 'di003' || e.data.txDiv === 'di004' || e.data.txDiv === 'di005' || e.data.txDiv === 'di006') {
    return '예치금 차감';
  } if (e.data.txDiv === 'bt002' || e.data.txDiv === 'bt112') {
    return '예치금 반환';
  } if (e.data.txDiv === 'dt001') {
    return '예치금 환급';
  } if (e.data.txDiv === 'dt103' || e.data.txDiv === 'bt105') {
    return '부채 상환';
  }

  return '-';
};
const paidChargingFormatter = (props:any) => (props.data.paidCharging === 0 || props.data.paidCharging === undefined ? '-' : props.data.paidCharging.toLocaleString());
const couponChargingFormatter = (props:any) => (props.data.couponCharging === 0 || props.data.couponCharging === undefined ? '-' : props.data.couponCharging.toLocaleString());
const paidExhaustionFormatter = (props:any) => (props.data.paidExhaustion === 0 || props.data.paidExhaustion === undefined ? '-' : props.data.paidExhaustion.toLocaleString());
const couponExhaustionFormatter = (props:any) => (props.data.couponExhaustion === 0 || props.data.couponExhaustion === undefined ? '-' : props.data.couponExhaustion.toLocaleString());
const paidReturnFormatter = (props:any) => (props.data.paidReturn === 0 || props.data.paidReturn === undefined ? '-' : props.data.paidReturn.toLocaleString());
const couponReturnFormatter = (props:any) => (props.data.couponReturn === 0 || props.data.couponReturn === undefined ? '-' : props.data.couponReturn.toLocaleString());
const paidRedemptionFormatter = (props:any) => (props.data.paidRedemption === 0 || props.data.paidRedemption === undefined ? '-' : props.data.paidRedemption.toLocaleString());
const couponRedemptionFormatter = (props:any) => (props.data.couponRedemption === 0 || props.data.couponRedemption === undefined ? '-' : props.data.couponRedemption.toLocaleString());
const refundFormatter = (props:any) => (props.data.refund === 0 || props.data.refund === undefined ? '-' : props.data.refund.toLocaleString());
const adjustPaidFormatter = (props:any) => (props.data.adjustPaid === 0 || props.data.adjustPaid === undefined ? '-' : props.data.adjustPaid.toLocaleString());
const adjustCouponFormatter = (props:any) => (props.data.adjustCoupon === 0 || props.data.adjustCoupon === undefined ? '-' : props.data.adjustCoupon.toLocaleString());
const depositDeductedFormatter = (props:any) => (props.data.depositDeducted === 0 || props.data.depositDeducted === undefined ? '-' : props.data.depositDeducted.toLocaleString());
const bt002Formatter = (props:any) => (props.data.bt002 === 0 || props.data.bt002 === undefined ? '-' : props.data.bt002.toLocaleString());
const depositReturnFormatter = (props:any) => (props.data.depositReturn === 0 || props.data.depositReturn === undefined ? '-' : props.data.depositReturn.toLocaleString());
const deptReturnFormatter = (props:any) => (props.data.dept === 0 || props.data.dept === undefined ? '-' : props.data.dept.toLocaleString());
const redemptionOfDeptReturnFormatter = (props:any) => (props.data.redemptionOfDept === 0 || props.data.redemptionOfDept === undefined ? '-' : props.data.redemptionOfDept.toLocaleString());
const freeRedemptionOfDeptReturnFormatter = (props:any) => (props.data.freeRedemptionOfDept === 0 || props.data.freeRedemptionOfDept === undefined ? '-' : props.data.freeRedemptionOfDept.toLocaleString());

const BzmoneyDailyUsageDetailsForMaker = () => {
  const setTitle = useOutletContext<any>();
  const [tabStatus, setTabStatus] = useState<any>('dailyUsageDetails');
  const [isDateValid, setIsDateValid] = useState<boolean>(true);
  const childRefOfDailyUsageDetails = useRef<any>(null);
  const childRefOfDetails = useRef<any>(null);
  const gridRefOfDailyUsageDetails = useRef<AgGridReact>(null);
  const gridRefOfDetails = useRef<AgGridReact>(null);
  const [rowDataOfDailyUsageData, setRowDataOfDailyUsageData] = useState<any[]>();
  const [rowDataOfDetails, setRowDataOfDetails] = useState<any[]>();
  const [columnDefsOfDailyUsageDetails] = useState<ColDef[]>([
    { field: 'basicDate', headerName: '날짜', cellRenderer: dateFormatter },
    {
      field: 'paidCharging',
      headerName: '유상 충전',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: paidChargingFormatter,
    },
    {
      field: 'couponCharging',
      headerName: '쿠폰 충전',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: couponChargingFormatter,
    },
    {
      field: 'paidExhaustion',
      headerName: '유상 소진',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: paidExhaustionFormatter,
    },
    {
      field: 'couponExhaustion',
      headerName: '쿠폰 소진',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: couponExhaustionFormatter,
    },
    {
      field: 'paidReturn',
      headerName: '유상 환급',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: paidReturnFormatter,
    },
    {
      field: 'couponReturn',
      headerName: '쿠폰 환급',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: couponReturnFormatter,
    },
    {
      field: 'paidRedemption',
      headerName: '유상 환수',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: paidRedemptionFormatter,
    },
    {
      field: 'couponRedemption',
      headerName: '쿠폰 환수',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: couponRedemptionFormatter,
    },
    {
      field: 'refund',
      headerName: '환불',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: refundFormatter,
    },
    {
      field: 'adjustPaid',
      headerName: '임의조정 유상',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: adjustPaidFormatter,
    },
    {
      field: 'adjustCoupon',
      headerName: '임의조정 쿠폰',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: adjustCouponFormatter,
    },
    {
      field: 'depositDeducted',
      headerName: '예치금 차감',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: depositDeductedFormatter,
    },
    {
      field: 'bt002',
      headerName: '예치금 반환',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: bt002Formatter,
    },
    {
      field: 'depositReturn',
      headerName: '예치금 환급',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: depositReturnFormatter,
    },
    {
      field: 'dept',
      headerName: '부채',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: deptReturnFormatter,
    },
    {
      field: 'redemptionOfDept',
      headerName: '부채 유상상환',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: redemptionOfDeptReturnFormatter,
    },
    {
      field: 'freeRedemptionOfDept',
      headerName: '부채 쿠폰상환',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: freeRedemptionOfDeptReturnFormatter,
    },

  ]);

  const paidRenderer = (e:any) => (e.data.paid === 0 || e.data.paid === undefined ? '-' : Math.abs(e.data.paid).toLocaleString());
  const freeRenderer = (e:any) => (e.data.free === 0 || e.data.free === undefined ? '-' : Math.abs(e.data.free).toLocaleString());
  const deptRenderer = (e:any) => (e.data.dept === 0 || e.data.dept === undefined ? '-' : Math.abs(e.data.dept).toLocaleString());
  const depBalanceRenderer = (e:any) => (e.data.depBalance === 0 || e.data.depBalance ===  undefined ? '-' : e.data.depBalance.toLocaleString());
  const bzmBalanceRenderer = (e:any) => (e.data.bzmBalance === 0 || e.data.bzmBalance === undefined ? '-' : e.data.bzmBalance.toLocaleString());

  const [columnDefsOfDetails] = useState<ColDef[]>([
    { field: 'basicDate', headerName: '날짜', cellRenderer: dateFormatter },
    {
      field: 'adDiv',
      headerName: '광고 유형',
      cellRenderer: adDivFormatter
    },
    {
      field: 'inUserNo',
      headerName: '충전 사업자번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'txUserNo',
      headerName: '소진 사업자 번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'txDivDesc',
      headerName: '구분',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'paid',
      headerName: '유상 비즈머니',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: paidRenderer,
    },
    {
      field: 'free',
      headerName: '쿠폰',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: freeRenderer,
    },
    {
      field: 'dept',
      headerName: '부채',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: deptRenderer,
    },
    {
      field: 'depBalance',
      headerName: '예치금 잔액',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: depBalanceRenderer,
    },
    {
      field: 'bzmBalance',
      headerName: '비즈머니 잔액',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: bzmBalanceRenderer,
    },
    {
      field: 'comment',
      headerName: '비고',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: true,
    suppressMovable: true,
    sortable: true,
  }), []);
  const {
    handleSubmit, setValue, getValues,
  } = useForm<BzmoneyDailyUsageSrchForm>();

  const onGridReadyOfDailyUsageDetails = useCallback(() => {
    const {
      startDate, endDate, accId,
    } = getValues();
    console.log(getValues());
    if (getValues('accId') !== undefined && isDateValid) {
      ApiUtil2.get<IBzmoneyDailyUsageDetailsList>('/api/bzm/detail/bzmDailyUsageForMaker', {
        params: {
          data: {
            startDate, endDate, accId,
          },
        },
      }).then((resp) => {
        setRowDataOfDailyUsageData(resp.data.data);
      });
    }
  }, []);

  const onGridReadyOfDetails = useCallback(() => {
    const {
      startDate, endDate, accId,
    } = getValues();
    console.log(getValues());
    if (getValues('accId') !== undefined && isDateValid) {
      ApiUtil2.get('/api/bzm/detail/bzmDetailsForMaker', {
        params: {
          data: {
            startDate, endDate, accId,
          },
        },
      }).then((resp) => {
        setRowDataOfDetails(resp.data.data);
      });
    }
  }, []);

  const downloadHandler = () => {
    window.location.assign(
      tabStatus === 'dailyUsageDetails'
        ? `/api/bzm/detail/bzmDailyUsageForMaker/download?accId=${getValues('accId')}&startDate=${getValues('startDate')}&endDate=${getValues('endDate')}`
        : `/api/bzm/detail/bzmDetailsForMaker/download?accId=${getValues('accId')}&startDate=${getValues('startDate')}&endDate=${getValues('endDate')}`,
    );
  };

  useEffect(() => {
    setTitle('비즈머니 이용내역');
    /** 초기 값 */
    setValue('startDate', dayjs().startOf('day').subtract(7, 'd').format('YYYYMMDD'));
    setValue('endDate', dayjs().startOf('day').format('YYYYMMDD'));
    /** 충전 사업자 번호 가져오기 */
    const userNoDatas: any[] = [];
    ApiUtil2.get('/api/bzm/common/findUsedUserNos').then((resp) => {
      /** 초기 데이터 삽입 */
      if (resp.data.data.usingUserNo === undefined) {
        resp.data.data.all.forEach((item:any) => {
          userNoDatas.push({
            id: item.accId, text: item.accId !== undefined ? item.userNo : `${item.userNo} : 충전 이력 없음`, userNo: item.userNo, disabled: item.accId === undefined,
          });
        });
      } else if (resp.data.data.usingUserNo !== undefined) {
        setValue('accId', resp.data.data.usingUserNo.accId);
        userNoDatas.push({
          id: resp.data.data.usingUserNo.accId, text: resp.data.data.usingUserNo.userNo, userNo: resp.data.data.usingUserNo.userNo, accId: resp.data.data.usingUserNo.accId,
        });
        resp.data.data.all.forEach((item:any) => {
          if (item.userNo !== resp.data.data.usingUserNo.userNo) {
            userNoDatas.push({
              id: item.accId, text: item.accId !== undefined ? item.userNo : `${item.userNo} : 충전 이력 없음`, userNo: item.userNo, disabled: item.accId === undefined,
            });
          }
        });
      }

      /** Select2 */
      $('#userNoInDailyUsageDetails').select2({
        width: '330px',
        minimumResultsForSearch: Infinity,
        placeholder: '선택하세요.',
        data: userNoDatas,
      }).on('select2:select', (e:any) => {
        console.log(e);
        setValue('accId', e.params.data.id);
      });

      if (resp.data.data.usingUserNo !== undefined) {
        $('#userNoInDailyUsageDetails').select2().val(resp.data.data.usingUserNo.accId).trigger('change');
        setValue('accId', resp.data.data.usingUserNo.accId);
      } else if (resp.data.data.usingUserNo === undefined && resp.data.data.all.length === 0) {
        /** 연결 된 사업자 번호가 없을 때 */
        $('#userNoInDailyUsageDetails').select2({ placeholder: '등록된 프로젝트가 없습니다.' });
        $('#userNoInDailyUsageDetails').attr('disabled', 'disabled');
      }

      const getDateDiff = (d1:any, d2:any) => {
        const date1 = new Date(d1);
        const date2 = new Date(d2);

        const diffDate = date1.getTime() - date2.getTime();

        return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
      };

      /** datepicker */
      $('#datepicker').daterangepicker({
        maxDate: dayjs().startOf('day'),
        startDate: dayjs().startOf('day').subtract(7, 'd'),
        endDate: dayjs().startOf('day'),
        opens: 'left',
        ranges: {
          '오늘 ': [dayjs().startOf('day'), dayjs().endOf('day')],
          '어제 ': [dayjs().startOf('day').subtract(1, 'days'), dayjs().endOf('day').subtract(1, 'days')],
          '이번 주': [dayjs().startOf('day').day(1), dayjs().day(1).endOf('day').subtract(-6, 'd')],
          '저번 주': [dayjs().startOf('day').subtract(1, 'week').day(1), dayjs().endOf('day').subtract(1, 'week').day(7)],
          '최근 7일': [dayjs().startOf('day').subtract(7, 'd'), dayjs().endOf('day').subtract(1, 'd')],
          '최근 30일': [dayjs().startOf('day').subtract(30, 'd'), dayjs().endOf('day').subtract(1, 'd')],
        },
      }, (start:any, end:any) => {
        const startDate = start.format('YYYYMMDD');
        const endDate = end.format('YYYYMMDD');
        if (getDateDiff(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')) > 93) {
          alertify.error('조회 기간은 최대 93일까지 설정할 수 있습니다.')
          setIsDateValid(false)
          return
        }
        setIsDateValid(true);
        setValue('startDate', startDate);
        setValue('endDate', endDate);
      });
    });
    return () => {
      setTitle('');
    };
  }, []);

  // 그리드에서 페이징 변경시 pagination 에 있는 함수 호출
  const changePaginationOfDailyUsageDetails = () => {
    childRefOfDailyUsageDetails.current!.onPaginationChanged();
  };
  const changePaginationOfDetails = () => {
    childRefOfDetails.current!.onPaginationChanged();
  };

  const dailyUsageDetailsHandler = () => {
    setTabStatus('dailyUsageDetails');
  };
  const detailsHandler = () => {
    setTabStatus('details');
  };

  const AddPagination = useCallback(() => {
    if (tabStatus === 'dailyUsageDetails') {
      return <Fragment><Pagination gridRef={ gridRefOfDailyUsageDetails } ref={childRefOfDailyUsageDetails}/></Fragment>;
    }
    return <Fragment><Pagination gridRef={gridRefOfDetails} ref={childRefOfDetails}/></Fragment>;
  }, [tabStatus]);

  useEffect(() => {
    if (tabStatus === 'dailyUsageDetails') {
      $('#dailyUsageDetailsBtn').prop('class', 'tab selected');
      $('#detailsBtn').prop('class', 'tab');
    } else if (tabStatus === 'details') {
      $('#detailsBtn').prop('class', 'tab selected');
      $('#dailyUsageDetailsBtn').prop('class', 'tab');
    }
  }, [tabStatus]);
  return (
        <Fragment>
            <div className="comp-tab">
                <button className="tab selected" onClick={dailyUsageDetailsHandler} id='dailyUsageDetailsBtn'>
                    <span className="tab-item">일간 이용내역</span>
                </button>
                <button className="tab" id='detailsBtn' onClick={detailsHandler}>
                    <span className="tab-item">상세 내역</span>
                </button>
            </div>
            <div className="comp-tab-content selected">
                <section className="wrap-section wrap-datagrid">
                    <form onSubmit={handleSubmit(tabStatus === 'dailyUsageDetails' ? onGridReadyOfDailyUsageDetails : onGridReadyOfDetails)}>
                    <div className="wrap-filter">
                        <div className="inner-filter">
                            <div className="box-left">
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">충전사업자번호</p>
                                    </div>
                                    <div className="box-filter">
                                        <select className="select2-single w-300" id='userNoInDailyUsageDetails'>
                                        </select>
                                    </div>
                                </div>
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">조회기간</p>
                                    </div>
                                    <div className="box-filter">
                                        <div className="comp-datepicker">
                                            <div className="inner-datepicker">
                                                <i className="ico ico-calendar"></i>
                                                <input type="text" className="tf-comm datepicker-range" id='datepicker' readOnly/>
                                                    <span className="fz-12 fc-2">사용자 설정</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-right">
                                <button type="submit" className="btn btn-tertiary-mint btn-ico" disabled={!isDateValid}><i className="ico ico-filter"></i> 조회 </button>
                            </div>
                        </div>
                    </div>
                    </form>
                    { getValues('accId') !== undefined
                      ? <Fragment>
                        <div className="box-header">
                            <div className="box-option">
                                <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={downloadHandler}><i className="ico ico-download"></i>다운로드</button>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="ag-grid">
                                <div className="ag-grid-inner">
                                    <AgGridReact
                                        ref={tabStatus === 'dailyUsageDetails' ? gridRefOfDailyUsageDetails : gridRefOfDetails}
                                        rowData={tabStatus === 'dailyUsageDetails' ? rowDataOfDailyUsageData : rowDataOfDetails}
                                        columnDefs={tabStatus === 'dailyUsageDetails' ? columnDefsOfDailyUsageDetails : columnDefsOfDetails }
                                        defaultColDef={defaultColDef}
                                        onGridReady={tabStatus === 'dailyUsageDetails' ? onGridReadyOfDailyUsageDetails : onGridReadyOfDetails}
                                        rowHeight={48}
                                        pagination={true}
                                        paginationPageSize={10}
                                        suppressPaginationPanel={true}
                                        onPaginationChanged={tabStatus === 'dailyUsageDetails' ? changePaginationOfDailyUsageDetails : changePaginationOfDetails}
                                        domLayout={'autoHeight'}
                                        noRowsOverlayComponent={NoDataTemplate}
                                    ></AgGridReact>
                                </div>
                            </div>
                        </div>
                        <div className="box-footer">
                          <AddPagination/>
                        {/* <Pagination gridRef={tabStatus === 'dailyUsageDetails' ? gridRefOfDailyUsageDetails : gridRefOfDetails} ref={tabStatus === 'dailyUsageDetails' ? childRefOfDailyUsageDetails : childRefOfDetails}/> */}
                        </div>
                        </Fragment>
                      : <NoDataTemplate/>
                    }
                </section>
            </div>
        </Fragment>
  );
};

export default BzmoneyDailyUsageDetailsForMaker;
