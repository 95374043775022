import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query' // try next react-query;
import _ from 'lodash';
import MngMdFbAdTab from './MngMdFbAdTab';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import {mediaFbPage} from "./MngMdReg";
import MngMdFbPageDialog from "./MngMdFbPageDialog";

export interface fbMdConfig{
  projectName: string,
  categoryName: string,
  adAccount: string,
  pixel: string,
  app: string,
  android: string,
  ios: string,
  useYn: string,
}

const MngMdFbUpdate = (props : any) => {
  const navigate = useNavigate();
  const [errorChk, isError] = useState({
    adAccount: false,
    pixel: false,
    app: false,
    android: false,
    ios: false,
  });

  const snsToken = useRef({
    snsToken: '',
    snsAppId: '',
    snsSecretAppId: '',
  });

  const respData = useRef({
    projectDiv3: '',
    fbAdSets: [],
    fbAdCategory: [],
    snsAdId: '',
    snsPixel: '',
    useYn: false,
  });

  const [isTokenReg, setTokenReg] = useState(false);
  const [isAdAccountSelect, setAdAccountSelect] = useState(false);
  const [isPixelSelect, setPixelSelect] = useState(false);
  const [customAudienceList, setCustomAudienceList] = useState([]);
  const [adPageList, setAdPageList] = useState([]);
  const [adsVolumes, setAdsVolumes] = useState([]);
  const [instagramAccountList, setInstagramAccountList] = useState([]);
  const childFbTabRef = useRef<any>(null);
  const [customConversionList, setCustomConversionList] = useState([]);

  const {
    register, getValues, setValue, // eslint-disable-line no-unused-vars
  } = useForm<fbMdConfig>({
  });
  const [adAccountList, setAdAccountList] = useState([]);
  const [adsPixelList, setAdsPixelList] = useState([]);
  const [appList, setAppList] = useState([]);

  const [showFbPageDialog, setShowFbPageDialog] = useState<boolean>(false);
  const [mediaFbPageList, setMediaFbPageList] = useState<mediaFbPage[]>([]);

  const useYnChangeEvent = (e : any) => {
    const chkVal = e.target.defaultValue;
    /** 프로젝트별 1개만 활성계정으로 유지하던 구조를 프로젝트-카테고리별 계정 방식으로 변경하였기 때문에, 다중 활성 계정 구조로 서비스가 가능해야함. 즉 다중 활성도 가능하게 수정 필요.
    if (chkVal === '1') {
      ApiUtil2.post('/api/mngMd/isExistActiveMd', {
        mediaDiv2: 'fbitg', projectDiv3: respData.current.projectDiv3, useYn: true, mediaConfigId: props.mediaConfigId, actYn: true,
      }).then((accResp) => {
        if (accResp.data.data) {
          alertify.error('이미 동일 프로젝트와 광고 노출 매체에 활성화 된 계정이 있습니다.<br/><br/>확인 후 다시 시도해 주세요.');
          setValue('useYn', '0');
        }
      });
    } else if (chkVal === '0' && respData.current.useYn) {
      alertify.confirm('해당 광고 집행 계정을 ‘비활성’으로 변경 하시겠습니까?<br/><br/>단, 변경 시 기존 광고 진행중인 해당 프로젝트 및 광고 노출 매체의 캠페인은 모두 종료 됩니다.', null, () => {
        setValue('useYn', '1');
      }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
    }
    ***/
    if (chkVal === '1') {
      alertify.confirm('해당 광고 집행 계정을 ‘활성’으로 변경 하시겠습니까?<br/><br/>', null, () => {
        setValue('useYn', '0');
      }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');

    } else if (chkVal === '0' && respData.current.useYn) {
      alertify.confirm('해당 광고 집행 계정을 ‘비활성’으로 변경 하시겠습니까?<br/><br/>단, 변경 시 기존 광고 진행중인 해당 프로젝트 및 광고 노출 매체의 캠페인은 모두 종료 됩니다.', null, () => {
        setValue('useYn', '1');
      }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
    }
  };

  const getMngMdFbDetail = async () => {
    const { data } = await ApiUtil2.get(`/api/mngMdFb/mediaFb/${props.mediaConfigId}`);
    return data;
  };

  const fbDetail = useQuery({
    queryKey: ['getMngMdFbDetail', props.mediaConfigId],
    queryFn: getMngMdFbDetail,
    enabled: false,
  })

  const resetForm = (resetComp : string) => {
    if (resetComp === 'account') {
      setPixelSelect(false);
      $('#adPixelSelect').val('').trigger('change');
      setValue('pixel', '');
    }

    $('#appSelect').val('').trigger('change');
    setValue('app', '');
    setValue('android', '');
    setValue('ios', '');
    childFbTabRef.current!.setUpdateForm([
      {
        instagramActorId: '', destinationType: 'WEBSITE', customEventType: 'SEARCH', convDomain: '', attributionSpec: 'CLICK_ONE_VIEW_ONE', customAudience: '', exCustomAudience: '',
      },
      {
        instagramActorId: '', destinationType: 'WEBSITE', customEventType: 'SEARCH', convDomain: '', attributionSpec: 'CLICK_ONE_VIEW_ONE', customAudience: '', exCustomAudience: '',
      },
      {
        instagramActorId: '', destinationType: 'WEBSITE', customEventType: 'SEARCH', convDomain: '', attributionSpec: 'CLICK_ONE_VIEW_ONE', customAudience: '', exCustomAudience: '',
      },
    ], [], true);
  };

  const mediaFbMod = useMutation({
    mutationFn: (formData: any) => ApiUtil2.post(`/api/mngMdFb/mediaFb/mod/${props.mediaConfigId}`, formData),
    onSuccess: (resp) => {
      if (resp.data.ok) {
        alertify.success('정상적으로 등록되었습니다.', 2, () => {
          navigate('/mngMd/mngMd')
        })
      }
    },
    // onError: (error) => {
    // },
    // onMutate: () => {
    // },
  })

  // const urlChange = (e: any) => {
  //   const { name, value } = e.target;
  //   setValue(name, value.length > 100 ? value.substring(0, 99) + value.substring(100, 101) : value);
  // };

  const modifyEvent = () => {
    const {
      app, pixel, adAccount, android, ios, useYn,
    } = getValues();
    const fbAdSetForm = childFbTabRef.current!.getAdSetForm();
    let isDestinationApp = false;
    _.forEach(fbAdSetForm.fbAdSets, (v: any) => {
      if (v.destinationType === 'WEBSITE_APP') {
        isDestinationApp = true;
      }
    });
    isError(() => ({
      adAccount: adAccount === null || adAccount === '',
      pixel: pixel === null || pixel === '',
      app: app === null || app === '',
      android: isDestinationApp && android === '' && ios === '',
      ios: isDestinationApp && android === '' && ios === '',
    }));
    let isAdSetFormValid = true;
    for (let i = 0; i < 3; i += 1) {
      if (fbAdSetForm.fbAdSets[i].convDomain === '') {
        isAdSetFormValid = false;
        break;
      }
    }
    if (adAccount === null || adAccount === '' || pixel === null || pixel === '' || app === null || app === '' || !isAdSetFormValid) {
      alertify.error('선택하지 않으신 필수 항목이 있습니다.<br/>확인 후 다시 시도해 주세요.');
    } else if (isDestinationApp && android === '' && ios === '') {
      alertify.error('전환 이벤트 위치를 웹사이트/앱으로<br/>설정하신 경우,<br/>앱의 스토어 주소는 Android or iOS 중<br/>최소 1개 이상 등록해 주셔야 합니다.');
    } else {
      const mediaAccNm = $('#adAccountSelect option:selected').text();
      const formData = {
        mediaAccNm,
        snsAdId: adAccount,
        snsPixel: pixel,
        snsApp: app,
        android,
        ios,
        useYn: useYn === '1',
        fbAdCategory: fbAdSetForm.fbAdCategory,
        fbAdSets: fbAdSetForm.fbAdSets,
      };
      mediaFbMod.mutate(formData);
    }
  };

  useEffect(() => {
    if (props.mediaConfigId !== null) {
      fbDetail.refetch().then((response) => {
        if (response.isSuccess) {
          const { data } = response.data;
          respData.current = data;
          setTokenReg(true);
          snsToken.current.snsToken = data.snsToken.snsToken;
          snsToken.current.snsAppId = data.snsToken.snsAppId;
          snsToken.current.snsSecretAppId = data.snsToken.snsSecretAppId;

          setValue('projectName', data.projectDiv3Desc);
          setValue('categoryName', data.mediaCatDivDesc);
          setValue('useYn', data.useYn ? '1' : '0');
          setAdAccountList(data.fbMeDto.adAccounts);
          setAdsPixelList(data.fbMeDto.adsPixels);
          setAppList(data.fbMeDto.apps);

          $('#adAccountSelect').select2({
            width: '330px',
            placeholder: '광고를 집행할 계정을 선택해 주세요.',
          }).on('select2:selecting', (e : any) => {
            e.preventDefault();
            const selectVal = e.params.args.data.id;
            if (selectVal !== respData.current.snsAdId) { // 기존 광고 집행 계정이랑 다른 계정 선택 했을 경우
              alertify.confirm('다른 광고 집행 계정으로 변경 하시겠습니까?<br/><br/>단, 변경 시 기존 기입 정보는 초기화되며, 현재 진행중인 캠페인에는 적용되지 않습니다.', () => {
                setValue('adAccount', selectVal);
                $('#adAccountSelect').val(selectVal).trigger('change');
                ApiUtil2.get(`/api/mngMd/getFbCustomAudiences?snsToken=${snsToken.current.snsToken}&snsSecretAppId=${snsToken.current.snsSecretAppId}&adAccountId=${selectVal}`)
                  .then((accResp) => {
                    resetForm('account');
                    setCustomAudienceList(accResp.data.data);
                  });

                ApiUtil2.get(`/api/mngMd/getFbCustomConversions?snsToken=${snsToken.current.snsToken}&snsSecretAppId=${snsToken.current.snsSecretAppId}&adAccountId=${selectVal}`)
                  .then((convcustomResp) => {
                    setCustomConversionList(convcustomResp.data.data);
                  });
              }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
            } else { // 기존 광고 집행 계정이랑 같은 계정 다시 선택 했을 경우
              setValue('adAccount', selectVal);
              $('#adAccountSelect').val(selectVal).trigger('change');
              $('#adAccountSelect').select2('close');
              ApiUtil2.get(`/api/mngMd/getFbCustomAudiences?snsToken=${snsToken.current.snsToken}&snsSecretAppId=${snsToken.current.snsSecretAppId}&adAccountId=${selectVal}`)
                .then((accResp) => {
                  resetForm('account');
                  setCustomAudienceList(accResp.data.data);
                });

              ApiUtil2.get(`/api/mngMd/getFbCustomConversions?snsToken=${snsToken.current.snsToken}&snsSecretAppId=${snsToken.current.snsSecretAppId}&adAccountId=${selectVal}`)
                .then((convcustomResp) => {
                  setCustomConversionList(convcustomResp.data.data);
                });
            }
          });
          $('#adPixelSelect').select2({
            width: '330px',
            placeholder: '광고를 전환할 픽셀을 선택해 주세요.',
          }).on('select2:selecting', (e : any) => {
            e.preventDefault();
            const selectVal = e.params.args.data.id;
            if (selectVal !== respData.current.snsPixel) { // 기존 픽셀이랑 다른 계정 선택 했을 경우
              alertify.confirm('다른 픽셀로 변경 하시겠습니까?<br/><br/>단, 변경 시 해당 픽셀의 웹사이트 설치 여부와<br/>맞춤 타겟들의 설정 값을 확인해 주셔야 합니다.', () => {
                $('#adPixelSelect').val(selectVal).trigger('change');
                setValue('pixel', selectVal);
                ApiUtil2.get(`/api/mngMd/getFbCustomAudiences?snsToken=${snsToken.current.snsToken}&snsSecretAppId=${snsToken.current.snsSecretAppId}&adAccountId=${$('#adAccountSelect').val()}`)
                  .then((accResp) => {
                    resetForm('pixel');
                    setCustomAudienceList(accResp.data.data);
                    setPixelSelect(true);
                  });

                ApiUtil2.get(`/api/mngMd/getFbCustomConversions?snsToken=${snsToken.current.snsToken}&snsSecretAppId=${snsToken.current.snsSecretAppId}&adAccountId=${$('#adAccountSelect').val()}`)
                  .then((convcustomResp) => {
                    setCustomConversionList(convcustomResp.data.data);
                  });
              }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
            } else { // 기존 픽셀이랑 같은 픽셀 다시 선택 했을 경우
              $('#adPixelSelect').val(selectVal).trigger('change');
              $('#adPixelSelect').select2('close');
              setValue('pixel', selectVal);
              ApiUtil2.get(`/api/mngMd/getFbCustomAudiences?snsToken=${snsToken.current.snsToken}&snsSecretAppId=${snsToken.current.snsSecretAppId}&adAccountId=${$('#adAccountSelect').val()}`)
                .then((accResp) => {
                  resetForm('pixel');
                  setCustomAudienceList(accResp.data.data);
                  setPixelSelect(true);
                });

              ApiUtil2.get(`/api/mngMd/getFbCustomConversions?snsToken=${snsToken.current.snsToken}&snsSecretAppId=${snsToken.current.snsSecretAppId}&adAccountId=${e.params.data.id}`)
                .then((convcustomResp) => {
                  setCustomConversionList(convcustomResp.data.data);
                });
            }
          });
          $('#appSelect').select2({
            width: '330px',
            placeholder: '광고를 전환할 앱을 선택해 주세요.',
          }).on('select2:select', (e) => {
            setValue('app', e.params.data.id);
          });

          setCustomAudienceList(data.customAudiences);
          setCustomConversionList(data.customConversions);
          setAdPageList(data.fbMeDto.pages);
          setAdsVolumes(data.fbMeDto.adsVolumes);
          setInstagramAccountList(data.fbMeDto.instagramAccounts);
          setMediaFbPageList(data.mediaFbPages);
        }
      });
    }
  }, [props.mediaConfigId]);
  useEffect(() => {
    $('#androidTooltip').tooltip({
      content: '<p class="fz-12 fc-0">전환 추적을 하실 앱이 등록된 구글 플레이 주소를<br/>입력해 주세요. 전환 이벤트 위치를 ‘웹사이트/앱＇으로 선택하신 세트가 있으신 경우, Android 혹은 iOS 중<br/>하나의 어플리케이션 주소는 필수로 입력해 주셔야<br/>합니다.</p>',
    });
    $('#iosTooltip').tooltip({
      content: '<p class="fz-12 fc-0">전환 추적을 하실 앱이 등록된 앱스토어 주소를<br/>입력해 주세요. 전환 이벤트 위치를 ‘웹사이트/앱＇으로 선택하신 세트가 있으신 경우 Android 혹은 iOS 중<br/>하나의 어플리케이션 주소는 필수로 입력해 주셔야<br/>합니다.</p>',
    });
  }, []);

  useEffect(() => {
    const data = respData.current as any;
    setValue('adAccount', data.snsAdId);
    setValue('pixel', data.snsPixel);
    setValue('app', data.snsApp);
    setValue('android', data.android);
    setValue('ios', data.ios);
    setAdAccountSelect(true);
    setPixelSelect(true);
    $('input[name="useYn"]').trigger('change');

    $('#adAccountSelect, #adPixelSelect, #appSelect').trigger('change');
  }, [appList]);

  useEffect(() => { // 리스트 불러오기가 완료 되면 여기서 데이터를 보내 준다.
    if (Object.keys(respData.current).length > 0 && (respData.current.fbAdSets.length > 0 || respData.current.fbAdCategory.length > 0)) {
      childFbTabRef.current!.setUpdateForm(respData.current.fbAdSets, respData.current.fbAdCategory, false);
    }
  }, [instagramAccountList]);

  useEffect(() => { // 리스트 불러오기가 완료 되면 여기서 데이터를 보내 준다.
    if ($('#adAccountSelect, #adPixelSelect, #appSelect').hasClass('select2-hidden-accessible')) {
      $('#adAccountSelect, #adPixelSelect, #appSelect').select2('destroy');
      $('#adAccountSelect').select2({
        width: '330px',
        placeholder: '광고를 집행할 계정을 선택해 주세요.',
      });
      $('#adPixelSelect').select2({
        width: '330px',
        placeholder: '광고를 전환할 픽셀을 선택해 주세요.',
      });
      $('#appSelect').select2({
        width: '330px',
        placeholder: '광고를 전환할 앱을 선택해 주세요.',
      });
    }
  }, [errorChk]);

  return (
    <Fragment>
      <section className="wrap-section wrap-tbl">
        <div className="box-body">
          <div className="tbl">
            <dl className="vertical">
              <dd>
                <div className="form-group">
                  <div className="container-fluid">
                    <div className="row flex-container">
                      <div className="col col-5">
                        <section className="wrap-section wrap-tbl">
                          <div className="box-body">
                            <div className="tbl">
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        와디즈 프로젝트 선택
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="input-group expand">
                                      <div className="inner-input-group">
                                        <input type="text" className="tf-comm" {...register('projectName')} disabled/>
                                      </div>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        매체 카테고리
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="input-group expand">
                                      <div className="inner-input-group">
                                        <input type="text" className="tf-comm" {...register('categoryName')} disabled/>
                                      </div>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        광고 노출 매체
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="input-group expand">
                                      <div className="inner-input-group">
                                        <input type="text" className="tf-comm" placeholder="" value="페이스북/인스타그램" disabled/>
                                      </div>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                      광고 집행 계정
                                      <i className="fz-12 fc-5">*</i>
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <select id="adAccountSelect" className={`select2-single expand ${errorChk.adAccount ? 'error' : ''}`} {...register('adAccount')} disabled={!isTokenReg}>
                                      {adAccountList.map((item : any) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                      ))}
                                    </select>
                                  </div>
                                </dd>
                              </dl>
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                      픽셀
                                      <i className="fz-12 fc-5">*</i>
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <select id="adPixelSelect" className={`select2-single expand ${errorChk.pixel ? 'error' : ''}`} {...register('pixel')} disabled={!isTokenReg}>
                                      {adsPixelList.map((item : any) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                      ))}
                                    </select>
                                  </div>
                                </dd>
                              </dl>
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                      앱
                                      <i className="fz-12 fc-5">*</i>
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <select id="appSelect" className={`select2-single expand ${errorChk.app ? 'error' : ''}`} {...register('app')} disabled={!isTokenReg}>
                                      {appList.map((item : any) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                      ))}
                                    </select>
                                  </div>
                                </dd>
                              </dl>
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                      Android 구글플레이 주소
                                      <Link to="#" id="androidTooltip" className="ico-tooltip" title=""></Link>
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className={`input-group expand ${errorChk.android ? 'negative' : ''}`}>
                                      <div className="inner-input-group">
                                        <input type="text" {...register('android', {
                                          disabled: !isAdAccountSelect,
                                        })} className="tf-comm" placeholder="선택하신 앱의 구글 플레이 주소를 입력해 주세요."/>
                                      </div>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                      iOS 앱스토어 주소
                                      <Link to="#" id="iosTooltip" className="ico-tooltip" title=""></Link>
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className={`input-group expand ${errorChk.ios ? 'negative' : ''}`}>
                                      <div className="inner-input-group">
                                        <input type="text" {...register('ios', {
                                          disabled: !isAdAccountSelect,
                                        })} className="tf-comm" placeholder="선택하신 앱의 앱스토어 주소를 입력해 주세요."/>
                                      </div>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        활성여부
                                        <i className="fz-12 fc-5">*</i>
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="comp-radio">
                                      <input type="radio" id="inp-radio-01" {...register('useYn')} value="1" onChange={useYnChangeEvent}/>
                                      <label htmlFor="inp-radio-01">활성</label>
                                    </div>
                                    <div className="comp-radio">
                                      <input type="radio" id="inp-radio-02" {...register('useYn')} value="0" onChange={useYnChangeEvent}/>
                                      <label htmlFor="inp-radio-02">비활성</label>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </section>
                      </div>
                      <MngMdFbAdTab isTokenReg={isTokenReg}
                                    isAdAccountSelect={isAdAccountSelect}
                                    isPixelSelect={isPixelSelect}
                                    adPageList={adPageList}
                                    adsVolumes={adsVolumes}
                                    instagramAccountList={instagramAccountList}
                                    customAudienceList={customAudienceList}
                                    customConversionList={customConversionList}
                                    mediaFbPageList={mediaFbPageList}
                                    setShowFbPageDialog={setShowFbPageDialog}
                                    ref={childFbTabRef}/>
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="box-footer">
          <div className="box-right">
            <button type="button" className="btn btn-secondary-outline" onClick={() => navigate('/mngMd/mngMd')}>취소</button>
            <button type="button" className="btn btn btn-primary" onClick={modifyEvent}>수정</button>
          </div>
        </div>
      </section>
      { showFbPageDialog ? <MngMdFbPageDialog setShowFbPageDialog={setShowFbPageDialog}
                                              isUpdate={true}
                                              mediaConfigId={props.mediaConfigId}
                                              adPageList={adPageList}
                                              adsVolumes={adsVolumes}
                                              mediaFbPageList={mediaFbPageList}
                                              setMediaFbPageList={setMediaFbPageList}/>
        : null }
    </Fragment>
  );
};

export default MngMdFbUpdate;
