import Cookies from 'universal-cookie'
export const loginRetry = () => {
  if (
    window.location.pathname.indexOf('login') < 0 &&
    window.location.pathname.indexOf('/adstudio/home') < 0 &&
    new Cookies().get('shadow') !== undefined
  ) {
    window.location.href = '/login'
  }
}
