import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { useMutation, useQuery } from '@tanstack/react-query' // try next react-query;
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { ApiUtil2 } from '@biz/api'
import { ApiUtil } from '@biz/api'
import alertify from 'alertifyjs'
import { sharedSets } from './MngMdGg';
import MultiFileUploaderGgLogo, { IFileTypes } from '../fileUploader/MultiFileUploaderGgLogo';
import { getByteFunction } from '../common/CommonFunc';

interface ggAdSetUpdate{
  uploadId:number;
  mediaAdSetId:number
  logo : string
  comNm:string
  adSetOrder:number
  segment:string[]|any
  uploadFiles : IFileTypes[] | any
}
interface mediaGg {
  mediaConfigId:number;
  projectDiv3:string;
  mediaDiv2:string;
  useYn:any;
  mediaAccNm: string;
  mediaAccId: string;
  ggCustomerId: string;
  snsAdId:string;
  excludeLocation:string;
  adSetDataList: ggAdSetUpdate[];

  customerId: string,
  customerName:string
  projectDiv3Name:string
  mediaDiv2Name:string
  adSetOrder:number
  tabOrder:number
  comNm:string
  mediaAdSetId:number
  segment:string[]|any
  uploadFiles : IFileTypes[] | any
  ok: boolean;
}

interface mediaGgForm {
  ok: boolean;
  data: mediaGg
}

interface segment{
  segmentName : string;
  segmentResourceName:string;
}
interface segments{
  ok: boolean;
  data : segment[]
}
const projectDiv3Map:any = {
  FUNDING: '펀딩 프로젝트 (오픈 예정)', FUNDING_ING: '펀딩 프로젝트 (펀딩 진행중)', STORE: '스토어 프로젝트',
};
const mediaDiv2Map:any = {
  fbitg: '페이스북 / 인스타그램', gdn: '구글 디스플레이 배너',
};
const MngMdGgUpdate = (props: any) => {
  const url = useLocation();
  const navigate = useNavigate();
  const [tabOrder, setTabOrder] = useState(1);
  const [ggMediaConfigId, setGgMediaConfigId] = useState(null);
  const [uploadFiles, setUploadFiles] = useState<IFileTypes[]>([]);
  const [uploadId, setUploadId] = useState<any>();
  // const ggAdvInfos = [{ id: '4414389470', text: '11h11m.ad', etc: '441-438-9470' }, { id: '4548707300', text: 'test계정', etc: '454-870-7300' }];
  const {
    register, getValues, setValue,
  } = useForm<mediaGg>();
  // 변경되는 데이터 폼
  const ggAdSetUpdateForm = useForm<ggAdSetUpdate>();

  const getMngMdGgDetail = async () => {
    const { data } = await ApiUtil2.get<mediaGgForm>('/api/mngMd/mngMdGg/mngMdGgDetailSearch', { params: { mediaConfigId: props.mediaConfigId } });
    return data;
  };

  const ggDetail = useQuery({
    queryKey: ['getMngMdGgDetail', ggMediaConfigId],
    queryFn: getMngMdGgDetail,
    enabled: false,
  })

  const checkMediaAccUse = async () => {
    const { data } = await ApiUtil2.get<mediaGgForm>('/api/mngMd/mngMdGg/checkMediaAccUse', {
      params: {
        mediaAccId: getValues('mediaAccId'),
        mediaConfigId: getValues('mediaConfigId'),
        projectDiv3: getValues('projectDiv3'),
      },
    })
    return data
  }

  const checkMediaAccUseQuery = useQuery({ queryKey: ['checkMediaAccUse'], queryFn: checkMediaAccUse, enabled: false })

  // 게제 제외 위치 지역
  const getSharedSets = async () => {
    const result = await ApiUtil2.get<sharedSets>('/api/mngMd/mngMdGg/sharedSetSearch', {
      params: { customerId: getValues('mediaAccId') },
    })
    return result.data
  }
  const getSharedSetsData = useQuery({ queryKey: ['getSharedSets'], queryFn: getSharedSets, enabled: false })
  const changeAdSetOrder = (order: number | any) => {
    setTabOrder(order)
    setValue('tabOrder', order)
  }

  // 잠재세그먼트 조회
  const getSegments = async () => {
    const result = await ApiUtil.get<segments>('/api/mngMd/mngMdGg/segmentSearch', {
      params: { customerId: getValues('mediaAccId') },
    })
    return result.data
  }
  const getSegmentsData = useQuery({ queryKey: ['getSegments'], queryFn: getSegments, enabled: false })

  // 광고주 조회
  const getGgCustomerInfos = async () => {
    const result = await ApiUtil.get<any>('/api/mngMd/mngMdGg/ggCustomerInfoSearch')
    return result.data
  }
  const getGgCustomerInfosApi = useQuery({
    queryKey: ['getGgCustomerInfos'],
    queryFn: getGgCustomerInfos,
    enabled: false,
  })
  const deleteFilesFunction = (deleteFileData: IFileTypes[]) => {
    setValue('uploadFiles', deleteFileData)
    setUploadFiles(getValues('uploadFiles'))
    const changeImageData = getValues('adSetDataList').map((mapData: ggAdSetUpdate) => {
      if (mapData.adSetOrder === getValues('tabOrder')) {
        ggAdSetUpdateForm.setValue('mediaAdSetId', mapData.mediaAdSetId)
        ggAdSetUpdateForm.setValue('adSetOrder', mapData.adSetOrder)
        ggAdSetUpdateForm.setValue('comNm', mapData.comNm)
        ggAdSetUpdateForm.setValue('segment', mapData.segment)
        ggAdSetUpdateForm.setValue('uploadFiles', getValues('uploadFiles'))
        return ggAdSetUpdateForm.getValues()
      }
      return mapData
    })
    setValue('adSetDataList', changeImageData)
  }
  const uploadFilesFunction = (fileData: IFileTypes) => {
    if (getValues('uploadFiles').length === 0) {
      setValue('uploadFiles', [fileData])
    } else {
      setValue('uploadFiles', [...getValues('uploadFiles'), fileData])
    }
    setUploadFiles(getValues('uploadFiles'))
    const changeImageData = getValues('adSetDataList').map((mapData: ggAdSetUpdate) => {
      if (mapData.adSetOrder === getValues('tabOrder')) {
        ggAdSetUpdateForm.setValue('mediaAdSetId', mapData.mediaAdSetId)
        ggAdSetUpdateForm.setValue('adSetOrder', mapData.adSetOrder)
        ggAdSetUpdateForm.setValue('comNm', mapData.comNm)
        ggAdSetUpdateForm.setValue('segment', mapData.segment)
        ggAdSetUpdateForm.setValue('uploadFiles', getValues('uploadFiles'))
        return ggAdSetUpdateForm.getValues()
      }
      return mapData
    })
    setValue('adSetDataList', changeImageData)
  }
  const ChangeLogoFiles = useCallback(
    () => (
      <MultiFileUploaderGgLogo
        showUploadFile={true}
        uploadId={uploadId}
        adSetOrder={tabOrder}
        uploadFiles={getValues('uploadFiles')}
        uploadFilesFunction={uploadFilesFunction}
        deleteFilesFunction={deleteFilesFunction}
      />
    ),
    [uploadFiles, tabOrder, uploadId, getValues('mediaAccId')]
  )
  // 광고 집행 계정 변경시 체크 및 세팅
  const changeAdvEvent = () => {
    // 게재 제외 위치 초기값
    const result: any[] = [{ id: '', text: '' }]
    $('#ggExlocationSelect').empty()
    // 광고주 변경시 refetch로 게제제외위치 지역 API 호출
    getSharedSetsData.refetch().then((r) => {
      if (r.isSuccess) {
        r.data.data.map((option) => {
          result.push({ id: option.sharedSetResourceName, text: option.sharedSetName })
          return result
        })
        $('#ggExlocationSelect').select2({
          width: '330px',
          minimumResultsForSearch: Infinity,
          placeholder: '게재 제외 위치 목록을 선택해 주세요.',
          data: result,
        })
        setValue('excludeLocation', '')
      }
    })
    // 잠재 세그먼트 초기값
    if (tabOrder === 2) {
      $('#segmentDl').show()
      const resultSegment: any[] = [{ id: '', text: '' }]
      $('#ggSegment').empty()
      getSegmentsData.refetch().then((r) => {
        if (r.isSuccess) {
          r.data.data.map((option) => {
            resultSegment.push({ id: option.segmentResourceName, text: option.segmentName })
            return resultSegment
          })
          $('#ggSegment').select2({
            width: '330px',
            placeholder: '타겟으로 설정할 잠재 세그먼트를 선택해 주세요.',
            data: resultSegment,
          })
        }
      })
    } else {
      $('#segmentDl').hide()
    }
  }
  // 최초 DB 데이터 불러오고 세팅
  useEffect(() => {
    setValue('tabOrder', 1)
    setGgMediaConfigId(props.mediaConfigId)
    if (ggMediaConfigId != null) {
      ggDetail.refetch().then((r) => {
        if (r.isSuccess) {
          setValue('mediaConfigId', r.data.data.mediaConfigId)
          setValue('projectDiv3Name', projectDiv3Map[r.data.data.projectDiv3])
          setValue('projectDiv3', r.data.data.projectDiv3)
          setValue('mediaDiv2Name', mediaDiv2Map[r.data.data.mediaDiv2])
          setValue('mediaDiv2', r.data.data.mediaDiv2)
          setValue('mediaAccId', r.data.data.mediaAccId)
          setValue('mediaAccNm', r.data.data.mediaAccNm)
          setValue('snsAdId', r.data.data.snsAdId)
          setValue('customerId', r.data.data.mediaAccId)
          setValue('excludeLocation', r.data.data.excludeLocation)
          // 구글 광고 계정 선택
          $('#ggAdvSelect').val(getValues('mediaAccId')).trigger('change')
          // 초기값
          const sharedResults: any[] = []
          $('#ggExlocationSelect').empty()
          // 광고주 변경시 refetch로 게제제외위치 지역 API 호출
          getSharedSetsData.refetch().then((shared) => {
            if (shared.isSuccess) {
              shared.data.data.map((option) => {
                sharedResults.push({ id: option.sharedSetResourceName, text: option.sharedSetName })
                return sharedResults
              })
              $('#ggExlocationSelect').select2({
                width: '330px',
                minimumResultsForSearch: Infinity,
                placeholder: '게재 제외 위치 목록을 선택해 주세요.',
                data: sharedResults,
              })
              $('#ggExlocationSelect').val(getValues('excludeLocation')).trigger('change')
            }
          })
          setValue('useYn', r.data.data.useYn)
          $(`input:radio[name=useYn][value=${r.data.data.useYn}]`).prop('checked', true)
          // $('[name=useYn][value="r.data.data.useYn"]').trigger('click');
          setValue('adSetDataList', r.data.data.adSetDataList)
          r.data.data.adSetDataList.map((mapData: ggAdSetUpdate) => {
            if (mapData.adSetOrder === 1) {
              setValue('comNm', mapData.comNm)
              setValue('uploadFiles', mapData.uploadFiles)
              setUploadFiles(mapData.uploadFiles)
              if (mapData.comNm !== undefined) {
                $('#comNmByte').text(getByteFunction(mapData.comNm))
              }
              return mapData
            }
            return mapData
          })
        }
      })
    }
  }, [ggMediaConfigId])
  const getUploadId = () => {
    ApiUtil.get('/api/common/getUploadId').then((response) => {
      setUploadId(response.data.data)
    })
  }
  //
  useEffect(() => {
    const advInfos: any[] = [{ id: '', text: '' }]
    getGgCustomerInfosApi.refetch().then((r) => {
      if (r.isSuccess) {
        r.data.data.forEach((info: any) => {
          const infoParse = JSON.parse(info)
          advInfos.push({ id: infoParse.id, text: `${infoParse.text}(${infoParse.etc})` })
        })

        $('#ggAdvSelect')
          .select2({
            width: '330px',
            minimumResultsForSearch: Infinity,
            placeholder: '광고를 집행할 계정을 선택해 주세요.',
            data: advInfos,
          })
          .on('select2:selecting', (e: any) => {
            e.preventDefault()
            if (getValues('mediaAccId') !== e.params.args.data.id) {
              alertify
                .confirm(
                  '다른 광고 집행 계정으로 변경 하시겠습니까?<br/>단, 변경 시 기존 기입 정보는 초기화 됩니다.',
                  () => {
                    $('#ggAdvSelect').val(e.params.args.data.id).trigger('change')
                    setValue('mediaAccId', e.params.args.data.id)
                    setValue('snsAdId', e.params.args.data.id)
                    advInfos.map((info: any) => {
                      if (info.id === e.params.args.data.id) {
                        setValue('mediaAccNm', info.text)
                      }
                      return null
                    })
                    // 광고 집행계정 변경시 데이터 초기 세팅
                    const initAdSetDataList = getValues('adSetDataList').map((mapData: ggAdSetUpdate) => {
                      // if (mapData.adSetOrder === getValues('tabOrder')) {
                      // }
                      // return mapData;
                      ggAdSetUpdateForm.setValue('mediaAdSetId', mapData.mediaAdSetId)
                      ggAdSetUpdateForm.setValue('adSetOrder', mapData.adSetOrder)
                      ggAdSetUpdateForm.setValue('comNm', '와디즈')
                      ggAdSetUpdateForm.setValue('segment', [])
                      ggAdSetUpdateForm.setValue('uploadFiles', [])
                      return ggAdSetUpdateForm.getValues()
                    })
                    setValue('adSetDataList', initAdSetDataList)
                    setValue('comNm', '와디즈')
                    setValue('uploadFiles', [])
                    setUploadFiles([])
                    changeAdvEvent()
                    changeAdSetOrder(1)
                    getUploadId()
                  }
                )
                .set({ labels: { cancel: '취소', ok: '확인' } })
                .setHeader('')
            } else {
              $('#ggAdvSelect').val(getValues('mediaAccId')).trigger('change')
            }
          })
      }
    })
    // 최초 들어올 시 게재 제외 위치 설정 세팅
    $('#ggExlocationSelect')
      .select2({
        width: '330px',
        minimumResultsForSearch: Infinity,
        placeholder: '게재 제외 위치 목록을 선택해 주세요.',
      })
      .on('select2:select', (e) => {
        setValue('excludeLocation', e.params.data.id)
      })
    // 최초 들어올 시 잠재세그먼트 세팅
    $('#segmentDl').hide()
    $('#ggSegment')
      .select2({
        width: '330px',
        placeholder: '타겟으로 설정할 잠재 세그먼트를 선택해 주세요.',
      })
      .on('change', () => {
        const multiData: any = $('#ggSegment').val()
        const curAdSetDataList = getValues('adSetDataList').map((mapData: ggAdSetUpdate) => {
          if (mapData.adSetOrder === getValues('tabOrder')) {
            ggAdSetUpdateForm.setValue('mediaAdSetId', mapData.mediaAdSetId)
            ggAdSetUpdateForm.setValue('adSetOrder', mapData.adSetOrder)
            ggAdSetUpdateForm.setValue('comNm', mapData.comNm)
            ggAdSetUpdateForm.setValue('segment', multiData)
            ggAdSetUpdateForm.setValue('uploadFiles', mapData.uploadFiles)
            return ggAdSetUpdateForm.getValues()
          }
          return mapData
        })
        setValue('adSetDataList', curAdSetDataList)
      })
    // 파일 업로드 ID
    getUploadId()
  }, [])
  useEffect(() => {
    $('[id^=ggAdSetOrder]').attr('class', 'tab')
    // 탭 selected class 변경처리
    $(`#ggAdSetOrder${tabOrder}`).addClass('selected')
    if (tabOrder === 2) {
      if (getValues('adSetDataList') !== undefined) {
        getValues('adSetDataList').map((mapData: ggAdSetUpdate) => {
          if (mapData.adSetOrder === 2) {
            setValue('comNm', mapData.comNm)
            setValue('segment', mapData.segment)
            setValue('uploadFiles', mapData.uploadFiles)
            setUploadFiles(mapData.uploadFiles)
            return mapData
          }
          return mapData
        })
      }
      $('#segmentDl').show()
      const resultSegment: any[] = [{ id: '', text: '' }]
      $('#ggSegment').empty()

      getSegmentsData.refetch().then((r) => {
        if (r.isSuccess) {
          r.data.data.map((option) => {
            resultSegment.push({ id: option.segmentResourceName, text: option.segmentName })
            return resultSegment
          })
          $('#ggSegment').select2({
            width: '330px',
            placeholder: '타겟으로 설정할 잠재 세그먼트를 선택해 주세요.',
            data: resultSegment,
          })
          $('#ggSegment').val(getValues('segment')).trigger('change')
        }
      })
    } else {
      if (getValues('adSetDataList') !== undefined) {
        getValues('adSetDataList').map((mapData: ggAdSetUpdate) => {
          if (mapData.adSetOrder === 1) {
            setValue('comNm', mapData.comNm)
            setValue('segment', [])
            setValue('uploadFiles', mapData.uploadFiles)
            setUploadFiles(mapData.uploadFiles)
            return mapData
          }
          return mapData
        })
      }
      $('#segmentDl').hide()
    }

    if (getValues('comNm') !== undefined) {
      $('#comNmByte').text(getByteFunction(getValues('comNm')))
    }
  }, [tabOrder])

  // 업체명 변경시 데이터 전달
  let changeComNm = ''
  const dataChangeEvent = useCallback((id: any, value: any) => {
    const byte = getByteFunction(value)
    if (byte <= 25) {
      changeComNm = value
      setValue(id, value)
      setValue(id, value)
      const curAdSetDataList = getValues('adSetDataList').map((mapData: ggAdSetUpdate) => {
        if (mapData.adSetOrder === getValues('tabOrder')) {
          ggAdSetUpdateForm.setValue('mediaAdSetId', mapData.mediaAdSetId)
          ggAdSetUpdateForm.setValue('adSetOrder', mapData.adSetOrder)
          ggAdSetUpdateForm.setValue('comNm', value)
          ggAdSetUpdateForm.setValue('segment', mapData.segment)
          ggAdSetUpdateForm.setValue('uploadFiles', mapData.uploadFiles)
          return ggAdSetUpdateForm.getValues()
        }
        return mapData
      })
      setValue('adSetDataList', curAdSetDataList)
      $('#comNmByte').text(byte)
    }
    setValue(id, changeComNm)
  }, [])
  const useYnEvent = (event: any) => {
    const useYnValue = event.currentTarget.value
    if (event.currentTarget.value === 'false') {
      event.preventDefault()
      alertify
        .confirm(
          '해당 광고 집행 계정을 `비활성`으로 변경 하시겠습니까?<br/><br/>' +
            '단, 변경 시 기존 광고 집행중인 해당 프로젝트 및 광고 노출 매체의 캠페인은 ' +
            '모두 종료 됩니다.',
          () => {
            $('input:radio[name=useYn][value=useYnValue]').prop('checked', true)
            setValue('useYn', useYnValue)
          }
        )
        .set({ labels: { cancel: '취소', ok: '확인' } })
        .setHeader('')
    } else {
      // useYn 체크 확인
      checkMediaAccUseQuery.refetch().then((r) => {
        if (r.isSuccess) {
          if (r.data.data) {
            alertify.error(
              '이미 동일 프로젝트와 광고 노출 매체에 활성화 된 계정이 있습니다.<br/>확인 후 다시 시도해 주세요.'
            )
          } else {
            $('input:radio[name=useYn][value=useYnValue]').prop('checked', true)
            setValue('useYn', useYnValue)
          }
        }
      })
      event.preventDefault()
    }
  }
  // 업데이터 관련 여부 체크
  const updateChangeCheck = (callback: any) => {
    ggDetail.refetch().then((r) => {
      if (r.isSuccess) {
        const { mediaAccId, excludeLocation, adSetDataList } = getValues()

        let comNm1
        let comNm2
        let changeComNm1
        let changeComNm2
        let segment2
        let changeSegment2
        let imageData1
        let imageData2
        let changeImageData1
        let changeImageData2
        r.data.data.adSetDataList.forEach((mapData: ggAdSetUpdate) => {
          if (mapData.adSetOrder === 1) {
            comNm1 = mapData.comNm
            imageData1 = JSON.stringify(mapData.uploadFiles.map((e: any) => e.fileId))
          } else {
            comNm2 = mapData.comNm
            segment2 = JSON.stringify(mapData.segment)
            imageData2 = JSON.stringify(mapData.uploadFiles.map((e: any) => e.fileId))
          }
        })
        adSetDataList.forEach((mapData: ggAdSetUpdate) => {
          if (mapData.adSetOrder === 1) {
            changeComNm1 = mapData.comNm
            changeImageData1 = JSON.stringify(mapData.uploadFiles.map((e: any) => e.fileId))
          } else {
            changeComNm2 = mapData.comNm
            changeSegment2 = JSON.stringify(mapData.segment)
            changeImageData2 = JSON.stringify(mapData.uploadFiles.map((e: any) => e.fileId))
          }
        })
        if (
          r.data.data.mediaAccId !== mediaAccId ||
          r.data.data.excludeLocation !== excludeLocation ||
          comNm1 !== changeComNm1 ||
          comNm2 !== changeComNm2 ||
          segment2 !== changeSegment2 ||
          imageData1 !== changeImageData1 ||
          imageData2 !== changeImageData2
        ) {
          callback(true)
        } else {
          callback(false)
        }
      }
    })
  }
  // 수정 처리
  const mediaGgUpdateApi = () => {
    const {
      mediaConfigId,
      projectDiv3,
      mediaDiv2,
      useYn,
      mediaAccNm,
      mediaAccId,
      snsAdId,
      excludeLocation,
      adSetDataList,
    } = getValues()
    const result = ApiUtil2.post<mediaGg>('/api/mngMd/mngMdGg/mediaGgUpdate', {
      mediaConfigId,
      projectDiv3,
      mediaDiv2,
      useYn,
      mediaAccNm,
      mediaAccId,
      snsAdId,
      excludeLocation,
      adSetDataList,
    })
    return result
  }
  const mediaGgUpdate = useMutation({
    mutationFn: mediaGgUpdateApi,
    onSuccess: (resp) => {
      if (resp.data.ok) {
        alertify.success('정상적으로 수정되었습니다.', 2, () => {
          navigate('/mngMd/mngMd')
        })
      }
    },
    // onError: (error) => {
    //   console.log(error);
    // },
    // onMutate: () => {
    //   console.log('등록 실행....');
    // },
  })
  const uploadIdSet = () => {
    const uploadIdSetData = getValues('adSetDataList').map((mapData: ggAdSetUpdate) => {
      const tempData = mapData;
      tempData.uploadId = uploadId;
      return tempData;
    });
    setValue('adSetDataList', uploadIdSetData);
  };
  const mediaUpdateSubmit = () => {
    let comNm = '';
    getValues('adSetDataList').map((mapData: ggAdSetUpdate) => {
      comNm = mapData.comNm;
      if (mapData.comNm === '') {
        $(`#comNm${tabOrder}`).addClass('negative');
      }
      return mapData;
    });
    if (comNm === '') {
      alertify.error('선택하지 않으신 필수 항목이 있습니다.<br/>확인 후 다시 시도해 주세요.');
    } else {
      updateChangeCheck((check:boolean) => {
        uploadIdSet();
        if (check) {
          alertify.confirm('변경된 내역을 저장 하시겠습니까?<br/><br/>단, 변경 시 현재 광고 진행중인 캠페인에는 영향을 주지 않으며, 이후 등록되는 캠페인에 적용됩니다.', () => {
            mediaGgUpdate.mutate();
          }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
        } else {
          mediaGgUpdate.mutate();
        }
      });
    }
  };

  return (
    <Fragment>
      <section className="wrap-section wrap-tbl">
        <div className="box-body">
          <div className="tbl">
            <dl className="vertical">
              <dd>
                <div className="form-group">
                  <div className="container-fluid">
                    <div className="row flex-container">
                      <div className="col col-5">
                        <section className="wrap-section wrap-tbl">
                          <div className="box-body">
                            <div className="tbl">
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        와디즈 프로젝트 선택
                                        <i className="fz-12 fc-5">*</i>
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="input-group expand">
                                      <div className="inner-input-group">
                                        <input type="text" className="tf-comm" {...register('projectDiv3Name')} placeholder=""
                                                disabled/>
                                      </div>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        광고 노출 매체
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="input-group expand">
                                      <div className="inner-input-group">
                                        <input type="text" className="tf-comm" {...register('mediaDiv2Name')} placeholder=""
                                               disabled/>
                                      </div>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        광고 집행 계정
                                        <i className="fz-12 fc-5">*</i>
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <select className="select2-single expand" id="ggAdvSelect">
                                    </select>
                                  </div>
                                </dd>
                              </dl>
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        게재 제외 위치 설정
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <select className="select2-single expand" id="ggExlocationSelect" {...register('excludeLocation')}>
                                    </select>
                                  </div>
                                </dd>
                              </dl>
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        활성여부
                                        <i className="fz-12 fc-5">*</i>
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="comp-radio">
                                      <input type="radio" id="inp-radio-01" {...register('useYn', { value: true })} value="true" onClick={(event) => useYnEvent(event)}/>
                                        <label htmlFor="inp-radio-01">활성</label>
                                    </div>
                                    <div className="comp-radio">
                                      <input type="radio" id="inp-radio-02" {...register('useYn', { value: false })} value="false" onClick={(event) => useYnEvent(event)}/>
                                        <label htmlFor="inp-radio-02">비활성</label>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div className="col col-7">
                        <section className="wrap-section wrap-tbl">
                          <div className="box-header">
                            <div className="box-tit">
                              <h2 className="fz-20 fc-1 fw-bold">광고 세트 설정</h2>
                            </div>
                            <div className="box-option">
                            </div>
                          </div>
                          <div className="box-body">
                            <div className="comp-tab">
                              <Link to={url} id="ggAdSetOrder1" className="tab" onClick={() => changeAdSetOrder(1)}>
                                <span className="tab-item">메이커 세트</span>
                              </Link>
                              <Link to={url} id="ggAdSetOrder2" className="tab" onClick={() => changeAdSetOrder(2)}>
                                <span className="tab-item">와디즈 기본 세트</span>
                              </Link>
                            </div>
                          </div>
                          <div className="comp-tab-content selected">
                            <div className="tbl">
                              <ChangeLogoFiles/>
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        업체명
                                        <i className="fz-12 fc-5">*</i>
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="input-group expand" id={`comNm${tabOrder}`}>
                                      <div className="inner-input-group" >
                                        <input type="text" className="tf-comm" id="comNm" {...register('comNm')} placeholder="업체명을 입력해 주세요."
                                               onChange={(event) => dataChangeEvent(event.currentTarget.id, event.currentTarget.value)}/>
                                      </div>
                                      <p className="fz-12 fc-3"><span className="fc-2" id="comNmByte">0</span> / 25</p>
                                      <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                              <dl className="vertical" id="segmentDl">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        잠재세그먼트
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <select className="select2-tags expand" id="ggSegment" multiple>
                                      <option value=""></option>
                                    </select>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="box-footer">
          <div className="box-right">
            <button type="button" className="btn btn-secondary-outline" onClick={() => navigate('/mngMd/mngMd')}>취소</button>
            <button type="button" className="btn btn btn-primary" onClick={mediaUpdateSubmit}>수정</button>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default MngMdGgUpdate;
