// ApiUtil2 에서 사용하는 paramsSerializer 를 복사해서 쓴다. 추후 제거할 필요가 있다.
export const useParamsSerializer = () => (params: any) =>
  Object.keys(params)
    .map(
      (key) =>
        `${key}=${
          typeof params[key] === 'object' || key === 'data'
            ? encodeURIComponent(JSON.stringify(params[key]))
            : params[key]
        }`
    )
    .join('&')
