import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { Dialog } from 'rc-easyui';
import NumberFormat from 'react-number-format';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query' // try next react-query;
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'

interface IBzmMngCouponRedemption{
    ok:boolean;
    data:any;
    makerId:any;
    couponRedemptionReqCost:number;
    couponRedemptionReason:string;
    possibleCost:number;
}
const BzmMngRedemptionDialog = (props:any) => {
  // dialog 요소
  const dialogRef = useRef<any>();
  const {
    getValues, setValue, register,
  } = useForm<IBzmMngCouponRedemption>();
  const [reasonCount, setReasonCount] = useState(0);

  const cancelEvent = () => {
    props.setRedemptionCouponDialog(false);
  };
  // 쿠폰 환수 관련 이벤트
  const redemptionApi = () => {
    setValue('possibleCost', props.possibleCost);
    setValue('makerId', props.makerId);
    const {
      makerId, couponRedemptionReqCost, couponRedemptionReason, possibleCost,
    } = getValues();
    const result = ApiUtil2.post<IBzmMngCouponRedemption>('/api/bzmAdmin/mng/bzmMngMaker/redemptionReq', {
      makerId, couponRedemptionReqCost, couponRedemptionReason, possibleCost,
    });
    return result;
  };
  const redemptionApiEvent = useMutation({
    mutationFn: redemptionApi,
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 계속 수정 처리
        alertify.success('쿠폰 환수가 정상적으로 진행 되었습니다.')
        props.setRedemptionCouponDialog(false)
        props.afterEvent()
      }
    },
  })
  const redemptionEvent = () => {
    if (props.possibleCost < getValues('couponRedemptionReqCost')) {
      alertify.error('환수 진행 금액은 환수 가능 금액 범위 안에서 입력해 주세요.');
      return;
    }
    redemptionApiEvent.mutate();
  };
  useEffect(() => {
    $('#bzmMngRedemptionDialog').dialog({
      width: 900, title: '쿠폰 환수', closeOnEscape: true,
    });
  }, []);
  return (
        <Fragment>
            <form>
                <Dialog title="쿠폰 환수" modal resizable shadow={false} ref={dialogRef} style={{ width: '900px' }}>
                    <div className="dialog-body">
                        <div className="wrap-section wrap-tbl">
                            <div className="box-body">
                                <div className="tbl">
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                        <span className="fz-16 fc-1">
                                                            환수 대상
                                                        </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <input type="text" className="tf-comm tf-unit" value={props.redemptionCouponTitle} disabled/>
                                                    </div>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                        <span className="fz-16 fc-1">
                                                            충전 사업자 번호
                                                        </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <input type="text" className="tf-comm tf-unit" value={props.userNo} disabled/>
                                                    </div>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                        <span className="fz-16 fc-1">
                                                            환수 가능 금액
                                                        </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group small expand">
                                                    <div className="inner-input-group">
                                                        <input type="text" className="tf-comm tf-unit" value ={props.possibleCost.toLocaleString() || 0 } disabled/>
                                                        <span className="fz-14 fc-1">원</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-section wrap-tbl">
                            <div className="box-body">
                                <div className="tbl">
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                        <span className="fz-16 fc-1">
                                                            환수 진행 금액
                                                        </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <NumberFormat id="couponReqCost"
                                                                      className="tf-comm tf-unit"
                                                                      thousandSeparator={true}
                                                                      maxLength={props.possibleCost}
                                                                      onChange={(e: any) => {
                                                                        setValue('couponRedemptionReqCost', Number(e.target.value.replaceAll(',', '')));
                                                                      }}
                                                        />
                                                        <span className="fz-14 fc-1">원</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                        <span className="fz-16 fc-1">
                                                            환수 사유
                                                        </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group small expand">
                                                    <div className="inner-input-group">
                                                        <textarea className="tf-textarea" placeholder="1 ~ 100자 내로 입력해 주세요." maxLength={100} {...register('couponRedemptionReason', {
                                                          required: {
                                                            value: true,
                                                            message: '환수 금액과 환수 사유는 필수적으로 입력해 주셔야 합니다.',
                                                          },
                                                        })} onChange={(e) => {
                                                          setReasonCount(e.target.value.length);
                                                        }}></textarea>
                                                    </div>
                                                    <p className="fz-12 fc-3"><span className="fc-2">{reasonCount}</span> / 100</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-secondary-outline large" onClick={cancelEvent}>취소
                        </button>
                        <button type="button" className="btn btn-primary large" onClick={redemptionEvent}>확인</button>
                    </div>
                </Dialog>
            </form>
        </Fragment>
  );
};
export default BzmMngRedemptionDialog;
