import { Modal, Button, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { FacebookFrame, FacebookContent } from '@biz/ui'
import { useCreationDataPreviewQuery } from './useCreationDataPreviewQuery'
import styles from './PreviewModal.module.scss'

type PreviewDataType = {
  adDesc: string
  creationDataInspHisAfDtos: {
    afMeta: string // JSON
    afName: string
    fileName: string
  }[]
  creationType: 'slide' | 'image'
  adTitle01: string
  clickBtnDivText: string
  adTitle02: string
}
type PreviewModalFunction = {
  isOpen: boolean
  onClose: () => void
  creationDataInspHisId?: number
}
export const PreviewModal: React.FC<PreviewModalFunction> = ({ isOpen, onClose, creationDataInspHisId }) => {
  const { data, isLoading } = useCreationDataPreviewQuery(creationDataInspHisId)
  const {
    adDesc: mainCopy,
    creationDataInspHisAfDtos: contentMeta,
    adTitle01,
    clickBtnDivText: ctaBtn,
    adTitle02,
  } = (data || {}) as PreviewDataType
  const imageList = (contentMeta || []).map((n) => ({
    afMeta: JSON.parse(n.afMeta),
    fileName: n.fileName,
    afName: n.afName,
  }))
  return (
    <Modal
      width={565}
      open={isOpen}
      closable={false}
      centered
      title={null}
      footer={[
        <div className={styles.modalFooter} key={'footer'}>
          <Button type="default" block size="large" onClick={onClose} style={{ width: 200 }}>
            {'닫기'}
          </Button>
        </div>,
      ]}
      className={styles.containPreviewModal}
    >
      <section className={styles.outline}>
        <FacebookFrame isPreview>
          {isLoading ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          ) : (
            <FacebookContent
              ctaBtn={ctaBtn}
              desc={adTitle02}
              mainCopy={mainCopy}
              imageList={imageList}
              adTitle={adTitle01}
            />
          )}
        </FacebookFrame>
      </section>
    </Modal>
  )
}
