import { Fragment, useEffect } from 'react'
import { useQueries, useQueryClient } from '@tanstack/react-query' // try next react-query;
import { ApiUtil } from '@biz/api'
import { useLoginStatusQuery } from '@biz/query'
import LeftMenuList from './LeftMenuList'
// import { loginSuccess } from '../Header/Header';

export interface Menu {
  id: string
  name: string
  path: string
  menuId: string
  show: boolean
  isLeaf: boolean
  isNew?: boolean
  children: any
}
const LeftMenu = () => {
  const { data: loginStatus } = useLoginStatusQuery()
  const queryClient = useQueryClient()

  const getMenus = async () => {
    const { data } = await ApiUtil.get<Menu[]>('/api/menu')
    return data
  }
  const getSubMenus = async () => {
    const { data } = await ApiUtil.get<Menu[][]>('/api/subMenuList')
    return data
  }
  // 다중 쿼리를 사용할때 useQueries 사용
  const queries = [
    {
      queryKey: ['menus'],
      queryFn: getMenus,
    },
    {
      queryKey: ['subMenus'],
      queryFn: getSubMenus,
    },
  ]
  const resultMenus = useQueries({ queries })
  const data = queryClient.getQueryData(['menus'])

  useEffect(() => {
    if (data === undefined) {
      queryClient.invalidateQueries({ queryKey: ['menus'] })
      queryClient.invalidateQueries({ queryKey: ['subMenus'] })
    }
  }, [data])

  useEffect(() => {
    if (loginStatus) {
      queryClient.invalidateQueries({ queryKey: ['menus'] })
      queryClient.invalidateQueries({ queryKey: ['subMenus'] })
    }
  }, [loginStatus])
  return (
    <Fragment>
      <aside className="lnb sidebar">
        <ul className="inner-lnb sidebar-menu" id="menuList">
          {resultMenus[0].status === 'success' && resultMenus[1].status === 'success' ? (
            <LeftMenuList
              items={resultMenus[0].data as Menu[]}
              loading={resultMenus[0].isLoading}
              subItems={resultMenus[1].data as Menu[][]}
            ></LeftMenuList>
          ) : null}
        </ul>
      </aside>
    </Fragment>
  )
}

export default LeftMenu
