import { useState } from 'react'
import { Table } from 'antd'
import { TableProps } from 'antd/es/table'
import type { InspectionType } from '@biz/api'
import { columnsFactory } from './columnsFactory'
import { sorterFactory } from './sorterFactory'
import styles from './AIInspectionTable.module.scss'
type SortOption = {
  field: keyof InspectionType
  order: 'ascend' | 'descend'
}
type AIInspectionTableFunction = {
  list?: InspectionType[]
  isLoading?: boolean
  onColumn?: (select: 'preview' | 'ai-result' | 'team-result', field: any, data?: InspectionType) => void
}
export const AIInspectionTable: React.FC<AIInspectionTableFunction> = ({ list, isLoading, onColumn }) => {
  const [sortOption, setSortOption] = useState<SortOption | undefined>({ field: 'regTime', order: 'descend' })
  const handleTableChange: TableProps<InspectionType>['onChange'] = (pagination, filters, sorter, extra) => {
    if (extra?.action === 'sort') {
      setSortOption(sorter as SortOption)
    }
  }

  const { field, order } = sortOption || {}
  const sortFn = !field || !order ? undefined : sorterFactory({ field, order })
  const sortedList =
    !field || !order || !sortFn ? list : (list || []).map((n) => n).sort((a, b) => sortFn(a?.[field], b?.[field]))
  return (
    <Table
      className={styles.containAIInspectionTable}
      rowClassName={styles.defaultRowClassName}
      scroll={{ x: 'max-content' }}
      dataSource={sortedList}
      columns={columnsFactory(onColumn)}
      rowKey={(row) => (row as { creationDataInspHisId: number }).creationDataInspHisId}
      onChange={handleTableChange}
      showSorterTooltip={{ target: 'sorter-icon' }}
      loading={isLoading}
      pagination={{
        position: ['bottomRight'],
        showSizeChanger: false,
        showTotal: (total) => `총 ${total}건`,
      }}
      tableLayout="fixed"
      locale={{
        emptyText: null,
      }}
      bordered
    />
  )
}
