/* eslint-disable func-names */
import $ from 'jquery'
import React, { useEffect, useState } from 'react'
import FroalaWYSIWYGEditor from 'react-froala-wysiwyg'
import type { MyComponentProps as FroalaWYSIWYGEditorProps } from 'react-froala-wysiwyg'
import classNames from 'classnames'
import { LANGUAGE } from 'froala-editor'
import type { Config, WYSIWYGEditor as WYSIWYGEditorType } from 'froala-editor'
import { camelCase, isEqual, merge, throttle } from 'lodash-es'

import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/char_counter.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/draggable.min.js'
import 'froala-editor/js/plugins/file.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/quick_insert.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/url.min.js'
import 'froala-editor/js/plugins/video.min.js'
import 'froala-editor/js/plugins/word_paste.min.js'

import { showToast } from '../Toast/toastUtil'

import defaultConfig from './settings/config'
import './settings/icons'
import './settings/language'

import './plugins/highlighter'
import './plugins/quote'

import 'froala-editor/css/froala_editor.min.css'
import 'froala-editor/css/plugins.pkgd.min.css'
// import 'font-awesome/scss/font-awesome.scss'

import './WYSIWYGEditor.scss'
import './WYSIWYGEditorContent.scss'

export interface WYSIWYGEditorProps extends Omit<FroalaWYSIWYGEditorProps, 'config'> {
  config?: Partial<Config>
  model?: string
  isError?: boolean
  isReadOnly?: boolean
}

const WYSIWYGEditor = ({ config: additionalConfig = {}, model, isError, isReadOnly, ...props }: WYSIWYGEditorProps) => {
  let temporaryFileName = ''
  const [froalaEditor, setFroalaEditor] = useState<WYSIWYGEditorType>()

  const mergedConfig: Partial<Config> = {
    ...defaultConfig,
    ...additionalConfig,
  }

  const replaceHTTPToHTTPS = async (editor: WYSIWYGEditorType) => {
    const { events, html } = editor
    const parser = new DOMParser()
    const parsedHTML = parser.parseFromString(html.get(), 'text/html')
    const body = parsedHTML.querySelector('body') as HTMLBodyElement
    const images = Array.from(body.querySelectorAll('img')).filter((image) => image.src.startsWith('http://'))

    if (images.length > 0) {
      images.forEach((image) => {
        image.src = image.src.replace(/^http:\/\//i, 'https://')
      })

      html.set(body.innerHTML)
      events.trigger('contentChanged', [], false)
    }
  }

  const setVideoOuterHTML = (editor: WYSIWYGEditorType) => {
    const { events, html, opts: options } = editor
    const parser = new DOMParser()
    const parsedHTML = parser.parseFromString(html.get(), 'text/html')
    const body = parsedHTML.querySelector('body') as HTMLBodyElement
    const iframes = Array.from(body.querySelectorAll('iframe')).filter(
      (iframe) =>
        iframe.parentElement &&
        iframe.parentElement.classList.contains('fr-video') === false &&
        options.videoAllowedProviders.some((provider: string) => iframe.src.includes(provider))
    )

    if (iframes.length > 0) {
      iframes.forEach((iframe) => {
        iframe.width = '640'
        iframe.height = '360'
        iframe.outerHTML = `<span class="fr-video fr-deletable fr-fvc fr-dvb fr-draggable" contenteditable="false" draggable="true">${iframe.outerHTML}</span>`
      })

      html.set(body.innerHTML)
      events.trigger('contentChanged', [], false)
    }
  }

  const initializeImageSize = async (editor: WYSIWYGEditorType) => {
    try {
      const { events, el } = editor

      const getIsExistDatasetProperty = (_image: HTMLImageElement, name: string) =>
        _image.hasAttribute(name) && _image.getAttribute(name) !== '0'

      const setSizeDataAttributes = (image: HTMLImageElement) => {
        const { width, height, naturalWidth, naturalHeight } = image

        if (height > 0) {
          image.setAttribute('data-width', `${width}`)
          image.setAttribute('data-height', `${height}`)
          image.setAttribute('data-natural-width', `${naturalWidth}`)
          image.setAttribute('data-natural-height', `${naturalHeight}`)
        }
      }

      const images = Array.from(el.querySelectorAll('img') as NodeListOf<HTMLImageElement>).filter(
        (image) =>
          (getIsExistDatasetProperty(image, 'data-width') &&
            getIsExistDatasetProperty(image, 'data-height') &&
            getIsExistDatasetProperty(image, 'data-natural-width') &&
            getIsExistDatasetProperty(image, 'data-natural-height')) === false
      )

      const promises = images.map(
        (image) =>
          new Promise((resolve) => {
            image.onload = () => {
              setSizeDataAttributes(image)
              resolve(true)
            }

            image.onerror = () => {
              resolve(false)
            }
          })
      )

      if (promises.length > 0) {
        const results = await Promise.all(promises)

        if (results.filter((result) => result === true).length > 0) {
          // @ts-ignore
          props?.onModelChange(editor.html.get(), { dirtyFlag: false })
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const config = {
    ...defaultConfig,
    ...additionalConfig,
    events: {
      'html.get': function (html: string) {
        return html.replace(/id="isPasted"/g, '')
      },
      keyup: function (event: KeyboardEvent) {
        const editor = this as unknown as WYSIWYGEditorType

        // https://github.com/froala/wysiwyg-editor/issues/4545
        if (editor.helpers.isIOS() && event.key === 'Backspace') {
          editor.cursor.backspace()
          event.preventDefault()
        }
      },

      ...additionalConfig.events,

      initialized: function () {
        const editor = this as unknown as WYSIWYGEditorType

        const { imageAllowedTypes, imageMaxSize } = mergedConfig

        if (imageAllowedTypes && imageMaxSize) {
          LANGUAGE.ko.translation['Drop image'] = `${imageMaxSize / 1024 / 1024}MB 이하의<br />${imageAllowedTypes
            .join(', ')
            .toUpperCase()} 파일`
        }

        if (additionalConfig.events?.initialize) {
          additionalConfig.events.initialize.bind(editor)()
        } else {
          initializeImageSize(editor)
        }

        // youtube, vimeo를 제외한 영상은 지원하지 않음
        const isNotSupported = (url: string) => {
          return (
            !defaultConfig.videoAllowedProviders?.some((provider: string) => url?.includes(provider)) &&
            !url?.includes('youtu.be')
          )
        }

        // video.min.js에서 영상 URL을 파싱하면서 에러나는 케이스가 있어 따로 처리해줌
        if (editor.video) {
          const originalInsertByURL = editor.video.insertByURL

          editor.video.insertByURL = (originalUrl: undefined | string) => {
            // quick insert (+ 버튼)으로 추가할 때
            if (originalUrl) {
              if (isNotSupported(originalUrl)) {
                // 지원하지 않는 영상인 경우 토스트 메시지 노출
                showToast('YouTube 또는 Vimeo 동영상 url을 입력해 주세요', { type: 'warning' })
                return
              }

              originalInsertByURL(originalUrl)
              return
            }

            const insertPopup = editor.popups.get('video.insert')
            const autoplayField = insertPopup.find('#videoPluginAutoplay')[0] as HTMLInputElement
            if (!insertPopup) {
              return
            }
            let targetUrl = insertPopup.find('.fr-video-by-url-layer input[type="text"]').val() || ''
            const isAutoplay = autoplayField ? autoplayField.checked : false

            if (isNotSupported(targetUrl)) {
              showToast('YouTube 또는 Vimeo 동영상 url을 입력해 주세요', { type: 'warning' })
              return
            }

            // vimeo일 때 (autoplay일 경우 따로 파라미터 추가해줘야함)
            if (targetUrl.includes('vimeo')) {
              if (isAutoplay) {
                targetUrl = targetUrl.trim().replace('?share=copy', '') + '?autoplay=1&background=1&mute=1'
              }
            }
            // youtube일 때 (feature 파라미터 있을 경우 autoplay 작동하지 않아 파라미터를 제거해줌)
            if (targetUrl.includes('youtu') && targetUrl.includes('?feature=shared')) {
              targetUrl = targetUrl.trim().replace('?feature=shared', '')
            }

            originalInsertByURL(targetUrl)
          }
        }

        setFroalaEditor(editor)
      },
      contentChanged: function () {
        const editor = this as unknown as WYSIWYGEditorType

        replaceHTTPToHTTPS(editor)
        setVideoOuterHTML(editor)

        if (additionalConfig.events?.contentChanged) {
          additionalConfig.events.contentChanged.bind(editor)()
        }
      },
      'image.beforeUpload': function (files: FileList) {
        const editor = this as unknown as WYSIWYGEditorType
        const file = files[0]

        if (file instanceof File) {
          temporaryFileName = file.name
        } else {
          // FIXME: Blob 이미지 복사/붙여넣기 시 Base64 PNG 파일로 변환하는 문제
          temporaryFileName = 'UNABLE_TO_UPLOAD'
          showToast('이 이미지는 붙여넣기할 수 없어요. 내용을 저장한 후 다시 시도해 주세요.', { type: 'warning' })

          return true
        }

        if (mergedConfig.imageMaxSize && file.size > mergedConfig.imageMaxSize) {
          editor.image.hideProgressBar(false)
          showToast(
            `${
              mergedConfig.imageMaxSize / 1024 / 1024
            }MB 이하의 이미지만 업로드할 수 있어요. 파일 용량을 확인해 주세요.`,
            { type: 'warning' }
          )

          return false
        }

        return true
      },
      'image.inserted': function (images: HTMLImageElement[]) {
        const editor = this as unknown as WYSIWYGEditorType
        const image = images[0]

        if (temporaryFileName === 'UNABLE_TO_UPLOAD') {
          image.remove()
          editor.popups.hideAll()

          return
        }

        image.alt = temporaryFileName

        if (additionalConfig.events?.['image.inserted']) {
          additionalConfig.events['image.inserted'].bind(editor)(images)
        }
      },
      'image.loaded': function (images: HTMLImageElement[]) {
        const image = images[0]
        const list = ['data-width', 'data-height', 'data-natural-width', 'data-natural-height']

        list.forEach((key) => {
          // 1. 초기 로딩 시 - 이미 데이터가 있을 경우 속성을 다시 넣어주지 않음
          // 1-1. 기존에 세팅된 이미지 너비/높이 값이 0이 아닌 경우
          if (image.hasAttribute(key) && image.getAttribute(key) !== '0') {
            return
          } else {
            // 기존에 세팅된 이미지 너비/높이 값이 0인 경우 서포터 영역에서 placeholder 지정이 안됨으로 속성값을 삭제하도록 처리
            if (image.getAttribute(key) === '0') {
              image.removeAttribute(key)
            }

            // 2. 업로드 시 - 데이터가 없을 경우 이미지 사이즈를 계산해서 속성에 추가해줌
            const valueName = camelCase(key.replace('data-', '')) as keyof HTMLImageElement // width, height, naturalWidth, naturalHeight
            if ((image[valueName] as number) > 0) {
              // 너비를 얻어올 수 있는 경우에만 처리하도록 수정
              image.setAttribute(key, String(image[valueName]))
            }
          }
        })
      },
      'image.replaced': function (images: JQuery<HTMLImageElement>) {
        const image = images.get()[0]
        const list = ['data-width', 'data-height', 'data-natural-width', 'data-natural-height']
        list.forEach((key) => {
          // 이전 데이터셋이 사라지지 않는 경우가 있어 삭제해줌
          if (image.getAttribute(key)) {
            image.removeAttribute(key)
          }
        })
      },
      'video.inserted': function ($video: JQuery<HTMLVideoElement>) {
        const element = $video.get(0) as unknown as Element
        if (element) {
          const iframeEl = element.querySelector('iframe')

          if (iframeEl && iframeEl.src) {
            // youtube short에 대한 예외처리(4 version에서의 editor 파싱 이슈)
            // 기존: https://www.youtube.com/shorts/s7GEAzQTAvw -> https://www.youtube.com/embed/shorts?/s7GEAzQTAvw&wmode=opaque&ref=0
            // 변경: https://www.youtube.com/shorts/s7GEAzQTAvw -> https://www.youtube.com/embed/s7GEAzQTAvw?&wmode=opaque&ref=0
            // 변경: https://www.youtube.com/embed/shorts?/s7GEAzQTAvwautoplay=1&mute=1&wmode=opaque&rel=0 -> https://www.youtube.com/embed/s7GEAzQTAvw?autoplay=1&mute=1&wmode=opaque&rel=0

            if (/youtube\.com\/embed\/shorts\?/i.test(iframeEl.src)) {
              let iframePath = iframeEl.src
              let param = ''
              const autoPlay = iframePath.match(/autoplay=[0-1]/g)
              const mute = iframePath.match(/mute=[0-1]/g)

              // 자동 실행
              if (autoPlay && autoPlay.length > 0) {
                iframePath = iframePath.replace(new RegExp(autoPlay[0], 'g'), '')
                param = autoPlay[0]
              }

              // 음소거 모드 여부
              if (mute && mute.length > 0) {
                iframePath = iframePath.replace(new RegExp(mute[0], 'g'), '')
                param = param.length > 0 ? `${param}&${mute[0]}` : mute[0]
              }

              const video = iframePath.match(/shorts\?\/([a-z0-9_-]+)/i)
              if (video && video.length > 1) {
                const videoId = video[1]
                iframeEl.src = `https://www.youtube.com/embed/${videoId}?${param}&wmode=opaque&ref=0`
              }
            }
            // vimeo 오토플레이일 경우 파라미터 중복으로 들어가는 이슈
            // video.min.js에서 원본 배열에 'autoplay=1&mute=1' 파라미터를 계속 붙여 원본을 변경해 버림 (업로드가 반복될수록 파라미터가 쌓이는 버그)
            // https://player.vimeo.com/video/762147108?autoplay=1&background=1&mute=1autoplay=1&mute=1autoplay=1&mute=1
            // &가 없이 'autoplay=1&mute=1'만 붙는 경우 제거해줌
            else if (iframeEl.src.includes('vimeo.com')) {
              let iframePath = iframeEl.src
              let param = ''
              const autoPlay = iframePath.match(/autoplay=[0-1]/g)
              const mute = iframePath.match(/mute=[0-1]/g)
              iframePath = iframePath.split('?')[0]

              // 자동 실행
              if (autoPlay && autoPlay.length > 0) {
                param = autoPlay[0] === 'autoplay=1' ? `${autoPlay[0]}&background=1` : autoPlay[0]
              }

              // 음소거 모드 여부
              if (mute && mute.length > 0) {
                param = param.length > 0 ? `${param}&${mute[0]}` : mute[0]
              }

              iframeEl.src = param ? `${iframePath}?${param}` : iframePath
            }

            // 중복된 쿼리가 들어간 경우를 대비해 제거해줌 (코드리뷰: https://github.com/wadiz-fe/wadiz-frontend/pull/11624)
            else {
              const params = new URLSearchParams(new URL(iframeEl.src).search)
              const uniqueParams = new URLSearchParams()
              for (const [key, value] of params) {
                uniqueParams.set(key, value)
              }
              iframeEl.src = `${new URL(iframeEl.src).href}?${uniqueParams.toString()}`
            }
          }
        }
      },
    },
  }

  useEffect(() => {
    if (froalaEditor) {
      // 초기화 함수는 렌더링 이후 단 한 번만 실행합니다.
      // 따라서 설정에 변경 사항이 발생해서 리렌더링을 하더라도 초기화 이벤트는 발생하지 않기 때문에 초기화 함수에 변경 사항이 발생한 경우 초기화 함수를 실행하도록 합니다.
      if (
        additionalConfig.events?.initialize &&
        !isEqual(additionalConfig.events.initialize, froalaEditor.opts.events.initialize)
      ) {
        additionalConfig.events.initialize.bind(froalaEditor)()
      }

      merge(froalaEditor.opts, additionalConfig)
    }
  }, [froalaEditor, additionalConfig])

  useEffect(() => {
    if (froalaEditor) {
      if (isReadOnly) {
        froalaEditor.edit.off()
      } else {
        froalaEditor.edit.on()
      }
    }
  }, [froalaEditor, isReadOnly])

  useEffect(() => {
    if (froalaEditor) {
      const modal = froalaEditor.el.closest('.ReactModalPortal')

      if (modal) {
        const modalZIndex = window.getComputedStyle(modal.querySelector('[class*="overlay"]')!).zIndex

        /**
         * 편집기 팝업 요소는 편집기 DOM이 아닌 body DOM에 렌더링합니다. 따라서 모달 안에 편집기가 있는 경우 편집기 팝업 요소가 모달의 레이어에 가려지는 문제가 발생합니다.
         *
         * - zIndex 옵션 설정: 설정한 값을 따릅니다.
         * - zIndex 옵션 설정 안 함: 모달의 zIndex 값을 따릅니다. (편집기 팝업 요소가 모달의 레이어에 가려지지 않도록 합니다.)
         */
        if (additionalConfig.zIndex === undefined) {
          merge(froalaEditor.opts, { zIndex: modalZIndex })
        }

        /**
         * 모달 내부 스크롤 시 활성 팝업을 제어하는 함수
         *
         * - 편집기 내부 영역 스크롤 시: 활성 팝업을 유지합니다.
         * - 편집기 외부 영역 스크롤 시: body DOM에 렌더링하는 활성 팝업을 숨깁니다. (편집기 팝업 요소를 편집기 외부 영역에 계속 노출하지 않도록 합니다.)
         */
        const handleModalScroll: EventListener = (event) => {
          const { popups } = froalaEditor
          const target = event.target as HTMLElement

          if (target.closest('.wysiwyg-editor') === null) {
            if (popups.isVisible('image.edit')) {
              popups.hide('image.edit')
            }

            if (popups.isVisible('table.edit')) {
              popups.hide('table.edit')
            }

            if (popups.isVisible('video.edit')) {
              popups.hide('video.edit')
            }
          }
        }

        const throttledModalScroll = throttle(handleModalScroll, 100)

        modal.addEventListener('scroll', throttledModalScroll, true)

        froalaEditor.events.on(
          'destroy',
          () => {
            modal.removeEventListener('scroll', throttledModalScroll, true)
          },
          false
        )
      }
    }
  }, [froalaEditor])

  return (
    <div className={classNames('wysiwyg-editor', { 'is-error': isError })}>
      <FroalaWYSIWYGEditor config={config} model={model} {...props} />
    </div>
  )
}

export default React.memo(WYSIWYGEditor)
