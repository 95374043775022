import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PostpaidMoneyManagementTab from "../PostpaidMoneyManagementTab";
import dayjs from "dayjs";
import {AgGridReact} from "ag-grid-react";
import NoDataTemplate from "../../../common/NoDataTemplate";
import Pagination from "../../../common/Pagination";
import {Link, useOutletContext} from "react-router-dom";
import {useForm} from "react-hook-form";
import { ApiUtil2 } from '@biz/api'
import {ColDef, GridSizeChangedEvent, ICellRendererParams} from "ag-grid-community";
import alertify from 'alertifyjs'
import qs from "qs";
import DlDialog from "../../../mngMakerAccount/DlDialog";
interface IPomInquiryForm {
    startDate: string;
    endDate: string;
    filter: string;
    query: string;
}
interface IPomInquiry {
    data: any;
    pomInquiryId: number;
    status: string;
    makerId: number;
    makerLoginId: string;
    makerNm: string;
    project2Id: string;
    updateTime: string;
    regTime: string;
    modifier: string;
}
const dateFormatterForDetails = (props: ICellRendererParams) => {
    const { date } = props.value;
    const { time } = props.value;
    return dayjs(new Date(date.year, date.month - 1, date.day, time.hour, time.minute)).format('YYYY-MM-DD HH:mm');
};
const PomInquiryManagement = () => {
    const [statusesMsg, setStatusesMsg] = useState<any>('');
    const [isDateValid, setIsDateValid] = useState<boolean>(true);
    const setTitle = useOutletContext<any>();
    const childRef = useRef<any>(null);
    const gridRef = useRef<AgGridReact>(null);
    const [rowData, setRowData] = useState<any[]>();
    let statuses:any[] = ['WAITING'];
    const [statusesForDownload, setStatusesForDownLoad] = useState<any[]>(statuses);
    const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false);
    const [dlParam, setDlParam] = useState<any>(null);

    const {
        register, handleSubmit, getValues, setValue,
    } = useForm<IPomInquiryForm>();
    const onGridReady = useCallback(() => {
        const {
            startDate, endDate, query, filter
        } = getValues();
        ApiUtil2.get<IPomInquiry>('/api/bzmAdmin/postpaidMoneyManagement/inquiry/list', {
            params: {
                startDate, endDate, query, filter, statuses: statuses
            },
            paramsSerializer: (params) => qs.stringify(params),
        }).then((resp) => {
            setRowData(resp.data.data);
        });
    }, []);
    const onGridSizeChanged = (params: GridSizeChangedEvent) => {
        params.api.sizeColumnsToFit();
    };
    const changePagination = () => {
        childRef.current!.onPaginationChanged();
    };
    const [columnDefs] = useState<ColDef[]>([
        {
            field: 'status',
            headerName: '처리 상태',
            cellClass: 'ag-center-aligned-cell',
        },
        {
            field: 'makerLoginId',
            headerName: '메이커 ID',
            cellClass: 'ag-center-aligned-cell',
            cellRenderer : (props:ICellRendererParams) => {
                return <Link to={`/bzmAdmin/postpaidMoneyManagement/inquiry/detail/${props.data.makerId}/${props.data.project2Id}/${props.data.pomInquiryId}`} className="txt-link">{props.data.makerLoginId}</Link>
            }
        },
        {
            field: 'makerNm',
            headerName: '메이커명',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
        },
        {
            field: 'project2Id',
            headerName: '프로젝트 번호',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
        },
        {
            field: 'updateTime',
            headerName: '최종 변경일시',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
            cellRenderer: dateFormatterForDetails,
        },
        {
            field: 'regTime',
            headerName: '최종 등록일시',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
            cellRenderer: dateFormatterForDetails,
        },
        {
            field: 'modifier',
            headerName: '수정자',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
        },
    ]);
    const defaultColDef = useMemo<ColDef>(() => ({
        flex: 1,
        resizable: false,
        suppressMovable: true,
        sortable: true,
    }), []);

    useEffect(() => {
        setTitle('후불머니 관리');
        /** 초기 값 */
        setValue('filter', 'MAKER_ID');
        setValue('startDate', dayjs().startOf('day').subtract(30, 'd').format('YYYYMMDD'));
        setValue('endDate', dayjs().startOf('day').format('YYYYMMDD'));
        const getDateDiff = (d1:any, d2:any) => {
            const date1 = new Date(d1);

            const date2 = new Date(d2);

            const diffDate = date1.getTime() - date2.getTime();
            return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
        };
        /** datepicker */
        $('#datepicker').daterangepicker({
            maxDate: dayjs().startOf('day'),
            startDate: dayjs().startOf('day').subtract(30, 'd'),
            endDate: dayjs().startOf('day'),
            opens: 'left',
            ranges: {
                '오늘 ': [dayjs().startOf('day'), dayjs().endOf('day')],
                '어제 ': [dayjs().startOf('day').subtract(1, 'days'), dayjs().endOf('day').subtract(1, 'days')],
                '이번 주': [dayjs().startOf('day').day(1), dayjs().day(1).endOf('day').subtract(-6, 'd')],
                '저번 주': [dayjs().startOf('day').subtract(1, 'week').day(1), dayjs().endOf('day').subtract(1, 'week').day(7)],
                '최근 7일': [dayjs().startOf('day').subtract(7, 'd'), dayjs().endOf('day').subtract(1, 'd')],
                '최근 30일': [dayjs().startOf('day').subtract(30, 'd'), dayjs().endOf('day').subtract(1, 'd')],
            },
        }, (start:any, end:any) => {
            const startDate = start.format('YYYYMMDD');
            const endDate = end.format('YYYYMMDD');
            if (getDateDiff(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')) > 93) {
              alertify.error('조회 기간은 최대 93일까지 설정할 수 있습니다.')
              setIsDateValid(false)
              return
            }
            setIsDateValid(true);
            setValue('startDate', startDate);
            setValue('endDate', endDate);
        });

        $('#dropdown-menu').on('click', (event) => {
            // 클릭시 드랍다운 창이 닫히기 때문에 해당 부분 처리
            event.stopPropagation();
        });

        setStatusesMsg(`${$(`#${statuses[0]}`).data('nm')} 외 ${statuses.length - 1} 개`);

        $('.statusesChkBox').on('click', (e:any) => {
            if ($(`#${e.target.id}`).is(':checked')) {
                statuses.push(e.target.id);
                setStatusesForDownLoad(statuses);
            } else if (!$(`#${e.target.id}`).is(':checked')) {
                statuses = statuses.filter((elem:any) => elem !== e.target.id);
                setStatusesForDownLoad(statuses);
            }

            if (statuses.length === 1) {
                setStatusesMsg(`${$(`#${statuses[0]}`).data('nm')}`);
            } else if (statuses.length > 1) {
                setStatusesMsg(`${$(`#${statuses[0]}`).data('nm')} 외 ${statuses.length - 1} 개`);
            }
        });

        return () => {
            setTitle('');
        };
    }, []);

    useEffect(() => {
        // 검색 구분
        $('#filter').select2({
            width: '200',
            placeholder: '선택',
            minimumResultsForSearch: Infinity,
        }).on('select2:select', (e) => {
            setValue('filter', e.params.data.id);
        });
    }, []);
    const downloadHandler = () => {

        setDlParam({
            startDate: getValues('startDate'),
            endDate: getValues('endDate'),
            statuses: statusesForDownload,
            filter: $('#filter').val(),
            query: $('#query').val(),
        });
        setIsDlDialogOpened(true);
        // window.location.assign(
        //     `/api/bzmAdmin/postpaidMoneyManagement/inquiry/download?startDate=${getValues('startDate')}&endDate=${getValues('endDate')}&statuses=${statusesForDownload}&filter=${$('#filter').val()}&query=${$('#query').val()}`,
        // );
    };
    return (
        <Fragment>
            <PostpaidMoneyManagementTab/>
            <section className="wrap-section wrap-datagrid">
                <form onSubmit={handleSubmit(onGridReady)}>
                    <div className="wrap-filter">
                        <div className="inner-filter">
                            <div className="box-left">
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">최종 변경 기간</p>
                                    </div>
                                    <div className="box-filter">
                                        <div className="comp-datepicker">
                                            <div className="inner-datepicker">
                                                <i className="ico ico-calendar"></i>
                                                <input type="text" className="tf-comm datepicker-range" id='datepicker' readOnly/>
                                                <span className="fz-12 fc-2">사용자 설정</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">처리 상태</p>
                                    </div>
                                    <div className="box-filter" id="statuesFilter">
                                        <div className="comp-dropdown w-200 select2 statues">
                                            <Link to='#' className="dropdown-toggle" data-toggle="dropdown">
                                                <div className="box-left">
                                                    <span className="fz-14">{statusesMsg}</span>
                                                </div>
                                                <div className="box-right">
                                                    <i className="ico ico-arrow"></i>
                                                </div>
                                            </Link>
                                            <div className="dropdown-menu expand" id='dropdown-menu'>
                                                <ul className="opt-selectbox">
                                                    <li className="opt-menu">
                                                        <div className="comp-checkbox small">
                                                            <input type="checkbox" id="WAITING" value="WAITING" className='statusesChkBox' data-nm='대기' defaultChecked={true}/>
                                                            <label htmlFor="WAITING">대기</label>
                                                        </div>
                                                    </li>
                                                    <li className="opt-menu">
                                                        <div className="comp-checkbox small">
                                                            <input type="checkbox" id="HOLDING" value="HOLDING" data-nm='보류' className='statusesChkBox'/>
                                                            <label htmlFor="HOLDING">보류</label>
                                                        </div>
                                                    </li>
                                                    <li className="opt-menu">
                                                        <div className="comp-checkbox small">
                                                            <input type="checkbox" id="REJECTED" value="REJECTED" data-nm='반려' className='statusesChkBox'/>
                                                            <label htmlFor="REJECTED">반려</label>
                                                        </div>
                                                    </li>
                                                    <li className="opt-menu">
                                                        <div className="comp-checkbox small">
                                                            <input type="checkbox" id="PERMITTED" value="PERMITTED" data-nm='승인' className='statusesChkBox'/>
                                                            <label htmlFor="PERMITTED">승인</label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">검색 구분</p>
                                    </div>
                                    <div className="box-filter">
                                        <select className="select2-single w-150"
                                                id="filter" {...register('filter')}>
                                            <option value="MAKER_ID" defaultChecked={true}>메이커ID</option>
                                            <option value="MAKER_NM">메이커명</option>
                                            <option value="PROJECT2_ID">프로젝트 ID</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">검색어</p>
                                    </div>
                                    <div className="box-filter">
                                        <div className="input-group comp-search">
                                            <div className="inner-input-group">
                                                <input type="text" className="tf-comm" id='query'
                                                       placeholder="검색어를 입력해 주세요." {...register('query')}></input>
                                                <i className="ico ico-search"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-right">
                                <button type="submit" className="btn btn-tertiary-mint btn-ico" disabled={!isDateValid}><i className="ico ico-filter"></i> 조회 </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="box-header">
                    <div className="box-option">
                        <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={downloadHandler}><i className="ico ico-download"></i>다운로드</button>
                    </div>
                </div>
                <div className="box-body">
                    <div className="ag-grid">
                        <div className="ag-grid-inner">
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                rowHeight={48}
                                pagination={true}
                                paginationPageSize={10}
                                suppressPaginationPanel={true}
                                onPaginationChanged={changePagination}
                                onGridSizeChanged={onGridSizeChanged}
                                domLayout={'autoHeight'}
                                noRowsOverlayComponent={NoDataTemplate}
                            />
                            { rowData === undefined ? <NoDataTemplate/> : null}
                        </div>
                    </div>
                </div>
                <div className="box-footer">
                    <Pagination gridRef={gridRef} ref={childRef}/>
                </div>
            </section>
            {
                isDlDialogOpened ?
                  <DlDialog setIsDlDialogOpened={setIsDlDialogOpened}
                            dlUrl={'/api/bzmAdmin/postpaidMoneyManagement/inquiry/download'}
                            dlParam={dlParam}
                  /> : null
            }
        </Fragment>
    );
};

export default PomInquiryManagement;
