import { axios } from '../axios'
type DtosType = {
  afMeta: string // '{"fileDiv":"IMG","width":1080,"height":1080,"fileSize":254949,"ext":"jpg"}'
  afName: string //'자개3.jpg'
  bucket: string //'_static'
  dlAuthDiv: string //'ALL'
  fileName: string //'https://rc-bizstatic.wadiz.kr/static/creationFb/20241119/1824718483/1824718973/1824718991.jpg'
  fileSize: number // 254949
  id: number //1974770174
  refId: number //1974770173
  sortOrder: number //1
}
type RegTimeType = {
  date: { year: number; month: number; day: number }
  time: { hour: number; minute: number; second: number; nano: number }
}

type AIInspectionCreationType = {
  adDesc: string // "해외에서는 hot! 한국에서는 what?\n영롱한 자개 빛에 반하고🥰 꼼꼼한 디테일에 놀라는😃\n자동애국심 장착 아름다운 #자개여권케이스\n✅가죽위에서도 빛을 잃지않는 특수 자개박!\n✅오래 사용해도 변형없는 특수 코팅 !\n✅여권범죄 방지 전자파 완벽차단!\n지금 wa배송으로 와디즈에서 만나보세요❤\n"
  adTitle01: string //""
  adTitle02: string //""
  clickBtnDiv: string // "LEARN_MORE_FB"
  clickBtnDivText: string //"더 알아보기"
  creationDataId: number // 1974770127
  creationDataInspHisAfDtos: DtosType[]
  creationType: string //"image"
  id: number //1974770173
  regId: number //1
  regTime: RegTimeType
  sortOrder: number //1
  wabizInspAgent: string //"admin"
  wabizInspReqTime: string //"202502031636"
  wabizInspStatus: string //"ing"
}
type ReturnType = {
  ok: boolean
  data: AIInspectionCreationType[]
}
export const fetchCreationDataInspectionHistory = (creationDataInspHisId: number): Promise<ReturnType> =>
  axios.get(`/api/mngAd/gptHis/getCreationDataInspHis?creationDataInspHisId=${creationDataInspHisId}`)
