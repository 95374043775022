import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { fetchPostAdminMemo } from '@biz/api'

type ParameterType = {
  adminInspStatus: 'ing' | 'ok' | 'fail'
  adminInspDiv: string
  adminMemo: string
  creationDataGptHisId : number}
type FunctionType = () => UseMutationResult<unknown, unknown, ParameterType, unknown>
export const useAdminMemoMutation: FunctionType = () =>
  useMutation({
    mutationFn: (param) =>
      fetchPostAdminMemo(param).then(
        () => {
          toast.done('정상적으로 저장 되었습니다.')
        },
        (err) => {
          const status = Math.round((err?.response?.status || 1) / 100)
          if (status === 4 || status === 5) {
            toast.error(`네트워크 에러 입니다. - ${err?.response?.status}`)
          }
        }
      ),
  })
