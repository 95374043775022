import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useLocation, useOutletContext } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { Modal, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import alertify from 'alertifyjs'

import { ApiUtil2 } from '@biz/api'
import { ApiUtil } from '@biz/api'
import type { CreationDetail } from '@biz/api'
import { PageTitle, AIInspectionResultModal } from '@biz/ui'

import CampAttachDialogForAdmin from '../../TargetCreateLayout/legacy/CampAttachDialogForAdmin'
import { Af } from '../../TargetCreateLayout/legacy/CampAttachDialog'
import { CreationInspectionHistoryModal } from '../../views/mngAd/creation/CreationInspectionHistoryModal'
import CreationAIHistoryModal from '../../views/mngAd/creation/CreationAIHistoryModal'
import PreviewImgDialog from './PreviewImgDialog'
import { GdnCreationDataIdContext } from './CreationDetailGdn'
import { getAttachBtn, getMemoBtn } from './CreationDetailFb'
import CreationDataInspMemoDialog from './CreationDataInspMemoDialog'
import { CreationDetailCard } from './CreationDetailCard'
import { FailReasonInputModal } from './FailReasonInputModal'

import styles from './CreationDetail.module.scss'

interface CreationDetailResponse {
  ok: boolean
  data: CreationDetail[]
}

/**
 * 소재 검수 상세 (전체 일괄 검수)
 */
const CreationDetailFbAll = (props: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const { campIds }: any = state
  const [isLoading, setIsLoading] = useState(false)
  const [isFailConfirmLoading, setIsFailConfirmLoading] = useState(false)
  const [creationDataId, setCreationDataId] = useState<string>('')
  const [creationData, setCreationData] = useState<CreationDetail[]>([])
  const [fileData, setFileData] = useState<any[]>([])
  const campIdRef = useRef<number>()
  const creationDataIdRef = useRef<string>('')

  // 검수 이력 다이얼로그 관련
  const [showCreationDataInspHisDialog, setShowCreationDataInspHisDialog] = useState<boolean>(false)
  const [isStopCreation, setIsStopCreation] = useState(false) // 소재 중단 여부 (반려 X)
  const [isOpenStopConfirmModal, setIsOpenStopConfirmModal] = useState(false) // 소재 중단 컨펌 모달
  const [isOpenFailReasonModal, setIsOpenFailReasonModal] = useState(false) // 소재 중단, 반려 사유 입력 모달
  const [failReasonMessage, setFailReasonMessage] = useState('') // 소재 중단, 반려 사유 입력 내용

  const UPDATE_CREATION_STATUS_API_ENDPOINT = `/api/admin/campaigns/creations/${creationDataId}/status`

  // 검수이력 다이얼로그 파람값
  const setCreationDataInspHisParams = (creationDataId: string, index: number) => {
    creationDataIdRef.current = creationDataId
    setShowCreationDataInspHisDialog(true)
  }

  // 챗지피티 다이얼로그 관련
  const [showCreationChatGptDialog, setShowCreationChatGptDialog] = useState<boolean>(false)
  const setChatGptDialogParams = (creationDataId: string, campId: number) => {
    creationDataIdRef.current = creationDataId
    campIdRef.current = campId
    setShowCreationChatGptDialog(true)
  }

  const setDialogMemoParams = (campId: number, creationDataId: string) => {
    campIdRef.current = campId
    creationDataIdRef.current = creationDataId
    setIsOpenedInspMemoDialog(true)
  }

  const getPrevData = async () => {
    setIsLoading(true)

    ApiUtil2.get<CreationDetailResponse>('/api/mngAd/creation/detail', {
      params: { data: { campIds: campIds.join(',') } },
    }).then(
      (resp) => {
        setIsLoading(false)

        setCreationData(resp.data.data)
        if (resp.data.data.length === 0) navigate('/mngAd/creation')
      },
      (reason) => {
        setIsLoading(false)
      }
    )
  }

  //일괄승인
  const confirmAllEvent = () => {
    const formData: { id: string; campId: any; status: string }[] = []
    const updatedItems = creationData.filter((item) => item.creationData.wabizInspStatus === 'ing')
    for (let i = 0; i < updatedItems.length; i++) {
      formData.push({
        id: updatedItems[i].creationDataId,
        campId: updatedItems[i].campId,
        status: 'ok',
      })
    }

    alertify
      .confirm(
        '승인 진행시, 검사 필요 유무와 상관없이 <br/> 모든 소재가 승인됩니다. <br/><br/> * 캠페인 기간이 지난 소재는 제외되어 승인됩니다.',
        () => {
          ApiUtil.post('/api/mngAd/creation/updateCreationStatusAll', {
            data: formData,
          }).then((resp) => {
            if (resp.data.data.intervalValid !== undefined && !resp.data.data.intervalValid) {
              alertify.alert('', resp.data.data.resultMsg).set('label', '확인')
            }
            confirmSuccess('정상적으로 승인 되었습니다.')
            getPrevData()
          })
        }
      )
      .set({ labels: { cancel: '취소', ok: '확인' } })
      .setHeader('모든 항목을 승인 하시겠습니까?')
  }

  // 소재 승인
  const handleConfirm = (campId: any, id: any) => {
    alertify
      .confirm('', () => {
        // FIXME: 신규 API 연동
        // ApiUtil.post(UPDATE_CREATION_STATUS_API_ENDPOINT, {
        ApiUtil.post('/api/mngAd/creation/updateCreationStatus', {
          id,
          campId,
          status: 'ok',
        }).then((resp) => {
          if (resp.data.data.intervalValid !== undefined && !resp.data.data.intervalValid) {
            alertify.alert('', resp.data.data.resultMsg).set('label', '확인')
          }
          getPrevData()
        })
      })
      .set({ labels: { cancel: '취소', ok: '승인' } })
      .setHeader('해당 소재를 검수 승인하시겠습니까?')
  }

  // 소재 반려 및 중단 사유 입력 모달 열기
  const openRejectReasonModal = (isStopCreation = false) => {
    setIsOpenFailReasonModal(true)
  }

  //소재 반려 다이얼로그 열기
  const handleReject = (campId: any, id: any) => {
    campIdRef.current = campId
    creationDataIdRef.current = id
    setCreationDataId(id)

    if (isStopCreation) {
      setIsStopCreation(true)
      setIsOpenStopConfirmModal(true)
    } else {
      setIsStopCreation(false)
      openRejectReasonModal()
    }
  }

  // 소재 중단, 반려 모달 확인 버튼 클릭 핸들러
  const handleFailConfirmButtonClick = () => {
    setIsFailConfirmLoading(true)

    if (failReasonMessage === '') {
      setIsFailConfirmLoading(false)
      confirmError('반려사유를 작성해주세요')
      return
    }

    // FIXME: 신규 API
    // ApiUtil.post(UPDATE_CREATION_STATUS_API_ENDPOINT, {
    ApiUtil.post('/api/mngAd/creation/updateCreationStatus', {
      id: creationDataIdRef.current,
      campId: campIdRef.current,
      status: isStopCreation ? 'stop_by_admin' : 'fail',
      msg: failReasonMessage,
    })
      .then(() => {
        setIsFailConfirmLoading(false)
        getPrevData()
        setIsOpenFailReasonModal(false)
        setFailReasonMessage('')

        // 소재 노출 중단한 경우
        if (isStopCreation) {
          alertify.success('소재 노출이 중단되었습니다.')
        }
      })
      .catch(() => {
        setIsFailConfirmLoading(false)
        setFailReasonMessage('')

        // 소재 노출 중단한 경우
        if (isStopCreation) {
          alertify.error('소재 중단에 실패했습니다.')
        }
      })
  }

  const getAf = async (id: any) => {
    ApiUtil2.post('/api/mngAd/creation/getFiles', { refId: id }).then((resp) => {
      // eslint-disable-next-line no-use-before-define
      if (resp.data.data.length !== 0) {
        setFileData(resp.data.data)
      }
    })
  }

  const setDialogParams = (campId: number, creationDataId: string, campAttach: any) => {
    campIdRef.current = campId
    creationDataIdRef.current = creationDataId
    campDialogAfRef.current = []
    campDialogAfRef.current = campAttach.afs.map(
      (v: Af) => new Af(v.fileName, v.fileSize, v.filePath, v.afId, v.lastModified)
    )
    setIsOpenedCampAttachDialog(true)
  }

  const event = (id: any) => {
    creationDataIdRef.current = id
    setCreationDataId(id)
    getAf(id)
    $('#previewDialog').dialog({ width: 1200, title: '이미지 미리보기', closeOnEscape: true })
    $('#previewDialog').dialog('open')
  }

  const handleDeleteItem = (id: any) => {
    const updatedItems = creationData.filter((item) => item.creationDataId !== id.toString())
    setCreationData(updatedItems)
    if (updatedItems.length === 0) navigate('/mngAd/creation')
  }

  const errorEvent = (msg: string) => {
    alertify.error(msg, 1)
  }

  const confirmError = (msg: string) => {
    errorEvent(msg)
  }

  const successEvent = (msg: string) => {
    alertify.success(msg, 1, () => {})
  }

  const confirmSuccess = (msg: string) => {
    successEvent(msg)
  }

  useEffect(() => {
    getPrevData()

    $('#closePreview').click(() => {
      setFileData([])
      $('#previewDialog').dialog('close')
    })

    return () => {
      $('#previewDialog').dialog('destroy')
    }
  }, [])

  const { isOpenedCampAttachDialog, setIsOpenedCampAttachDialog, campDialogAfRef } = getAttachBtn()

  const { isOpenedInspMemoDialog, setIsOpenedInspMemoDialog } = getMemoBtn()

  return (
    <div className={styles.container}>
      <PageTitle>일괄 검수</PageTitle>
      <GdnCreationDataIdContext.Provider value={{ creationDataId, setCreationDataId }}>
        <PreviewImgDialog fileData={fileData} />
        {/* <FailCreationDialog campId={campIdRef.current} creationDataId={creationDataId} getPrevData={getPrevData} /> */}
      </GdnCreationDataIdContext.Provider>

      {/* 소재 중단 확인을 위한 컨펌 모달 */}
      {isOpenStopConfirmModal && (
        <Modal
          title="소재를 중단하시겠어요?"
          width={400}
          open={isOpenStopConfirmModal}
          okText="중단하기"
          cancelText="취소"
          centered
          className={styles.stopConfirmModal}
          classNames={{
            content: styles.content,
            body: styles.body,
            header: styles.header,
            footer: styles.footer,
          }}
          onOk={() => {
            setIsOpenStopConfirmModal(false)
            openRejectReasonModal(true)
          }}
          onCancel={() => setIsOpenStopConfirmModal(false)}
        >
          <p>
            현재 진행 상태인 소재입니다. <br />
            소재를 신중하게 확인한 후에 중단해 주세요.
          </p>
        </Modal>
      )}

      {/* 반려, 중단 사유 작성 모달 */}
      <FailReasonInputModal
        isOpen={isOpenFailReasonModal}
        campId={campIdRef.current}
        creationDataId={creationDataIdRef.current}
        isStopCreation={isStopCreation}
        isLoading={isFailConfirmLoading}
        getPrevData={getPrevData}
        handleConfirm={handleFailConfirmButtonClick}
        handleClose={() => {
          setIsOpenFailReasonModal(false)
          setFailReasonMessage('')
        }}
        handleChange={(event) => {
          setFailReasonMessage(event.target.value)
        }}
      />
      {isOpenedCampAttachDialog ? (
        <CampAttachDialogForAdmin
          setIsOpenedCampAttachDialog={setIsOpenedCampAttachDialog}
          campId={campIdRef.current}
          creationDataId={creationDataIdRef.current}
          afs={campDialogAfRef}
        />
      ) : null}
      {isOpenedInspMemoDialog ? (
        <CreationDataInspMemoDialog
          setIsOpenedInspMemoDialog={setIsOpenedInspMemoDialog}
          campId={campIdRef.current}
          creationDataId={creationDataIdRef.current}
        />
      ) : null}
      {showCreationDataInspHisDialog ? (
        <CreationInspectionHistoryModal
          isOpen={showCreationDataInspHisDialog}
          creationDataId={creationDataIdRef.current}
          searchType="admin"
          setShowCreationDataInspHisDialog={setShowCreationDataInspHisDialog}
        />
      ) : null}
      <AIInspectionResultModal
        isOpen={showCreationChatGptDialog}
        onClose={() => setShowCreationChatGptDialog(false)}
        data={
          !!campIdRef.current && !!creationDataIdRef.current
            ? {
                campId: campIdRef.current,
                creationDataId: Number(creationDataIdRef.current),
                creationDataGptHisId: 0,
              }
            : undefined
        }
      />
      {/* {showCreationChatGptDialog && (
        <CreationAIHistoryModal
          campId={campIdRef.current}
          creationDataId={creationDataIdRef.current}
          setShowCreationChatGptDialog={setShowCreationChatGptDialog}
        />
      )} */}

      {isLoading && <Spin indicator={<LoadingOutlined spin />} size="large" />}

      {creationData.length > 0 && (
        <section className="wrap-section wrap-tbl">
          <div className="box-header">
            <div className="box-tit">
              <button
                type="button"
                className="btn btn-primary-outline"
                onClick={() => {
                  navigate('/mngAd/creation')
                }}
              >
                이전 목록으로
              </button>
            </div>
            <div className="box-option">
              <button type="button" className="btn btn-primary" onClick={confirmAllEvent}>
                일괄 승인
              </button>
            </div>
          </div>
          <div className="box-body">
            <div className="tbl">
              <dl className="vertical">
                <dd>
                  <div className="form-group">
                    <div className={`${styles.row}`}>
                      {/**
                       * 정책정리,, 230823
                       * 검수필요키워드 X + 일반 => 일반, 검정색
                       * 검수필요키워드 o + 일반 => 검수필요, 검정색
                       *         x        + 검수필요 => 검수필요, 빨간색
                       */}
                      {creationData.map((data: CreationDetail, cardIndex: number) => (
                        <CreationDetailCard
                          type="all"
                          data={data}
                          index={cardIndex}
                          handleReject={handleReject}
                          handleConfirm={handleConfirm}
                          setChatGptDialogParams={setChatGptDialogParams}
                          setDialogParams={setDialogParams}
                          setCreationDataInspHisParams={setCreationDataInspHisParams}
                          setDialogMemoParams={setDialogMemoParams}
                          event={event}
                          handleDeleteItem={handleDeleteItem}
                        />
                      ))}
                    </div>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </section>
      )}
    </div>
  )
}

export default CreationDetailFbAll
