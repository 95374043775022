import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { PlainObject } from 'select2';
import { useNavigate, useOutletContext } from 'react-router';
import MngMdFb from './MngMdFb';
import MngMdGg from './MngMdGg';
import alertify from 'alertifyjs'
import MngMdGgAdTab from './MngMdGgAdTab';
import MngMdFbAdTab from './MngMdFbAdTab';
import MngMdFbPageDialog from './MngMdFbPageDialog';

export interface defaultMediaData {
  md:string,
  project:string
}

export interface mediaFbPage{
  mediaFbPageId? : bigint,
  adDpPageId : string // 페이지 ID
  name : string // 이름
  useYn : string // 사용 여부
  sortOrder : number // 광고세트 순서
  regTime : Date,
  regName : string,
}

const MngMdReg = () => {
  const navigate = useNavigate();
  const childFbRef = useRef<any>(null);
  const childFbTabRef = useRef<any>(null);
  const childGgRef = useRef<any>(null);
  const setTitle = useOutletContext<any>();
  const [project, setProject] = useState('');
  const [category, setCategory] = useState('');

  const [md, setMd] = useState('FB');
  const regProjectSelect = useRef<HTMLSelectElement>(null);
  const regCategorySelect = useRef<HTMLSelectElement>(null);

  const regMdSelect = useRef<HTMLSelectElement>(null);

  const [isSubmit, setSubmit] = useState(false);

  const [ggCustomerId, setGgCustomerId] = useState('');

  // 페북 state
  const [isTokenReg, setTokenReg] = useState(false);
  const [isAdAccountSelect, setAdAccountSelect] = useState(false);
  const [isPixelSelect, setPixelSelect] = useState(false);
  const [customAudienceList, setCustomAudienceList] = useState([]);
  const [adPageList, setAdPageList] = useState([]);
  const [adsVolumes, setAdsVolumes] = useState([]);
  const [instagramAccountList, setInstagramAccountList] = useState([]);
  const [customConversionList, setCustomConversionList] = useState([]);
  const [showFbPageDialog, setShowFbPageDialog] = useState<boolean>(false);
  const [mediaFbPageList, setMediaFbPageList] = useState<mediaFbPage[]>([]);

  useEffect(() => {
    setTitle('매체 및 광고세트 설정 등록');
    $(regMdSelect.current as PlainObject).select2({
      width: '330px',
      minimumResultsForSearch: Infinity,
      dropdownCssClass: 'medium',
    }).on('select2:selecting', (e : any) => {
      e.preventDefault();
      alertify.confirm('광고 노출 매체를 변경 하시겠습니까?<br/>단, 변경 시 기존 기입 정보는 초기화 됩니다.', () => {
        $(regMdSelect.current as PlainObject).val(e.params.args.data.id).trigger('change');

        // FB
        setTokenReg(false);
        setAdAccountSelect(false);
        setPixelSelect(false);
        setCustomAudienceList([]);
        setAdPageList([]);
        setAdsVolumes([]);
        setInstagramAccountList([]);
        setCustomConversionList([]);
        setMediaFbPageList([]);

        setMd(e.params.args.data.id);
      }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
    });

    $(regProjectSelect.current as PlainObject).select2({
      width: '330px',
      minimumResultsForSearch: Infinity,
      dropdownCssClass: 'medium',
      placeholder: '프로젝트 종류를 선택해 주세요.',
    }).on('select2:select', (e) => {
      setProject(e.params.data.id);
    });

    $(regCategorySelect.current as PlainObject).select2({
      width: '330px',
      minimumResultsForSearch: Infinity,
      dropdownCssClass: 'medium',
      placeholder: '카테고리 종류를 선택해 주세요.',
    }).on('select2:select', (e) => {
      setCategory(e.params.data.id);
    });
    return () => {
      $('.tooltip').remove();
    };

  }, []);
  const mediaRegSubmit = () => {
    if (md === 'FB') {
      const fbAdSetForm = childFbTabRef.current!.getAdSetForm();
      childFbRef.current!.mediaFbRegSubmit(fbAdSetForm);
    } else {
      childGgRef.current!.mediaGgRegSubmit();
    }
  };
  // 구글 광고세트 설정 데이터를 가져오는 부분
  const ggAdSetDataFunction = (ggAdSetData:any) => {
    childGgRef.current!.mediaGgAdSetDataSetting(ggAdSetData);
  };
  // 구글 광고주 선택 ID
  const customerIdProps = (id:any) => {
    setGgCustomerId(id);
  };
  const insertCustomAudienceList = (list : any) => {
    setCustomAudienceList(list);
  };
  const insertCustomConversionList = (list : any) => {
    setCustomConversionList(list);
  };
  const insertAdPageList = (list : any) => {
    setAdPageList(list);
  };
  const getAdsVolumes = (list : any) => {
    setAdsVolumes(list);
  };
  const insertInstagramAccountList = (list : any) => {
    setInstagramAccountList(list);
  };
  return (
    <Fragment>
      <section className="wrap-section wrap-tbl">
        <div className="box-body">
          <div className="tbl">
            <dl className="vertical">
              <dd>
                <div className="form-group">
                  <div className="container-fluid">
                    <div className="row flex-container">
                      <div className="col col-5">
                        <section className="wrap-section wrap-tbl">
                          <div className="box-body">
                            <div className="tbl">
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        와디즈 프로젝트 선택
                                        <i className="fz-12 fc-5">*</i>
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <select className="select2-single expand" ref={regProjectSelect}>
                                      <option value=""></option>
                                      <option value="FUNDING">펀딩 프로젝트 (오픈 예정)</option>
                                      <option value="FUNDING_ING">펀딩 프로젝트 (펀딩 진행중)</option>
                                      <option value="STORE">스토어 프로젝트</option>
                                    </select>
                                  </div>
                                </dd>
                              </dl>
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        매체 카테고리 선택
                                        <i className="fz-12 fc-5">*</i>
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <select className="select2-single expand" ref={regCategorySelect}>
                                      <option value="DEFAULT">기본(선택안함)</option>
                                      <option value="GAME_MOVIE">게임/영화</option>
                                      <option value="SOCIAL">소셜</option>
                                      <option value="PET">반려동물</option>
                                      <option value="KIDS">키즈</option>
                                      <option value="BEAUTY">뷰티</option>
                                      <option value="SPORTS_OUTDOOR">스포츠/아웃도어</option>
                                      <option value="TRAVEL">여행</option>
                                      <option value="BOOK">도서</option>
                                      <option value="ART_CHARACTER_GOODS_HANDMADE">아트/캐릭터/굿즈/핸드메이드</option>
                                      <option value="CLASS">전자책/클래스</option>
                                      <option value="TECH_DOMESTIC">테크/가전</option>
                                      <option value="FASHION">페션 잡화</option>
                                      <option value="FOOD">푸드</option>
                                      <option value="HOME_LIVING">홈/리빙</option>
                                    </select>
                                  </div>
                                </dd>
                              </dl>
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        광고 노출 매체
                                        <i className="fz-12 fc-5">*</i>
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <select className="select2-single expand" ref={regMdSelect}>
                                      <option value="FB">페이스북 / 인스타그램</option>
                                      <option value="GG">구글 디스플레이 배너</option>
                                    </select>
                                  </div>
                                </dd>
                              </dl>
                              {md === 'FB' ? <MngMdFb
                                                      md={md}
                                                      project={project}
                                                      category={category}
                                                      isTokenReg={isTokenReg}
                                                      setTokenReg={setTokenReg}
                                                      isAdAccountSelect={isAdAccountSelect}
                                                      setAdAccountSelect={setAdAccountSelect}
                                                      isPixelSelect={isPixelSelect}
                                                      setPixelSelect={setPixelSelect}
                                                      insertCustomAudienceList={insertCustomAudienceList}
                                                      insertAdPageList={insertAdPageList}
                                                      getAdsVolumes={getAdsVolumes}
                                                      insertInstagramAccountList={insertInstagramAccountList}
                                                      insertCustomConversionList={insertCustomConversionList}
                                                      setMediaFbPageList={setMediaFbPageList}
                                                      mediaFbPageList={mediaFbPageList}
                                                      regProjectSelect={regProjectSelect}
                                                      regCategorySelect={regCategorySelect}
                                                      setSubmit={setSubmit}
                                                      ref={childFbRef}/>
                                : <MngMdGg md={md} project={project} ref={childGgRef} customerIdProps={customerIdProps} regProjectSelect={regProjectSelect} regCategorySelect={regCategorySelect} />}
                            </div>
                          </div>
                        </section>
                      </div>
                      {/* 디자인 문제 때문에 광고세트 설정 부분이 분리가 필요 */}
                      {md === 'FB' ? <MngMdFbAdTab isTokenReg={isTokenReg}
                                                   isAdAccountSelect={isAdAccountSelect}
                                                   isPixelSelect={isPixelSelect}
                                                   adPageList={adPageList}
                                                   adsVolumes={adsVolumes}
                                                   instagramAccountList={instagramAccountList}
                                                   customAudienceList={customAudienceList}
                                                   customConversionList={customConversionList}
                                                   mediaFbPageList={mediaFbPageList}
                                                   setShowFbPageDialog={setShowFbPageDialog}
                                                   ref={childFbTabRef}/>
                        : <MngMdGgAdTab ggAdSetDataFunction={ggAdSetDataFunction} ggCustomerId={ggCustomerId}/>}
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
      <div className="box-footer">
        <div className="box-right">
          <button type="button" className="btn btn-secondary-outline" onClick={() => navigate('/mngMd/mngMd')}>취소</button>
          <button type="button" className="btn btn btn-primary" onClick={mediaRegSubmit} disabled={isSubmit}>등록</button>
        </div>
      </div>
      </section>
      { showFbPageDialog ? <MngMdFbPageDialog setShowFbPageDialog={setShowFbPageDialog}
                                              isUpdate={false}
                                              adPageList={adPageList}
                                              adsVolumes={adsVolumes}
                                              mediaFbPageList={mediaFbPageList}
                                              setMediaFbPageList={setMediaFbPageList}/>
        : null }
    </Fragment>
  );
};

export default MngMdReg;
