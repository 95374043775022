import Cookies from 'universal-cookie'
import dayjs from 'dayjs'

const cookies = new Cookies()
export const authToken = {
  get isEnabled() {
    return !!cookies.get('token')
  },
  clear() {
    cookies.remove('token', { path: '/' })
  },
  set(token: string, exp?: Date) {
    cookies.set('token', token, { path: '/', expires: exp })
  },
  get() {
    return cookies.get('token')
  },
}

export const shadowToken = {
  get isShadowLogin() {
    return !!cookies.get('shadow')
  },
  set(token: string) {
    cookies.set('shadow', token, { path: '/' })
  },
  get() {
    return cookies.get('shadow')
  },
  clear() {
    cookies.remove('shadow', { path: '/' })
  },
  save(path: string) {
    const tick = dayjs().toString()
    sessionStorage.setItem(
      'shadowCloseInfo',
      JSON.stringify({
        time: tick,
        shadow: cookies.get('shadow'),
        auth: authToken.get(),
        path,
      })
    )
  },
  restore() {
    const infoData = sessionStorage.getItem('shadowCloseInfo')
    if (!infoData) {
      return
    }
    const { time, shadow, auth, path } = JSON.parse(infoData)
    if (dayjs().diff(dayjs(time)) < 10000) {
      cookies.set('shadow', shadow, { path: '/' })
      authToken.set(auth)
    }
    sessionStorage.removeItem('shadowCloseInfo')
    return path
  },
}
