import {forwardRef, Fragment, useEffect, useRef, useState} from "react";
import {Dialog} from "rc-easyui";
import {useForm} from "react-hook-form";
import { useMutation } from '@tanstack/react-query'
import alertify from 'alertifyjs'
import _, { isEmpty } from 'lodash'
import dayjs from 'dayjs'
import { ApiUtil2 } from '@biz/api'

interface MdMonitoringMemoForm {
  logId: bigint
  memo: string
}

const MdSyncMonitoringMemoDialog = (props: any, ref: any) => {
  const dialogRef = useRef<any>()

  const [memoLength, setMemoLength] = useState<number>(0)
  const [memoUpdateTime, setMemoUpdateTime] = useState<any>(null)

  const { register, setValue, getValues, watch } = useForm<MdMonitoringMemoForm>()

  const clearMemo = () => {
    setValue('memo', '')
    setMemoLength(0)
  }

  const updateMemo = useMutation({
    mutationFn: (formData: any) => ApiUtil2.post(`/api/mngMonitoring/mdSyncMonitoring/updateMemo`, formData),
    onSuccess: (resp) => {
      if (resp.data.ok) {
        alertify.success('정상적으로 적용되었습니다.')
        props.setShowMemoDialog(false)
        props.onGridReady()
      }
    },
  })

  const submitMemo = () => {
    const formData = {
      ...getValues(),
    }

    if (_.isEmpty(formData.memo)) {
      alertify.error('기입된 내용이 없습니다.<br/>확인 후 다시 시도해 주세요.')
      return
    }

    updateMemo.mutate(formData)
  }

  useEffect(() => {
    if (typeof props.selectedLog.memoUpdateTime != 'undefined' && !isEmpty(props.selectedLog.memoUpdateTime)) {
      const { date } = props.selectedLog.memoUpdateTime
      const { time } = props.selectedLog.memoUpdateTime

      setMemoUpdateTime(
        dayjs(new Date(date.year, date.month - 1, date.day, time.hour, time.minute, time.second)).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      )
    }

    setValue('logId', props.selectedLog.logId)
    setValue('memo', props.selectedLog.memo)
    setMemoLength(typeof props.selectedLog.memo == 'undefined' ? 0 : props.selectedLog.memo.length)
  }, [])

  return (
    <Fragment>
      <Dialog title="메모 편집" modal resizable shadow={false} ref={dialogRef} style={{ width: '900px' }}>
        <div id="mdSyncMonitoringMemoDialog" className="dialog">
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl className="column-two">
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">최종 수정 일시</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <span className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-2">{memoUpdateTime}</b>
                            </span>
                          </span>
                        </span>
                      </div>
                    </dd>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">최종 수정자</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <span className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-2">{props.selectedLog.memoUpdateName}</b>
                            </span>
                          </span>
                        </span>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">메모</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="input-group expand">
                          <div className="inner-input-group">
                            <textarea
                              className="tf-textarea"
                              placeholder="400자까지 기입이 가능합니다."
                              maxLength={400}
                              {...register('memo')}
                              onChange={(e) => setMemoLength(e.target.value.length)}
                            ></textarea>
                            <i className="ico ico-delete" onMouseDown={clearMemo}></i>
                          </div>
                          <p className="fz-12 fc-3">
                            <span className="fc-2">{memoLength}</span> / 400
                          </p>
                          <p className="fz-12">
                            <i className="ico"></i>체크 / 에러 문구 내용 영역
                          </p>
                        </div>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button
              type="button"
              className="btn btn-secondary-outline large"
              onClick={(e) => props.setShowMemoDialog(false)}
            >
              취소
            </button>
            <button type="button" className="btn btn-primary large" onClick={submitMemo}>
              수정
            </button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  )
}

export default forwardRef(MdSyncMonitoringMemoDialog)
