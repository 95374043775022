import { Fragment, useEffect, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query' // try next react-query;
import alertify from 'alertifyjs'
import { ApiUtil, ApiUtil2, ApiUtil3, downloadUrl } from '@biz/api'
import { PageTitle } from '@biz/ui'
import { ISystemParam } from './ConsultingSetting'
import { IFileTypes } from '../../views/fileUploader/MultiFileUploaderForConsulting'

export interface IFile {
  id: number
  refId: number
  fileName: string
  fileSize: number
  afName: string
  sortOrder: string
  object: File
}

export interface IConsulting {
  data: any
  consultingId: string
  projectId: string
  projectNm: string
  projectLu: string
  adminId: string
  loginId: string
  makerId: string
  makerNm: string
  expYn: boolean
  mediaDiv3Str: string
  consultingT01: string
  consultingT02: string
  consultingStatus: string
  totalBudget: number
  consultingFees: string
  regTime: string

  publicUrl: string
  fileCnt: number
  fileList: IFile[] | any
}

interface uploadFiles {
  uploadFiles: IFile[] | any
}

const ConsultingDetail = () => {
  const [adminList, setAdminList] = useState<string[][]>([])
  const { consultingId } = useParams()
  const [statusList, setStatusList] = useState<string[][]>([])
  const [fee, setFee] = useState(0)
  const [freeYn, setFreeYn] = useState('')
  const [files, setFiles] = useState<IFileTypes[]>([])

  const { setValue, register, getValues } = useForm<IConsulting>()

  // 광고대행 수수료
  const getSetting = async () => {
    const api = ApiUtil2.get<ISystemParam>('/api/mngAd/consulting/settingDetail').then((resp) => {
      const fees = Number(resp.data.data.find((val: { id: string }) => val.id === 'CONSULTING_FEE').value)
      const feeVat = Number(resp.data.data.find((val: { id: string }) => val.id === 'CONSULTING_FEE_VAT').value)
      const freeYn = resp.data.data.find((val: { id: string }) => val.id === 'CONSULTING_FREE_YN').value
      setFee(fees + feeVat)
      setFreeYn(freeYn)
    })
    return api
  }

  const getPrevData = async () => {
    const promise = ApiUtil2.get<IConsulting>('/api/mngAd/consulting/detail', { params: { data: { consultingId } } })
    return promise
  }

  const getFiles = async () => {
    const promise = await ApiUtil2.get<uploadFiles>('/api/mngAd/consulting/getFiles', {
      params: { data: { refId: consultingId } },
    })
    return promise
  }

  function bytesToSize(bytes: number) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10)
    if (i === 0) return `${bytes} ${sizes[i]}`
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
  }

  const consultingDetailData = useQuery({ queryKey: ['consultingDetail', consultingId], queryFn: getPrevData })
  const consultingFileData = useQuery({ queryKey: ['consultingFiles', consultingId], queryFn: getFiles })

  const setPrevData = async () => {
    consultingDetailData.refetch().then((data: any) => {
      if (data.isSuccess) {
        const consulting = data.data.data.data
        setValue('consultingId', consulting.id)
        setValue('projectId', consulting.projectId)
        setValue('makerId', consulting.makerId)
        setValue('loginId', consulting.loginId)
        setValue('adminId', consulting.adminId)
        $('#adminId').val(consulting.adminId).select2({ placeholder: '담당자 선택' })
        setValue('makerNm', consulting.makerNm)
        setValue('projectNm', consulting.projectNm)
        setValue('projectLu', consulting.projectLu)
        setValue('consultingFees', consulting.consultingFees)
        setValue('totalBudget', consulting.totalBudget)
        setValue('expYn', consulting.expYn)
        setValue('consultingT01', consulting.consultingT01)
        setValue('consultingT02', consulting.consultingT02)
        setValue('consultingStatus', consulting.consultingStatus)
        $('#status').val(consulting.consultingStatus).select2()
        setValue('mediaDiv3Str', consulting.mediaDiv3Str)
        setValue('regTime', consulting.regTime.date)
      }
    })
  }

  const setFileData = async () => {
    consultingFileData.refetch().then((data: any) => {
      if (data.data.data.length !== 0) {
        // eslint-disable-next-line prefer-destructuring
        setFiles(data.data.data.data)
        setValue('fileList.fileId', data.data.data.data.id)
        setValue('fileList.refId', data.data.data.data.refId)
        setValue('fileList.fileName', data.data.data.data.fileName)
        setValue('fileList.afName', data.data.data.data.afName)
        setValue('fileList.sortOrder', data.data.data.data.sortOrder)
        setValue('fileList.fileSize', data.data.data.data.fileSize)
        setValue('fileCnt', data.data.data.data.length)
      }
    })
  }

  const successEvent = (msg: string) => {
    alertify.success(msg, 1)
  }

  const confirmSuccess = (msg: string) => {
    successEvent(msg)
  }

  useEffect(() => {
    $('#adminId')
      .select2({
        width: '200',
        minimumResultsForSearch: Infinity,
        placeholder: '담당자 선택',
      })
      .on('select2:select', (e) => {
        setValue('adminId', e.params.data.id)
        ApiUtil.post('/api/mngAd/consulting/updateAdminId', { adminId: e.params.data.id, consultingId }).then(() => {
          confirmSuccess('저장되었습니다.')
        })
      })

    $('#status')
      .select2({
        width: '200',
        placeholder: '선택',
      })
      .on('select2:select', (e) => {
        if (e.params.data.id === 'END') {
          alertify
            .confirm('종료로 변경한 이후에는 진행상태를 바꿀 수 없습니다.', () => {
              ApiUtil.post('/api/mngAd/consulting/updateStatus', {
                status: e.params.data.id,
                consultingId,
              }).then(() => {
                confirmSuccess('저장되었습니다.')
                setValue('consultingStatus', e.params.data.id)
              })
            })
            .set({ labels: { cancel: '취소', ok: '확인' } })
            .setHeader('종료로 변경하시겠습니까?')
        } else {
          ApiUtil.post('/api/mngAd/consulting/updateStatus', {
            status: e.params.data.id,
            consultingId,
          }).then(() => {
            confirmSuccess('저장되었습니다.')
          })
        }
      })

    /* adminId */
    ApiUtil.get('/api/mngAd/consulting/getAdminList').then((resp) => {
      setAdminList(resp.data.data)
    })

    /* consultingStatus */
    ApiUtil.get('/api/mngAd/consulting/getStatus').then((resp) => {
      const options = Object.keys(resp.data.data).map((key) => [String(key), resp.data.data[key]])
      setStatusList(options)
    })

    setPrevData()
    getSetting()
    getFiles()
    setFileData()
  }, [])

  const downloadFiles = async (e: any) => {
    await ApiUtil3.post('/api/file/downloadReq', { fileId: e.id }).then((resp: any) => {
      downloadUrl(resp.data.data, e.afName)
    })
  }

  return (
    <Fragment>
      <PageTitle>광고 대행 관리</PageTitle>
      <section className="wrap-section wrap-tbl">
        <div className="box-header">
          <div className="box-tit">
            <h2 className="fz-20 fc-1 fw-bold">광고 대행 상세정보</h2>
          </div>
        </div>
        <div className="box-body">
          <div className="tbl">
            <dl className="column-two">
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">광고 대행 고유번호</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <span className="comp-txt">
                    <span className="table">
                      <span className="table-cell">
                        <b className="fz-14 fc-2">{consultingId}</b>
                      </span>
                    </span>
                  </span>
                </div>
              </dd>
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">광고 대행 신청일</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <span className="comp-txt">
                    <span className="table">
                      <span className="table-cell">
                        <b className="fz-14 fc-2">{consultingDetailData?.data?.data.data.regTime}</b>
                      </span>
                    </span>
                  </span>
                </div>
              </dd>
            </dl>
            <dl className="column-two">
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">담당자</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <select
                    id="adminId"
                    className="select2-single w-200"
                    value={consultingDetailData?.data?.data.data.adminId}
                    {...register('adminId')}
                  >
                    <option></option>
                    {adminList.map((a: any, k: any) => (
                      <option key={k} value={a.id}>
                        {a.adminNm}
                      </option>
                    ))}
                  </select>
                </div>
              </dd>
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">진행상태</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <select
                    id="status"
                    className="select2-single w-200"
                    value={consultingDetailData?.data?.data.data.consultingStatus}
                    disabled={consultingDetailData?.data?.data.data.consultingStatus === 'END'}
                    {...register('consultingStatus')}
                  >
                    {statusList.map((a: any) => (
                      <option key={a} value={a[0]}>
                        {a[1]}
                      </option>
                    ))}
                  </select>
                </div>
              </dd>
            </dl>
            <dl className="column-two">
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">메이커명</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <span className="comp-txt">
                    <span className="table">
                      <span className="table-cell">
                        <b className="fz-14 fc-2">{consultingDetailData?.data?.data.data.makerNm}</b>
                      </span>
                    </span>
                  </span>
                </div>
              </dd>
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">메이커ID</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <span className="comp-txt">
                    <span className="table">
                      <span className="table-cell">
                        <b className="fz-14 fc-2">{consultingDetailData?.data?.data.data.loginId}</b>
                      </span>
                    </span>
                  </span>
                </div>
              </dd>
            </dl>
            <dl className="column-two">
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">와디즈 프로젝트ID</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <span className="comp-txt">
                    <span className="table">
                      <span className="table-cell">
                        <b className="fz-14 fc-2">{consultingDetailData?.data?.data.data.projectId}</b>
                      </span>
                    </span>
                  </span>
                </div>
              </dd>
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">와디즈 프로젝트명</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <span className="comp-txt">
                    <span className="table">
                      <span className="table-cell">
                        {consultingDetailData?.data?.data.data.projectLu !== undefined ? (
                          <b className="fz-14 fc-2">
                            <a
                              className="txt-link"
                              target="_blank"
                              rel="noreferrer"
                              href={consultingDetailData?.data?.data.data.projectLu}
                            >
                              {consultingDetailData?.data?.data.data.projectNm}
                            </a>
                          </b>
                        ) : (
                          <b className="fz-14 fc-2">
                            <a className="txt-link">{consultingDetailData?.data?.data.data.projectNm}</a>
                          </b>
                        )}
                        {/* <a href="#javascript" onClick={() => { window.location.href = `${consultingDetailData?.data?.data.data.projectLu}`; }}><b className="fz-14 fc-2">{consultingDetailData?.data?.data.data.projectNm}</b></a> */}
                      </span>
                    </span>
                  </span>
                </div>
              </dd>
            </dl>
            <dl className="vertical">
              <dd>
                <div className="form-group">
                  <section className="wrap-section wrap-tbl">
                    <div className="box-body">
                      <div className="tbl">
                        <dl className="vertical column-two">
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">
                                페이스북, 인스타그램, 구글 등의 온라인 광고를 직접 집행해보신 경험이 있으신가요?
                              </span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <span className="comp-txt">
                                <span className="table">
                                  <span className="table-cell">
                                    <b className="fz-14 fc-2">
                                      {consultingDetailData?.data?.data.data.expYn === true ? '예' : '아니오'}
                                    </b>
                                  </span>
                                </span>
                              </span>
                            </div>
                          </dd>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">
                                광고를 희망하는 매체광고를 희망하는 매체를 선택해주세요.
                              </span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <p className="comp-txt">
                                <span className="table">
                                  <span className="table-cell">
                                    {consultingDetailData?.data?.data.data.mediaDiv3Str != undefined ? (
                                      consultingDetailData?.data?.data.data.mediaDiv3Str.map((a: any) => (
                                        <b className="fz-14 fc-2" key={a}>
                                          {a}
                                        </b>
                                      ))
                                    ) : (
                                      <b className="fz-14 fc-2">-</b>
                                    )}
                                  </span>
                                </span>
                              </p>
                            </div>
                          </dd>
                        </dl>
                        <dl className="vertical column-two">
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">필수로 포함되었으면 하는 키워드를 입력해주세요.</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <span className="comp-txt">
                                <span className="table">
                                  <span className="table-cell">
                                    <b className="fz-14 fc-2">{consultingDetailData?.data?.data.data.consultingT01}</b>
                                  </span>
                                </span>
                              </span>
                            </div>
                          </dd>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">제품에 대한 소구 포인트를 서술해주세요.</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <span className="comp-txt">
                                <span className="table">
                                  <span className="table-cell">
                                    <b className="fz-14 fc-2">{consultingDetailData?.data?.data.data.consultingT02}</b>
                                  </span>
                                </span>
                              </span>
                            </div>
                          </dd>
                        </dl>
                        <dl className="vertical column-two">
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">광고를 집행할 총 예산을 입력해주세요.</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <p className="comp-txt">
                                <span className="table">
                                  <span className="table-cell">
                                    <b className="fz-18 fc-1">
                                      <span className="fc-5">
                                        {consultingDetailData?.data?.data.data.totalBudget != undefined
                                          ? consultingDetailData?.data?.data.data.totalBudget
                                              .toString()
                                              .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
                                          : 0}
                                      </span>{' '}
                                      원
                                    </b>
                                    <b className="fz-12 fc-3">
                                      광고 대행 수수료 :{' '}
                                      <span className="fc-5">
                                        {freeYn === 'false'
                                          ? fee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
                                          : 0}
                                      </span>{' '}
                                      원 (VAT 포함)
                                    </b>
                                  </span>
                                </span>
                              </p>
                            </div>
                          </dd>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1"></span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <a
                                type="button"
                                target="_blank"
                                rel="noreferrer"
                                href="https://app.smartsheet.com/sheets/3Gc28PgfrmjFpHMhXJJPJ2FcmxpMfmq347m7fQq1?view=grid"
                                className="btn btn-primary-outline"
                              >
                                연락처 정보 보기
                              </a>
                            </div>
                          </dd>
                        </dl>
                        <dl className="vertical">
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">
                                광고 소재 제작시 참고할 수 있는 광고용 이미지 및 영상 소재를 등록해주세요.
                              </span>
                            </div>
                          </dt>
                          <dd>
                            {files.length === 0 ? null : (
                              <div className="form-group">
                                <div className="comp-file-list">
                                  <div className="file-list-top">
                                    <span className="fz-14 fc-2">
                                      첨부 파일 총 <span className="fc-5">{getValues('fileCnt')}</span>개
                                    </span>
                                  </div>
                                  <div className="file-list-bottom">
                                    {files.map((file: any) => {
                                      const { id, afName, fileSize } = file
                                      return (
                                        <div className="file-item" key={id}>
                                          <i className="ico ico-file-list"></i>
                                          <span className="fz-12 fc-2" id="afName">
                                            {afName}
                                          </span>
                                          <span className="fz-12 fc-3">({bytesToSize(Number(fileSize))})</span>
                                          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                          <i
                                            className="ico ico-download"
                                            onClick={() => {
                                              downloadFiles(file).then()
                                            }}
                                          ></i>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            )}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </section>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="box-footer">
          <div className="box-left">
            <Link to="/mngAd/consulting">
              <button type="button" className="btn btn-secondary-outline">
                이전
              </button>
            </Link>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default ConsultingDetail
