import { useMutation, useQueryClient, type UseMutationResult } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { fetchPostAdminConfirmState } from '@biz/api'
import { updateAdminConfirmStateCache } from '../useAdminConfirmStateQuery'

type FunctionType = () => UseMutationResult<unknown, unknown, number, unknown>
export const useAdminConfirmStateMutation: FunctionType = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (creationDataId) => fetchPostAdminConfirmState(creationDataId).then((res) => {
      if (res?.data?.creationId) {
        updateAdminConfirmStateCache(queryClient, res?.data?.creationId, res?.data)
      } else {
        toast.warning('제대로 반영되었는지 확인이 필요합니다. ')
      }
      console.log(res)
      return res?.data
    }, (err) => {
      console.log(err)
      toast.error('반영되지 않았습니다. 잠시후 다시 시도해주세요.')
    }),
  })
}
