import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useOutletContext } from 'react-router';
import { Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query' // try next react-query;
import dayjs from 'dayjs';
import qs from 'qs';
import NoDataTemplate from '../common/NoDataTemplate';
import Pagination from '../common/Pagination';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'

interface mediaConfigSearchForm {
  projectDiv3s: string,
  projectDiv3: string,
  mediaDiv2: string,
  useYn: string,
  mediaAccNm: string
}

interface IMediaConfig {
  projectDiv3: string;
  mediaDiv2: string;
  useYn: boolean;
  mediaAccNm: string;
  updateTime: string;
  updateId: string;
  mediaConfigId:number;
  loginId: string;
}
interface IMediaConfigList {
  ok: boolean;
  data: IMediaConfig[];
}

const projectDiv3Map:any = {
  FUNDING: '펀딩 프로젝트 (오픈 예정)', FUNDING_ING: '펀딩 프로젝트 (펀딩 진행중)', STORE: '스토어 프로젝트',
};
const mediaDiv2Map:any = {
  fbitg: '페이스북 / 인스타그램', gdn: '구글 디스플레이 배너',
};
const projectDiv3Renderer = (props: ICellRendererParams) => {
  const { mediaConfigId } = props.data;
  const { mediaDiv2 } = props.data;
  const queryString = {
    mediaConfigId,
    mediaDiv2,
  };
  const value = projectDiv3Map[props.value];
  return <Link className="txt-link" to={`/mngMd/mngMdUpdate?${qs.stringify(queryString)}`}>{value}</Link>;
};
const mediaDiv2Renderer = (props: ICellRendererParams) => mediaDiv2Map[props.value];
const useYnRenderer = (props: ICellRendererParams) => {
  const useYn = props.value;
  return useYn === true ? '활성' : '비활성';
};
const dateFormatRenderer = (props: ICellRendererParams) => {
  const { date } = props.value;
  const { time } = props.value;
  return dayjs(new Date(date.year, date.month - 1, date.day, time.hour, time.minute)).format('YYYY-MM-DD HH:mm');
};

const MngMd = () => {
  // 타이틀 설정
  const setTitle = useOutletContext<any>();

  const childRef = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>();
  const [columnDefs] = useState<ColDef[]>([
    {

      field: 'projectDiv3',
      headerName: '프로젝트',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      minWidth: 300,
      resizable: true,
      cellRenderer: projectDiv3Renderer,
    },
    {
      field: 'mediaCatDivDesc',
      minWidth: 200,
      resizable: true,
      headerName: '카테고리'
    },
    {
      field: 'mediaDiv2',
      minWidth: 100,
      resizable: true,
      headerName: '노출매체',
      cellRenderer: mediaDiv2Renderer,
    },
    {
      field: 'useYn',
      minWidth: 10,
      resizable: true,
      headerName: '상태',
      cellRenderer: useYnRenderer,
    },
    {
      field: 'mediaAccNm',
      minWidth: 400,
      resizable: true,
      headerName: '광고 집행 계정',
    },
    { field: 'updateTime', minWidth: 50, resizable: true, headerName: '최근 수정일', cellRenderer: dateFormatRenderer },
    { field: 'loginId', minWidth: 30, resizable: true, headerName: '수정자' },

  ]);

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);
  const {
    register, handleSubmit, setValue, getValues,
  } = useForm<mediaConfigSearchForm>();

  // 기본 그리드 hegith 기본이 48이다.
  const onGridReady = useCallback(() => {
    const {
      projectDiv3s,
      mediaDiv2,
      useYn,
      mediaAccNm,
    } = getValues();
    // 데이터가 여부에 따라 height를 다르게 주어야 한다....
    // AG GRID 에 대한 디자인을 진행하다보니 일단 이렇게... ApiUtil2에 처리되어 있다.
    ApiUtil2.get<IMediaConfigList>('/api/mngMd/mngMdList', {
      params: {
        projectDiv3s, mediaDiv2, useYn, mediaAccNm,
      },
      paramsSerializer: (params) => qs.stringify(params),
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);
  const mediaDel = useMutation({
    mutationFn: (formData: any) => ApiUtil2.post(`/api/mngMd/deleteMngMd`, formData),
    onSuccess: (resp) => {
      if (resp.data.ok) {
        alertify.success('선택한 항목이 삭제되었습니다.')
        onGridReady()
      }
    },
    // onError: (error) => {
    // },
    // onMutate: () => {
    // },
  })

  const mngMdDeleteEvent = () => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    const mngMdIds = selectedRows.map((checkedData:IMediaConfig) => checkedData.mediaConfigId);
    for (let i = 0; i < selectedRows.length; i += 1) {
      if (selectedRows[i].useYn) {
        alertify.error('\'비활성\' 상태의 항목만 삭제가 가능합니다.');
        return;
      }
    }
    mediaDel.mutate(mngMdIds);
  };
  const projectDiv3CheckEvent = () => {
    const query = 'input[name=projectDiv3]:checked';
    const checkeds = document.querySelectorAll(query);
    const checkedValues:any = [];
    const checkedText:any = [];
    checkeds.forEach((el:any) => {
      checkedText.push(el.nextElementSibling.textContent);
      checkedValues.push(el.getAttribute('value'));
    });
    setValue('projectDiv3s', checkedValues);
    const filterText = document.getElementById('projectDiv3Text') as HTMLElement;
    if (checkedText.length === 4 || checkedText.length === 0) {
      filterText.innerText = '전체';
    } else {
      filterText.innerText = checkedText[0] + (checkedText.length === 1 ? '' : ` 외 ${checkedText.length - 1}개`);
    }
  };
  // 그리드에서 페이징 변경시 pagination 에 있는 함수 호출
  const changePagination = () => {
    childRef.current!.onPaginationChanged();
  };

  const textCheck = (event:any) => {
    const text = event.target.value;
    if (event.target.value.length >= 100) {
      const chanegText = text.slice(0, -2);
      setValue('mediaAccNm', chanegText);
    }
  };
  useEffect(() => {
    setTitle('매체 및 광고세트 설정 관리');
    $('#mediaDiv2Select').select2({
      width: '200px',
      minimumResultsForSearch: Infinity,
      // dropdownCssClass: 'small',
    }).on('select2:select', (e) => {
      setValue('mediaDiv2', e.params.data.id);
    });
    $('#useYnSelect').select2({
      width: '120px',
      minimumResultsForSearch: Infinity,
      // dropdownCssClass: 'small',
    }).on('select2:select', (e) => {
      setValue('useYn', e.params.data.id);
    });

    return () => {
      setTitle('');
    };
  }, []);
  return (
    <Fragment>
      <section className="wrap-section wrap-datagrid">
        {/* 필터 */}
        <form onSubmit={handleSubmit(onGridReady)}>
          {/* <input type="hidden" {...register('projectDiv3s')} /> */}
        <div className="wrap-filter">
          <div className="inner-filter">
            <div className="box-left">
              <div className="item-filter">
                <div className="filter-tit">
                  <p className="fz-12 fc-2">프로젝트</p>
                </div>
                <div className="form-group">
                  <div className="comp-dropdown">
                    <Link to="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                      <div className="box-left">
                        <span className="fz-14" id="projectDiv3Text">전체</span>
                      </div>
                      <div className="box-right">
                        <i className="ico ico-arrow"></i>
                      </div>
                    </Link>
                    <div className="dropdown-menu">
                      <ul className="opt-selectbox">
                        <li className="opt-menu">
                          <div className="comp-checkbox small">
                            <input type="checkbox" id="projectDiv3-1" {...register('projectDiv3')} onChange={projectDiv3CheckEvent} value="FUNDING"/>
                              <label htmlFor="projectDiv3-1">펀딩 프로젝트 (오픈 예정)</label>
                          </div>
                        </li>
                        <li className="opt-menu">
                          <div className="comp-checkbox small" >
                            <input type="checkbox" id="projectDiv3-2" {...register('projectDiv3')} onChange={projectDiv3CheckEvent} value="FUNDING_ING"/>
                              <label htmlFor="projectDiv3-2">펀딩 프로젝트 (펀딩 진행중)</label>
                          </div>
                        </li>
                        <li className="opt-menu">
                          <div className="comp-checkbox small">
                            <input type="checkbox" id="projectDiv3-3" {...register('projectDiv3')} onChange={projectDiv3CheckEvent} value="STORE"/>
                              <label htmlFor="projectDiv3-3">스토어 프로젝트</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-filter">
                <div className="filter-tit">
                  <p className="fz-12 fc-2">노출 매체</p>
                </div>
                <div className="box-filter">
                  <select id="mediaDiv2Select" className="select2-single" {...register('mediaDiv2')}>
                    <option value="">전체</option>
                    <option value="fbitg">페이스북 / 인스타그램</option>
                    <option value="gdn">구글 디스플레이 배너</option>
                  </select>
                </div>
              </div>
              <div className="item-filter">
                <div className="filter-tit">
                  <p className="fz-12 fc-2">상태</p>
                </div>
                <div className="box-filter">
                  <select id="useYnSelect" className="select2-single" {...register('useYn')}>
                    <option value="">전체</option>
                    <option value="true">활성</option>
                    <option value="false">비활성</option>
                  </select>
                </div>
              </div>
              <div className="item-filter">
                <div className="filter-tit">
                  <p className="fz-12 fc-2">광고 집행 계정</p>
                </div>
                <div className="box-filter">
                  <div className="input-group">
                    <div className="inner-input-group">
                      <input type="text" className="tf-comm" placeholder="" {...register('mediaAccNm')} onChange={textCheck}/>
                    </div>
                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-right">
              <button type="submit" className="btn btn-tertiary-mint btn-ico"><i className="ico ico-filter"></i>조회
              </button>
            </div>
          </div>
        </div>
        </form>
        {/* 필터 끝 */}
        {/* 등록 삭제 */}
        <div className="box-header">
          <div className="box-option">
            <Link to={'/mngMd/mngMdReg'}>
              <button type="button" className="btn btn-primary">등록</button>
            </Link>
            <button type="button" className="btn btn-secondary-outline" onClick={mngMdDeleteEvent}>삭제</button>
          </div>
        </div>
        {/* 등록 삭제 끝 */}
        {/* 데이터 그리드 */}
        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  suppressRowClickSelection={true}
                  rowSelection={'multiple'}
                  rowHeight={48}
                  pagination={true}
                  paginationPageSize={10}
                  suppressPaginationPanel={true}
                  onPaginationChanged={changePagination}
                  domLayout={'autoHeight'}
                  noRowsOverlayComponent = { NoDataTemplate }
              ></AgGridReact>
            </div>
          </div>
        </div>
        {/* 데이터 그리드 끝 */}
        <div className="box-footer">
          <Pagination gridRef={gridRef} ref={childRef}/>
        </div>
      </section>
    </Fragment>
  );
};

export default MngMd;
