import { useQuery, type QueryClient } from '@tanstack/react-query'
import { fetchCampaignValidation } from '@biz/api'
import { useCurrentCampaignIdQuery, loadCurrentCampaignId } from '@biz/query'

const queryRootKey = '@app/TargetCreatePage/useCampaignValidation'
export const useCampaignValidationQuery = (isEnabled: boolean) => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  return useQuery({
    queryKey: [queryRootKey, campaignId],
    queryFn: () => (!!campaignId ? fetchCampaignValidation(campaignId) : Promise.reject({})),
    enabled: isEnabled && Boolean(campaignId),
    gcTime: 0,
  })
}

export const loadCampaignValidation = (queryClient: QueryClient) => async (isEnabled: boolean) => {
  const campaignId = await loadCurrentCampaignId(queryClient)()
  if (!campaignId || !isEnabled) {
    return
  }

  return await queryClient.fetchQuery({
    queryKey: [queryRootKey, campaignId],
    queryFn: () => fetchCampaignValidation(campaignId),
  })
}
