import { useState } from 'react'
import { Button } from 'antd'
import { ReactComponent as PlusIcon } from '@common/svg/plus.svg'
import { type ImageMetaDataType } from '@biz/query'
import { EmptyPreview } from '@biz/ui'
import { ImagePickerModal } from './ImagePickerModal'
import { ImageCarousel } from './ImageCarousel'
import styles from './ImagePanel.module.scss'

type ImagePanelFunction = {
  value?: ImageMetaDataType[]
  onChange?: (v: ImageMetaDataType[], current?: ImageMetaDataType) => void
  onDelete?: (afId: string | number) => void
  disabled?: boolean
}
export const ImagePanel: React.FC<ImagePanelFunction> = ({ value, onChange, onDelete, disabled }) => {
  const [isOpen, setOpen] = useState(false)
  const images = value || []
  const isSlideImageOnly = images.length > 0
  const doNotInsertImage =
    images.length > 0 &&
    (() => {
      const { width, height } = images[0]?.afMeta || { width: 1, height: 0 } // 데이터가 없다면 width와 height는 다른 값이 되게
      return width !== height
    })()
  return (
    <div className={styles.containImagePanel}>
      <div className={styles.buttonContainer}>
        <Button
          size="small"
          type="text"
          icon={<PlusIcon />}
          onClick={() => setOpen(true)}
          disabled={disabled || images.length >= 10 || doNotInsertImage}
        >
          {isSlideImageOnly ? '슬라이드 추가' : '이미지 추가'}
        </Button>
      </div>
      <div className={styles.imageContainer}>
        {images.length > 0 ? <ImageCarousel list={images} onDelete={onDelete} /> : <EmptyPreview />}
      </div>
      <div className={styles.bottomComment}>
        <div>
          {'* 이미지/영상 크기에 따라 미리보기에서 일부 표시되지 않으나 실제 광고 노출시에는 정상적으로 노출됩니다.'}
        </div>
      </div>
      <ImagePickerModal
        isSlideImageOnly={isSlideImageOnly}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onConfirm={(confirmImage: ImageMetaDataType) => {
          onChange?.([...images, confirmImage], confirmImage)
          setOpen(false)
        }}
      />
    </div>
  )
}
