import { useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query'
import { fetchAttachedDocumentList } from '@biz/api'
import { useCurrentCampaignIdQuery } from '@biz/query'

const queryKey = ['@biz/query/targetAdContext/useAttachDocument']
export type AttachedDocumentType = Partial<{
  file: File
  publicUrl: string
  uploadId: number
  // to modify attached document info
  fileName: string
  fileSize: number
  filePath: string
  // uploadId: number
  lastModified: string
}>

type ModifyHeaderType = {
  id: number
  campId: number
  project2Id: string
}

type DataType = {
  attachedDocuments: AttachedDocumentType[]
  modifyHeader: ModifyHeaderType
}
export const setAttachDocument = (queryClient: QueryClient) => async (value: AttachedDocumentType[]) => {
  const defaultData = queryClient.getQueryData(queryKey) as DataType
  queryClient.setQueryData(queryKey, { modifyHeader: defaultData.modifyHeader, attachedDocuments: value })
}

export const useAttachDocumentQuery = () => {
  const queryClient = useQueryClient()

  const { data: campaignId } = useCurrentCampaignIdQuery()

  const enabled = campaignId !== undefined
  return {
    ...useQuery<unknown, unknown, DataType, string[]>({
      queryKey,
      queryFn: () =>
        !enabled
          ? Promise.resolve({}) // type guard
          : !!campaignId
          ? fetchAttachedDocumentList(campaignId).then((res) => {
              const { id, project2Id, campId, afs } = res.data
              const attachedDocuments = (afs || []).map((n) => ({
                fileName: n.fileName,
                fileSize: n.fileSize,
                filePath: n.filePath,
                uploadId: n.afId,
                lastModified: n.lastModified,
              }))
              return {
                attachedDocuments,
                modifyHeader: { id, project2Id, campId },
              }
            })
          : Promise.reject('캠페인이 선택되지 않음'),
      enabled,
      refetchOnMount: true,
    }),
    update: setAttachDocument(queryClient),
    invalidate: () => {
      queryClient.invalidateQueries({ queryKey })
    },
  }
}
