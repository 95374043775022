import { Form, DatePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { ItemLabel } from '../ItemLabel'
import { presentList } from './presents'

const RangeWrapPickerName = 'range'

export const RangeWrapPicker: React.FC & {
  key: string
  serialize: (value: any) => { startDate: string; endDate: string }
} = () => (
  <Form.Item
    name={RangeWrapPickerName}
    label={<ItemLabel>AI 검수요청/완료</ItemLabel>}
    layout="vertical"
    style={{ minWidth: 240 }}
  >
    <DatePicker.RangePicker presets={presentList} allowClear={false} />
  </Form.Item>
)

RangeWrapPicker.key = RangeWrapPickerName
RangeWrapPicker.serialize = (values: any) => {
  const { [RangeWrapPickerName]: value } = values
  return {
    startDate: ((value as [Dayjs, Dayjs])[0] || dayjs()).format('YYYYMMDD'), //"20250106"
    endDate: ((value as [Dayjs, Dayjs])[1] || dayjs()).format('YYYYMMDD'), //"20250204"
  }
}
