import { useState, useCallback } from 'react'
import { Button } from 'antd'
import { toast } from 'react-toastify'
import { useBizNumberListInfoQuery, useRemainAmountQuery } from '@biz/query'
import { type PayType } from '../PaymentType'
import { ChargeCompleteModal } from './ChargeCompleteModal'
import { usePaymentMutation } from './_queries/usePaymentMutation'
import styles from './BizMoneyChargeActionButton.module.scss'

type BizMoneyChargeActionButtonFunction = {
  projectId: string | undefined
  amount: number
  payType: PayType
  onComplete: () => void
}

export const BizMoneyChargeActionButton: React.FC<BizMoneyChargeActionButtonFunction> = ({
  projectId,
  amount,
  payType,
  onComplete,
}) => {
  const [isCompleteOpen, setCompleteOpen] = useState(false)
  const { isLoading, data: userData, refetch } = useBizNumberListInfoQuery()
  const { data: remainAmountInfo, refetch: remainRefetch } = useRemainAmountQuery(true, 'BIZ_MONEY')
  const { currentCost } = remainAmountInfo || {}

  const mutation = usePaymentMutation()
  const doAction = useCallback(() => {
    if (!projectId) {
      toast.error('projectId를 가져오지 못했어요. 잠시후 시도해주세요')
      return
    }
    if (!payType) {
      console.error('타입가드: payType입력이 제대로 되고 있지 않음')
      return
    }
    mutation
      .mutateAsync({
        projectId,
        cost: amount,
        txDiv: payType,
      })
      .then(
        () => {
          refetch()
          remainRefetch()
          setCompleteOpen(true)
        },
        (err) => {
          toast.error(`${err?.message || '충전을 실패했습니다. 잠시후 시도해주세요.'}`)
        }
      )
  }, [amount, projectId, payType])

  const doClose = () => {
    setCompleteOpen(false)
    onComplete()
  }
  return (
    <div className={styles.BizMoneyChargeActionButton}>
      <Button
        type="primary"
        block
        size="large"
        disabled={amount === 0 || payType === null || !projectId}
        onClick={doAction}
        loading={mutation.isPending}
        className={styles.button}
      >
        {'충전하기'}
      </Button>
      {!isLoading && (
        <ChargeCompleteModal
          isOpen={isCompleteOpen}
          onClose={doClose}
          amount={amount}
          userNo={userData?.current?.userNo || '****'}
          remain={currentCost || 0}
        />
      )}
    </div>
  )
}
