import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { useOutletContext } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';

import { useQuery } from '@tanstack/react-query' // try next react-query;
import { useForm } from 'react-hook-form';
import { ApiUtil2 } from '@biz/api'
import BzmMngMakerCouponDialog from './dialog/BzmMngMakerCouponDialog';
import BzmMngRedemptionDialog from './dialog/BzmMngRedemptionDialog';
import BzmMngMakerBzmReturnDialog from './dialog/BzmMngMakerBzmReturnDialog';
import BzmMngExpireDialog from './dialog/BzmMngExpireDialog';
import BzmMngMakerAllCurrentBzmDialog from './dialog/BzmMngMakerAllCurrentBzmDialog';

interface IUserNo{
    projectId:string;
    userNo:string;
}

interface IMngMakerDetail {
    data:any;
    makerId:number;
    makerLoginId:string;
    makerNm:string;
    makerStatus:string;
    agreeYn:boolean;
    liveYn:boolean;
    userNo:string;
    sidNo:string;
    bizDiv:string;
    costPaid:number;
    costFree:number;
    campBalancePaid:number;
    campBalanceFree:number;
    returnableCostPaid:number;
    returnableCostFree:number;
    projectId:string;
    findUserNos:IUserNo[];
    accId:number | undefined;
}
const MakerStatusMap:any = {
  IA: '휴면',
  NM: '정상',
  RA: '정상',
  DO: '탈퇴',
};
const BzmMngMakerDetail = () => {
  const setTitle = useOutletContext<any>();
  const navigate = useNavigate();
  const { makerId } = useParams();
  const { param } = useParams();
  const [liveYn, setLiveYn] = useState(false);
  // 금액 관련
  const [costPaid, setCostPaid] = useState(0);
  const [costFree, setCostFree] = useState(0);
  const [balancePaid, setBalancePaid] = useState(0);
  const [balanceFree, setBalanceFree] = useState(0);
  const [returnableCostPaid, setReturnableCostPaid] = useState(0);
  const [returnableCostFree, setReturnableCostFree] = useState(0);
  const userNoSelect = useRef<HTMLSelectElement>(null);
  // 쿠폰 지급 관련
  const [couponMakerIds, setCouponMakerIds] = useState<any[]>();
  const [couponTitle, setCouponTitle] = useState('');
  const [couponDialog, setCouponDialog] = useState<boolean>(false);
  // 쿠폰 환수 관련
  const [redemptionCouponTitle, setRedemptionCouponTitle] = useState('');
  const [redemptionCouponDialog, setRedemptionCouponDialog] = useState<boolean>(false);
  // 비즈머니 환급 관련
  const [returnDialog, setReturnDialog] = useState<boolean>(false);
  // 비즈머니 유효기간 다이얼로그 관련
  const [expireDialog, setExpireDialog] = useState<boolean>(false);
  // 전체 비즈머니 현황 다이얼로그 관련
  const [currentBzmDialog, setCurrentBzmDialog] = useState<boolean>(false);
  const [noLiveYnUserNo, setNoLiveYnUserNo] = useState<boolean>(true);
  const {
    setValue, getValues,
  } = useForm<IMngMakerDetail>();

  const getDetailInfoApi = () => {
    const result = ApiUtil2.get<IMngMakerDetail>('/api/bzmAdmin/mng/bzmMngMaker/detailInfo', { params: { data: { makerId, accId: getValues('accId'), projectId: getValues('projectId') } } });
    return result;
  };
  const getDetailInfoApiEvent = useQuery({
    queryKey: ['getDetailInfoApi', makerId],
    queryFn: getDetailInfoApi,
    enabled: false,
  })

  const getUserNosApi = () => {
    const result = ApiUtil2.get<IMngMakerDetail>('/api/bzmAdmin/mng/bzmMngMaker/getDetailInfoUserNos', {
      params: { data: { makerId, accId: getValues('accId') } },
    })
    return result
  }
  const getUserNosApiEvent = useQuery({ queryKey: ['getUserNosApi', makerId], queryFn: getUserNosApi, enabled: false })
  const settingDetailInfo = () => {
    getDetailInfoApiEvent.refetch().then((r:any) => {
      if (r.isSuccess) {
      // 공통 항목 넣어주기
        const info = r.data.data.data;
        setValue('makerId', info.makerId);
        setValue('makerLoginId', info.makerLoginId);
        setValue('makerNm', info.makerNm);
        setValue('makerStatus', info.makerStatus);
        setValue('agreeYn', info.agreeYn);
        setLiveYn(info.liveYn);
        setCostPaid(info.costPaid || 0);
        setCostFree(info.costFree || 0);
        setBalancePaid(info.campBalancePaid || 0);
        setBalanceFree(info.campBalanceFree || 0);
        setReturnableCostPaid(info.returnableCostPaid || 0);
        setReturnableCostFree(info.returnableCostFree || 0);
      }
    });
  };
  const settingUserNo = () => {
    getUserNosApiEvent.refetch().then((r:any) => {
      if (r.isSuccess) {
        const info = r.data.data.data;
        $('#userNoSelect').empty();
        info.forEach((d:IMngMakerDetail) => {
          if (d.liveYn) setNoLiveYnUserNo(false);
          let text = '-';
          if (d.userNo !== undefined) text = d.liveYn ? `${d.userNo} (활성)` : d.userNo;
          const projectIdSelect = d.projectId;
          $('#userNoSelect').append(`<option id=${projectIdSelect} value=${projectIdSelect} data-accId=${d.accId}> ${text}</option>`);
        });
        // 초기값을 세팅
        $('#userNoSelect').val(getValues('projectId')).trigger('change');
        setValue('accId', $(`#userNoSelect option[id=${getValues('projectId')}]`).data('accid'));
        settingDetailInfo();
      }
    });
  };
  const prevListEvent = () => {
    navigate('/bzmAdmin/mng/mngMaker');
  };
  // 쿠폰 - 지급
  const giveCouponEvent = () => {
    setCouponDialog(true);
    setCouponTitle(getValues('makerNm'));
    setCouponMakerIds(new Array(makerId));
  };
  // 쿠폰 - 환수
  const takeBackEvent = () => {
    setRedemptionCouponTitle(getValues('makerNm'));
    setRedemptionCouponDialog(true);
  };

  // 비즈머니 환급
  const showReturnDialog = () => {
    setReturnDialog(true);
  };

  // 비즈머니 내역 가기
  const goBzmListEvent = () => {
    window.open(`/bzmAdmin/detail/${getValues('projectId')}/${getValues('makerId')}`);
  };
  // 비즈머니 유효기간 다이얼로그 띄우기
  const showExpireDialog = () => {
    setExpireDialog(true);
  };
  // 비즈머니 전체 현황 다이얼로그 띄우기
  const showCurrentBzmDialog = () => {
    setCurrentBzmDialog(true);
  };
  useEffect(() => {
    setTitle('계정 비즈머니 정보');
    return () => {
      setTitle('');
    };
  }, []);

  useEffect(() => {
    $('#userNoSelect').select2({
      width: '330px',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e : any) => {
      const selectData = document.getElementById(e.params.data.id) as HTMLElement;
      setValue('projectId', e.params.data.id);
      setValue('accId', Number(selectData.dataset.accid));
      settingDetailInfo();
    });
  });
  useEffect(() => {
    const projectIdValue = param?.split('_')[1];
    if (projectIdValue !== undefined) {
      setValue('projectId', projectIdValue);
      settingUserNo();
    }
  }, []);
  return (
    <Fragment>
        {
            couponDialog ? <BzmMngMakerCouponDialog setCouponDialog={setCouponDialog} couponMakerIds={couponMakerIds} couponTitle={couponTitle} afterEvent = {settingDetailInfo}/> : null
        }
        {
            redemptionCouponDialog ? <BzmMngRedemptionDialog setRedemptionCouponDialog = {setRedemptionCouponDialog} makerId = {makerId} userNo = {param?.split('_')[0]} redemptionCouponTitle = {redemptionCouponTitle} afterEvent = {settingDetailInfo} possibleCost={costFree} /> : null
        }
        {
            returnDialog ? <BzmMngMakerBzmReturnDialog setReturnDialog ={setReturnDialog} makerId = {makerId} afterEvent = {settingDetailInfo} returnableCostPaid={returnableCostPaid} returnableCostFree = {returnableCostFree} /> : null
        }
        {
            expireDialog ? <BzmMngExpireDialog setExpireDialog={setExpireDialog} makerId={getValues('makerId')} showType='maker' /> : null
        }
        {
            currentBzmDialog ? <BzmMngMakerAllCurrentBzmDialog setCurrentBzmDialog={setCurrentBzmDialog} makerId={getValues('makerId')}/> : null
        }
        <div className="wrap-section wrap-tbl">
            <div className="box-header">
                <div className="box-tit">00
                    <button type="button" className="btn btn-secondary-outline" onClick = {prevListEvent}>이전 목록으로</button>
                </div>
                <div className="box-option">
                    <button type="button" className="btn btn-primary" onClick={showCurrentBzmDialog}>전체 비즈머니 현황</button>
                    <button type="button" className="btn btn-primary-outline" onClick={showExpireDialog}>비즈머니 유효기간</button>
                    <button type="button" className="btn btn-info-outline" onClick={goBzmListEvent}>비즈머니 내역</button>
                </div>
            </div>
            <div className="box-body">
                <div className="tbl">
                    <dl className="vertical">
                        <dd>
                            <div className="form-group">
                                <div className="wrap-section wrap-tbl">
                                    <div className="box-header">
                                        <div className="box-tit">
                                            <h2 className="fz-20 fc-1 fw-bold">메이커 계정정보</h2>
                                        </div>
                                    </div>
                                    <div className="box-body">
                                        <div className="tbl">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col col-10-2">
                                                        <dl className="vertical">
                                                            <dt>
                                                                <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                메이커ID
                                                            </span>
                                                                </div>
                                                            </dt>
                                                            <dd>
                                                                <div className="form-group">
                                                                    <div className="input-group expand">
                                                                        <div className="inner-input-group">
                                                                            <input type="text" className="tf-comm" value={getValues('makerLoginId') || ''} disabled/>

                                                                        </div>
                                                                        <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <div className="col col-10-2">
                                                        <dl className="vertical">
                                                            <dt>
                                                                <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                메이커명
                                                            </span>
                                                                </div>
                                                            </dt>
                                                            <dd>
                                                                <div className="form-group">
                                                                    <div className="input-group expand">
                                                                        <div className="inner-input-group">
                                                                            <input type="text" className="tf-comm" value={getValues('makerNm') || ''} disabled/>

                                                                        </div>
                                                                        <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <div className="col col-10-2">
                                                        <dl className="vertical">
                                                            <dt>
                                                                <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                충전 사업자 번호
                                                            </span>
                                                                </div>
                                                            </dt>
                                                            <dd>
                                                                <div className="form-group">
                                                                    <select className="select2-single expand" id="userNoSelect" ref={userNoSelect} >
                                                                    </select>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <div className="col col-10-2">
                                                        <dl className="vertical">
                                                            <dt>
                                                                <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                계정상태
                                                            </span>
                                                                </div>
                                                            </dt>
                                                            <dd>
                                                                <div className="form-group">
                                                                    <div className={getValues('makerStatus') === 'NM' || getValues('makerStatus') === 'RA' ? 'txt-state mint large' : 'txt-state red large'}>{MakerStatusMap[getValues('makerStatus')]}</div>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <div className="col col-10-2">
                                                        <dl className="vertical">
                                                            <dt>
                                                                <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                약관 동의 여부
                                                            </span>
                                                                </div>
                                                            </dt>
                                                            <dd>
                                                                <div className="form-group">
                                                                    <div className="txt-state black large">{getValues('agreeYn') ? '동의' : '미동의'}</div>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col col-4">
                                            <div className="wrap-section wrap-tbl">
                                                <div className="box-header">
                                                    <div className="box-tit">
                                                        <h2 className="fz-20 fc-1 fw-bold">보유 비즈머니</h2>
                                                    </div>
                                                </div>
                                                <div className="box-body">
                                                    <div className="tbl">
                                                        <dl className="vertical">
                                                            <dt>
                                                                <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                유상 비즈머니
                                                            </span>
                                                                </div>
                                                            </dt>
                                                            <dd>
                                                                <div className="form-group">
                                                                    <span className="comp-txt">
                                                                <span className="table">
                                                                    <span className="table-cell">
                                                                        <b className="fz-14 fc-2">{costPaid.toLocaleString()} 원</b>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                        <dl className="vertical">
                                                            <dt>
                                                                <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                쿠폰
                                                            </span>
                                                                    {
                                                                        (liveYn || noLiveYnUserNo)
                                                                          ? <button type="button" className="btn btn btn-primary small" onClick={giveCouponEvent}>지급</button> : null
                                                                    }
                                                                    {
                                                                        costFree !== 0 && liveYn
                                                                          ? <button type="button" className="btn btn-secondary-outline small" onClick={takeBackEvent}>환수</button> : null
                                                                    }

                                                                </div>
                                                            </dt>
                                                            <dd>
                                                                <div className="form-group">
                                                                    <span className="comp-txt">
                                                                <span className="table">
                                                                    <span className="table-cell">
                                                                        <b className="fz-14 fc-2">{costFree.toLocaleString()} 원</b>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-4">
                                            <div className="wrap-section wrap-tbl">
                                                <div className="box-header">
                                                    <div className="box-tit">
                                                        <h2 className="fz-20 fc-1 fw-bold">예치금</h2>
                                                    </div>
                                                </div>
                                                <div className="box-body">
                                                    <div className="tbl">
                                                        <dl className="vertical">
                                                            <dt>
                                                                <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                유상 비즈머니
                                                            </span>
                                                                </div>
                                                            </dt>
                                                            <dd>
                                                                <div className="form-group">
                                                                    <span className="comp-txt">
                                                                <span className="table">
                                                                    <span className="table-cell">
                                                                        <b className="fz-14 fc-2">{balancePaid.toLocaleString()} 원</b>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                        <dl className="vertical">
                                                            <dt>
                                                                <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                쿠폰
                                                            </span>
                                                                </div>
                                                            </dt>
                                                            <dd>
                                                                <div className="form-group">
                                                                    <span className="comp-txt">
                                                                <span className="table">
                                                                    <span className="table-cell">
                                                                        <b className="fz-14 fc-2">{balanceFree.toLocaleString()} 원</b>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-4">
                                            <div className="wrap-section wrap-tbl">
                                                <div className="box-header">
                                                    <div className="box-tit">
                                                        <h2 className="fz-20 fc-1 fw-bold">환급 가능 비즈머니</h2>
                                                    </div>
                                                    <div className="box-option">
                                                        {
                                                            returnableCostPaid !== 0 || returnableCostFree !== 0
                                                              ? <button type="button" className="btn btn-primary-outline" onClick={showReturnDialog}>비즈머니 환급</button> : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="box-body">
                                                    <div className="tbl">
                                                        <dl className="vertical">
                                                            <dt>
                                                                <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                유상 비즈머니
                                                            </span>
                                                                </div>
                                                            </dt>
                                                            <dd>
                                                                <div className="form-group">
                                                                    <span className="comp-txt">
                                                                <span className="table">
                                                                    <span className="table-cell">
                                                                        <b className="fz-14 fc-2">{returnableCostPaid.toLocaleString()} 원</b>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                        <dl className="vertical">
                                                            <dt>
                                                                <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                쿠폰
                                                            </span>
                                                                </div>
                                                            </dt>
                                                            <dd>
                                                                <div className="form-group">
                                                                    <span className="comp-txt">
                                                                <span className="table">
                                                                    <span className="table-cell">
                                                                        <b className="fz-14 fc-2">{returnableCostFree.toLocaleString()} 원</b>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </Fragment>
  );
};
export default BzmMngMakerDetail;
