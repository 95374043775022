import { Form } from 'antd'
import { targetFormRegistry, FacebookContent } from '@biz/ui'
import { ctaTable, type CTAListType } from '@biz/api'

type ContentPreviewFunction = {
  containerName: number
}
export const ContentPreview: React.FC<ContentPreviewFunction> = ({ containerName }) => {
  const {
    CreativeAssetsName,
    InputAdCopyName,
    InputAdTitleName,
    InputAdDescriptionName,
    ImageManagerName,
    CTASelectorName,
  } = targetFormRegistry
  const fieldList = [InputAdCopyName, InputAdTitleName, InputAdDescriptionName, ImageManagerName, CTASelectorName].map(
    (n) => [CreativeAssetsName, containerName, n]
  )
  return (
    <Form.Item dependencies={fieldList}>
      {({ getFieldValue }) => {
        const dataList = getFieldValue([CreativeAssetsName, containerName])
        return (
          !!dataList && (
            <FacebookContent
              ctaBtn={ctaTable[dataList?.[CTASelectorName] as CTAListType]}
              desc={dataList?.[InputAdDescriptionName]}
              mainCopy={dataList[InputAdCopyName]}
              imageList={dataList[ImageManagerName]}
              adTitle={dataList[InputAdTitleName]}
            />
          )
        )
      }}
    </Form.Item>
  )
}
