import { useMemo, useCallback } from 'react'
export type OptionLabelType = string
export type OptionValueType = string
export type OptionType = { label: OptionLabelType; value: OptionValueType }
type MapType = { [index: OptionLabelType & OptionValueType]: OptionLabelType & OptionValueType }
export const useTranslateOptions = (options: OptionType[]) => {
  const baseOption = (options || []).filter((n) => n.value !== 'all')
  const labelList = baseOption.map((n) => n.label)
  const l2v = useMemo(() => baseOption.reduce<MapType>((acc, n) => ({ ...acc, [n.label]: n.value }), {}), [options])
  const v2l = useMemo(() => baseOption.reduce<MapType>((acc, n) => ({ ...acc, [n.value]: n.label }), {}), [options])
  return {
    labelList,
    labelToValue: useCallback((values?: OptionValueType[]) => (values || []).map((n) => l2v[n]), []),
    valueToLabel: useCallback((values?: string) => {
      return !values ? [] : values === 'all' ? labelList : values.split(',').map((n) => v2l[n])
    }, []),
  }
}
