import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query' // try next react-query;
import { ApiUtil } from '@biz/api'
import MultiFileUploaderGgLogo, { IFileTypes } from '../fileUploader/MultiFileUploaderGgLogo';
import { getByteFunction } from '../common/CommonFunc';

interface segment{
    segmentName : string;
    segmentResourceName:string;
}
interface segments{
    ok: boolean;
    data : segment[]
}
export interface ggAdSet{
  uploadFiles : IFileTypes[];
  uploadId:number;
  comNm:string;
  adSetOrder:number;
  segment:string[]|any;
}

const MngMdGgAdSet = (props:any) => {
  const {
    register, getValues, setValue,
  } = useForm<ggAdSet>();
  const [uploadFiles, setUploadFiles] = useState<IFileTypes[]>([]);
  const [tabOrder, setTabOrder] = useState(1);
  const [showUploadFile, setShowUploadFile] = useState(false);

  // 잠재세그먼트 조회
  const getSegments = async () => {
    const result = await ApiUtil.get<segments>('/api/mngMd/mngMdGg/segmentSearch', {
      params: { customerId: props.ggCustomerId },
    });
    return result.data;
  };
  const getSegmentsData = useQuery({ queryKey: ['getSegments'], queryFn: getSegments, enabled: false })

  const AddSegment = useCallback(() => {
    if (props.changeOrder === 2) {
      return (
              <Fragment>
              <dl className="vertical">
                  <dt>
                      <div className="dt-inner">
                            <span className="fz-16 fc-1">
                                잠재세그먼트
                            </span>
                      </div>
                  </dt>
                  <dd>
                      <div className="form-group">
                          <select className="select2-tags expand" id="ggSegment" multiple disabled>
                              <option value=""></option>
                          </select>
                      </div>
                  </dd>
              </dl>
              </Fragment>
      );
    }
    return <Fragment></Fragment>;
  }, [props.changeOrder]);

  const uploadFilesFunction = (fileData:IFileTypes) => {
    if (getValues('uploadFiles') === undefined) {
      setValue('uploadFiles', [fileData]);
    } else {
      setValue('uploadFiles', [...getValues('uploadFiles'), fileData]);
    }
    setUploadFiles(getValues('uploadFiles'));
    props.adSetDataFunction(getValues());
  };

  const deleteFilesFunction = (deleteFileData:IFileTypes[]) => {
    setValue('uploadFiles', deleteFileData);
    props.adSetDataFunction(getValues());
  };
  useEffect(() => {
    setValue('uploadId', props.uploadId);
    if (props.ggCustomerId !== '') { // 선택된 광고주가 있을 경우
      $('#comNm').removeAttr('disabled');
      $('#ggSegment').removeAttr('disabled');
      // $('#multiFileUploaderText').removeAttr('disabled');
      setShowUploadFile(true);
      // $('#ggLogoSingFileUploadDiv').removeClass('disabled');
      // $('#ggLogoSingFileUpload').removeAttr('disabled');
      setValue('comNm', '와디즈');
    }
    // 초기값
    const result: any[] = [{ id: '', text: '' }];
    $('#ggSegment').empty();
    getSegmentsData.refetch().then((r) => {
      if (r.isSuccess) {
        r.data.data.map((option) => {
          result.push({ id: option.segmentResourceName, text: option.segmentName });
          return result;
        });
        $('#ggSegment').select2({
          // allowClear: true,
          // tags: true,
          width: '330px',
          // minimumResultsForSearch: Infinity,
          placeholder: '타겟으로 설정할 잠재 세그먼트를 선택해 주세요.',
          data: result,
        });
        if (getValues('segment') !== '') {
          $('#ggSegment').val(getValues('segment')).trigger('change');
        }
      }
    });

    if (getValues('comNm') !== undefined) {
      $('#comNmByte').text(getByteFunction(getValues('comNm')));
    }
  }, [props.ggCustomerId]);

  useEffect(() => {
    setTabOrder(props.changeOrder);
    if (props.datas !== undefined) {
      props.datas.map((mapData:ggAdSet) => {
        if (mapData.adSetOrder === props.changeOrder) {
          setValue('comNm', mapData.comNm);
          setValue('uploadFiles', mapData.uploadFiles);
          setUploadFiles(mapData.uploadFiles);
          setValue('segment', mapData.segment);
          return mapData;
        }
        return mapData;
      });
    }

    if (props.ggCustomerId !== '') { // 선택된 광고주가 있을 경우
      $('#comNm').removeAttr('disabled');
      $('#ggSegment').removeAttr('disabled');
      setShowUploadFile(true);
      // $('#ggLogoSingFileUploadDiv').removeClass('disabled');
      // $('#ggLogoSingFileUpload').removeAttr('disabled');
      if (getValues('comNm') === '') {
        setValue('comNm', '와디즈');
      }
    }
    setValue('adSetOrder', props.changeOrder);

    $('#ggSegment').select2({
      // allowClear: true,
      // tags: true,
      width: '330px',
      // minimumResultsForSearch: Infinity,
      placeholder: '타겟으로 설정할 잠재 세그먼트를 선택해 주세요.',
    }).on('change', () => {
      const multiData = $('#ggSegment').val();
      setValue('segment', multiData);
      // 셀렉트 변경시 데이터전달
      props.adSetDataFunction(getValues());
    });
    // $('#comNmByte').text(getByte(getValues('comNm')));
  }, [props.changeOrder]);

  const ChangeLogoFiles = useCallback(() => <MultiFileUploaderGgLogo showUploadFile={showUploadFile} uploadId={props.uploadId} adSetOrder={props.changeOrder} uploadFiles={getValues('uploadFiles')} uploadFilesFunction={uploadFilesFunction} deleteFilesFunction={deleteFilesFunction}/>, [uploadFiles, props.changeOrder, props.uploadId, showUploadFile]);

  // 업체명 변경시 데이터 전달
  let comNm = '';
  const dataChangeEvent = useCallback((id:any, value:any) => {
    const byte = getByteFunction(value);
    if (byte <= 25) {
      comNm = value;
      setValue(id, value);
      props.adSetDataFunction(getValues());
      $('#comNmByte').text(byte);
    }
    setValue(id, comNm);
  }, []);

  return (
    <Fragment>
        <div className="comp-tab-content selected">
            <div className="tbl">
                <ChangeLogoFiles/>
                <dl className="vertical">
                    <dt>
                        <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        업체명
                                        <i className="fz-12 fc-5">*</i>
                                    </span>
                        </div>
                    </dt>
                    <dd>
                        <div className="form-group">
                            <div className="input-group expand" id={`comNm${tabOrder}`}>
                                <div className="inner-input-group">
                                    <input type="text" className="tf-comm" id="comNm" {...register('comNm')} placeholder="업체명을 입력해 주세요."
                                           onChange={(event) => dataChangeEvent(event.currentTarget.id, event.currentTarget.value)} disabled/>
                                </div>
                                <p className="fz-12 fc-3"><span className="fc-2" id="comNmByte">0</span> / 25</p>
                                {/* <p className="fz-12">체크 / 에러 문구 내용 영역</p> */}
                            </div>
                        </div>
                    </dd>
                </dl>
                <AddSegment/>
            </div>
        </div>
    </Fragment>
  );
};

export default MngMdGgAdSet;
