import { useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { fetchAIInspectionList } from '@biz/api'

export const useAIInspectionListQuery = ({ params }: { params: string | null }) => {
  const queryClient = useQueryClient()
  const queryKey = ['@app/biz-center/AIInspectionFilter/AIInspectionHistory', params]
  return {
    ...useQuery({
      queryKey,
      queryFn: () => (!Boolean(params) ? Promise.reject() : fetchAIInspectionList(params).then((res) => {
        if (res?.ok === true) {
          return res?.data || []
        }
        if(res?.ok === false) {
          const {  msg } = res?.data as unknown as { title: string, msg: string } || { title: '', msg: '' }
          toast.error(`${msg}`)
          return []
        }
      })),
      enabled: Boolean(params),
      staleTime: 500,
      gcTime: 500,
    }),
    update: () => queryClient.invalidateQueries({ queryKey }),
  }
}
