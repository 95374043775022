import { shadowToken as platformShadowToken } from '../../../center/platform-query/src/_hooks/authToken'

const checkIsMakerOrShadowLoginInBiz = () => {
  // 비즈센터 아닌 경우
  if (!window.location.host.includes('biz.wadiz.kr')) {
    return true
  }

  const info = localStorage.getItem('user')

  // 어드민 로그인 시 userInfo 저장 (AdminLoginPage/Login.tsx)
  if (info !== null) {
    const userInfo = JSON.parse(info)
    if (userInfo.ok) {
      if (userInfo.data.authorities.indexOf('ROLE_SHADOW') >= 0) {
        // 쉐도우 로그인 권한 가진 관리자 (슈퍼 관리자) - 실제 메이커의 광고 만들기 가능하므로 수집 O
        return true
      } else if (userInfo.data.authorities.indexOf('ROLE_ADMIN_ACTION') >= 0) {
        // 쉐도우 로그인 권한없는 관리자 - 수집 X
        return false
      }
      if (userInfo.data.roleGroup === 'USER_MGR') {
        // 쉐도우 로그인 권한없는 관리자 - 수집 X
        return false
      }
    }
  }

  // 메이커 로그인 시 userInfo 없음
  return true
}

interface TrackingBrazeParams {
  type: 'event' | 'attr'
  name: string
  value?: any
}
/**
 * Braze 수집
 * @param type Tracking 타입
 * @param name 이름
 * @param value 값
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trackingBraze = ({ type, name, value }: TrackingBrazeParams) => {
  // 비즈센터에서는 메이커 로그인 또는 쉐도우 로그인 한 경우에만 데이터 수집
  const isMakerOrShadowLoginInBiz = checkIsMakerOrShadowLoginInBiz()

  if (!window.appboy || !isMakerOrShadowLoginInBiz) {
    return
  }

  try {
    if (type === 'event') {
      window.appboy.logCustomEvent(name, value)
    }

    if (type === 'attr') {
      if (typeof window.appboy.getUser === 'function') {
        window.appboy.getUser().setCustomUserAttribute(name, value)
      }
    }
  } catch (e) {}

  return null
}
