import { ReactComponent as StartIcon } from '@common/svg/star.svg'
import styles from './Star.module.scss'

type StarFunction = {
  children?: React.ReactNode
}
export const Star: React.FC<StarFunction> = ({ children }) => (
  <div className={styles.containStarComment}>
    {children}
    <StartIcon />
  </div>
)

