import React, { forwardRef, useRef, useState, useMemo, useEffect } from 'react'
import { HelpOIcon, HelpIcon } from '@wadiz-frontend/waffle-icons-modified'
import cx from 'classnames'
import { uniqueId } from 'lodash-es'

import MessageBox from './TooltipMessageBox'

import styles from './Tooltip.module.scss'
import useOutsideClickRef from '../useOutsideClickRef'

export type Placement = 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end'

export interface Props {
  /** 툴팁 아이디 */
  id?: string

  /** 툴팁 라벨 */
  label?: string

  /** label 블라인드 텍스트 처리 **/
  isBlindText?: boolean

  /** 툴팁 표시 위치 */
  placement?: Placement

  /** 마우스 오버 시 툴팁 표시 여부 (터치 환경은 적용 안 함) */
  hover?: boolean

  className?: string

  children: React.ReactNode

  /** 메시지박스 넓이 **/
  messageWidth?: number

  /** 메시지박스 zIndex */
  portalZIndex?: number
}

export const Tooltip = forwardRef<HTMLButtonElement, Props>(
  (
    {
      id,
      label,
      isBlindText,
      placement = 'bottom-start',
      hover = false,
      className,
      messageWidth,
      portalZIndex,
      children,
    },
    ref
  ) => {
    const [button, setButton] = useState<HTMLButtonElement | null>(null)
    const iconRef = useRef<HTMLSpanElement>(null)
    const messageBoxRef = useRef<HTMLDivElement>(null)
    const rootRef = useOutsideClickRef({
      callback: () => setButton(null),
    })

    const isHover = useMemo(() => typeof window !== 'undefined' && !('ontouchstart' in window) && hover, [hover])

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (event.type === 'mouseenter' && !isHover) return

      if (iconRef) {
        setButton(button ? null : event.currentTarget)
      }
    }

    const handleClose = (event?: React.MouseEvent<HTMLElement>) => {
      if (event && event.type === 'mouseleave' && !isHover) return
      setButton(null)
    }

    const uid = useMemo(() => {
      if (id) {
        return id
      }
      return uniqueId('Tooltip_')
    }, [id])

    const isOpened = Boolean(button)

    return (
      <div ref={rootRef}>
        <button
          ref={ref}
          type="button"
          className={cx(styles.button, label && styles.withLabel, className, { [styles.open]: isOpened })}
          aria-describedby={uid}
          onClick={handleOpen}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
        >
          {label !== undefined && (
            <span className={cx(styles.label, { [styles.blindText]: isBlindText })}>{label}</span>
          )}
          <span ref={iconRef} className={styles.helpIconWrap}>
            <HelpOIcon className={styles.helpOutlineIcon} />
            <HelpIcon className={styles.helpIcon} />
          </span>
        </button>
        <MessageBox
          id={uid}
          refObject={messageBoxRef}
          portalZIndex={portalZIndex}
          isOpened={isOpened}
          button={button}
          target={iconRef ? iconRef.current : null}
          placement={placement}
          messageWidth={messageWidth}
        >
          {typeof children === 'function' ? (children as Function)() : children}
        </MessageBox>
      </div>
    )
  }
)

export default Tooltip
