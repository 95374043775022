import * as ReactDOM from 'react-dom/client'
import App from './app/App'
import { LicenseManager } from 'ag-grid-enterprise'
import { initializeBraze } from '@common/utils'

import './styles.scss'

initializeBraze()

// TODO: VITE ENV 테스트
// LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_ENTERPRISE_LICENSE_KEY)
LicenseManager.setLicenseKey(
  'CompanyName=11h11m,LicensedGroup=11h11m,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-029565,SupportServicesEnd=22_June_2023_[v2]_MTY4NzM4ODQwMDAwMA==738dcf91bc827ec63df36f855d59d545'
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
