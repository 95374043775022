import { useQuery, type UseQueryResult, type QueryClient } from '@tanstack/react-query'
import { fetchAdminConfirmState, type AdminConfirmStateType } from '@biz/api'

const makeQueryKey = (creationDataId: number) => ['@apps/biz-center/AdminConfirmState', creationDataId]
type UseFunctionType = ({ creationDataId }: { creationDataId: number }) => UseQueryResult
export const useAdminConfirmStateQuery: UseFunctionType = ({ creationDataId }) =>
  useQuery({
    queryKey: makeQueryKey(creationDataId),
    queryFn: () => fetchAdminConfirmState(creationDataId).then((res) => res?.data),
    enabled: Boolean(creationDataId),
    refetchOnWindowFocus: 'always',
  })

export const updateAdminConfirmStateCache = (
  queryClient: QueryClient,
  creationDataId: number,
  data: AdminConfirmStateType
) => {
  queryClient.setQueryData(makeQueryKey(creationDataId), { ...data, creationDataId })
}
