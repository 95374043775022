import { useState } from 'react'
import { Button, Popover } from 'antd'
import { ReactComponent as ChevronDownIcon } from '@common/svg/chevronDown.svg'
import styles from './PopoverButton.module.scss'

type PopoverButtonFunction = {
  children?: React.ReactNode
  caption?: React.ReactNode
  className?: string
}
export const PopoverButton: React.FC<PopoverButtonFunction> = ({ caption, children, className }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <Popover
      content={children}
      trigger="click"
      open={isOpen}
      onOpenChange={setOpen}
      placement="bottomLeft"
      arrow={false}
    >
      <Button
        onClick={() => setOpen(!isOpen)}
        className={`${className} ${isOpen ? styles.selectedButton : styles.buttonContent}`}
      >
        <div>{caption}</div>
        <ChevronDownIcon className={`${styles.icon}  ${isOpen ? styles.openIcon : ''}`} />
      </Button>
    </Popover>
  )
}
