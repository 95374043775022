import { useState, useEffect } from 'react'
import { Form } from 'antd'
import { useParams } from 'react-router'
import { alertify } from 'alertifyjs'
import dayjs from 'dayjs'
import { useLoaderData } from 'react-router'
import { trackingBraze } from '@common/utils'
import { GlobalSpin } from '@platform/share/ui'
import { type ValidCheckInfoType } from '@biz/api'
import { useCampaignValidQuery } from '@biz/query'
import { InspectionBypassModal, targetFormRegistry } from '@biz/ui'
import { useCreationValidQuery, useCreationStatusMutation } from './_queries'
import type { CampaignInfoTabFieldsType } from '../../../../CampaignInfoTab'

const { CreativeAssetsName } = targetFormRegistry

type ContentInspectionFunction = {
  isOpen: boolean
  creationDataId: number
  onClose: () => void
}
export const ContentInspection: React.FC<ContentInspectionFunction> = ({ isOpen, creationDataId, onClose }) => {
  const params = useParams();
  const form = Form.useFormInstance()
  const confirmData = form.getFieldsValue()
  const { initData } = useLoaderData() as {
    initData: CampaignInfoTabFieldsType
  }

  const creativeAssets = confirmData?.[CreativeAssetsName]
  const isValidReady = isOpen === true && (creativeAssets || []).length > 0

  const useCampaignValidQueryResult = useCampaignValidQuery(isValidReady, creationDataId)
  const { data: res, isLoading: isValidDataLoading } = useCampaignValidQueryResult
  const { data: validData, ok: validation } = res || ({} as { ok: boolean; data: ValidCheckInfoType })
  const inChecking = isOpen === true && isValidDataLoading

  // 2. 문제 있는 키워드가 있다면, 그냥 진행할지 여부를 묻고 진행한다.
  const [isIgnore, setIgnore] = useState(false)
  const isIgnoreOpen = isOpen === true && !isValidDataLoading && validData?.catProhKwdValid === false && !isIgnore

  // 문제 키워드가 없으면 바로 다음 단계를 진행,
  const inCreationValid = Boolean(validData?.catProhKwdValid) || isIgnore
  const { data: creationValidData, isLoading: isCreationValidLoading } = useCreationValidQuery(
    inCreationValid,
    creationDataId
  )
  const isLoading = isOpen === true && isValidDataLoading

  const creationMutation = useCreationStatusMutation()
  useEffect(() => {
    if (isOpen && !isCreationValidLoading && !!creationValidData && !isIgnoreOpen) {
      const { creationCnt, creationCorrectCnt } = creationValidData
      if (creationCnt !== creationCorrectCnt) {
        alertify.error(`소재의 검수가 통과되지 않았습니다. <br/>소재ID : ${creationDataId}`)
        onClose()
      } else {
        // 소재 검수 요청 시작
        creationMutation.mutateAsync({ id: creationDataId }).then((result) => {
          // 검수 요청 완료한 경우 Braze 수집
          trackingBraze({
            type: 'event',
            name: 'biz_target_complete',
            value: {
              date: new Date().toISOString(),
              adname: confirmData?.campaignName || initData.campaignName,
              adID: params.campId,
              area: '페이스북/인스타그램', // FIXME: 추후 매체 추가 시 수정 필요
              project_no: initData.projectSelect.replace(/^[a-zA-Z]+/, ''), // 앞의 모든 알파벳 제거
              startAt: dayjs(confirmData?.range[0] || initData.range[0]).toISOString(),
              endAt: dayjs(confirmData?.range[1] || initData.range[1]).toISOString(),
            },
          })
          window.appboy?.requestImmediateDataFlush()

          onClose()
        })
      }
    }
  }, [isOpen, isIgnoreOpen, creationValidData, isCreationValidLoading, creationDataId, onClose])

  useEffect(() => {
    if (validation === false) {
      const { title, msg } = validData as unknown as { title: string; msg: string } // TODO 타입정리가 필요함.
      alertify.error(msg || '알려지지 않은 오류가 있습니다. ', { title })
      onClose()
    }
  }, [validation, validData])

  // useEffect(() => {
  //   // 쿼리의 enabled 옵션으로 최초 실행 여부를 결정하고 이후 쿼리 재실행은 refetch 함수를 사용해야 합니다.
  //   if (isValidReady) {
  //     console.log('refetch')
  //     useCampaignValidQueryResult.refetch()
  //   }
  // }, [isValidReady])

  return (
    <>
      <GlobalSpin loading={isLoading} />
      {!inChecking && (
        <InspectionBypassModal
          category={validData?.category || ''}
          keywords={validData?.catProhKwd || []}
          isOpen={isIgnoreOpen}
          onClose={onClose}
          onConfirm={() => setIgnore(true)}
        />
      )}
    </>
  )
}
