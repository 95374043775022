import { axios } from '../axios'
// (관리자)소재별 관리자 확인여부 조회 https://wadiz.atlassian.net/wiki/spaces/TF/pages/16490266772/-+API#%F0%9F%86%95(%EA%B4%80%EB%A6%AC%EC%9E%90)%EC%86%8C%EC%9E%AC%EB%B3%84-%EA%B4%80%EB%A6%AC%EC%9E%90-%ED%99%95%EC%9D%B8%EC%97%AC%EB%B6%80-%EC%A1%B0%ED%9A%8C
// (관리자)소재별 관리자 확인완료 처리 https://wadiz.atlassian.net/wiki/spaces/TF/pages/16490266772/-+API#%F0%9F%86%95-(%EA%B4%80%EB%A6%AC%EC%9E%90)%EC%86%8C%EC%9E%AC%EB%B3%84-%EA%B4%80%EB%A6%AC%EC%9E%90-%ED%99%95%EC%9D%B8%EC%99%84%EB%A3%8C-%EC%B2%98%EB%A6%AC
export type AdminConfirmStateType = {
  labelId: number // integer 라벨 아이디 (관리자 확인 여부 테이블의 키)
  regTime: string //  datetime 등록시간
  regId: number // integer 등록자 아이디
  creationDataId: number //  integer 소재 아이디
  creationId: number // 소재 아이디인가?
}
type ConfirmStateType = AdminConfirmStateType & {
  manualCheckYn: boolean //  boolean 확인 여부
}
type ReturnType = {
  ok: boolean
  data: ConfirmStateType | AdminConfirmStateType
}
export const fetchAdminConfirmState = (creationDataId: number): Promise<ReturnType> =>
  axios.get(`/api/admin/campaigns/creations/${creationDataId}/manualCheck`)

export const fetchPostAdminConfirmState = (creationDataId: number): Promise<ReturnType> =>
  axios.post(`/api/admin/campaigns/creations/${creationDataId}/manualCheck`)
