import React from 'react';

import './WYSIWYGEditorContent.scss';

export interface WYSIWYGEditorContentViewProps {
  content?: string;
}

export const WYSIWYGEditorContentView = React.forwardRef<HTMLDivElement, WYSIWYGEditorContentViewProps>(
  ({ content = '' }, ref) => {
    if (!content) {
      return null;
    }

    return (
      <div ref={ref} style={{ position: 'relative' }}>
        <div className="inner-contents fr-view" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    );
  },
);
