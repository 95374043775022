import { useState } from 'react'
import { Form } from 'antd'
import { CampaignCaption, targetFormRegistry } from '@biz/ui'
import { useUpdateCampaign } from '../_hooks/useUpdateCampaign'
import { LocationTypeSwitch } from './LocationTypeSwitch'
import { LocationInput } from './LocationInput'
import { NationSelect } from './NationSelect'
import nationsList from './NationSelect/dummyData'

const isLive = import.meta.env.VITE_ENV == 'prod'
const { LocationSelectorName, CountriesSelectorName } = targetFormRegistry
export const LocationSelector: React.FC = () => {
  const doUpdate = useUpdateCampaign()
  const [isKorea, setIsKorea] = useState(true)
  return (
    <div>
      {!isLive && (
        <CampaignCaption tooltip={'해당 국가 혹은 지역에 거주하거나 위치한 사람들에게 광고를 표시합니다.'}>
          {'국가'}
        </CampaignCaption>
      )}
      {!isLive && <LocationTypeSwitch value={isKorea} onChange={setIsKorea} />}
      {isKorea && (
        <>
          <CampaignCaption
            isSub
            tooltip={
              "대한민국 외 지역은 설정할 수 없어요. 해외 국가 타겟의 캠페인을 생성하고 싶다면 국가 유형에서 '대한민국 외 국가'를 선택해 주세요"
            }
          >
            {'지역'}
          </CampaignCaption>
          <Form.Item name={LocationSelectorName}>
            <LocationInput onChange={doUpdate} />
          </Form.Item>
        </>
      )}
      {!isLive && !isKorea && (
        <Form.Item name={CountriesSelectorName}>
          <NationSelect options={nationsList} />
        </Form.Item>
      )}
    </div>
  )
}
