import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { fetchAIHistoryCategory } from '@biz/api'

const queryKey = ['@biz/AIInspectionFilter/CategoryFIlter/Category']

type UseFunctionType = () => UseQueryResult<any>
export const useCategoryQuery: UseFunctionType = () =>
  useQuery({
    queryKey,
    queryFn: () => fetchAIHistoryCategory().then((res) => res.data),
  })
