import { Button, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ReactComponent as InfoIcon } from '@common/svg/info.svg'
import { useAdminConfirmStateQuery } from './useAdminConfirmStateQuery'
import { useAdminConfirmStateMutation } from './useAdminConfirmStateMutation'
import styles from './IDPanel.module.scss'

type CheckedType = {
  manualCheckYn: boolean
}
type IDPanelFunction = {
  campaignId: number
  creationDataId: number
  onItemChange?: () => void
}
export const IDPanel: React.FC<IDPanelFunction> = ({ campaignId, creationDataId, onItemChange }) => {
  const { data, isLoading } = useAdminConfirmStateQuery({ creationDataId })
  const mutation = useAdminConfirmStateMutation()
  const doConfirm = () => mutation.mutateAsync(creationDataId).then(() => onItemChange?.())
  const { manualCheckYn } = (data || {}) as CheckedType
  return (
    <div className={styles.containIDPanel}>
      <dl className={styles.info}>
        <div>
          <dt>캠페인 ID:</dt>
          <dd>{campaignId}</dd>
        </div>
        <div>
          <dt>소재 ID:</dt>
          <dd>{creationDataId}</dd>
        </div>
      </dl>
      {isLoading || mutation.isPending ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <div className={styles.noticeBox}>
          <div className={styles.infoMessage}>
            <InfoIcon />
            {manualCheckYn
              ? '관리자 확인이 완료된 소재입니다.'
              : `소재 확인이 완료되면 '확인 완료' 버튼을 눌러주세요. 관리자 확인은 한 번만 할 수 있으니 신중하게 확인해 주세요.`}
          </div>
          {!manualCheckYn && (
            <Button className={styles.button} onClick={doConfirm}>
              확인 완료
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
