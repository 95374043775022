import { Button } from 'antd'
import { ColumnType } from 'antd/es/table'

import { ReactComponent as SortIcon } from '@common/svg/sortUpDown.svg'
import { ReactComponent as SortUpIcon } from '@common/svg/sortUp.svg'
import { ReactComponent as SortDownIcon } from '@common/svg/sortDown.svg'

import type { InspectionType } from '@biz/api'

import styles from './columnsFactory.module.scss'

const inspectionTable: { [index: string]: string } = {
  all: '전체',
  ing: '검수중',
  ok: '승인',
  fail: '반려',
  error: '실패',
}
const inspectionStatusTable: { [index: string]: string } = {
  ing: '검수중',
  error: '실패',
  ok: '승인',
  fail: '반려',
}
const mediaTypeTable: { [index: string]: string } = {
  all: '전체',
  text: '텍스트',
  img: '이미지',
  movie: '영상',
  slide: '슬라이드',
}
const InspectionTypeTable: { [index: string]: string } = {
  aiApprove: 'AI 자동 심의',
  adminApprove: '관리자 심의',
}
const renderSortIcon = (() => {
  const icons = {
    ascend: <SortUpIcon />,
    descend: <SortDownIcon />,
  }
  return ({ sortOrder }: { sortOrder: 'ascend' | 'descend' | null }) => (!!sortOrder ? icons[sortOrder] : <SortIcon />)
})()

type FactoryType = (
  onColumn?: (select: 'preview' | 'ai-result' | 'team-result', field: any, data?: InspectionType) => void
) => ColumnType<any>[]
export const columnsFactory: FactoryType = (onColumn) => {
  return [
    {
      dataIndex: 'regTime',
      title: 'AI 검수 요청 일시',
      align: 'center',
      fixed: 'left',
      sorter: true,
      sortIcon: renderSortIcon,
    },
    {
      dataIndex: 'makerName',
      title: '메이커명',
      align: 'center',
      fixed: 'left',
    },
    {
      dataIndex: 'campId',
      title: '캠페인 ID',
      align: 'center',
      fixed: 'left',
      sorter: true,
      sortIcon: renderSortIcon,
    },
    {
      dataIndex: 'creationDataId',
      title: '소재 ID',
      align: 'center',
      fixed: 'left',
      sorter: true,
      sortIcon: renderSortIcon,
    },
    {
      dataIndex: 'inspectionMode',
      title: '심의 방식',
      align: 'center',
      render: (record) => InspectionTypeTable[record] || '-',
    },
    {
      dataIndex: 'catCodeDesc',
      title: '카테고리',
      align: 'center',
    },
    {
      dataIndex: 'creationDataInspHisId',
      title: '검수 요청 소재',
      align: 'center',
      render: (record, data) => (
        <Button type="text" onClick={() => onColumn?.('preview', record, data)} className={styles.linkButton}>
          미리보기
        </Button>
      ),
    },
    {
      dataIndex: 'isInspEqual',
      title: '검수 결과 일치 여부',
      align: 'center',
      render: (params: any) =>
        !!params ? <span style={{ color: '#495057' }}>일치</span> : <span style={{ color: '#f66' }}>불일치</span>,
    },
    {
      dataIndex: 'gptInspStatus',
      title: 'AI 검수 결과',
      align: 'center',
      render: (record, data) => (
        <Button type="text" onClick={() => onColumn?.('ai-result', record, data)} className={styles.linkButton}>
          {inspectionTable[record as string] || '-'}
        </Button>
      ),
    },
    {
      dataIndex: 'adminInspStatus',
      title: '관리자 검수 결과',
      align: 'center',
      render: (record, data) => (
        <Button type="text" onClick={() => onColumn?.('team-result', record, data)} className={styles.linkButton}>
          {inspectionStatusTable[record as string] || '-'}
        </Button>
      ),
    },
    {
      dataIndex: 'adminInspDiv',
      title: '관리자 검수 유형',
      align: 'center',
      render: (record: string) =>
        (record || '')
          .split(',')
          .map((n) => mediaTypeTable[n])
          .filter((n) => !!n)
          .join(',') || '-',
    },
    {
      dataIndex: 'adminManualCheckTime',
      title: '관리자 확인 일시',
      align: 'center',
      sorter: true,
      sortIcon: renderSortIcon,
    },
  ]
}
