import React, { forwardRef } from 'react';
import cx from 'classnames';
import styles from './NotificationBadge.module.scss';

type Size = 'sm' | 'md' | 'lg';
type Type = 'overlay' | 'default';

export interface NotificationBadgeProps {
  /**
   * Badge 크기
   */
  size?: Size;

  /**
   * Badge 타입
   */

  type?: Type;

  /**
   * Badge 표시될 값
   */
  value: number | string;

  /**
   * Badge 표시 될 최대값
   */
  maxValue?: number;

  /**
   * 클래스 명
   */
  className?: string;
}

const NotificationBadge = forwardRef<HTMLSpanElement, NotificationBadgeProps>(
  ({ value, size = 'md', type = 'default', maxValue = 99, className }, ref) => {
    // 숫자일 때만 1보다 작은지 체크
    if (typeof value === 'number' && value < 1) {
      return null;
    }

    let displayValue = typeof value === 'number' ? `${value}` : value;
    if (typeof value === 'number' && value > maxValue) {
      displayValue = `${maxValue}+`;
    }

    let badgeClasses = cx(
      styles.badge,
      {
        [styles.sm]: size === 'sm',
        [styles.md]: size === 'md',
        [styles.lg]: size === 'lg',
      },
      { [styles.overlay]: type === 'overlay' },
      className,
      displayValue.length === 1 && styles.circular
    );

    return (
      <span ref={ref} className={badgeClasses}>
        {displayValue}
      </span>
    );
  }
);

export default React.memo(NotificationBadge);
