import { useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query'

const queryKey = ['@biz/query/targetAdContext/useCurrentCampaignId']
export const setCurrentCampaignId = (queryClient: QueryClient) => async (value: number | null) =>
  queryClient.setQueryData(queryKey, value)

export const useCurrentCampaignIdQuery = () => {
  const queryClient = useQueryClient()
  return {
    ...useQuery({
      queryKey,
      queryFn: () => null,
      staleTime: Infinity,
      gcTime: Infinity,
    }),
    update: setCurrentCampaignId(queryClient),
  }
}

type LoadFunctionType = (queryClient: QueryClient) => () => Promise<number | undefined | null>
export const loadCurrentCampaignId: LoadFunctionType = (queryClient) => async () => queryClient.getQueryData(queryKey)
