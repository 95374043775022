/// <reference types="vite/client" />

/**
 * Vite, Webpack에서 환경 변수를 읽어오는 유틸 함수
 * @param key
 */
export const getEnv = (key: string): string | undefined => {
  if (typeof import.meta !== 'undefined' && import.meta.env) {
    return import.meta.env[`VITE_${key}`]
  } else if (typeof process !== 'undefined' && process.env) {
    return process.env[`REACT_APP_${key}`]
  }
  return undefined
}

/**
 * 현재 hostname에 따라 환경 변수를 반환하는 함수
 * */
export const getEnvByHost = () => {
  const host = window.location.hostname // rc-admin-business.wadiz.kr...

  // TODO: 추후 환경 추가될 경우 케이스 추가 필요
  if (host.startsWith('localhost')) {
    // 로컬에서 설정한 ENV 환경 변수 사용
    return getEnv('ENV')
  } else if (host.startsWith('rc2')) {
    return 'rc2'
  } else if (host.startsWith('rc')) {
    return 'rc'
  } else {
    return 'live'
  }
}
