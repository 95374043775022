

import type { InspectionType } from '@biz/api'

type InspectionTypeValues = InspectionType[keyof InspectionType]
type SortFn = (a: InspectionTypeValues, b: InspectionTypeValues) => number

// order: 'ascend' | 'descend'
const stringsSorter = [
  (a: InspectionTypeValues, b: InspectionTypeValues) =>
    a === undefined || b === undefined ? 0 : (a as string).localeCompare(b as string),
  (a: InspectionTypeValues, b: InspectionTypeValues) =>
    a === undefined || b === undefined ? 0 : (b as string).localeCompare(a as string),
]
const numbersSorter = [
  (a: InspectionTypeValues, b: InspectionTypeValues) => Number(a) - Number(b),
  (a: InspectionTypeValues, b: InspectionTypeValues) => Number(b) - Number(a),
]
const sortTable: { [K in keyof InspectionType]: SortFn[] } = {
  regTime: stringsSorter,
  makerName: stringsSorter,
  campId: numbersSorter,
  creationDataId: numbersSorter,
  inspectionMode: stringsSorter,
  catCodeDesc: stringsSorter,
  creationDataInspHisId: numbersSorter,
  creationDataGptHisId: numbersSorter,
  completeTime: stringsSorter,
  catDiv: stringsSorter,
  catCode: stringsSorter,
  labelId: numbersSorter,
  isInspEqual: numbersSorter,
  gptInspStatus: stringsSorter,
  adminInspStatus: stringsSorter,
  adminInspDiv: stringsSorter,
  adminManualCheckTime: stringsSorter,
}
export type SortFactory = (params: {
  field: keyof InspectionType
  order: 'ascend' | 'descend'
}) => SortFn

export const sorterFactory:SortFactory = ({ field, order }) => {
  const sortArr = sortTable[field] || stringsSorter
  return order === 'ascend' ? sortArr[0] : sortArr[1]
}
