import { axios } from '../axios'
// https://wadiz.atlassian.net/wiki/spaces/TF/pages/16686153729/-+AI+API#API-%EC%9A%94%EC%95%BD
export type InspectionType = {
  creationDataGptHisId: number // 1920670009
  creationDataId: number // 1920381128
  creationDataInspHisId: number // 1920381169
  campId: number // 1920381125
  regTime: string //'2025-01-06 14:56:54'
  completeTime: string //'2025-01-06 14:57:10'
  makerName: string //'메이커'
  catDiv: string //'DEFAULT'
  catCodeDesc: string //'테크·가전 > 웨어러블'
  catCode: string //'B0060'
  isInspEqual: boolean // true
  gptInspStatus: string //'ok'
  adminInspStatus: string //'ok'
  adminInspDiv: string // 'text'
  inspectionMode: string //  String 검수 모드 [1] aiApprove [2] adminApprove
  labelId: number // Long 라벨 ID (BE 에서 관리하는 데이터)
  adminManualCheckTime: string // LocalDateTime
}
type ReturnType = {
  ok: boolean
  data: InspectionType[]
}
export const fetchAIInspectionList = (params: string | null): Promise<ReturnType> =>
  params === null ? Promise.reject('null return') : axios.get(`/api/mngAd/gptHis/list?${params}`)
